import React from 'react';
import { Modal, Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../utils/analytics';

const ConfirmDialog = ({
    openDialog,
    titleLabel,
    children,
    okBtnText,
    onOkClicked,
    cancelBtnText,
    secondaryButtonClicked,
    onCancelClicked,
    showCloseButton = true,
    titleComponent
}) => {
    const { t } = useTranslation();
    const title = t(titleLabel) || titleLabel;
    const okButtonText = okBtnText ? okBtnText : t('global.next');
    const cancelButtonText = cancelBtnText ? cancelBtnText : t('global.l_button_cancel');

    return (
        <Modal
            open={openDialog}
            backdrop="static"
            onClose={onCancelClicked}
            onExit={() => analytics.trackDialog(titleLabel, false)}
            onEnter={() => analytics.trackDialog(titleLabel, true)}
            keyboard={false}>
            <Modal.Header closeButton={showCloseButton}>
                <Modal.Title as={titleComponent}>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button onClick={secondaryButtonClicked || onCancelClicked} appearance="subtle">
                    {cancelButtonText}
                </Button>
                <Button onClick={onOkClicked} appearance="primary" autoFocus>
                    {okButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDialog;
