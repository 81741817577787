import React from 'react';
import LabeledInputWithSymbol from './LabeledInputWithSymbol';
import { Divider, IconButton, Stack } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { formatNumber } from './NumericFormatter';
import AttachedToggleInput from './AttachedToggleInput';

const AddNewCost = ({ pop, type, rule, data, setData, isSmallBusiness }) => {
    const { t } = useTranslation();
    const propertyTypes = {
        kitchen: t('create_forecast.l_kitchen'),
        garage: t('create_forecast.l_garage'),
        misc: t('create_forecast.l_other'),
        renovation: t('create_forecast.l_renovation')
    };
    return (
        <>
            <Divider />
            <Stack className="additional-cost-item">
                <h3 className="page-subtitle">Kostenpunkt {propertyTypes[type]}</h3>
                <IconButton icon={<FontAwesomeIcon icon={faTrash} />} onClick={pop} />
            </Stack>
            <LabeledInputWithSymbol
                label={t('create_forecast.l_cost_item_value')}
                type="number"
                infoText={t('create_forecast.i_cost_item_value')}
                symbolIcon="€"
                name={'l_purchase_price_' + type}
                rule={rule}
                helpText={
                    isSmallBusiness
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.['l_purchase_price_' + type]
                                  ? data['l_purchase_price_' + type] -
                                        data['l_purchase_price_' + type] / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
            />
            <AttachedToggleInput
                label={t('create_forecast.l_relevant_for_real_estate_transfer_tax')}
                onChange={(state) =>
                    setData({ ...data, ['included_in_l_real_estate_transfer_tax_' + type]: state })
                }
                defaultChecked={
                    data['included_in_l_real_estate_transfer_tax_' + type] !== undefined
                        ? data['included_in_l_real_estate_transfer_tax_' + type]
                        : true
                }
            />
        </>
    );
};

export default AddNewCost;
