import React from 'react';
import { FlexboxGrid } from 'rsuite';

const ClickInput = ({ label, labelClass, onClick, actionName, actionClass }) => {
    const labelColSpan = actionName ? 10 : 24;
    const actionColSpan = actionName ? 14 : 0;
    return (
        <FlexboxGrid className="click-input" data-testid="click-input">
            <FlexboxGrid.Item colspan={labelColSpan}>
                <p className={labelClass}>{label}</p>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={actionColSpan}>
                {actionName && (
                    <span onClick={onClick} className={`action cursor-pointer ${actionClass}`}>
                        {actionName}
                    </span>
                )}
            </FlexboxGrid.Item>
        </FlexboxGrid>
    );
};

export default ClickInput;
