import signUnauthorizedOut from './signUnauthorizedOut';

export default (url, type, body, stringifyBody = true) => {
    let headers = {
        Accept: 'application/json',
        'Access-Control-Allow-Origin':
            (process.env.REACT_APP_LOCAL_SERVER || 'http://localhost:3000') + '/'
    };

    if (stringifyBody) {
        headers['Content-Type'] = 'application/json';
    }
    return fetch(url, {
        method: type,
        body: stringifyBody ? JSON.stringify(body) : body,
        headers: headers,
        credentials: 'include'
    })
        .then((resp) => resp.json())
        .then((resp) => {
            if (resp?.redirect) {
                return signUnauthorizedOut();
            }
            return resp;
        });
};
