import React from 'react';
import { Toggle } from 'rsuite';

const AttachedToggleInput = ({
    label,
    defaultChecked,
    labelClassname = 'label',
    toggleClassname = 'toggle',
    onChange
}) => {
    return (
        <div className="attached-toggle-input">
            <p className={`attached-toggle-input-${labelClassname}`}>{label}</p>
            <Toggle
                className={`attached-toggle-input-${toggleClassname}`}
                defaultChecked={defaultChecked}
                onChange={onChange}
            />
        </div>
    );
};

export default AttachedToggleInput;
