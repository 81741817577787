import React from 'react';
import InputBase from './InputBase';
import LogoUpload from './LogoUpload';

const UploadLogoInput = ({ label, infoText, infoTextTooltip, className }) => {
    return (
        <InputBase
            label={label}
            infoText={infoText}
            infoTextTooltip={infoTextTooltip}
            className={className}>
            <LogoUpload />
        </InputBase>
    );
};

export default UploadLogoInput;
