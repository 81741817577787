import React, { Fragment, useEffect, useState } from 'react';
import ContentPageBase from '../../components/ContentPageBase';
import { Divider, Dropdown, Stack, IconButton, SelectPicker } from 'rsuite';
import CreateOrEditProperty from './CreateOrEditProperty';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    setOpenedPropertyForecast,
    setProperties,
    setCurrentProperties,
    setCurrentProperty,
    getProperties,
    setCurrentTaxsubject,
    setRequestMessage,
    setRequestFinished
} from '../../reducers/userSlice';
import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
import { useTranslation } from 'react-i18next';
import AlertDialog from '../../components/dialogs/AlertDialog';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import PlusIcon from '@rsuite/icons/Plus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTableColumns,
    faEdit,
    faUser,
    faPlus,
    faTrash,
    faEllipsisH
} from '@fortawesome/pro-solid-svg-icons';
import subscriptionLimit from '../../utils/subscriptionLimit';
import PropertyDashboard from './PropertyDashboard';
import LockableButton from '../../components/LockableButton';
import constants from '../../utils/constants';
import getTaxsubjectName from '../../utils/getTaxsubjectName';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import sendRequest from '../../utils/sendRequest';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';

const Properties = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);
    const properties = useSelector((state) => state.user.properties);
    const currentProperties = useSelector((state) => state.user.currentProperties);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);
    const taxSubjectsIDs = taxsubjects.map((taxSubject) => taxSubject._id);
    const user = useSelector((state) => state.user.user);

    const [showAddTaxsubjectMessage, setShowAddTaxsubjectMessage] = useState(false);
    const [editID, setEditID] = useState();
    const [ownerID, setOwnerID] = useState(undefined);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openLimitModal, setOpenLimitModal] = useState(false);
    const [openClonePropertyModal, setOpenClonePropertyModal] = useState(false);
    const [propertyToDelete, setPropertyToDelete] = useState({});
    const [taxSubjectsDropdownOptions, setTaxSubjectsDropdownOptions] = useState([]);

    const simplifiedDetailsMaxWidth = 750;

    useEffect(() => {
        setOpenDeleteModal(false);
        if (location.pathname === constants.ROUTES.PROPERTIES.LIST)
            analytics.trackPage(analyticsConstants.PAGES.PROPERTIES);
    }, [location.pathname]);

    useEffect(() => {
        if (taxSubjectsIDs.length === 0) {
            setShowAddTaxsubjectMessage(true);
            navigate(constants.ROUTES.PROPERTIES.LIST);
        } else {
            setShowAddTaxsubjectMessage(false);
        }
        dispatch(getProperties(taxSubjectsIDs));
    }, [taxsubjects]);

    useEffect(() => {
        if (currentTaxsubject) {
            const currentPropertiesList = properties.filter(
                (property) => property._owner === currentTaxsubject._id
            );
            dispatch(setCurrentProperties(currentPropertiesList));
        } else {
            dispatch(setCurrentProperties(properties));
        }
    }, [currentTaxsubject, properties, dispatch, setCurrentProperties]);

    useEffect(() => {
        if (!taxsubjects) return;
        const taxSubjectsDropdownOptions = [];
        taxsubjects.forEach((taxSubject) => {
            taxSubjectsDropdownOptions.push({
                label: getTaxsubjectName(taxSubject),
                value: taxSubject?._id
            });
        });
        setTaxSubjectsDropdownOptions(taxSubjectsDropdownOptions);
    }, [taxsubjects, setTaxSubjectsDropdownOptions]);

    const getTaxSubjectNameById = (taxSubjectID) => {
        const taxSubject = taxsubjects.find((taxSubject) => taxSubject._id === taxSubjectID);
        if (!taxSubject) return '';
        return getTaxsubjectName(taxSubject);
    };

    const handleNewProperty = () => {
        setEditID(undefined);
        setOwnerID(undefined);
        navigate(constants.ROUTES.PROPERTIES.CREATE);
    };

    const handleEdit = (editID, ownerID) => {
        setEditID(editID);
        setOwnerID(ownerID);
        analytics.trackClick('edit_properties', analyticsConstants.CATEGORIES.PROPERTIES);
        navigate(constants.ROUTES.PROPERTIES.EDIT);
    };

    const openPropertyDashboard = (property) => {
        if (!property) return;
        dispatch(setCurrentProperty(property));
        navigate(constants.ROUTES.PROPERTIES.DASHBOARD);
    };

    const handleDelete = (deleteID) => {
        if (!deleteID) return;
        const propertyToDelete = properties.find((property) => property._id === deleteID);
        if (!propertyToDelete) return;
        setPropertyToDelete(propertyToDelete);
        setOpenDeleteModal(true);
    };

    const sendDeleteRequest = () => {
        const updatedProperty = {
            ...propertyToDelete,
            deleteflag: true
        };
        dispatch(setProperties({ data: updatedProperty, IDs: taxSubjectsIDs }));
    };

    const addTaxSubject = () => {
        navigate(constants.ROUTES.TAXSUBJECTS.CREATE);
        analytics.trackClick('add_taxsubject', analyticsConstants.CATEGORIES.TAX_SUBJECTS);
    };

    return (
        <>
            {showAddTaxsubjectMessage ? (
                <ContentPageBase className="add-taxsubject-message">
                    <p className="no-properties-msg">
                        {t('list_property.l_no_tax_subject_message')}
                    </p>
                    <IconButton size="sm" icon={<PlusIcon />} onClick={addTaxSubject}>
                        {t('list_tax_subject.l_add_new_tax_subject')}
                    </IconButton>
                </ContentPageBase>
            ) : location.pathname === constants.ROUTES.PROPERTIES.DASHBOARD ? (
                <PropertyDashboard handleEdit={handleEdit} />
            ) : location.pathname === constants.ROUTES.PROPERTIES.CREATE ||
              location.pathname === constants.ROUTES.PROPERTIES.EDIT ? (
                <CreateOrEditProperty
                    editID={editID}
                    ownerID={ownerID}
                    taxSubjectsIDs={taxSubjectsIDs}
                />
            ) : (
                <ContentPageBase className="property-page">
                    <Stack justifyContent="space-between">
                        <SelectPicker
                            className="tax-subject-dropdown"
                            size="md"
                            data={taxSubjectsDropdownOptions}
                            placeholder={t('list_property.l_filter_tax_subject')}
                            renderValue={(value, item, selectedElement) => (
                                <>
                                    {<FontAwesomeIcon className="rs-icon" icon={faUser} />}
                                    {selectedElement}
                                </>
                            )}
                            value={currentTaxsubject ? currentTaxsubject._id : null}
                            searchable={false}
                            onChange={(value) => {
                                const selectedTaxSubject = taxsubjects.find(
                                    (taxSubject) => taxSubject._id === value
                                );
                                dispatch(setCurrentTaxsubject(selectedTaxSubject));
                                analytics.trackChangeTaxSubject(selectedTaxSubject?._id);
                            }}
                        />
                        <LockableButton
                            buttonLabel={t('create_property.l_add_new_property')}
                            dialogBody={t('list_property.l_plan_limit_reached').replace(
                                '{{plan}}',

                                constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE[
                                    Object.keys(
                                        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE
                                    ).filter(
                                        (item) =>
                                            constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE[
                                                item
                                            ].value === user?.subscriptionData?.plan_name
                                    )?.[0]
                                ]?.label
                            )}
                            dialogTitle={t('list_property.h_plan_limit_reached')}
                            jumpToPlansPage={() => navigate(constants.ROUTES.PLANS)}
                            isOpen={openLimitModal}
                            setIsOpen={setOpenLimitModal}
                            locked={
                                properties.filter((property) => property?.isTest === false)
                                    .length >=
                                subscriptionLimit(user?.subscriptionData?.plan_name, user)
                                    .properties
                            }
                            onClick={handleNewProperty}
                        />
                    </Stack>
                    <div className="entity-list-header">
                        <Stack className="property-list-header">
                            <Stack.Item flex={4}>{t('list_property.l_address_line')}</Stack.Item>
                            <Stack.Item flex={1}>{t('list_property.l_zip')}</Stack.Item>
                            {window.innerWidth > simplifiedDetailsMaxWidth && (
                                <Stack.Item flex={3}>{t('list_property.l_city')}</Stack.Item>
                            )}
                            <Stack.Item flex={3}>{t('list_property.l_tax_subject')}</Stack.Item>
                            {window.innerWidth > simplifiedDetailsMaxWidth && (
                                <Stack.Item flex={1.5}>
                                    {t('list_property.l_property_type')}
                                </Stack.Item>
                            )}
                            <Stack.Item flex={2}></Stack.Item>
                        </Stack>
                    </div>

                    <Divider className="entity-list-divider" />
                    {currentProperties.length > 0 ? (
                        currentProperties.map((property, index) => (
                            <Fragment key={index}>
                                <Stack
                                    className="entity-list-row"
                                    onClick={() => openPropertyDashboard(property)}>
                                    <Stack.Item flex={4}>
                                        {property?.isTest ? '(TEST) ' : ''}
                                        {property?.propertiesData?.l_address_line
                                            ? property.propertiesData.l_address_line
                                            : property?.propertiesData?.l_street +
                                              ' ' +
                                              property?.propertiesData?.l_house_number +
                                              (property?.propertiesData?.l_stair
                                                  ? '/' + property?.propertiesData?.l_stair
                                                  : '') +
                                              (property?.propertiesData?.l_door_number
                                                  ? '/' + property?.propertiesData?.l_door_number
                                                  : '')}
                                    </Stack.Item>
                                    <Stack.Item flex={1}>
                                        {property?.propertiesData?.l_zip}
                                    </Stack.Item>
                                    {window.innerWidth > simplifiedDetailsMaxWidth && (
                                        <Stack.Item flex={3}>
                                            {property?.propertiesData?.l_city}
                                        </Stack.Item>
                                    )}
                                    <Stack.Item flex={3}>
                                        {getTaxSubjectNameById(property._owner)}
                                    </Stack.Item>
                                    {window.innerWidth > simplifiedDetailsMaxWidth && (
                                        <Stack.Item flex={1.5}>
                                            {t(property?.propertiesData?.l_property_type)}
                                        </Stack.Item>
                                    )}
                                    <Stack.Item flex={2}>
                                        <Dropdown
                                            renderToggle={(props, ref) => (
                                                <IconButton
                                                    {...props}
                                                    ref={ref}
                                                    icon={<FontAwesomeIcon icon={faEllipsisH} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            className="actions-dropdown"
                                            placement="bottomEnd"
                                            trigger="hover"
                                            noCaret>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openPropertyDashboard(property);
                                                    analytics.trackClick(
                                                        'open_property_dashboard',
                                                        analyticsConstants.CATEGORIES.PROPERTIES
                                                    );
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faTableColumns}
                                                />
                                                {t('list_property.l_dashboard')}
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(property?._id, property?._owner);
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faEdit}
                                                />
                                                {t('list_property.l_edit')}
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    dispatch(
                                                        setOpenedPropertyForecast(
                                                            properties.indexOf(property).toString()
                                                        )
                                                    );
                                                    navigate(
                                                        constants.ROUTES.FORECAST_CALCULATION.HOME
                                                    );
                                                    analytics.trackClick(
                                                        'open_forecast_calculation',
                                                        analyticsConstants.CATEGORIES.PROPERTIES
                                                    );
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faPlus}
                                                />
                                                {t('list_property.l_create_forecast_calculation')}
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    analytics.trackClick(
                                                        'clone_property',
                                                        analyticsConstants.CATEGORIES.PROPERTIES
                                                    );
                                                    setOpenLimitModal(
                                                        properties.filter(
                                                            (property) => property?.isTest === false
                                                        ).length >=
                                                            subscriptionLimit(
                                                                user?.subscriptionData?.plan_name,
                                                                user
                                                            ).properties
                                                    );
                                                    if (
                                                        properties.filter(
                                                            (property) => property?.isTest === false
                                                        ).length <
                                                        subscriptionLimit(
                                                            user?.subscriptionData?.plan_name,
                                                            user
                                                        ).properties
                                                    ) {
                                                        setOpenClonePropertyModal(property);
                                                    }
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faClone}
                                                />
                                                {t('list_property.l_clone')}
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(property?._id);
                                                    analytics.trackClick(
                                                        'delete_property',
                                                        analyticsConstants.CATEGORIES.PROPERTIES
                                                    );
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faTrash}
                                                />
                                                {t('list_property.l_delete')}
                                            </DropdownItem>
                                        </Dropdown>
                                    </Stack.Item>
                                </Stack>

                                <Divider className="entity-list-divider" />
                            </Fragment>
                        ))
                    ) : currentTaxsubject ? (
                        <p className="no-properties-msg">
                            {t('list_property.l_empty_for_current_tax_subject_message')}
                        </p>
                    ) : (
                        <p className="no-properties-msg">{t('list_property.l_empty_message')}</p>
                    )}

                    <ConfirmDialog
                        titleLabel="Klonen bestätigen"
                        openDialog={openClonePropertyModal}
                        okBtnText={t('list_property.l_clone')}
                        onCancelClicked={() => setOpenClonePropertyModal(false)}
                        onOkClicked={() => {
                            sendRequest('/cloneProperty', 'post', {
                                property: openClonePropertyModal
                            }).then((resp) => {
                                if (resp.property) {
                                    dispatch(
                                        setRequestMessage({
                                            type: 'success',
                                            message: t('create_property.l_property_saved')
                                        })
                                    );
                                    dispatch(setRequestFinished(true));
                                    dispatch(getProperties(taxSubjectsIDs));
                                    handleEdit(resp.property._id, resp.property._owner);
                                } else {
                                    dispatch(setRequestFinished(true));
                                    dispatch(
                                        setRequestMessage({
                                            type: 'error',
                                            message: t('global.e_server_error')
                                        })
                                    );
                                }
                            });
                            setOpenClonePropertyModal(false);
                        }}
                        cancelBtnText={t('global.cancel')}>
                        {t('list_property.l_clone_property_dialog_body')}
                    </ConfirmDialog>
                    <AlertDialog
                        titleLabel="list_property.h_delete_property"
                        openDialog={openDeleteModal}
                        onOkClicked={() => {
                            sendDeleteRequest();
                            setOpenDeleteModal(false);
                            analytics.trackClick(
                                'delete_property',
                                analyticsConstants.CATEGORIES.PROPERTIES
                            );
                        }}
                        onCancelClicked={() => {
                            setOpenDeleteModal(false);
                            analytics.trackClick(
                                'cancel_delete_property',
                                analyticsConstants.CATEGORIES.PROPERTIES
                            );
                        }}>
                        <p>{t('list_property.m_delete_property_confirmation')}</p>
                    </AlertDialog>
                </ContentPageBase>
            )}
        </>
    );
};

export default Properties;
