import React, { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ScenarioCard from '../../components/ScenarioCard';
import ScenarioDataPage from '../scenarioPages/ScenarioDataPage';
import constants from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, SelectPicker, Placeholder, IconButton } from 'rsuite';
import { getProperties, getTaxsubjects, setOpenedPropertyForecast } from '../../reducers/userSlice';
import sendRequest from '../../utils/sendRequest';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import AddNewScenario from '../../components/AddNewScenario';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import ForecastQuickCheck from './ForecastQuickCheck';

const ForecastSidebar = ({ forecastTables, setForecastTables }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.user);
    const properties = useSelector((state) => state.user.properties);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const scenarios = useSelector((state) => state.user.scenarios);
    const activeScenarios = useSelector((state) => state.user.activeScenarios);
    const taxSubjects = useSelector((state) => state.user.taxsubjects);

    const [openScenarioPage, setOpenScenarioPage] = useState(false);
    const [firstProfitableYear, setFirstProfitableYear] = useState({});
    const [scenariosLoading, setScenariosLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const scenariosLocked = useMemo(
        () =>
            user.subscriptionData.plan_name ===
                constants.SUBSSCRIPTION_TYPE.INVALID_SUBSCRIPTION_TYPE.none.value ||
            user.subscriptionData.plan_name ===
                constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.basic.value,
        [user]
    );

    const propertyList = useMemo(
        () => [
            ...Object.keys(properties).map((item) => ({
                label:
                    (properties[item]?.isTest ? '(TEST) ' : '') +
                    (properties[item].propertiesData?.l_zip
                        ? properties[item].propertiesData?.l_zip + ', '
                        : '') +
                    (properties[item].propertiesData.l_address_line
                        ? properties[item].propertiesData.l_address_line
                        : properties[item].propertiesData.l_street) +
                    ' ' +
                    (properties[item].propertiesData?.l_house_number
                        ? properties[item].propertiesData?.l_house_number
                        : '') +
                    `${
                        properties[item].propertiesData?.l_stair
                            ? `/${properties[item].propertiesData.l_stair}`
                            : ''
                    }` +
                    `${
                        properties[item].propertiesData?.l_door_number
                            ? `/${properties[item].propertiesData.l_door_number}`
                            : ''
                    }`,
                value: item,
                key: item
            }))
        ],
        [properties]
    );

    useEffect(() => {
        if (taxSubjects.length === 0) {
            dispatch(getTaxsubjects());
        }
    }, [taxSubjects]);

    useEffect(() => {
        if (properties.length === 1) dispatch(setOpenedPropertyForecast('0'));
        else if (taxSubjects.length > 0 && properties.length === 0) {
            dispatch(getProperties(taxSubjects.map((item) => item._id)));
        }
    }, [properties]);

    let selectedTaxsubject = useMemo(
        () =>
            taxSubjects.filter((item) => item._id === properties?.[openedPropertyForecast]?._owner),
        [properties, openedPropertyForecast, taxSubjects]
    );

    const runCalculations = () => {
        setScenariosLoading(true);
        if (!properties?.[openedPropertyForecast]?._id) return;
        sendRequest('/forecastCalculations', 'post', {
            ID: properties?.[openedPropertyForecast]?._id,
            isSmallBusiness: selectedTaxsubject[0]?.taxsubjectsData?.l_small_business_scheme,
            tax_rate: selectedTaxsubject[0]?.taxsubjectsData?.tax_progression,
            preset: constants.CALCULATION_PRESETS.FORECAST
        }).then((resp) => {
            if (resp?.error) {
                if (forecastTables.length > 0) setForecastTables([]);
                console.debug('Missing data for forecast calculation:', resp?.error);
                setScenariosLoading(false);
            } else {
                let yearsUntilProfit = {};
                resp.result.map((table) => {
                    let tableArray = Object.values(table)[0];
                    if (tableArray[0]?.yearsUntilProfit === undefined) {
                        yearsUntilProfit = {
                            ...yearsUntilProfit,
                            [Object.keys(table)[0]]: '-'
                        };
                    } else
                        yearsUntilProfit = {
                            ...yearsUntilProfit,
                            [Object.keys(table)[0]]: tableArray[0].yearsUntilProfit
                        };
                });
                setTimeout(() => {
                    setScenariosLoading(false);
                    setForecastTables(resp.result);
                    setFirstProfitableYear(yearsUntilProfit);
                }, 100);
            }
        });
    };

    useEffect(() => {
        if (openedPropertyForecast && openedPropertyForecast !== '') {
            runCalculations();
        } else {
            if (Object.keys(firstProfitableYear).length > 0) setFirstProfitableYear({});
            if (forecastTables.length > 0) setForecastTables([]);
        }
    }, [openedPropertyForecast]);

    let customScenarios = useMemo(
        () =>
            scenarios.map((scenario, index) => ({
                ...scenario,
                color: constants.COLORS.scenarioColors[index]
            })),
        [scenarios]
    );

    let hobbyismLimit = useMemo(() => {
        return Object.values(
            Object.values(forecastTables && forecastTables.length > 0 ? forecastTables[0] : [])[0]
                ? Object.values(
                      forecastTables && forecastTables.length > 0 ? forecastTables[0] : []
                  )[0]
                : []
        )[0]?.year >= 2024
            ? properties[openedPropertyForecast]?.propertiesData?.l_type_of_rental ===
              constants.RENTAL_TYPES.LARGE
                ? 30
                : 25
            : properties[openedPropertyForecast]?.propertiesData?.l_type_of_rental ===
                constants.RENTAL_TYPES.LARGE
              ? 25
              : 20;
    }, [forecastTables, properties, openedPropertyForecast]);

    return (
        <div className="forecast-sidebar">
            {properties.length > 0 && openScenarioPage ? (
                <ScenarioDataPage
                    setOpenScenarioPage={setOpenScenarioPage}
                    openScenarioPage={openScenarioPage}
                    forecastDataComplete={forecastTables.length > 0}
                    activeScenarios={activeScenarios}
                    saveTriggered={runCalculations}
                />
            ) : (
                <>
                    {properties.length > 0 && (
                        <>
                            <SelectPicker
                                data={propertyList}
                                placeholder={'Objekt auswählen...'}
                                value={openedPropertyForecast}
                                className="property-selector"
                                searchable={false}
                                onChange={(item) => {
                                    if (item !== openedPropertyForecast)
                                        dispatch(setOpenedPropertyForecast(item));
                                    analytics.trackClick(
                                        'change_property',
                                        analyticsConstants.CATEGORIES.FORECAST_CALCULATION
                                    );
                                }}
                            />
                            <Divider />
                        </>
                    )}
                    <div className="scrollable-area">
                        {openedPropertyForecast &&
                            openedPropertyForecast !== '' &&
                            (forecastTables.length > 0 ? (
                                <>
                                    {scenariosLoading && (
                                        <Placeholder
                                            rowHeight={25}
                                            rowMargin={10}
                                            style={{
                                                marginTop: '1rem',
                                                marginLeft: '0.7rem'
                                            }}
                                            active
                                        />
                                    )}
                                    {!scenariosLoading && (
                                        <>
                                            {forecastTables?.[0]?.Prognoserechnung && (
                                                <ScenarioCard
                                                    color="#73dea8"
                                                    label="Prognoserechnung"
                                                    numberOfYears={
                                                        firstProfitableYear?.Prognoserechnung
                                                    }
                                                    activeScenarios={activeScenarios}
                                                    setOpenScenarioPage={setOpenScenarioPage}
                                                    forecastTables={forecastTables}
                                                    scenarios={scenarios}
                                                    limit={hobbyismLimit}
                                                />
                                            )}

                                            {forecastTables?.[1]?.['Ist Daten'] && (
                                                <ScenarioCard
                                                    color="#3b365c"
                                                    label="Aktuell"
                                                    tableKey="Ist Daten"
                                                    labelDetail="(inkl. Ist Daten)"
                                                    numberOfYears={
                                                        firstProfitableYear?.['Ist Daten']
                                                    }
                                                    activeScenarios={activeScenarios}
                                                    setOpenScenarioPage={setOpenScenarioPage}
                                                    forecastTables={forecastTables}
                                                    limit={hobbyismLimit}
                                                    scenarios={scenarios}
                                                />
                                            )}
                                            <div className="scenario-divider">
                                                <span>{t('forecast.l_other_scenarios')}</span>
                                                <Divider />
                                            </div>
                                            <div className="scenario-container">
                                                {customScenarios.map((scenario) => (
                                                    <ScenarioCard
                                                        id={scenario._id}
                                                        key={scenario._id}
                                                        locked={scenariosLocked}
                                                        color={scenario.color}
                                                        label={scenario.scenario_name}
                                                        numberOfYears={
                                                            firstProfitableYear?.[
                                                                scenario.scenario_name
                                                            ]
                                                        }
                                                        limit={hobbyismLimit}
                                                        activeScenarios={activeScenarios}
                                                        setOpenScenarioPage={setOpenScenarioPage}
                                                        forecastTables={forecastTables}
                                                        scenarios={scenarios}
                                                    />
                                                ))}
                                                <AddNewScenario
                                                    locked={scenariosLocked}
                                                    addScenario={() => {
                                                        setOpenScenarioPage({
                                                            scenario_name:
                                                                'Scenario ' +
                                                                parseInt(
                                                                    customScenarios.length + 1
                                                                ),
                                                            color: constants.COLORS.scenarioColors[
                                                                scenarios.length
                                                            ]
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : scenariosLoading ? (
                                <Placeholder
                                    rowHeight={25}
                                    rowMargin={10}
                                    style={{
                                        marginTop: '1rem',
                                        marginLeft: '0.7rem'
                                    }}
                                    active
                                />
                            ) : (
                                <ForecastQuickCheck
                                    isSmallBusiness={
                                        selectedTaxsubject[0]?.taxsubjectsData
                                            ?.l_small_business_scheme
                                    }
                                    runCalculations={runCalculations}
                                    setScenariosLoading={setScenariosLoading}
                                />
                            ))}
                    </div>
                    {openedPropertyForecast &&
                        openedPropertyForecast !== '' &&
                        forecastTables.length > 0 && (
                            <div className="forecast-sidebar-footer">
                                <Divider />
                                <IconButton
                                    appearance="primary"
                                    icon={<FontAwesomeIcon icon={faPen} />}
                                    size="md"
                                    className="edit-forecast-button"
                                    onClick={() => {
                                        analytics.trackClick(
                                            'edit_forecast',
                                            analyticsConstants.CATEGORIES.FORECASTS
                                        );
                                        navigate(constants.ROUTES.FORECAST_CALCULATION.FORECAST);
                                    }}>
                                    {t('forecast.l_edit_forecast_calculation')}
                                </IconButton>
                            </div>
                        )}
                </>
            )}
            <ConfirmDialog
                onCancelClicked={() => setDialogOpen(false)}
                openDialog={dialogOpen}
                titleLabel={t('forecast.h_upgrade_plan')}
                cancelBtnText={t('global.cancel')}
                okBtnText={t('account.l_change_plan')}
                onOkClicked={() => navigate(constants.ROUTES.PLANS)}>
                {t('forecast.l_upgrade_plan_message2')}
            </ConfirmDialog>
        </div>
    );
};
export default ForecastSidebar;
