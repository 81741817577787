import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, Stack, Divider, IconButton } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    setTaxsubjects,
    setCurrentTaxsubject,
    getProperties,
    setCurrentProperties,
    setOpenedPropertyForecast
} from '../../reducers/userSlice';
import AlertDialog from '../../components/dialogs/AlertDialog';
import ContentPageBase from '../../components/ContentPageBase';
import { AiFillDelete } from 'react-icons/ai';
import { BiSolidEditAlt } from 'react-icons/bi';
import { IoPersonAdd } from 'react-icons/io5';
import CreateInvitationModal from '../../components/CreateInvitationModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableColumns, faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import analyticsConstants from '../../utils/analyticsConstants';
import { analytics } from '../../utils/analytics';
import subscriptionLimit from '../../utils/subscriptionLimit';
import LockableButton from '../../components/LockableButton';
import constants from '../../utils/constants';
import getTaxsubjectName from '../../utils/getTaxsubjectName';

const TaxSubjectsList = ({ editTaxSubject }) => {
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedTaxSubject, setSelectedTaxSubject] = useState(null);
    const user = useSelector((state) => state.user.user);
    const taxSubjects = useSelector((state) => state.user.taxsubjects);
    const properties = useSelector((state) => state.user.properties);
    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const [invitationModalOpen, setInvitationModalOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.TAX_SUBJECTS);
        setInvitationModalOpen(false);
        setOpenDeleteModal(false);
    }, []);

    useEffect(() => {
        dispatch(getProperties(taxSubjects.map((item) => item._id)));
    }, [dispatch, getProperties, taxSubjects]);

    const getNumberOfProperties = (taxsubjectID) => {
        return properties.filter((property) => property._owner === taxsubjectID).length;
    };

    const handleInvite = (selectedID) => {
        setSelectedTaxSubject(taxSubjects.find((doc) => doc._id === selectedID));
        setInvitationModalOpen(true);
    };

    const handleDelete = (selectedID) => {
        setSelectedTaxSubject(taxSubjects.find((doc) => doc._id === selectedID));
        setOpenDeleteModal(true);
    };

    const sendDeleteRequest = () => {
        const updatedTaxSubject = {
            ...selectedTaxSubject,
            deleteflag: true
        };
        dispatch(setTaxsubjects({ data: updatedTaxSubject }));
        if (updatedTaxSubject._id === currentTaxsubject?._id) {
            dispatch(setCurrentTaxsubject(null));
        }
        if (properties[openedPropertyForecast]?._owner === updatedTaxSubject._id) {
            dispatch(setOpenedPropertyForecast(null));
        }
    };

    const handleOpenTaxsubjectDashboard = (taxsubject) => {
        dispatch(setCurrentTaxsubject(taxsubject));
        const currentPropertiesList = properties.filter(
            (property) => property._owner === taxsubject._id
        );
        dispatch(setCurrentProperties(currentPropertiesList));
        navigate(constants.ROUTES.TAXSUBJECTS.DASHBOARD);
    };

    return (
        <ContentPageBase className="taxsubjects-page">
            <CreateInvitationModal
                opened={invitationModalOpen}
                setOpened={setInvitationModalOpen}
                taxsubjectID={selectedTaxSubject && selectedTaxSubject._id}
                setSelectedTaxSubject={setSelectedTaxSubject}
            />
            <Stack justifyContent="flex-end">
                <LockableButton
                    buttonLabel={t('list_tax_subject.l_add_new_tax_subject')}
                    dialogBody={t('list_tax_subject.l_plan_limit_reached').replace(
                        '{{plan}}',

                        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE[
                            Object.keys(
                                constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE
                            ).filter(
                                (item) =>
                                    constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE[item]
                                        .value === user?.subscriptionData?.plan_name
                            )?.[0]
                        ]?.label
                    )}
                    dialogTitle={t('list_tax_subject.h_plan_limit_reached')}
                    jumpToPlansPage={() => navigate(constants.ROUTES.PLANS)}
                    locked={
                        taxSubjects.filter((taxsubject) => taxsubject?.isTest === false).length >=
                        subscriptionLimit(user?.subscriptionData?.plan_name, user).taxsubjects
                    }
                    onClick={() => {
                        dispatch(setCurrentTaxsubject());
                        navigate(constants.ROUTES.TAXSUBJECTS.CREATE);
                    }}
                />
            </Stack>

            <div className="entity-list-header">
                <Stack>
                    <Stack.Item style={{ width: '25%' }}>
                        {t('list_tax_subject.l_tax_subject_name')}
                    </Stack.Item>
                    <Stack.Item style={{ width: '25%' }}>
                        {t('list_tax_subject.l_company')}
                    </Stack.Item>
                    <Stack.Item style={{ width: '15%' }}>
                        {t('list_tax_subject.l_property_count')}
                    </Stack.Item>
                    <Stack.Item style={{ width: '23%' }}>
                        {t('list_tax_subject.l_tax_id')}
                    </Stack.Item>
                    <Stack.Item style={{ width: '12%' }}></Stack.Item>
                </Stack>
            </div>
            <Divider className="entity-list-divider" />

            {taxSubjects && taxSubjects.length > 0 ? (
                taxSubjects.map((taxsubject, index) => (
                    <Fragment key={index}>
                        <Stack
                            className="entity-list-row"
                            onClick={() => handleOpenTaxsubjectDashboard(taxsubject)}>
                            <Stack.Item style={{ width: '25%' }}>
                                <div className="tax-subjects-list-name-column">
                                    {taxsubject?.isTest ? '(TEST) ' : ''}
                                    {taxsubject?.taxsubjectsData?.l_firstname &&
                                        taxsubject?.taxsubjectsData?.l_firstname}
                                    {taxsubject?.taxsubjectsData?.l_lastname &&
                                        ' ' + taxsubject?.taxsubjectsData?.l_lastname}
                                </div>
                            </Stack.Item>
                            <Stack.Item style={{ width: '25%' }}>
                                {taxsubject?.taxsubjectsData?.l_company &&
                                    taxsubject?.taxsubjectsData?.l_company}
                            </Stack.Item>
                            <Stack.Item style={{ width: '15%' }}>
                                {getNumberOfProperties(taxsubject._id)}
                            </Stack.Item>
                            <Stack.Item style={{ width: '23%' }}>
                                {taxsubject?.taxsubjectsData?.tax_number}
                            </Stack.Item>
                            <Stack.Item style={{ width: '12%' }}>
                                <Dropdown
                                    renderToggle={(props, ref) => (
                                        <IconButton
                                            {...props}
                                            ref={ref}
                                            icon={<FontAwesomeIcon icon={faEllipsisH} />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    )}
                                    className="actions-dropdown"
                                    placement="bottomEnd"
                                    trigger="hover"
                                    noCaret>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            analytics.trackClick(
                                                'open_taxsubject_dashboard',
                                                analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                            );
                                            e.stopPropagation();
                                            handleOpenTaxsubjectDashboard(taxsubject);
                                        }}>
                                        <FontAwesomeIcon
                                            className="rs-icon"
                                            icon={faTableColumns}
                                        />
                                        {t('list_tax_subject.l_dashboard')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            analytics.trackClick(
                                                'edit_taxsubject',
                                                analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                            );
                                            e.stopPropagation();
                                            editTaxSubject(taxsubject._id);
                                        }}>
                                        <BiSolidEditAlt size="1.1em" />
                                        {t('list_tax_subject.l_edit')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        className={taxsubject?.isTest ? 'disabled' : ''}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (taxsubject?.isTest === false) {
                                                analytics.trackClick(
                                                    'invite_taxsubject',
                                                    analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                                );
                                                handleInvite(taxsubject._id);
                                            }
                                        }}>
                                        <IoPersonAdd size="1.1em" />
                                        {t('list_tax_subject.l_invite')}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={(e) => {
                                            analytics.trackClick(
                                                'delete_taxsubject',
                                                analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                            );
                                            e.stopPropagation();
                                            handleDelete(taxsubject._id);
                                        }}>
                                        <AiFillDelete size="1.1em" />
                                        {t('list_tax_subject.l_delete')}
                                    </Dropdown.Item>
                                </Dropdown>
                            </Stack.Item>
                        </Stack>
                        <Divider className="entity-list-divider" />
                    </Fragment>
                ))
            ) : (
                <p className="no-tax-subjects-message">{t('list_tax_subject.l_empty_message')}</p>
            )}

            <AlertDialog
                titleLabel="list_tax_subject.h_delete_tax_subject"
                openDialog={openDeleteModal}
                onOkClicked={() => {
                    sendDeleteRequest();
                    setOpenDeleteModal(false);
                    analytics.trackClick(
                        'delete_taxsubject',
                        analyticsConstants.CATEGORIES.TAX_SUBJECTS
                    );
                }}
                onCancelClicked={() => {
                    setOpenDeleteModal(false);
                    analytics.trackClick(
                        'cancel_delete_taxsubject',
                        analyticsConstants.CATEGORIES.TAX_SUBJECTS
                    );
                }}>
                <p>
                    {t('list_tax_subject.m_delete_tax_subject_confirmation', {
                        'tax-subject-name': getTaxsubjectName(selectedTaxSubject)
                    })}
                </p>
            </AlertDialog>
        </ContentPageBase>
    );
};

export default TaxSubjectsList;
