import React from 'react';
import { Toggle } from 'rsuite';
import InputBase from './InputBase';

const ToggleInput = ({
    label,
    defaultChecked,
    onToggle,
    disabled,
    autoFocus,
    infoText,
    hideInfoIcon,
    infoTextTooltip,
    isIndentedInput,
    className
}) => {
    return (
        <InputBase
            className={`toggle-input ${className}`}
            label={label}
            infoText={infoText}
            hideInfoIcon={hideInfoIcon}
            infoTextTooltip={infoTextTooltip}>
            <Toggle
                defaultChecked={defaultChecked}
                onChange={onToggle}
                disabled={disabled}
                autoFocus={autoFocus}
                isIndentedInput={isIndentedInput}
            />
        </InputBase>
    );
};

export default ToggleInput;
