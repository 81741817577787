import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Trans } from 'react-i18next';
import LoginContainer from '../components/LoginContainer';
import LoginFooterWithLink from '../components/LoginFooterWithLink';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';

const EmailSent = () => {
    const { email, type } = useParams();
    const translateKey =
        type == 'password'
            ? 'authentication.l_password_reset_email_sent_info'
            : 'authentication.l_confirmation_email_sent_info';

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.EMAIL_SENT);
    }, []);

    return (
        <LoginContainer footer={<LoginFooterWithLink />}>
            <div>
                <Trans i18nKey={translateKey} components={{ email: email }} />
            </div>
        </LoginContainer>
    );
};

export default EmailSent;
