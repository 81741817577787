import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from 'rsuite';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import constants from '../utils/constants';

const AddNewScenario = ({ addScenario, locked }) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <Stack
                onClick={() => (locked ? setDialogOpen(true) : addScenario())}
                className="scenario-card">
                <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ color: '#73DEA8' }}
                    className="colored-circle"
                />
                <Stack direction="column" alignItems="flex-start">
                    <Stack.Item className="scenario-card-title">
                        {t('forecast.l_new_scenario')}
                    </Stack.Item>
                    <Stack.Item className={`scenario-card-info`}>
                        <Stack justifyContent="space-between" spacing={10}>
                            Hinzufügen
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack>
            <ConfirmDialog
                onCancelClicked={() => setDialogOpen(false)}
                openDialog={dialogOpen}
                titleLabel={'Upgrade your plan'}
                cancelBtnText={t('global.cancel')}
                okBtnText={t('account.l_change_plan')}
                onOkClicked={() => navigate(constants.ROUTES.PLANS)}>
                {`You don't have access to scenarios. To have access please sign up to a plan`}
            </ConfirmDialog>
        </>
    );
};

export default AddNewScenario;
