import React, { useEffect, useMemo, useRef, useState } from 'react';
import LabeledInputWithSymbol from '../components/LabeledInputWithSymbol';
import DropdownInput from '../components/DropdownInput';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, IconButton, Stack } from 'rsuite';
import showValidationErrors from '../utils/showValidationErrors';
import sendRequest from '../utils/sendRequest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEllipsisH, faTrash } from '@fortawesome/free-solid-svg-icons';
import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
import { useDispatch } from 'react-redux';
import { setRequestFinished, setRequestMessage } from '../reducers/userSlice';
import constants from '../utils/constants';

const EditUserDialog = ({ state, setState, handleDelete, handleCancel, saveUserInfo }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).adminEditUser.objectTypeSchemaSpec;
    const formRef = useRef();
    const dispatch = useDispatch();
    const [data, setData] = useState();

    useEffect(() => {
        if (state.user && data?._id !== state.user?._id) setData(state.user);
    });

    const save = async () => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        if (hasError) {
            showValidationErrors(formRef.current.root);
            return;
        }
        const localData = { ...data };
        // removing 'subscriptionData.plan_name' created by name attribute in form.control
        delete localData['subscriptionData.plan_name'];
        if (!data?.subscriptionData?.plan_name.includes('custom')) {
            if (localData.l_costs) delete localData.l_costs;
            if (localData.l_property_limit) delete localData.l_property_limit;
            if (localData.l_tax_subject_limit) delete localData.l_tax_subject_limit;
        }
        if (state.user.onboardingPassed === false && data.onboardingPassed === true) {
            const trialEndDate = new Date();
            trialEndDate.setDate(trialEndDate.getDate() + 7);
            localData.trialEndDate = trialEndDate;
        }
        if (JSON.stringify(state.user) !== JSON.stringify(data)) saveUserInfo(localData);
        if (state.user?.subscriptionData?.plan_name !== data?.subscriptionData?.plan_name) {
            if (data?.subscriptionData?.plan_name.includes('custom'))
                sendRequest('/getCustomPriceId', 'post', localData).then((resp) => {
                    if (resp.success) {
                        dispatch(setRequestFinished(true));
                        dispatch(
                            setRequestMessage({
                                type: 'success',
                                message: t('admin.l_custom_price_created')
                            })
                        );
                    } else {
                        dispatch(setRequestFinished(true));
                        dispatch(
                            setRequestMessage({
                                type: 'error',
                                message: t('admin.e_custom_price_created')
                            })
                        );
                    }
                });
            sendRequest('/sendBrevoEmail', 'post', {
                PLAN: Object.values(constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE).find(
                    (option) => option.value === data?.subscriptionData?.plan_name
                ).label,
                email: localData.email,
                type: 5
            }).then((resp) => {
                if (resp.success) {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: t('admin_area.l_user_updated_email_sent')
                        })
                    );
                } else {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: t('admin.e_user_updated_email_sent')
                        })
                    );
                }
            });
        }
        setState({ open: false, user: undefined });
    };

    const userTypeOptions = useMemo(() => {
        let options = [
            ...Object.values(constants.USER_TYPES).map((type) => ({
                label: t(type),
                value: t(type)
            }))
        ];

        if (data?.userType === 'Admin') options.push({ label: 'Admin', value: 'Admin' });
        return options;
    }, [data?.userType]);

    return (
        <ConfirmDialog
            openDialog={state.open}
            onCancelClicked={() => setState({ open: false, user: undefined })}
            onOkClicked={save}
            nestedField={true}
            showCloseButton={false}
            titleComponent={() => (
                <Stack justifyContent="space-between">
                    <Stack.Item>
                        <h3>Benutzer bearbeiten</h3>
                    </Stack.Item>
                    <Dropdown
                        renderToggle={(props, ref) => (
                            <IconButton
                                {...props}
                                ref={ref}
                                icon={<FontAwesomeIcon icon={faEllipsisH} />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        )}
                        className="actions-dropdown"
                        placement="bottomEnd"
                        trigger="hover"
                        noCaret>
                        <DropdownItem
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(state?.user?._id);
                            }}>
                            <FontAwesomeIcon className="rs-icon" icon={faTrash} />
                            Löschen
                        </DropdownItem>
                        {state.user?.subscriptionData?.subscription_id && (
                            <DropdownItem
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleCancel(
                                        state.user?.subscriptionData?.subscription_id,
                                        state?.user?._id,
                                        1
                                    );
                                }}>
                                <FontAwesomeIcon className="rs-icon" icon={faBan} />
                                Plan stornieren
                            </DropdownItem>
                        )}
                    </Dropdown>
                </Stack>
            )}>
            <Form
                onChange={(object) =>
                    setData({ ...data, ...object, subscriptionData: data?.subscriptionData })
                }
                formDefaultValue={state.user}
                ref={formRef}
                checkTrigger="none">
                <LabeledInputWithSymbol
                    label={t('edit_user.l_firstname')}
                    type="text"
                    name="firstname"
                    rule={validationSchemas.l_firstname}
                    hideInfoIcon
                />
                <LabeledInputWithSymbol
                    label={t('edit_user.l_lastname')}
                    type="text"
                    name="lastname"
                    hideInfoIcon
                    rule={validationSchemas.l_lastname}
                />
                <LabeledInputWithSymbol
                    label={t('edit_user.l_email')}
                    type="text"
                    hideInfoIcon
                    name="email"
                    rule={validationSchemas.l_email}
                />
                <DropdownInput
                    label={t('edit_user.l_user_type')}
                    alignRight
                    name="userType"
                    hideInfoIcon
                    dropdownOptionReady
                    rule={validationSchemas.l_user_type}
                    dropdownOption={userTypeOptions}
                    placeholder={t('global.l_please_select')}
                    disabled={data?.userType === 'Admin'}
                    value={data?.userType}
                />
                <DropdownInput
                    label={t('edit_user.l_status')}
                    alignRight
                    name="onboardingPassed"
                    dropdownOptionReady
                    hideInfoIcon
                    rule={validationSchemas.l_status}
                    dropdownOption={[
                        { value: false, label: t('edit_user.l_status_locked') },
                        { value: true, label: t('edit_user.l_status_unlocked') }
                    ]}
                    placeholder={t('global.l_please_select')}
                    value={data?.onboardingPassed}
                />
                <DropdownInput
                    label={t('edit_user.l_plan')}
                    alignRight
                    name="subscriptionData.plan_name"
                    hideInfoIcon
                    dropdownOptionReady
                    disabled={state.user?.subscriptionData?.subscription_id}
                    dropdownOption={Object.values(
                        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE
                    )
                        .filter(
                            (option) =>
                                data?.userType === 'Admin' || option.label.includes(data?.userType)
                        )
                        .map((type) => ({
                            label: type.label,
                            value: type.value
                        }))}
                    onChange={(item) =>
                        setData({
                            ...data,
                            subscriptionData: { ...data.subscriptionData, plan_name: item }
                        })
                    }
                    placeholder={t('global.l_please_select')}
                    value={data?.subscriptionData?.plan_name}
                />
                {(data?.subscriptionData?.plan_name ===
                    constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.manager.value ||
                    data?.subscriptionData?.plan_name ===
                        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.enterprise.value) && (
                    <>
                        <LabeledInputWithSymbol
                            label={t('edit_user.l_costs')}
                            type="number"
                            name="l_costs"
                            hideInfoIcon
                            rule={validationSchemas.l_costs}
                        />
                        <LabeledInputWithSymbol
                            label={t('edit_user.l_property_limit')}
                            type="number"
                            name="l_property_limit"
                            hideInfoIcon
                            rule={validationSchemas.l_property_limit}
                        />
                        <LabeledInputWithSymbol
                            label={t('edit_user.l_tax_subject_limit')}
                            type="number"
                            name="l_tax_subject_limit"
                            hideInfoIcon
                            rule={validationSchemas.l_tax_subject_limit}
                        />
                    </>
                )}
            </Form>
        </ConfirmDialog>
    );
};

export default EditUserDialog;
