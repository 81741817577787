import React from 'react';
import { Modal, Button } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../utils/analytics';

const AlertDialog = ({
    openDialog,
    titleLabel,
    children,
    okBtnText,
    onOkClicked,
    cancelBtnText,
    onCancelClicked
}) => {
    const { t } = useTranslation();
    const title = t(titleLabel) || titleLabel;
    const okButtonText = okBtnText ? okBtnText : t('global.l_button_delete');
    const cancelButtonText = cancelBtnText ? cancelBtnText : t('global.l_button_cancel');

    return (
        <Modal
            open={openDialog}
            role="alertdialog"
            onClose={onCancelClicked}
            onExit={() => analytics.trackDialog(titleLabel, false)}
            onEnter={() => analytics.trackDialog(titleLabel, true)}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
            <Modal.Footer>
                <Button onClick={onCancelClicked} appearance="subtle" autoFocus>
                    {cancelButtonText}
                </Button>
                <Button onClick={onOkClicked} appearance="primary" color="red">
                    {okButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertDialog;
