import analyticsConstants from './analyticsConstants';
import analyticsConfig from './analyticsConfig';

const developerMode = process.env.NODE_ENV === 'development';

const identifyEvent = (userId) => {
    if (developerMode) {
        console.debug('Identify', 'User ID', userId);
    } else {
        analyticsConfig.identify(userId, {
            origin: 'frontend'
        });
    }
};

const pageEvent = (pageName) => {
    if (developerMode) {
        console.debug('Page', 'Page View', JSON.stringify(pageName));
    } else {
        analyticsConfig.page({
            url: pageName,
            origin: 'frontend'
        });
    }
};

const trackEvent = (name, properties) => {
    if (developerMode) {
        console.debug('Track', name, JSON.stringify(properties));
    } else {
        analyticsConfig.track(name, {
            origin: 'frontend',
            ...properties
        });
    }
};

export const analytics = {
    identifyUser: (userId) => {
        identifyEvent(userId);
    },

    trackPage: (pageName) => {
        pageEvent({ url: pageName });
    },

    trackClick: (label, category, active_step, has_validation_errors) => {
        trackEvent(analyticsConstants.EVENT_NAMES.BUTTON_CLICK, {
            label,
            category,
            active_step,
            has_validation_errors
        });
    },

    trackToggle: (label, category, checked) => {
        trackEvent(analyticsConstants.EVENT_NAMES.TOGGLE, {
            label,
            category,
            checked
        });
    },

    trackDropdown: (label, selected_value, category) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DROPDOWN, {
            label,
            selected_value,
            category
        });
    },

    trackModal: (modal_name, category, open) => {
        trackEvent(analyticsConstants.EVENT_NAMES.MODAL, {
            modal_name,
            category,
            open
        });
    },

    trackDialog: (dialog_title, open) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DIALOG, {
            dialog_title,
            open
        });
    },

    trackHelpPanel: (title, open) => {
        trackEvent(analyticsConstants.EVENT_NAMES.HELP_PANEL, {
            title,
            open
        });
    },

    trackSelectNavItem: (active_page, active_step, category, has_validation_errors) => {
        trackEvent(analyticsConstants.EVENT_NAMES.SELECT_NAV_ITEM, {
            active_page,
            active_step,
            category,
            has_validation_errors
        });
    },

    trackUpdateUser: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_USER, {
            category: analyticsConstants.CATEGORIES.USERS,
            user_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdatePassword: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_PASSWORD, {
            category: analyticsConstants.CATEGORIES.USERS,
            user_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteUser: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_USER, {
            category: analyticsConstants.CATEGORIES.USERS,
            user_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteLogo: (success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_LOGO, {
            category: analyticsConstants.CATEGORIES.USERS,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdateLogo: (success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_LOGO, {
            category: analyticsConstants.CATEGORIES.USERS,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackSearchUser: (search_term) => {
        trackEvent(analyticsConstants.EVENT_NAMES.SEARCH_USER, {
            category: analyticsConstants.CATEGORIES.USERS,
            search_term
        });
    },

    trackCreateTaxsubject: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CREATE_TAXSUBJECT, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            taxsubject_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdateTaxsubject: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_TAXSUBJECT, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            taxsubject_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteTaxsubject: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_TAXSUBJECT, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            taxsubject_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackChangeTaxSubject: (id) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CHANGE_TAXSUBJECT, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            taxsubject_id: id
        });
    },

    trackCreateInvite: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CREATE_INVITE, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            invite_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDisableInvite: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DISABLE_INVITE, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            invite_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackAcceptInvite: (id, success, message) => {
        trackEvent(analyticsConstants.EVENT_NAMES.ACCEPT_INVITE, {
            category: analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            invite_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED,
            message: message
        });
    },

    trackCreateProperty: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CREATE_PROPERTY, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdateProperty: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_PROPERTY, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteProperty: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_PROPERTY, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackChangeProperty: (id) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CHANGE_PROPERTY, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id
        });
    },

    trackCreateForecast: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CREATE_FORECAST, {
            category: analyticsConstants.CATEGORIES.FORECASTS,
            forecast_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdateForecast: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_FORECAST, {
            category: analyticsConstants.CATEGORIES.FORECASTS,
            forecast_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteForecast: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_FORECAST, {
            category: analyticsConstants.CATEGORIES.FORECASTS,
            forecast_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackCreateHistoricalData: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.CREATE_HISTORICAL_DATA, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackUpdateHistoricalData: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.UPDATE_HISTORICAL_DATA, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    },

    trackDeleteHistoricalData: (id, success) => {
        trackEvent(analyticsConstants.EVENT_NAMES.DELETE_HISTORICAL_DATA, {
            category: analyticsConstants.CATEGORIES.PROPERTIES,
            property_id: id,
            status: success ? analyticsConstants.STATUS.SUCCESS : analyticsConstants.STATUS.FAILED
        });
    }
};
