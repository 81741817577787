import React, { useEffect, useRef } from 'react';
import { InlineEdit } from 'rsuite';
import { NumericFormatter, formatNumber } from './NumericFormatter';

const InlineEditInput = ({
    value,
    type,
    onChange,
    mainProps,
    name,
    autoFocus,
    onFocus,
    unlimitedDecimals = false
}) => {
    useEffect(() => {
        onChange(value);
    }, [value]);
    const inlineEditRef = useRef();
    useEffect(() => {
        if (autoFocus && inlineEditRef.current) {
            inlineEditRef.current.click();
            inlineEditRef.current.focus();
        }
    }, [autoFocus]);
    return (
        <InlineEdit
            showControls={false}
            value={value}
            className="inline-edit-input"
            ref={inlineEditRef}
            onFocus={() => {
                inlineEditRef.current.click();
                onFocus();
            }}>
            {(props, ref) => {
                const { plaintext, ...rest } = props;
                if (plaintext) {
                    return (
                        <span>{value !== undefined && !isNaN(value) && formatNumber(+value)}</span>
                    );
                }

                return (
                    <NumericFormatter
                        {...rest}
                        {...mainProps}
                        type={type}
                        ref={ref}
                        name={name}
                        unlimitedDecimals={unlimitedDecimals}
                        value={value}
                        onChange={onChange}
                    />
                );
            }}
        </InlineEdit>
    );
};

export default InlineEditInput;
