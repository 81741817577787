import React from 'react';

const ContentPageBase = ({
    header,
    leftItem,
    topItem,
    footer,
    className,
    children,
    bgIcon,
    altBgIcon,
    bgClassName
}) => {
    return (
        <>
            {bgIcon && <img src={bgIcon} alt={altBgIcon} className={bgClassName} />}
            <div
                className={`rs-content-wrapper ${className ? className : ''}
                ${leftItem ? 'has-left-item' : ''}`}>
                {leftItem && <div className="left-item">{leftItem}</div>}
                {topItem && <div className="top-item">{topItem}</div>}
                <div className="main-item">
                    <div className={`page-content ${bgIcon ? 'transparent' : ''}`}>
                        {header && <h2 className="page-content-header">{header}</h2>}
                        {children}
                    </div>
                    {footer}
                </div>
            </div>
        </>
    );
};

export default ContentPageBase;
