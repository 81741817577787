import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import pia_de_at from './locale/PIA_German_AT.json';

i18next.use(initReactI18next).init({
    fallbackLng: 'de_at',
    // debug: true,
    resources: { de_at: { translation: pia_de_at } },
    interpolation: { escapeValue: false }
});
