import React, { useEffect, useState } from 'react';
import { readAndCompressImage } from 'browser-image-resizer';
import { useSelector, useDispatch } from 'react-redux';
import { setRequestFinished, setRequestMessage, setCustomLogo } from '../reducers/userSlice';
import { Uploader, Loader } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faTrash } from '@fortawesome/pro-solid-svg-icons';
import sendRequest from '../utils/sendRequest';
import AlertDialog from './dialogs/AlertDialog';
import { analytics } from '../utils/analytics';

function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const ImageUpload = () => {
    const dispatch = useDispatch();

    const userId = useSelector((state) => state.user.userId);
    const customLogo = useSelector((state) => state.user.customLogo);

    const [uploadKey, setUploadKey] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [fileInfo, setFileInfo] = useState(null);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);

    useEffect(() => {
        if (userId && customLogo) {
            const path = `data:image/png;base64, ${customLogo}`;
            setFileInfo(path);
        }
    }, [customLogo, userId]);

    // Image compression config
    const compressConfig = {
        quality: 1,
        maxWidth: 1000,
        maxHeight: 1000,
        autoRotate: true,
        debug: true
    };

    const handleUpload = (files) => {
        const file = files[files.length - 1];
        // check file size
        const maxSize = 10 * 1024 * 1024; // 10MB in bytes
        if (file.blobFile.size > maxSize) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: 'File is too large. Please select a file smaller than 10MB.'
                })
            );
            return;
        }
        // check file type
        const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        if (!acceptedTypes.includes(file.blobFile.type)) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: 'Invalid file type. Please select a .png, .jpg, or .jpeg file.'
                })
            );
            return;
        }

        setUploading(true);

        readAndCompressImage(file.blobFile, compressConfig).then(async (compressedFile) => {
            const blob = compressedFile.slice(0, compressedFile.size, compressedFile.type);
            previewFile(blob, (value) => {
                setFileInfo(value);
            });
            // Create a new File object with the same name as the original file
            const fileWithExtension = new File([compressedFile], file.name, {
                type: compressedFile.type
            });
            const formData = new FormData();
            formData.append('file', fileWithExtension);

            sendRequest('/uploadAndReplaceImage', 'post', formData, false).then((resp) => {
                if (resp.success) {
                    setUploadKey((prevKey) => prevKey + 1);
                    dispatch(setCustomLogo(resp.newLogo));
                    dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: 'Uploaded successfully'
                        })
                    );
                } else {
                    setFileInfo(null);
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: 'Upload failed'
                        })
                    );
                }
                analytics.trackUpdateLogo(resp.success);
                dispatch(setRequestFinished(true));
                setUploading(false);
            });
        });
    };

    const handleDelete = () => {
        sendRequest('/deleteImage', 'post').then((resp) => {
            if (resp.success) {
                dispatch(setCustomLogo(null));
                setFileInfo(null);
                setOpenAlertDialog(false);
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: 'Deleted successfully'
                    })
                );
            } else {
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: 'Delete failed'
                    })
                );
            }
            analytics.trackDeleteLogo(resp.success);
            dispatch(setRequestFinished(true));
        });
    };

    return (
        <>
            <Uploader
                key={uploadKey}
                className="image-uploader"
                fileListVisible={false}
                listType="picture"
                accept="image/*"
                autoUpload={false}
                onChange={(files) => {
                    handleUpload(files);
                }}>
                <button className="uploader-btn">
                    {uploading && <Loader backdrop center />}
                    {fileInfo ? (
                        <div
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenAlertDialog(true);
                            }}>
                            <FontAwesomeIcon className="rs-icon" icon={faTrash} />
                            <img
                                alt="upload preview"
                                src={fileInfo}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    zIndex: 1,
                                    opacity: 0.5
                                }}
                                onError={() => setFileInfo(null)}
                            />
                        </div>
                    ) : (
                        <FontAwesomeIcon className="rs-icon" icon={faCamera} />
                    )}
                </button>
            </Uploader>

            <AlertDialog
                openDialog={openAlertDialog}
                titleLabel="Confirm Delete Logo"
                okBtnText="OK"
                onOkClicked={handleDelete}
                onCancelClicked={() => setOpenAlertDialog(false)}>
                <p>Are you sure you want to delete logo image</p>
            </AlertDialog>
        </>
    );
};

export default ImageUpload;
