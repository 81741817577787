import React, { useEffect } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import AddNewCost from '../../components/AddNewCost';
import { useTranslation } from 'react-i18next';
import { SchemaModel, NumberType } from 'schema-typed';
import DropdownInput from '../../components/DropdownInput';
import constants from '../../utils/constants';
import { formatNumber } from '../../components/NumericFormatter';
import DatePickerInput from '../../components/DatePickerInput';
import { Divider } from 'rsuite';

const Purchase = ({ data, setData, pages, setPages, children }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).purchase.objectTypeSchemaSpec;
    let models = pages.map(() => {
        return SchemaModel({
            l_cost_item_value: NumberType()
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required'))
        });
    });
    const deleteAddNewCostPage = (page) => {
        setData({ ...data, ['l_purchase_price_' + page.type]: '' });
        setPages(pages.filter((item) => item.id !== page.id));
    };
    let building_price = data?.l_purchase_price_building ? +data.l_purchase_price_building : 0;
    pages.map(
        (page) =>
            (building_price += data?.['l_purchase_price_' + page.type]
                ? +data?.['l_purchase_price_' + page.type]
                : 0)
    );
    useEffect(() => {
        if (data?.l_purchase_price_building) {
            setData({ ...data, l_purchase_price: building_price });
        }
    }, [data?.l_purchase_price_building, building_price]);

    return (
        <>
            <DatePickerInput
                label={t('create_forecast.l_purchase_date')}
                type="date"
                name="l_purchase_date"
                infoText={t('create_forecast.i_purchase_date')}
                autoFocus
                onChange={(value) => {
                    setData({ ...data, l_purchase_date: value });
                }}
                value={data?.l_purchase_date}
                rule={validationSchemas.l_purchase_date}
            />
            <DropdownInput
                label={t('create_forecast.l_purchase_type')}
                infoText={t('create_forecast.i_purchase_type')}
                alignRight
                name="l_purchase_type"
                dropdownOptionReady
                rule={validationSchemas.l_purchase_type}
                dropdownOption={[
                    {
                        value: constants.VAT_PAYER_TYPES.not_small_business,
                        label: t('create_forecast.l_purchase_type_1')
                    },
                    {
                        value: constants.VAT_PAYER_TYPES.small_business,
                        label: t('create_forecast.l_purchase_type_2')
                    }
                ]}
                placeholder={t('global.l_please_select')}
                value={data?.l_purchase_type}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_purchase_price_building')}
                type="number"
                infoText={t('create_forecast.i_purchase_price_building')}
                symbolIcon="€"
                name="l_purchase_price_building"
                helpText={
                    data?.l_purchase_type === constants.VAT_PAYER_TYPES.small_business
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_purchase_price_building
                                  ? data?.l_purchase_price_building -
                                        data?.l_purchase_price_building / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
                rule={validationSchemas.l_purchase_price}
            />
            {pages?.map((page, index) => (
                <AddNewCost
                    key={index}
                    type={page.type}
                    data={data}
                    setData={setData}
                    isSmallBusiness={
                        data?.l_purchase_type === constants.VAT_PAYER_TYPES.small_business
                    }
                    pop={() => deleteAddNewCostPage(page)}
                    rule={
                        models[index]?.spec?.l_cost_item_value ||
                        models[index]?.$spec?.l_cost_item_value
                    }
                />
            ))}
            {children}
            <Divider />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_purchase_price')}
                type="number"
                infoText={t('create_forecast.i_purchase_price')}
                symbolIcon="€"
                disabled
                name="l_purchase_price"
                helpText={
                    data?.l_purchase_type === constants.VAT_PAYER_TYPES.small_business
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_purchase_price
                                  ? data.l_purchase_price - data.l_purchase_price / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
            />
        </>
    );
};

export default Purchase;
