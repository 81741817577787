import React, { useEffect, useState } from 'react';
import { Placeholder, Modal, Button } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import sendRequest from '../utils/sendRequest';
import {
    getProperties,
    getTaxsubjects,
    setRequestFinished,
    setRequestMessage
} from '../reducers/userSlice';
import { useTranslation, Trans } from 'react-i18next';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';
import subscriptionLimit from '../utils/subscriptionLimit';
import constants from '../utils/constants';

const AccepterModal = ({ token, open, setOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const user = useSelector((state) => state.user.user);
    const properties = useSelector((state) => state.user.properties);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);

    const [pageData, setPageData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [invalidInviteMessage, setInvalidInviteMessage] = useState(
        t('access_sharing.e_invitation_invalid')
    );
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (pageData?.taxsubjectID) {
            setLoaded(false);
            setTimeout(() => {
                dispatch(
                    getProperties([
                        ...taxsubjects.map((subject) => subject._id),
                        pageData.taxsubjectID
                    ])
                );
            }, 50);
        }
    }, [pageData, taxsubjects]);

    useEffect(() => {
        if (properties.length > 0) setLoaded(true);
    }, [properties]);

    const propertiesLocked =
        properties.length >= subscriptionLimit(user?.subscriptionData?.plan_name, user).properties;
    const taxsubjectsLocked =
        taxsubjects.lengths >=
        subscriptionLimit(user?.subscriptionData?.plan_name, user).taxsubjects;

    useEffect(() => {
        if (!open || !token) return;
        sendRequest('/invites/getAccepterPageData', 'post', { token }).then((resp) => {
            if (resp.invalid_token || resp.already_exist) {
                setIsValid(false);
                if (resp.already_exist) {
                    setInvalidInviteMessage(t('access_sharing.e_already_accepted'));
                }
            } else if (resp.message.success) {
                setPageData({
                    ...pageData,
                    inviterName: resp.inviterName,
                    taxsubjectName: resp.taxsubjectName,
                    taxsubjectID: resp.taxsubjectID
                });
                setIsValid(true);
            }
            setLoaded(true);
        });
    }, [open]);

    const openPlans = () => {
        setOpen(false);
        navigate(constants.ROUTES.PLANS);
    };

    const acceptInvite = () => {
        if (!isValid) {
            setOpen(false);
            navigate(constants.ROUTES.HOME);
            return;
        }
        sendRequest('/invites/accept', 'post', { token }).then((resp) => {
            if (resp.invalid_token || resp.already_exist) {
                if (resp.invalid_token) {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: t('access_sharing.e_invitation_invalid')
                        })
                    );
                    analytics.trackAcceptInvite(resp.invite._id, false, 'invalid_token');
                } else {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'info',
                            message: t('access_sharing.e_already_accepted')
                        })
                    );
                    analytics.trackAcceptInvite(resp.invite._id, false, 'already_accepted');
                }
                setOpen(false);
                navigate(constants.ROUTES.HOME);
                return;
            }
            if (resp.message.success) {
                dispatch(getTaxsubjects());
                setOpen(false);
                navigate(constants.ROUTES.HOME);
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('access_sharing.m_invite_accepted')
                    })
                );
                analytics.trackAcceptInvite(resp.invite._id, true);
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('access_sharing.e_server_error')
                    })
                );
                analytics.trackAcceptInvite(resp.invite._id, false);
            }
        });
    };

    const rejectInvite = () => {
        setOpen(false);
        navigate(constants.ROUTES.HOME);
    };

    return (
        <Modal
            className="accepter-modal"
            open={open}
            onEnter={() => {
                analytics.trackModal(
                    'accept_invite_modal',
                    analyticsConstants.CATEGORIES.TAX_SUBJECTS,
                    true
                );
            }}
            onExit={() => {
                setOpen(false);
                analytics.trackModal(
                    'accept_invite_modal',
                    analyticsConstants.CATEGORIES.TAX_SUBJECTS,
                    false
                );
            }}
            size="md"
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton={false}>
                <Modal.Title>{t('invitation.h_invitation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loaded ? (
                    isValid ? (
                        <Trans
                            i18nKey={
                                propertiesLocked || taxsubjectsLocked
                                    ? 'invitation.l_invitation_description_outside_plan'
                                    : 'invitation.l_invitation_description_within_plan'
                            }
                            components={{ br: <br />, strong: <strong />, ul: <ul />, li: <li /> }}
                            values={{
                                inviter: pageData.inviterName,
                                'tax-subject': pageData.taxsubjectName,
                                'num-properties': properties.length,
                                'taxsubject-from': taxsubjects.length + 1,
                                'taxsubject-to': subscriptionLimit(
                                    user?.subscriptionData?.plan_name,
                                    user
                                ).taxsubjects,
                                'property-from': properties.length,
                                'property-to': subscriptionLimit(
                                    user?.subscriptionData?.plan_name,
                                    user
                                ).properties
                            }}
                        />
                    ) : (
                        <p>{invalidInviteMessage}</p>
                    )
                ) : (
                    <>
                        <Placeholder.Paragraph active rows={1} />
                        <br />
                        <Placeholder.Paragraph active rows={2} />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isValid && (
                    <Button onClick={rejectInvite} appearance="subtle">
                        {t('invitation.l_decline_invite')}
                    </Button>
                )}
                <Button
                    onClick={propertiesLocked || taxsubjectsLocked ? openPlans : acceptInvite}
                    appearance="primary">
                    {isValid
                        ? propertiesLocked || taxsubjectsLocked
                            ? t('invitation.l_upgrade')
                            : t('invitation.l_accept_invite')
                        : t('invitation.l_ok')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AccepterModal;
