import React from 'react';
import { useSelector } from 'react-redux';
import 'rsuite/dist/rsuite.min.css';
import './style/css/App.css';
import Signup from './pages/Signup';
import Login from './pages/Login';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserId } from './reducers/userSlice';
import ForgottenPassw from './pages/ForgottenPassw';
import ResetPassw from './pages/ResetPassw';
import Main from './pages/Main';
import EmailSent from './pages/EmailSent';
import Profile from './pages/contentPages/Profile';
import constants from './utils/constants';
import TaxSubjects from './pages/contentPages/TaxSubjects';
import Properties from './pages/contentPages/Properties';
import ForecastCalculation from './pages/contentPages/ForecastCalculation';
import TaxSubjectDashboard from './pages/taxSubjectsPages/TaxSubjectDashboard';
import AddEditTaxSubject from './pages/taxSubjectsPages/AddEditTaxSubject';
import Admin from './pages/contentPages/Admin';
import E1b from './pages/contentPages/E1b';
import Plans from './pages/contentPages/Plans';
import PropertyDashboard from './pages/contentPages/PropertyDashboard';
import CreateOrEditProperty from './pages/contentPages/CreateOrEditProperty';
import Forecast from './pages/contentPages/Forecast';
import HistoricalData from './pages/contentPages/HistoricalData';

const App = () => {
    const dispatch = useDispatch();
    const loaded = useSelector((state) => state.user.loaded);

    dispatch(getUserId());

    return (
        loaded && (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login/:message?" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/forgot-password" element={<ForgottenPassw />} />
                        <Route path="/reset-password" element={<ResetPassw />} />
                        <Route path="/email-sent/:email/:type" element={<EmailSent />} />
                        <Route path="/" element={<Main />}>
                            <Route path="/admin" element={<Admin />} />
                            <Route path={constants.ROUTES.PROFILE} element={<Profile />} />
                            <Route
                                path={constants.ROUTES.TAXSUBJECTS.LIST}
                                element={<TaxSubjects />}>
                                <Route
                                    path={constants.ROUTES.TAXSUBJECTS.DASHBOARD}
                                    element={<TaxSubjectDashboard />}
                                />
                                <Route
                                    path={constants.ROUTES.TAXSUBJECTS.CREATE}
                                    element={<AddEditTaxSubject />}
                                />
                                <Route
                                    path={constants.ROUTES.TAXSUBJECTS.EDIT}
                                    element={<AddEditTaxSubject />}
                                />
                            </Route>

                            <Route path={constants.ROUTES.PROPERTIES.LIST} element={<Properties />}>
                                <Route
                                    path={constants.ROUTES.PROPERTIES.DASHBOARD}
                                    element={<PropertyDashboard />}
                                />
                                <Route
                                    path={constants.ROUTES.PROPERTIES.CREATE}
                                    element={<CreateOrEditProperty />}
                                />
                                <Route
                                    path={constants.ROUTES.PROPERTIES.EDIT}
                                    element={<CreateOrEditProperty />}
                                />
                            </Route>
                            <Route
                                path={constants.ROUTES.FORECAST_CALCULATION.HOME}
                                element={<ForecastCalculation />}>
                                <Route
                                    path={constants.ROUTES.FORECAST_CALCULATION.FORECAST}
                                    element={<Forecast />}
                                />
                                <Route
                                    path={constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA}
                                    element={<HistoricalData />}
                                />
                            </Route>
                            <Route path={constants.ROUTES.E1B} element={<E1b />} />
                            <Route path={constants.ROUTES.PLANS} element={<Plans />} />
                        </Route>
                        <Route path="/invite/:inviteToken?" element={<Main />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </BrowserRouter>
                {window.innerWidth > 1024 && (
                    <span className="version-label">{`Version ${process.env.REACT_APP_APP_VERSION || constants.VERSION.number} - ${process.env.REACT_APP_GIT_VERSION || constants.VERSION.commit_id}`}</span>
                )}
            </>
        )
    );
};

export default App;
