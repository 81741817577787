import React from 'react';
import { Button } from 'rsuite';

const LoginFooterWithButton = ({ pText, btnText, onClick }) => {
    return (
        <>
            <p>{pText}</p>
            <Button appearance="ghost" onClick={onClick}>
                {btnText}
            </Button>
        </>
    );
};

export default LoginFooterWithButton;
