import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { formatNumber } from '../../components/NumericFormatter';

const Expenses_2 = ({ forecastData }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).expenses_2.objectTypeSchemaSpec;

    return (
        <>
            <h4>{t('create_historic_forecast_data.h_expenses_2')}</h4>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_operating_costs_20')}
                type="number"
                name="l_operating_costs_20"
                infoText={t('create_historic_forecast_data.i_operating_costs_20')}
                autoFocus
                symbolIcon="€"
                rule={validationSchemas.l_operating_costs_20}
                helpText={t('create_historic_forecast_data.l_planned_based_on_forecast').replace(
                    '{{value}}',
                    formatNumber(forecastData?.l_operating_costs_20)
                )}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_operating_costs_10')}
                type="number"
                name="l_operating_costs_10"
                infoText={t('create_historic_forecast_data.i_operating_costs_10')}
                symbolIcon="€"
                rule={validationSchemas.l_operating_costs_10}
                helpText={t('create_historic_forecast_data.l_planned_based_on_forecast').replace(
                    '{{value}}',
                    formatNumber(forecastData?.l_operating_costs_10)
                )}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_reserves')}
                type="number"
                name="l_reserves"
                infoText={t('create_historic_forecast_data.i_reserves')}
                symbolIcon="€"
                rule={validationSchemas.l_reserves}
                helpText={t('create_historic_forecast_data.l_planned_based_on_forecast').replace(
                    '{{value}}',
                    formatNumber(forecastData?.l_reserves)
                )}
            />
        </>
    );
};

export default Expenses_2;
