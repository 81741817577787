import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import ContentPageBase from '../../components/ContentPageBase';
import DashboardPanel from '../../components/DashboardPanel';
import { getForecastData, getProperties, setCurrentTaxsubject } from '../../reducers/userSlice';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import { useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';

const Dashboard = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.user);
    const taxSubjects = useSelector((state) => state.user.taxsubjects);
    const properties = useSelector((state) => state.user.properties);
    const forecastData = useSelector((state) => state.user.forecastData);

    const firstStepFeatures = [
        {
            key: 'step_1',
            title: t('dashboard.l_step1'),
            description: t('dashboard.l_step1_description'),
            checked:
                taxSubjects &&
                Object.keys(taxSubjects.filter((taxsubject) => taxsubject?.isTest === false))
                    .length > 0,
            btnLabel: t('dashboard.l_create_now'),
            onBtnClicked: () => {
                analytics.trackClick(
                    'create_first_tax_subject',
                    analyticsConstants.CATEGORIES.TAX_SUBJECTS
                );
                dispatch(setCurrentTaxsubject());
                navigate(constants.ROUTES.TAXSUBJECTS.CREATE);
            }
        },

        {
            key: 'step_2',
            title: t('dashboard.l_step2'),
            description: t('dashboard.l_step2_description'),
            checked:
                properties &&
                Object.keys(properties.filter((property) => property?.isTest === false)).length > 0
        },

        {
            key: 'step_3',
            title: t('dashboard.l_step3'),
            description: t('dashboard.l_step3_description'),
            checked: Array.isArray(forecastData)
                ? forecastData?.filter((forecast) => forecast?.isTest === false)?.length > 0
                : Object.keys(forecastData).length > 0 && forecastData?.isTest === false
        }
    ];

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.DASHBOARD);
    }, []);

    useEffect(() => {
        if (taxSubjects.length > 0 && properties.length === 0)
            dispatch(getProperties(taxSubjects?.map((item) => item._id)));
    }, [taxSubjects]);

    useEffect(() => {
        if (
            properties.length > 0 &&
            (Object.keys(forecastData).length === 0 || forecastData?.length === 0)
        )
            dispatch(getForecastData(properties?.map((item) => item._id)));
    }, [properties]);

    return (
        user?.onboardingPassed && (
            <ContentPageBase header={t('dashboard.h_dashboard')}>
                <div>{t('dashboard.l_intro_text')}</div>
                <div className="dashboard-green-bg">{t('dashboard.l_demo_data_intro_message')}</div>
                <div style={{ marginTop: '1.25rem' }}>{t('dashboard.l_or_add_your_own_data')}</div>
                {firstStepFeatures.map((feature) => (
                    <DashboardPanel
                        isSelectable={false}
                        key={feature.key}
                        title={feature.title}
                        description={feature.description}
                        checked={feature.checked}
                        btnLabel={feature.btnLabel}
                        onBtnClicked={feature.onBtnClicked}
                    />
                ))}

                <div>
                    <Trans
                        i18nKey="dashboard.l_questons_text"
                        components={{
                            link1: <Link to="https://www.renteezy.at/kontakt" target="_blank" />,
                            link2: <Link to="mailto:support@renteezy.com" target="_blank" />,
                            b: <b />
                        }}
                    />
                </div>
            </ContentPageBase>
        )
    );
};

export default Dashboard;
