import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, CheckboxGroup } from 'rsuite';
import { useTranslation } from 'react-i18next';
import {
    setShowLoading,
    setRequestFinished,
    setRequestMessage,
    getUser
} from '../../reducers/userSlice';
import constants from '../../utils/constants.js';
import sendRequest from '../../utils/sendRequest';
import { initializePaddle } from '@paddle/paddle-js';
import ContentPageBase from '../../components/ContentPageBase';
import PricingFeaturesCard from '../../components/PricingFeaturesCard';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import AlertDialog from '../../components/dialogs/AlertDialog';
import subscriptionExists from '../../utils/subscriptionExists';
import plansOverviewData from '../../utils/plansOverviewData';
import { useNavigate } from 'react-router-dom';
import CarouselWithStepper from '../../components/CarouselWithStepper.jsx';

const Payment = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [paddle, setPaddle] = useState();
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [priceId, setPriceId] = useState(null);
    const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [openCancelSubDialog, setOpenCancelSubDialog] = useState(false);
    const [plansAndFeatures, setPlansAndFeatures] = useState(plansOverviewData());
    const [isSubscriptionCanceled, setIsSubscriptionCanceled] = useState(false);
    const [cancelReasons, setCancelReasons] = useState([]);
    const [carouselIndex, setCarouselIndex] = useState();

    const user = useSelector((state) => state.user.user);
    const paymentsConfig = useSelector((state) => state.user.paymentsConfig);

    const prevSubscription = useRef(null);
    const subscriptionStatus = useRef(false);

    useEffect(() => {
        dispatch(getUser());
    }, []);

    useEffect(() => {
        initializePaddle({
            environment: paymentsConfig.environment,
            seller: paymentsConfig.sellerID,
            eventCallback: (data) => {
                handleCheckoutEvents(data);
            }
        }).then((paddleInstance) => setPaddle(paddleInstance));
    }, [paymentsConfig]);

    useEffect(() => {
        if (user) {
            dispatch(setShowLoading(false));
            setIsSubscriptionCanceled(user?.subscriptionData.scheduled_change?.action === 'cancel');
            setSubscriptionId(user?.subscriptionData?.subscription_id);
            updatePlansAndFeatures(user?.subscriptionData.plan_name);
            if (
                user.userType === t(constants.USER_TYPES.TA) &&
                !user?.subscriptionData?.subscription_id &&
                paddle &&
                user?.subscriptionData?.plan_name &&
                user.subscriptionData?.planName !== 'None'
            ) {
                let priceId = user?.subscriptionData?.plan_name.includes('custom')
                    ? user?.custom_price_id
                    : paymentsConfig.PRICE_IDS[user?.subscriptionData?.plan_name];
                handleSubscriptionCheckout(priceId);
            }
        }
    }, [user, paddle]);

    const updatePlansAndFeatures = (planName) => {
        let subscriptionFound = false;

        let updatedFeatures = plansAndFeatures.map((feature) => {
            feature.enabled = !subscriptionExists(planName) || subscriptionFound;
            if (feature.handle === planName) {
                subscriptionFound = true;
            }
            feature.footerText =
                subscriptionFound && feature.name === planName
                    ? t('plan_overview.l_current_plan')
                    : '';

            return feature;
        });

        const priceIds = paymentsConfig.PRICE_IDS;
        updatedFeatures = plansAndFeatures.map((feature) => ({
            ...feature,
            priceId: priceIds[feature.name.toLowerCase()]
        }));

        if (prevSubscription.current) {
            if (prevSubscription.current !== planName) {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('Subscription successfull')
                    })
                );
            }
        }

        if (
            subscriptionStatus.cancelRequested &&
            user?.subscriptionData.scheduled_change?.action === 'cancel'
        ) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'success',
                    message: 'Subscription Cancelled'
                })
            );
            subscriptionStatus.cancelRequested = false;
        }

        prevSubscription.current = planName;
        setPlansAndFeatures(updatedFeatures);
    };

    const handleSubscriptionCheckout = (priceId) => {
        if (priceId === paymentsConfig.PRICE_IDS.manager) {
            window.open(constants.MANAGER_PLAN_FORM_URL, '_blank');
            return;
        }

        setPriceId(priceId);
        if (!user?.subscriptionData?.subscription_id) {
            dispatch(setShowLoading(true));
            openPaddleCheckout(priceId);
        } else {
            setOpenUpgradeDialog(true);
        }
    };

    const handleCheckoutEvents = (data) => {
        switch (data.name) {
            case 'checkout.closed':
                if (!paymentCompleted) dispatch(setShowLoading(false));
                else setPaymentCompleted(false);
                if (user.userType === t(constants.USER_TYPES.TA)) navigate(constants.ROUTES.HOME);
                break;
            case 'checkout.payment.initiated':
                setPaymentCompleted(true);
                break;
            default:
                break;
        }
    };

    const openPaddleCheckout = (priceId) => {
        paddle?.Checkout.open({
            items: [{ priceId: priceId }],
            customer: {
                email: user.email
            },
            customData: { userId: user?._id }
        });
    };

    const changeSubscription = () => {
        setOpenUpgradeDialog(false);
        dispatch(setShowLoading(true));
        sendRequest(`/updateSubscription/${subscriptionId}/${priceId}`, 'get').then((resp) => {
            !resp.success && showErrorToastMessage();
        });
    };

    const cancelSubscription = () => {
        setOpenCancelSubDialog(false);
        dispatch(setShowLoading(true));
        subscriptionStatus.cancelRequested = true;
        sendRequest(`/cancelSubscription`, 'post', {
            effective_from:
                process.env?.NODE_ENV === 'production' ? 'next_billing_period' : 'immediately',
            subscriptionId: user?.subscriptionData?.subscription_id,
            cancelReasons: cancelReasons
        }).then((resp) => {
            !resp.success && showErrorToastMessage();
        });
    };

    const showErrorToastMessage = () => {
        dispatch(setShowLoading(false));
        dispatch(setRequestFinished(true));
        dispatch(
            setRequestMessage({
                type: 'error',
                message: t('global.i_message_error')
            })
        );
    };

    return (
        user?.userType !== t(constants.USER_TYPES.TA) && (
            <ContentPageBase header={t('plan_overview.h_plan_overview')} className="payment-page">
                <ConfirmDialog
                    openDialog={openUpgradeDialog}
                    titleLabel={t('plan_overview.h_change_plan')}
                    okBtnText={t('plan_overview.l_confirm_upgrade')}
                    onOkClicked={changeSubscription}
                    onCancelClicked={() => setOpenUpgradeDialog(false)}>
                    <p>{t('plan_overview.l_change_plan')}</p>
                </ConfirmDialog>
                <AlertDialog
                    openDialog={openCancelSubDialog}
                    titleLabel={t('plan_overview.l_cancel_plan')}
                    okBtnText={t('plan_overview.l_cancel_plan')}
                    cancelBtnText={t('global.l_button_cancel')}
                    onOkClicked={cancelSubscription}
                    onCancelClicked={() => setOpenCancelSubDialog(false)}>
                    <p>{t('plan_overview.l_cancel_plan_message')}</p>
                    <CheckboxGroup
                        className="payment-cancel-reasons"
                        value={cancelReasons}
                        onChange={(value) => {
                            setCancelReasons(value);
                        }}>
                        <Checkbox value={t('plan_overview.l_cancel_plan_option_1')}>
                            {t('plan_overview.l_cancel_plan_option_1')}
                        </Checkbox>
                        <Checkbox value={t('plan_overview.l_cancel_plan_option_2')}>
                            {t('plan_overview.l_cancel_plan_option_2')}
                        </Checkbox>
                        <Checkbox value={t('plan_overview.l_cancel_plan_option_3')}>
                            {t('plan_overview.l_cancel_plan_option_3')}
                        </Checkbox>
                    </CheckboxGroup>
                </AlertDialog>
                {window.innerWidth < 1024 ? (
                    <CarouselWithStepper
                        length={plansAndFeatures.length}
                        setOutsideIndex={setCarouselIndex}>
                        {plansAndFeatures.map((plan) => (
                            <PricingFeaturesCard
                                key={plan.id}
                                name={plan.name}
                                primaryFeatures={plan.primaryFeatures}
                                secondaryFeatures={plan.secondaryFeatures}
                                price={plan.price}
                                priceSuffix={plan.priceSuffix}
                                highlight={
                                    !subscriptionExists(user?.subscriptionData.plan_name) &&
                                    plan.highlight
                                }
                                enabled={plan.enabled}
                                footerText={plan.footerText}
                                buttonText={plan.buttonText}
                                onButtonClick={() => handleSubscriptionCheckout(plan.priceId)}
                            />
                        ))}
                    </CarouselWithStepper>
                ) : (
                    <div className="payment-page-plansContainer">
                        {plansAndFeatures.map((plan) => (
                            <PricingFeaturesCard
                                key={plan.id}
                                name={plan.name}
                                primaryFeatures={plan.primaryFeatures}
                                secondaryFeatures={plan.secondaryFeatures}
                                price={plan.price}
                                priceSuffix={plan.priceSuffix}
                                highlight={
                                    !subscriptionExists(user?.subscriptionData.plan_name) &&
                                    plan.highlight
                                }
                                enabled={plan.enabled}
                                footerText={plan.footerText}
                                buttonText={plan.buttonText}
                                onButtonClick={() => handleSubscriptionCheckout(plan.priceId)}
                            />
                        ))}
                    </div>
                )}

                {subscriptionExists(user?.subscriptionData?.plan_name) &&
                    !isSubscriptionCanceled && (
                        <div
                            className={`payment-page-cancel-plan-text ${user?.subscriptionData?.plan_name}`}
                            onClick={() => setOpenCancelSubDialog(true)}>
                            {(carouselIndex === undefined ||
                                (carouselIndex === 0 &&
                                    user?.subscriptionData?.plan_name === 'basic') ||
                                (carouselIndex === 1 &&
                                    user?.subscriptionData?.plan_name === 'pro')) &&
                                t('plan_overview.l_cancel_plan')}
                        </div>
                    )}
            </ContentPageBase>
        )
    );
};

export default Payment;
