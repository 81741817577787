import { faChartLine, faFile, faTable, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faFilePdf } from '@fortawesome/pro-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ButtonGroup, IconButton, SelectPicker, Stack, Dropdown } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from 'rsuite/esm/Animation/Collapse';
import Fade from 'rsuite/esm/Animation/Fade';
import { exportReportToPdf } from '../utils/exportReportToPdf';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setActiveE1bData } from '../reducers/userSlice';
import constants from '../utils/constants';
import forecastTableGraphOptions from '../utils/forecastTableGraphOptions';

const TableAndGraphContainer = ({
    header,
    chartOpenName,
    tableOpenName,
    yearInterval,
    setYearInterval,
    data,
    deleteForecast,
    children
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const properties = useSelector((state) => state.user.properties);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);

    const generatePdf = exportReportToPdf();

    const [chartsOpen, setChartsOpen] = useState();
    const [tablesOpen, setTablesOpen] = useState();
    const [showExportToPdf, setShowExportToPdf] = useState(false);

    useEffect(() => {
        setShowExportToPdf(data.labels.length > 0 && properties[openedPropertyForecast]?._id);
    }, [data, openedPropertyForecast]);

    // add counter to labels
    data.labels = data.labels.map((label, index) => {
        if (label.toString().includes(`(${index + 1})`)) return label;
        else return [label, `(${index + 1})`];
    });

    useEffect(() => {
        let chartsOpenLoc = localStorage.getItem(chartOpenName);
        let tablesOpenLoc = localStorage.getItem(tableOpenName);
        // localstorage stores only strings
        if (chartsOpenLoc === null) {
            localStorage.setItem(chartOpenName, true);
            setChartsOpen(true);
        } else setChartsOpen(chartsOpenLoc == 'true');
        if (tablesOpenLoc === null) {
            localStorage.setItem(tableOpenName, true);
            setTablesOpen(true);
        } else setTablesOpen(tablesOpenLoc == 'true');
    }, [localStorage]);

    const setOpenState = (type) => {
        const analyticsType = type === 'chart' ? 'chart' : 'table';
        const analyticsStatus = type === 'chart' ? chartsOpen : tablesOpen;
        const analyticsLabel = `${analyticsType}_${analyticsStatus ? 'closed' : 'opened'}`;
        analytics.trackClick(analyticsLabel, analyticsConstants.CATEGORIES.FORECASTS);
        if (type === 'chart') {
            localStorage.setItem(chartOpenName, !chartsOpen);
            setChartsOpen((prev) => !prev);
        } else {
            localStorage.setItem(tableOpenName, !tablesOpen);
            setTablesOpen((prev) => !prev);
        }
    };
    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart, args, options) => {
            const { ctx } = chart;
            let dataset =
                chart.config?.data?.datasets?.[2] &&
                chart.config?.data?.datasets?.[2]?.data.length > 0
                    ? chart.config?.data?.datasets?.[2]
                    : chart.config?.data?.datasets?.[0];
            let firstProfitableYear = dataset.data.findIndex((item) => item > 0);
            if (firstProfitableYear === -1) firstProfitableYear = dataset.data.length;
            let firstRentIndex = chart.config?.data?.datasets?.[0]?.firstRent;
            let limit = chart.config?.data?.datasets?.[0]?.limit;
            let emptyAreaWidth =
                (chart.chartArea.width *
                    (firstProfitableYear > limit + firstRentIndex
                        ? limit + firstRentIndex
                        : chart.scales.x.max)) /
                chart.scales.x.max;
            let redAreaWidth =
                (chart.chartArea.width *
                    (firstProfitableYear > limit + firstRentIndex
                        ? firstProfitableYear - (limit + firstRentIndex)
                        : 0)) /
                chart.scales.x.max;
            let startX = chart.chartArea.left + emptyAreaWidth;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color;
            ctx.fillRect(
                startX,
                chart.chartArea.top,
                chart.scales.x.max < limit + 1 ? 0 : redAreaWidth,
                chart.chartArea.height
            );
            ctx.restore();
        }
    };

    return (
        <>
            <div className="table-and-graph-container">
                <div>
                    <Stack
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className="title-stack">
                        <h3>{header}</h3>
                        <Stack spacing={10} justifyContent="flex-end" className="controls-panel">
                            {window.innerWidth > 750 && (
                                <Fade in={tablesOpen || chartsOpen}>
                                    <SelectPicker
                                        cleanable={false}
                                        data={[
                                            { label: '15 Jahre', value: 15 },
                                            { label: '20 Jahre', value: 20 },
                                            { label: '25 Jahre', value: 25 },
                                            { label: '30 Jahre', value: 30 },
                                            { label: '40 Jahre', value: 40 },
                                            { label: '50 Jahre', value: 50 }
                                        ]}
                                        value={yearInterval}
                                        searchable={false}
                                        onChange={(item) => {
                                            analytics.trackDropdown(
                                                'year_interval',
                                                item,
                                                analyticsConstants.CATEGORIES.FORECASTS
                                            );
                                            setYearInterval(item);
                                        }}
                                    />
                                </Fade>
                            )}
                            {window.innerWidth > 750 && (
                                <ButtonGroup>
                                    <IconButton
                                        active={chartsOpen}
                                        icon={<FontAwesomeIcon icon={faChartLine} />}
                                        onClick={() => setOpenState('chart')}
                                    />
                                    <IconButton
                                        active={tablesOpen}
                                        icon={<FontAwesomeIcon icon={faTable} />}
                                        onClick={setOpenState}
                                    />
                                </ButtonGroup>
                            )}

                            {(window.innerWidth < 750 ||
                                (properties[openedPropertyForecast] &&
                                    tableOpenName === 'forecastTableOpen')) && (
                                <Dropdown
                                    renderToggle={(props, ref) => (
                                        <IconButton
                                            active={true}
                                            {...props}
                                            ref={ref}
                                            icon={<FontAwesomeIcon icon={faEllipsisH} />}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    )}
                                    className="actions-dropdown"
                                    placement="bottomEnd"
                                    trigger={
                                        window.innerWidth <= 1024
                                            ? ['click', 'contextMenu', 'hover']
                                            : 'hover'
                                    }
                                    noCaret>
                                    {window.innerWidth <= 750 && (
                                        <Dropdown.Item>
                                            <ButtonGroup className="collapse-actions">
                                                <IconButton
                                                    active={chartsOpen}
                                                    icon={<FontAwesomeIcon icon={faChartLine} />}
                                                    onClick={() => setOpenState('chart')}
                                                />
                                                <IconButton
                                                    active={tablesOpen}
                                                    icon={<FontAwesomeIcon icon={faTable} />}
                                                    onClick={setOpenState}
                                                />
                                            </ButtonGroup>
                                        </Dropdown.Item>
                                    )}
                                    {showExportToPdf && (
                                        <Dropdown.Item
                                            className="export-to-pdf"
                                            onClick={(e) => {
                                                analytics.trackClick(
                                                    'export_to_pdf',
                                                    analyticsConstants.CATEGORIES.FORECASTS
                                                );
                                                e.stopPropagation();
                                                generatePdf(
                                                    properties[openedPropertyForecast]?._id
                                                );
                                            }}>
                                            <FontAwesomeIcon className="rs-icon" icon={faFilePdf} />
                                            {t('forecast.l_export_pdf')}
                                        </Dropdown.Item>
                                    )}
                                    {data?.datasets && data.datasets[2]?.data.length > 0 && (
                                        <Dropdown.Item
                                            className="export-to-pdf"
                                            onClick={(e) => {
                                                analytics.trackClick(
                                                    'open_e1b',
                                                    analyticsConstants.CATEGORIES.FORECASTS
                                                );
                                                e.stopPropagation();
                                                const activeE1bData = {
                                                    property: properties?.[openedPropertyForecast],
                                                    year: data?.labels?.[0]?.[0].toString()
                                                };
                                                dispatch(setActiveE1bData(activeE1bData));
                                                navigate(constants.ROUTES.E1B);
                                            }}>
                                            <FontAwesomeIcon className="rs-icon" icon={faFile} />
                                            {t('forecast.l_open_e1b')}
                                        </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                        className="export-to-pdf"
                                        onClick={(e) => {
                                            analytics.trackClick(
                                                'delete_forecast_data',
                                                analyticsConstants.CATEGORIES.FORECASTS
                                            );
                                            e.stopPropagation();
                                            deleteForecast();
                                        }}>
                                        <FontAwesomeIcon className="rs-icon" icon={faTrash} />
                                        {t('forecast.l_delete_forecast')}
                                    </Dropdown.Item>
                                    {window.innerWidth <= 750 && (
                                        <>
                                            <Dropdown.Separator></Dropdown.Separator>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(15)}
                                                active={yearInterval === 15}>
                                                15 Jahre
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(20)}
                                                active={yearInterval === 20}>
                                                20 Jahre
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(25)}
                                                active={yearInterval === 25}>
                                                25 Jahre
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(30)}
                                                active={yearInterval === 30}>
                                                30 Jahre
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(40)}
                                                active={yearInterval === 40}>
                                                40 Jahre
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setYearInterval(50)}
                                                active={yearInterval === 50}>
                                                50 Jahre
                                            </Dropdown.Item>
                                            {/* </Dropdown.Menu> */}
                                        </>
                                    )}
                                </Dropdown>
                            )}
                        </Stack>
                    </Stack>
                    <Collapse className="graph-container" in={chartsOpen} dimension="height">
                        <div>
                            <Line
                                options={forecastTableGraphOptions}
                                data={data}
                                plugins={tableOpenName === 'forecastTableOpen' ? [plugin] : []}
                            />
                        </div>
                    </Collapse>
                    <Collapse in={tablesOpen} dimension="height">
                        <div>
                            {tableOpenName === 'forecastTableOpen'
                                ? React.cloneElement(children, { graphOpen: chartsOpen })
                                : children}
                        </div>
                    </Collapse>
                </div>
            </div>
        </>
    );
};

export default TableAndGraphContainer;
