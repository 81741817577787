import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import 'leaflet/dist/leaflet.css';
import App from './App';
import store from './store/store';
import './localization/i18next';
import { AnalyticsProvider } from 'use-analytics';
import analyticsConfig from './utils/analyticsConfig';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <AnalyticsProvider instance={analyticsConfig}>
            <App />
        </AnalyticsProvider>
    </Provider>
);
