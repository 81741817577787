import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Toggle, Message, useToaster } from 'rsuite';
import sendRequest from '../utils/sendRequest';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import constants from '../utils/constants';
import LoginContainer from '../components/LoginContainer';
import LoginFooterWithButton from '../components/LoginFooterWithButton';
import showValidationErrors from '../utils/showValidationErrors';
import hideValidationError from '../utils/hideValidationError';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';

const Signup = () => {
    const [loginInfo, setLoginInfo] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [acceptMarketingMails, setAcceptMarketingMails] = useState(false);
    const [isVerifyPage, setIsVerifyPage] = useState(false);

    const userId = useSelector((state) => state.user.userId);
    const { t } = useTranslation();
    const validationSchema = getValidationSchemas(t).signup.objectTypeSchemaSpec;
    const navigate = useNavigate();
    const formRef = useRef();
    const toaster = useToaster();

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.SIGNUP);
    }, []);

    const errorMessage = (message) => {
        return (
            <Message showIcon type="error" closable>
                {message}
            </Message>
        );
    };

    const handleOnSubmit = async () => {
        let hasError = !formRef.current.check();
        if (hasError || !termsAccepted) {
            if (hasError) {
                showValidationErrors(formRef.current.root);
                await toaster.push(errorMessage(t('global.e_form_error')), {
                    duration: constants.MESSAGE_DURATION
                });
            }

            if (!termsAccepted)
                await toaster.push(errorMessage(t('authentication.e_tos')), {
                    duration: constants.MESSAGE_DURATION
                });
            return;
        }
        sendRequest('/signup', 'post', {
            email: loginInfo.email,
            password: loginInfo.password,
            marketingConsent: acceptMarketingMails
        }).then((resp) => {
            if (resp.success) setIsVerifyPage(true);
            else {
                if (resp.message)
                    toaster.push(errorMessage(t('authentication.e_email_already_registered')), {
                        duration: constants.MESSAGE_DURATION
                    });
                else
                    toaster.push(errorMessage(t('global.e_server_error')), {
                        duration: constants.MESSAGE_DURATION
                    });
            }
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleOnSubmit();
        }
    };

    if (userId) return <Navigate replace to="/" />;
    if (isVerifyPage)
        return <Navigate replace to={`/email-sent/${loginInfo.email}/confirmation`} />;
    else
        return (
            <LoginContainer
                footer={
                    <LoginFooterWithButton
                        pText={t('authentication.l_already_registered')}
                        btnText={t('authentication.login_now')}
                        onClick={() => navigate('/login')}
                    />
                }>
                <h2>{t('authentication.h_register')}</h2>
                <Form
                    ref={formRef}
                    onKeyDown={handleKeyDown}
                    checkTrigger="none"
                    onChange={(object) => {
                        setLoginInfo(object);
                    }}>
                    <Form.Group controlId="email">
                        <Form.Control
                            name="email"
                            type="email"
                            autoFocus
                            placeholder={t('authentication.l_email')}
                            errorPlacement="bottomStart"
                            onChange={(value, event) => hideValidationError(event)}
                            rule={validationSchema.email}
                        />
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Control
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder={t('authentication.l_password')}
                            errorPlacement="bottomStart"
                            onChange={(value, event) => hideValidationError(event)}
                            rule={validationSchema.password}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>
                            <Toggle
                                onChange={() => setAcceptMarketingMails(!acceptMarketingMails)}
                            />
                            <div>{t('authentication.l_marketing_email_consent')}</div>
                        </Form.ControlLabel>
                    </Form.Group>
                    <Form.Group>
                        <Form.ControlLabel>
                            <Toggle onChange={() => setTermsAccepted(!termsAccepted)} />
                            <div>
                                <Trans
                                    i18nKey="authentication.l_tos_and_privacy_consent"
                                    components={{
                                        link1: (
                                            <Link
                                                to="https://www.renteezy.at/nutzungsbedingungen"
                                                target="_blank"
                                            />
                                        ),
                                        link2: (
                                            <Link
                                                to="https://www.renteezy.at/datenschutzbestimmungen?hsLang=de-at"
                                                target="_blank"
                                            />
                                        )
                                    }}
                                />
                            </div>
                        </Form.ControlLabel>
                    </Form.Group>
                    <Form.Group>
                        <Button appearance="primary" onClick={handleOnSubmit}>
                            {t('authentication.l_register')}
                        </Button>
                    </Form.Group>
                </Form>
            </LoginContainer>
        );
};

export default Signup;
