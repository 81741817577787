import React from 'react';
import { Button, Panel, Stack } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';

const DashboardPanel = ({
    title,
    description,
    isSelectable,
    checked,
    onPanelClicked,
    btnLabel,
    onBtnClicked
}) => {
    const iconOpacity = checked ? 1 : 0.08;
    return (
        <Panel
            header={
                <Stack justifyContent="space-between">
                    <span className="dashboard-panel-title">{title}</span>
                    <FontAwesomeIcon
                        className="rs-icon"
                        icon={faBadgeCheck}
                        style={{ opacity: iconOpacity }}
                    />
                </Stack>
            }
            shaded
            className={`dashboard-panel ${checked ? 'active' : ''}`}
            onClick={() => isSelectable && onPanelClicked()}>
            <div className="dashboard-panel-description">
                {description}
                {btnLabel && (
                    <Button appearance="primary" onClick={onBtnClicked}>
                        {btnLabel}
                    </Button>
                )}
            </div>
        </Panel>
    );
};

export default DashboardPanel;
