import React from 'react';
import ResponsiveNav from '@rsuite/responsive-nav';

const ResponsiveStatusNavBar = ({ active, onSelect, titles, justified, ...props }) => {
    return (
        <ResponsiveNav {...props} activeKey={active} onSelect={onSelect} justified={justified}>
            {titles.map((item) => (
                <ResponsiveNav.Item key={item.eventKey} eventKey={item.eventKey}>
                    {item.text}
                </ResponsiveNav.Item>
            ))}
        </ResponsiveNav>
    );
};

export default ResponsiveStatusNavBar;
