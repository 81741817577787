const analyticsConstants = {
    EVENT_NAMES: {
        BUTTON_CLICK: 'Click Button',
        TOGGLE: 'Toggle',
        MODAL: 'Modal',
        DIALOG: 'Dialog',
        DROPDOWN: 'Dropdown',
        HELP_PANEL: 'Help Panel',
        SELECT_NAV_ITEM: 'Select Nav Item',
        UPDATE_USER: 'Update user',
        UPDATE_PASSWORD: 'Update Password',
        DELETE_USER: 'Delete User',
        UPDATE_LOGO: 'Update Logo',
        DELETE_LOGO: 'Delete Logo',
        CREATE_TAXSUBJECT: 'Create Tax Subject',
        UPDATE_TAXSUBJECT: 'Update Tax Subject',
        DELETE_TAXSUBJECT: 'Delete Tax Subject',
        CHANGE_TAXSUBJECT: 'Change Tax Subject',
        CREATE_INVITE: 'Create Invite',
        DISABLE_INVITE: 'Disable Invite',
        ACCEPT_INVITE: 'Accept Invite',
        CREATE_PROPERTY: 'Create Property',
        UPDATE_PROPERTY: 'Update Property',
        CHANGE_PROPERTY: 'Change Property',
        DELETE_PROPERTY: 'Delete Property',
        CREATE_FORECAST: 'Create Forecast',
        UPDATE_FORECAST: 'Update Forecast',
        DELETE_FORECAST: 'Delete Forecast',
        CREATE_HISTORICAL_DATA: 'Create Historical Data',
        UPDATE_HISTORICAL_DATA: 'Update Historical Data',
        DELETE_HISTORICAL_DATA: 'Delete Historical Data'
    },
    PAGES: {
        ADMIN: 'Admin',
        EMAIL_SENT: 'Email Sent',
        FORGOTTEN_PASSWORD: 'Forgotten Password',
        LOGIN: 'Login',
        RESET_PASSWORD: 'Reset Password',
        SIGNUP: 'Signup',
        DASHBOARD: 'Dashboard',
        PROFILE: 'Profile',
        TAX_SUBJECTS: 'Tax Subjects',
        TAX_SUBJECT_DASHBOARD: 'Tax Subject Dashboard',
        CREATE_TAX_SUBJECT: 'Create Tax Subject',
        EDIT_TAX_SUBJECT: 'Edit Tax Subject',
        PROPERTIES: 'Properties',
        PROPERTY_DASHBOARD: 'Property Dashboard',
        CREATE_PROPERTY: 'Create Property',
        EDIT_PROPERTY: 'Edit Property',
        FORECAST_CALCULATION: 'Forecast Calculation',
        CREATE_FORECAST: 'Create Forecast',
        EDIT_FORECAST: 'Edit Forecast',
        HISTORICAL_DATA: 'Historical Data'
    },
    CATEGORIES: {
        USERS: 'users',
        TAX_SUBJECTS: 'tax_subjects',
        PROPERTIES: 'properties',
        FORECASTS: 'forecasts'
    },
    STATUS: {
        SUCCESS: 'success',
        FAILED: 'failed'
    }
};

export default analyticsConstants;
