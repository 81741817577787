import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from 'rsuite';
import { faCircle, faLock } from '@fortawesome/pro-solid-svg-icons';
import { faCircle as faCircleEmpty } from '@fortawesome/free-regular-svg-icons';
import ConfirmDialog from './dialogs/ConfirmDialog';
import { setActiveScenarios } from '../reducers/userSlice';
import { useDispatch } from 'react-redux';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import constants from '../utils/constants';

const ScenarioCard = ({
    id,
    color,
    label,
    labelDetail,
    numberOfYears,
    activeScenarios,
    setOpenScenarioPage,
    forecastTables,
    scenarios,
    locked,
    limit,
    tableKey
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dialogOpen, setDialogOpen] = useState(false);
    let scenarioLabel = tableKey ? tableKey : label;
    const active =
        activeScenarios &&
        activeScenarios.includes(scenarioLabel + (id ? id : '')) &&
        forecastTables.length > 0;

    const activeClicked = (e) => {
        e.stopPropagation();
        dispatch(
            setActiveScenarios(
                active
                    ? activeScenarios.filter(
                          (scenario) => scenario !== scenarioLabel + (id ? id : '')
                      )
                    : [...activeScenarios, scenarioLabel + (id ? id : '')]
            )
        );
    };

    const componentClicked = () => {
        setOpenScenarioPage(
            scenarios.filter((scenario) => scenario.scenario_name === scenarioLabel).length > 0
                ? {
                      ...scenarios.filter(
                          (scenario) => scenario.scenario_name === scenarioLabel
                      )[0],
                      color: color
                  }
                : {
                      scenario_name: scenarioLabel,
                      color: color
                  }
        );
    };

    return (
        <>
            <Stack
                onClick={locked ? () => setDialogOpen(true) : () => componentClicked()}
                className="scenario-card">
                <FontAwesomeIcon
                    icon={locked ? faLock : active ? faCircle : faCircleEmpty}
                    style={{ color: color }}
                    className="colored-circle"
                    onClick={activeClicked}
                />
                <Stack direction="column" alignItems="flex-start">
                    <Stack.Item className="scenario-card-title">
                        {label}
                        <span>{labelDetail}</span>
                    </Stack.Item>
                    <Stack.Item
                        className={`scenario-card-info ${
                            numberOfYears === '-' || numberOfYears > limit ? 'hobbyism' : ''
                        }`}>
                        <Stack justifyContent="space-between" spacing={10}>
                            {t('forecast.l_total_surplus_after_year_x').replace(
                                '{{year}}',
                                numberOfYears !== undefined ? numberOfYears : '-'
                            )}
                            {(numberOfYears === '-' || numberOfYears > limit) && (
                                <FontAwesomeIcon icon={faWarning} className="warning-icon" />
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>
                {/*<FontAwesomeIcon icon={faChevronRight} className="disclosure" />*/}
            </Stack>
            <ConfirmDialog
                onCancelClicked={() => setDialogOpen(false)}
                openDialog={dialogOpen}
                titleLabel={t('forecast.h_upgrade_plan')}
                cancelBtnText={t('global.cancel')}
                okBtnText={t('account.l_change_plan')}
                onOkClicked={() => navigate(constants.ROUTES.PLANS)}>
                {t('forecast.l_upgrade_plan_message2')}
            </ConfirmDialog>
        </>
    );
};

export default ScenarioCard;
