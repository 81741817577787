import React, { useEffect, useState, useRef } from 'react';
import { InputGroup, Form } from 'rsuite';
import InputBase from './InputBase';
import { NumericFormatter } from './NumericFormatter';
import InlineEditInput from './InlineEditInput';

const DFInputBase = ({ value, onChange, disabled, props, name, onFocus }) => {
    useEffect(() => {
        onChange(value);
    }, [value]);
    return (
        <NumericFormatter
            {...props}
            value={value}
            onChange={onChange}
            disabled={disabled}
            name={name}
            onFocus={onFocus}
        />
    );
};

const DoubleFieldedInput = ({
    label,
    infoText,
    infoTextTooltip,
    name,
    fullValue,
    basePercent,
    helpText,
    disabled,
    onChange,
    autoFocus,
    valueRule,
    className,
    percentRule,
    isIndentedInput,
    hideInfoIcon
}) => {
    const firstInputRef = useRef();
    const secondInputRef = useRef();

    const [percent, setPercent] = useState(basePercent ? +parseFloat(basePercent) : 0);
    const [mainValue, setMainValue] = useState(fullValue ? +((fullValue * percent) / 100) : 0);
    const [activeInput, setActiveInput] = useState('first');

    useEffect(() => {
        if (disabled) setPercent(basePercent ? +parseFloat(basePercent) : 0);
    }, [basePercent]);

    useEffect(() => {
        autoFocus && firstInputRef.current && firstInputRef.current.children[0].children[0].focus();
    }, [autoFocus]);

    useEffect(() => {
        fullValue && setMainValue(+((fullValue * percent) / 100));
    }, [fullValue]);

    const handleOnChange = (value, isPercentInput) => {
        // not crucial to have this, but showValidationErrors() is executing after this
        // and shows errors even tho the values have changed. To remove this the navigaton error showing
        // needs to be refactored/optimized
        setTimeout(() => {
            firstInputRef.current && firstInputRef.current.classList.add('hide-error-msg');
            secondInputRef.current && secondInputRef.current.classList.add('hide-error-msg');
        }, 0.1);
        let localValue;
        let localPercent;

        if (value === '' || value === null || value === undefined) {
            localValue = '';
            localPercent = '';
        } else {
            localValue = isPercentInput ? +((fullValue * value) / 100) : value;
            localPercent = isPercentInput ? value : (localValue * 100) / fullValue;
        }
        setMainValue(localValue);
        setPercent(localPercent);
        onChange(localValue, localPercent);
    };

    return (
        <InputBase
            label={label}
            infoText={infoText}
            className={className}
            hideInfoIcon={hideInfoIcon}
            infoTextTooltip={infoTextTooltip}
            isIndentedInput={isIndentedInput}>
            <Form.Group>
                <InputGroup className="custom-input-2">
                    <Form.Control
                        ref={firstInputRef}
                        name={name + '_percent'}
                        value={percent && +parseFloat(percent)}
                        onChange={
                            activeInput === 'first'
                                ? (value) => handleOnChange(value, true)
                                : undefined
                        }
                        disabled={disabled}
                        autoFocus={autoFocus}
                        unlimitedDecimals
                        rule={percentRule}
                        accepter={InlineEditInput}
                        autoComplete="off"
                        onFocus={() => setActiveInput('first')}
                        autoCorrect="off"
                        spellCheck="off"
                    />
                    <InputGroup.Addon className="custom-input-1-icon">%</InputGroup.Addon>
                </InputGroup>
                <InputGroup className="custom-input-3">
                    <Form.Control
                        ref={secondInputRef}
                        name={name}
                        errorPlacement="bottomEnd"
                        value={mainValue && +parseFloat(mainValue)}
                        disabled={disabled}
                        accepter={DFInputBase}
                        onChange={
                            activeInput === 'second'
                                ? (value) => handleOnChange(value, false)
                                : undefined
                        }
                        rule={valueRule}
                        onFocus={() => setActiveInput('second')}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="off"
                    />
                    <InputGroup.Addon className="custom-input-1-icon">€</InputGroup.Addon>
                </InputGroup>
                {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
            </Form.Group>
        </InputBase>
    );
};

export default DoubleFieldedInput;
