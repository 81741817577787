import React, { useRef, useState } from 'react';
import { Button, Divider, Form } from 'rsuite';
import InputWithLabelOnTop from '../../components/InputWithLabelOnTop';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import DoubleFieldedInput from '../../components/DoubleFieldedInput';
import constants from '../../utils/constants';
import analyticsConstants from '../../utils/analyticsConstants';
import { useNavigate } from 'react-router-dom';
import { analytics } from '../../utils/analytics';
import { useDispatch, useSelector } from 'react-redux';
import sendRequest from '../../utils/sendRequest';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import { setForecastData, setRequestFinished, setRequestMessage } from '../../reducers/userSlice';
import ToggleInput from '../../components/ToggleInput';

const ForecastQuickCheck = ({ runCalculations, setScenariosLoading }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();
    const dispatch = useDispatch();

    const properties = useSelector((state) => state.user.properties);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);

    const [data, setData] = useState({ l_financing_via_loan: true });
    const [dialogOpen, setDialogOpen] = useState(false);
    const validationSchema = getValidationSchemas(t).forecastQuickCheck.objectTypeSchemaSpec;

    let purchase_price =
        (data.l_purchase_price_building ? +data.l_purchase_price_building : 0) +
        (data.l_renovation_costs ? +data.l_renovation_costs : 0);

    const saveQuickCheck = async (isRedirect) => {
        let forecastData = await sendRequest('/getForecastDataFromQuickCheck', 'post', {
            data,
            property: properties[openedPropertyForecast]
        }).then((resp) => {
            if (resp.success === false) return;
            return resp;
        });
        dispatch(
            setForecastData({
                forecastsData: forecastData,
                _owner: properties[openedPropertyForecast]._id
            })
        );
        setDialogOpen(false);
        if (isRedirect) {
            setTimeout(() => navigate(constants.ROUTES.FORECAST_CALCULATION.FORECAST), 1000);
        } else {
            setScenariosLoading(true);
            setTimeout(() => runCalculations(), 1000);
        }
    };

    return (
        <>
            <Form
                onChange={(object) => {
                    const diffKeys = Object.keys(object)
                        .reduce((acc, key) => {
                            if (!(key in data) || data[key] !== object[key]) {
                                acc.push(key);
                            }
                            return acc;
                        }, [])
                        .filter(
                            (item) =>
                                ![
                                    'financing_amount',
                                    'financing_amount_percent',
                                    'l_financing_via_loan'
                                ].includes(item)
                        );
                    if (diffKeys.length > 0) setData((prev) => ({ ...prev, ...object }));
                }}
                ref={formRef}
                formValue={data}
                className="quick-check-form">
                <InputWithLabelOnTop
                    label="Brutto Kaufpreis Objekt" //{t('create_forecast.l_purchase_price_building')}
                    tooltipMessage={t('create_forecast.i_purchase_price_building')}
                    type="number"
                    symbolIcon="€"
                    autoFocus
                    name="l_purchase_price_building"
                    rule={validationSchema.l_purchase_price}
                />
                <InputWithLabelOnTop
                    label="Sanierungskosten"
                    type="number"
                    name="l_renovation_costs"
                    symbolIcon="€"
                    infoText={t('create_forecast.i_depreciation')}
                    rule={validationSchema.l_renovation_costs}
                />
                <InputWithLabelOnTop
                    label="Abschreibungsdauer - Sanierung"
                    type="number"
                    name="l_depreciation"
                    symbolIcon="Jahre"
                    infoText={t('create_forecast.i_depreciation')}
                    rule={validationSchema.l_depreciation}
                />
                <ToggleInput
                    label={t('create_forecast.l_financing_via_loan')}
                    infoText="tooltip"
                    className="label-on-top"
                    hideInfoIcon
                    defaultChecked={data.l_financing_via_loan}
                    onToggle={(value) =>
                        setData((prev) => ({ ...prev, l_financing_via_loan: value }))
                    }
                />
                {(data?.l_financing_via_loan === undefined ||
                    data?.l_financing_via_loan === true) && (
                    <>
                        <InputWithLabelOnTop
                            label={t('create_forecast.l_loan_term')}
                            tooltipMessage={t('create_forecast.i_loan_term')}
                            type="number"
                            symbolIcon="Monate"
                            name="l_loan_term"
                            rule={validationSchema.l_loan_term}
                        />
                        <InputWithLabelOnTop
                            label="Zinssatz" //{t('create_forecast.l_loan_adjustable_rate')}
                            tooltipMessage={t('create_forecast.i_loan_adjustable_rate')}
                            type="number"
                            symbolIcon="%"
                            name="l_loan_adjustable_rate"
                            rule={validationSchema.l_loan_fixed_rate}
                        />
                        <DoubleFieldedInput
                            label="Kreditbetrag (in % vom Kaufpreis)" //{t('create_forecast.l_loan_down_payment')}
                            infoText={t('create_forecast.l_loan_down_payment')}
                            name="financing_amount"
                            fullValue={purchase_price}
                            className="label-on-top"
                            hideInfoIcon
                            basePercent={
                                data?.financing_amount_percent !== undefined
                                    ? data.financing_amount_percent
                                    : 90
                            }
                            onChange={(item1, item2) =>
                                setData((prev) => ({
                                    ...prev,
                                    financing_amount: +item1,
                                    financing_amount_percent: +item2,
                                    l_financing_via_loan: true
                                }))
                            }
                            percentRule={validationSchema.l_loan_down_payment_percent}
                            valueRule={validationSchema.l_loan_down_payment}
                        />
                    </>
                )}
                <InputWithLabelOnTop
                    label={t('create_forecast.l_expected_monthly_rent')}
                    tooltipMessage={t('create_forecast.i_expected_monthly_rent')}
                    type="number"
                    symbolIcon="€"
                    name="l_expected_monthly_rent"
                    rule={validationSchema.l_expected_monthly_rent}
                />

                {/* <Button
                    className="forecast-edit-btn"
                    onClick={async () => {
                        const analyticsLabel = 'create_forecast';
                        analytics.trackClick(
                            analyticsLabel,
                            analyticsConstants.CATEGORIES.FORECASTS
                        );
                        const hasError = await formRef.current
                            .checkAsync()
                            .then((resp) => resp.hasError);
                        if (hasError) navigate(constants.ROUTES.FORECAST_CALCULATION.FORECAST);
                        else saveQuickCheck(true);
                    }}>
                    {t('create_forecast.l_open_advanced_forecast_entry')}
                </Button> */}
                <div className="forecast-sidebar-footer">
                    <Divider />
                    <Button
                        appearance="primary"
                        onClick={async () => {
                            analytics.trackClick(
                                'create_new_forecast',
                                analyticsConstants.CATEGORIES.FORECASTS
                            );
                            const hasError = await formRef.current
                                .checkAsync()
                                .then((resp) => resp.hasError);
                            if (hasError) {
                                dispatch(setRequestFinished(true));
                                dispatch(
                                    setRequestMessage({
                                        type: 'error',
                                        message: t('general.e_generic_form_error')
                                    })
                                );
                                return;
                            }
                            setDialogOpen(true);
                        }}>
                        {t('create_forecast.l_submit_quick_check')}
                    </Button>
                </div>
            </Form>
            <ConfirmDialog
                cancelBtnText={t('create_forecast.l_submit_quick_check_secondary')}
                okBtnText={t('create_forecast.l_submit_quick_check_primary')}
                onCancelClicked={() => setDialogOpen(false)}
                secondaryButtonClicked={() => saveQuickCheck(true)}
                onOkClicked={() => saveQuickCheck(false)}
                openDialog={dialogOpen}
                titleLabel={t('create_forecast.h_submit_quick_check_modal')}>
                {t('create_forecast.l_submit_quick_check_text')}
            </ConfirmDialog>
        </>
    );
};

export default ForecastQuickCheck;
