import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from './SimpleTable';

const CashflowTable = ({ tables, historicalYears, labels, labelClicked, colors }) => {
    const { t } = useTranslation();

    const numberFormat = Intl.NumberFormat('de-DE');
    let numberOfScenarios = colors.length;
    let customData = [];
    let purchase_price_net_incl_anchilliary_purchase_costs = {
        ['label']: {
            label: t('forecast.l_purchase_price_net_incl_ancillary_costs')
        }
    };
    let vat_before_rent_start = {
        ['label']: {
            label: t('forecast.l_vat_before_rent_start')
        }
    };
    let vat_refund = {
        ['label']: { label: t('forecast.l_vat_refund') }
    };
    let borrowed_funds_before_interest = {
        ['label']: { label: t('forecast.l_loan_amount') }
    };
    let rent_earnings_after_costs = {
        ['label']: {
            label: t('forecast.l_rent_income_after_cost_deduction')
        }
    };
    let financing = {
        ['label']: {
            label: t('forecast.l_financing_costs'),
            tooltip: t('forecast.i_financing_costs')
        }
    };
    let interest = {
        ['label']: {
            label: t('forecast.l_interest_expense'),
            tooltip: t('forecast.i_interest_expense')
        }
    };
    let repayment_full = {
        ['label']: { label: t('forecast.l_repayment') }
    };
    let l_cash_flow_before_taxes = {
        ['label']: {
            label: t('forecast.l_cash_flow_before_taxes')
        }
    };
    let l_tax_refund_or_payment = {
        ['label']: {
            label: t('forecast.l_tax_refund_or_payment')
        }
    };
    let l_cash_flow_after_taxes = {
        ['label']: {
            label: t('forecast.l_cash_flow_after_taxes')
        }
    };
    let l_cash_flow_after_taxes_cumulated = {
        ['label']: {
            label: t('forecast.l_cash_flow_after_taxes_cumulated')
        }
    };
    tables.map((table, index) => {
        table[Object.keys(table)[0]].map((item) => {
            purchase_price_net_incl_anchilliary_purchase_costs = {
                ...purchase_price_net_incl_anchilliary_purchase_costs,
                [item.year]: numberFormat.format(
                    Math.round(item.purchase_price_net_incl_anchilliary_purchase_costs)
                )
            };
            vat_before_rent_start = {
                ...vat_before_rent_start,
                [item.year]: numberFormat.format(Math.round(item.vat_before_rent_start))
            };
            vat_refund = {
                ...vat_refund,
                [item.year]: numberFormat.format(Math.round(item.vat_refund))
            };
            borrowed_funds_before_interest = {
                ...borrowed_funds_before_interest,
                [item.year]: numberFormat.format(Math.round(item.borrowed_funds_before_interest))
            };
            rent_earnings_after_costs = {
                ...rent_earnings_after_costs,
                [item.year]: numberFormat.format(Math.round(item.rent_earnings_after_costs))
            };
            financing = {
                ...financing,
                [item.year]: numberFormat.format(Math.round(item.financing))
            };
            interest = {
                ...interest,
                [item.year]: numberFormat.format(Math.round(item.interest_amount_full))
            };
            repayment_full = {
                ...repayment_full,
                [item.year]: numberFormat.format(Math.round(item.repayment_full))
            };
            l_cash_flow_before_taxes = {
                ...l_cash_flow_before_taxes,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_before_taxes))
            };
            l_tax_refund_or_payment = {
                ...l_tax_refund_or_payment,
                [item.year]: numberFormat.format(Math.round(item.l_tax_refund_or_payment))
            };
            l_cash_flow_after_taxes = {
                ...l_cash_flow_after_taxes,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_after_taxes))
            };
            l_cash_flow_after_taxes_cumulated = {
                ...l_cash_flow_after_taxes_cumulated,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_after_taxes_cumulated))
            };
        });
        let i = 0;
        customData[index + numberOfScenarios * i] =
            purchase_price_net_incl_anchilliary_purchase_costs;
        i++;
        customData[index + numberOfScenarios * i] = vat_before_rent_start;
        i++;
        customData[index + numberOfScenarios * i] = vat_refund;
        i++;
        customData[index + numberOfScenarios * i] = borrowed_funds_before_interest;
        i++;
        customData[index + numberOfScenarios * i] = rent_earnings_after_costs;
        i++;
        customData[index + numberOfScenarios * i] = financing;
        i++;
        customData[index + numberOfScenarios * i] = interest;
        i++;
        customData[index + numberOfScenarios * i] = repayment_full;
        i++;
        customData[index + numberOfScenarios * i] = l_cash_flow_before_taxes;
        i++;
        customData[index + numberOfScenarios * i] = l_tax_refund_or_payment;
        i++;
        customData[index + numberOfScenarios * i] = l_cash_flow_after_taxes;
        i++;
        customData[index + numberOfScenarios * i] = l_cash_flow_after_taxes_cumulated;
        i++;
        purchase_price_net_incl_anchilliary_purchase_costs = {};
        vat_before_rent_start = {};
        vat_refund = {};
        borrowed_funds_before_interest = {};
        rent_earnings_after_costs = {};
        financing = {};
        interest = {};
        repayment_full = {};
        financing = {};
        l_cash_flow_before_taxes = {};
        l_tax_refund_or_payment = {};
        l_cash_flow_after_taxes = {};
        l_cash_flow_after_taxes_cumulated = {};
    });

    return (
        <SimpleTable
            historicalYears={historicalYears}
            table={customData}
            labels={labels}
            labelClicked={labelClicked}
            colors={colors}
        />
    );
};

export default CashflowTable;
