import React from 'react';
import { Nav } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

const StatusNavBar = ({ active, onSelect, titles, ...props }) => {
    const iconStatusComplete = <FontAwesomeIcon icon={faCheckCircle} className="icon-checked" />;
    const iconStatusError = <FontAwesomeIcon icon={faCheckCircle} className="icon-checked-error" />;
    const iconStatusSuccess = (
        <FontAwesomeIcon icon={faCheckCircle} className="icon-checked-success" />
    );

    return (
        <Nav {...props} vertical activeKey={active} onSelect={onSelect}>
            {titles.map((item) => (
                <Nav.Item key={item.eventKey} eventKey={item.eventKey}>
                    {item.text}
                    {item.hasError
                        ? iconStatusError
                        : item?.completed
                          ? iconStatusSuccess
                          : iconStatusComplete}
                </Nav.Item>
            ))}
        </Nav>
    );
};

export default StatusNavBar;
