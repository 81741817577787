import React from 'react';
import { Button } from 'rsuite';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import { FaArrowCircleRight } from 'react-icons/fa';

const PricingFeaturesCard = ({
    name,
    price,
    priceSuffix,
    primaryFeatures,
    secondaryFeatures,
    buttonText,
    highlight = false,
    enabled = true,
    footerText,
    onButtonClick
}) => {
    return (
        <div className="pricing-features-card" style={{ pointerEvents: enabled ? 'auto' : 'none' }}>
            <div className="pricing-features-card-name">{name}</div>
            <div className="pricing-features-card-price">
                {price}
                {priceSuffix && <span> / Jahr</span>}
            </div>

            <div className="pricing-features-card-primaryFeatures">
                {primaryFeatures.map((feature) => (
                    <div key={feature.key}>
                        <div>
                            <FaArrowCircleRight />
                        </div>
                        {feature.description}
                    </div>
                ))}
            </div>

            <div className="pricing-features-card-secondaryFeatures">
                {secondaryFeatures.map((feature) => (
                    <div key={feature.key} style={{ opacity: feature.enabled ? 1 : 0.3 }}>
                        <div>
                            <CheckRoundIcon />
                        </div>
                        {feature.description}
                    </div>
                ))}
            </div>

            <div className="pricing-features-card-footer">
                {enabled ? (
                    <Button
                        appearance={highlight ? 'primary' : 'ghost'}
                        style={{
                            backgroundColor: highlight && '#3b365c',
                            color: highlight && 'white'
                        }}
                        onClick={onButtonClick}>
                        {buttonText}
                    </Button>
                ) : (
                    <div>{footerText}</div>
                )}
            </div>
        </div>
    );
};

export default PricingFeaturesCard;
