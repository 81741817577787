import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializePaddle } from '@paddle/paddle-js';
import ContentPageBase from '../../components/ContentPageBase';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { Form, Divider, ButtonGroup, Button } from 'rsuite';
import ClickInput from '../../components/ClickInput';
import ToggleInput from '../../components/ToggleInput';
import ProfileSettingsContainer from '../../components/ProfileSettingsContainer';
import sendRequest from '../../utils/sendRequest';
import { useNavigate } from 'react-router-dom';
import {
    setRequestFinished,
    setRequestMessage,
    setUserId,
    getUser
} from '../../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import isEmail from 'validator/lib/isEmail';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import showValidationErrors from '../../utils/showValidationErrors';
import UploadLogoInput from '../../components/UploadLogoInput';
import subscriptionExists from '../../utils/subscriptionExists';
import subscriptionExpired from '../../utils/subscriptionExpired';
import constants from '../../utils/constants';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import getUTCDate from '../../utils/getUTCDate';

const Profile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const formRef = useRef();

    const user = useSelector((state) => state.user.user);
    const paymentsConfig = useSelector((state) => state.user.paymentsConfig);

    const [data, setData] = useState({
        firstname: '',
        lastname: '',
        firma: '',
        strasse: '',
        postleitzahl: '',
        stadt: '',
        _owner: user?._id
    });
    const [marketingConsent, setMarketingConsent] = useState(false);

    const [loaded, setLoaded] = useState(false);
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

    const [plansAndPaymentData, setPlansAndPaymentData] = useState(null);
    const [paddle, setPaddle] = useState();

    const formatDate = (date) => {
        const defaultDate = getUTCDate(date);
        const formattedDate = defaultDate.toLocaleDateString('de-DE', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
        return formattedDate;
    };

    const validationSchema = getValidationSchemas(t).profile.objectTypeSchemaSpec;

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.PROFILE);
        sendRequest('/getUserData', 'get').then((resp) => {
            setData({ ...data, ...resp });
            setMarketingConsent(resp.marketingConsent);
            setLoaded(true);
        });
    }, []);

    useEffect(() => {
        initializePaddle({
            environment: paymentsConfig.environment,
            seller: paymentsConfig.sellerID
        }).then((paddleInstance) => setPaddle(paddleInstance));
    }, [paymentsConfig]);

    useEffect(() => {
        user?.subscriptionData && parsePlansAndPaymentData(user?.subscriptionData);
    }, [user]);

    const parsePlansAndPaymentData = (data) => {
        const updateData = {
            planName: data.plan_name,
            billingInterval: data.billing_cycle?.interval,
            amount: data.unit_price?.amount.slice(0, -2),
            currencyCode: data.unit_price?.currency_code,
            customerId: data.customer_id,
            subscriptionId: data.subscription_id,
            status: data.status,
            startsAt: formatDate(data.current_billing_period?.starts_at),
            endsAt: formatDate(data.current_billing_period?.ends_at),
            isSubscriptionCanceled: data.scheduled_change?.action === 'cancel'
        };
        setPlansAndPaymentData(updateData);
    };

    const updatePaymentMethod = () => {
        analytics.trackClick('update_payment_method', analyticsConstants.CATEGORIES.USERS);
        sendRequest(`/updatePaymentMethod/${plansAndPaymentData.subscriptionId}`, 'get').then(
            (resp) => {
                resp.data && openPaddleCheckout(resp.data);
            }
        );
    };

    const openPaddleCheckout = (transactionId) => {
        paddle?.Checkout.open({
            transactionId: transactionId
        });
    };

    const updateUser = async () => {
        analytics.trackClick('update_profile', analyticsConstants.CATEGORIES.USERS);
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        analytics.trackClick('update_profile', analyticsConstants.CATEGORIES.USERS, 0, hasError);
        if (hasError) {
            showValidationErrors(formRef.current.root);
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: t('account.i_account_update_error')
                })
            );
            return;
        }
        setUpdateButtonDisabled(true);
        sendRequest('/editUser', 'post', data).then((resp) => {
            if (resp.success) {
                dispatch(getUser(resp.data));
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('account.i_account_update_success')
                    })
                );
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('account.i_account_update_error')
                    })
                );
                setUpdateButtonDisabled(false);
            }
            analytics.trackUpdateUser(user?._id, resp.success);
        });
    };

    const editEmail = () => {
        console.log('Edit Email');
    };

    const editPassword = () => {
        analytics.trackClick('update_password', analyticsConstants.CATEGORIES.USERS);
        if (isEmail(data.email)) {
            sendRequest('/forgotPass', 'post', { email: data.email }).then((resp) => {
                if (resp.success) {
                    analytics.trackUpdatePassword(user?._id, true);
                    sendRequest('/logout', 'post').then(() => {
                        dispatch(setUserId(undefined));
                    });
                    navigate(`/email-sent/${data.email}/password`);
                } else {
                    analytics.trackUpdatePassword(user?._id, false);
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: t('account.i_account_update_error')
                        })
                    );
                }
            });
        }
    };

    const updateMarketingConsent = (state) => {
        analytics.trackToggle(
            'marketing_notifications',
            analyticsConstants.CATEGORIES.USERS,
            state
        );
        sendRequest('/editUserMarketingConsent', 'post', {
            marketingConsent: state
        }).then((resp) => {
            if (resp.success) {
                setMarketingConsent(state);
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('account.i_account_update_success')
                    })
                );
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('account.i_account_update_error')
                    })
                );
            }
        });
    };

    return (
        <ContentPageBase
            header={t('account.h_account')}
            footer={
                <div className="page-content-footer clearfix">
                    <Divider />
                    <ButtonGroup>
                        <Button
                            onClick={() => navigate(constants.ROUTES.HOME)}
                            className={updateButtonDisabled ? 'invisible' : ''}>
                            {t('global.cancel')}
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={updateUser}
                            disabled={updateButtonDisabled}>
                            {t('global.submit')}
                        </Button>
                    </ButtonGroup>
                </div>
            }>
            {loaded && (
                <>
                    <Form
                        formDefaultValue={data}
                        ref={formRef}
                        checkTrigger="none"
                        onChange={(object) => {
                            setData({ ...data, ...object });
                            setUpdateButtonDisabled(false);
                        }}>
                        <>
                            <h3 className="page-subtitle">{t('account.h_contact_person')}</h3>
                            <LabeledInputWithSymbol
                                label={t('account.l_firstname')}
                                autoFocus
                                type="text"
                                name="firstname"
                                rule={validationSchema.firstname}
                            />
                            <LabeledInputWithSymbol
                                label={t('account.l_lastname')}
                                type="text"
                                name="lastname"
                                rule={validationSchema.lastname}
                            />

                            <h3 className="page-subtitle">{t('account.h_address')}</h3>
                            <LabeledInputWithSymbol
                                label={t('account.l_company')}
                                infoTextTooltip
                                type="text"
                                name="firma"
                            />
                            <LabeledInputWithSymbol
                                label={t('account.l_address_line')}
                                type="text"
                                name="strasse"
                            />
                            <LabeledInputWithSymbol
                                label={t('account.l_zip')}
                                type="number"
                                formatNumeric={false}
                                alignLeft={true}
                                name="postleitzahl"
                                rule={validationSchema.zipCode}
                            />
                            <LabeledInputWithSymbol
                                label={t('account.l_city')}
                                type="text"
                                name="stadt"
                            />
                        </>
                    </Form>
                    {user.userType !== t(constants.USER_TYPES.INVESTOR) && (
                        <UploadLogoInput
                            label={t('account.l_company_logo')}
                            className={'logo-upload'}
                        />
                    )}
                </>
            )}

            {/* TO DO */}
            <h3 className="page-subtitle">{t('account.h_email')}</h3>
            <ClickInput label={data.email} onClick={editEmail} />

            <h3 className="page-subtitle">{t('account.h_password')}</h3>
            <ClickInput
                label={t('account.l_password')}
                actionName={t('global.change')}
                onClick={editPassword}
            />

            <h3 className="page-subtitle">{t('account.h_notifications')}</h3>
            <ToggleInput
                disabled={true}
                defaultChecked
                label={t('account.l_system_notifications')}
                infoText={t('account.i_system_notifications')}
                // infoTextTooltip
            />
            {loaded && (
                <ToggleInput
                    defaultChecked={marketingConsent}
                    label={t('account.l_marketing_notifications')}
                    onToggle={(state) => updateMarketingConsent(state)}
                />
            )}

            <ProfileSettingsContainer header={t('account.h_plan')}>
                <div className="plan-details-container">
                    {subscriptionExists(plansAndPaymentData?.planName) &&
                    !(
                        user.userType === t(constants.USER_TYPES.TA) &&
                        getUTCDate(user?.trialEndDate) > getUTCDate()
                    ) ? (
                        <div>
                            <ClickInput
                                label={
                                    constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE[
                                        Object.keys(
                                            constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE
                                        ).filter(
                                            (item) =>
                                                constants.SUBSSCRIPTION_TYPE
                                                    .VALID_SUBSCRIPTION_TYPE[item].value ===
                                                plansAndPaymentData?.planName
                                        )?.[0]
                                    ]?.label
                                }
                                actionName={
                                    user?.userType !== t(constants.USER_TYPES.TA)
                                        ? t('account.l_change_plan')
                                        : undefined
                                }
                                onClick={() => {
                                    if (user?.userType !== t(constants.USER_TYPES.TA)) {
                                        analytics.trackClick(
                                            'update_plan',
                                            analyticsConstants.CATEGORIES.USERS
                                        );
                                        navigate(constants.ROUTES.PLANS);
                                    }
                                }}
                            />

                            <div>
                                <p>
                                    {t('account.l_plan_costs').replace(
                                        '{{costs}}',
                                        plansAndPaymentData?.amount
                                    )}
                                </p>

                                <p>
                                    {t('account.l_plan_next_payment').replace(
                                        '{{date}}',
                                        plansAndPaymentData?.endsAt
                                    )}
                                </p>
                            </div>
                            {!plansAndPaymentData?.isSubscriptionCanceled && (
                                <>
                                    <ClickInput
                                        label={t('account.l_payment_method')}
                                        actionName={t('account.l_update_payment_method')}
                                        onClick={updatePaymentMethod}
                                    />
                                </>
                            )}
                        </div>
                    ) : (
                        <>
                            <ClickInput
                                label={
                                    subscriptionExpired(plansAndPaymentData?.planName)
                                        ? 'None'
                                        : t('account.l_trial_plan')
                                }
                                actionName={
                                    user?.userType === t(constants.USER_TYPES.TA)
                                        ? undefined
                                        : t('account.l_purchase_plan')
                                }
                                onClick={() => {
                                    if (user?.userType !== t(constants.USER_TYPES.TA)) {
                                        analytics.trackClick(
                                            'update_plan',
                                            analyticsConstants.CATEGORIES.USERS
                                        );
                                        navigate(constants.ROUTES.PLANS);
                                    }
                                }}
                            />
                        </>
                    )}
                </div>

                <ClickInput
                    label={t('account.l_need_help')}
                    actionName={t('authentication.l_contact_support')}
                    onClick={() => {
                        analytics.trackClick(
                            'contact_support',
                            analyticsConstants.CATEGORIES.USERS
                        );
                        window.open('mailto:contact@renteezy.at', '_blank');
                    }}
                />
            </ProfileSettingsContainer>
        </ContentPageBase>
    );
};

export default Profile;
