import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Forecast from './Forecast';
import ContentPageBase from '../../components/ContentPageBase';
import HistoricalData from './HistoricalData';
import ForecastSidebar from '../forecastPages/ForecastSidebar';
import ForecastTablesAndGraphs from '../forecastPages/ForecastTablesAndGraphs';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import constants from '../../utils/constants';
import ResponsiveStatusNavBar from '../../components/ResponsiveStatusNavBar';
import { useTranslation } from 'react-i18next';

const ForecastCalculation = () => {
    const location = useLocation();
    const { t } = useTranslation();

    const properties = useSelector((state) => state.user.properties);

    const [forecastTables, setForecastTables] = useState([]);
    const [historicalDataYear, setHistoricalDataYear] = useState();
    const [active, setActive] = useState(1);

    // tab nav for mobile
    const navbar = [
        { eventKey: 1, text: t('forecast.l_select_scenarios') },
        { eventKey: 2, text: t('forecast.l_result_view') }
    ];

    useEffect(() => {
        if (location.pathname === constants.ROUTES.FORECAST_CALCULATION.HOME)
            analytics.trackPage(analyticsConstants.PAGES.FORECAST_CALCULATION);
    }, [location]);

    return location.pathname === constants.ROUTES.FORECAST_CALCULATION.FORECAST ? (
        <Forecast forecastTables={forecastTables} />
    ) : location.pathname === constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA ? (
        <HistoricalData
            year={historicalDataYear}
            forecastTable={{
                ...forecastTables?.[0]?.Prognoserechnung.filter(
                    (item) => item.year === historicalDataYear
                )?.[0]
            }}
        />
    ) : (
        <ContentPageBase
            className={`forecast-calculation-page ${properties.length > 0 && forecastTables.length > 0 ? '' : 'empty'}`}
            leftItem={
                window.innerWidth > 1024 ? (
                    <ForecastSidebar
                        forecastTables={forecastTables}
                        setForecastTables={setForecastTables}
                    />
                ) : null
            }
            topItem={
                window.innerWidth <= 1024 ? (
                    <ResponsiveStatusNavBar
                        appearance="tabs"
                        active={active}
                        onSelect={setActive}
                        titles={navbar}
                        justified
                    />
                ) : null
            }>
            {window.innerWidth <= 1024 && active === 1 && (
                <ForecastSidebar
                    forecastTables={forecastTables}
                    setForecastTables={setForecastTables}
                />
            )}
            {((window.innerWidth <= 1024 && active === 2) || window.innerWidth > 1024) && (
                <ForecastTablesAndGraphs
                    forecastTables={forecastTables}
                    setForecastTables={setForecastTables}
                    setHistoricalDataYear={setHistoricalDataYear}
                />
            )}
        </ContentPageBase>
    );
};

export default ForecastCalculation;
