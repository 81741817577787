import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, IconButton, Stack, Input, Divider, Panel } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import InputWithLabelOnTop from '../../components/InputWithLabelOnTop';
import { faCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCircle as emptyCircle } from '@fortawesome/free-regular-svg-icons';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getHistoricalData,
    getScenarios,
    setActiveScenarios,
    setForecastData,
    setHistoricalData,
    setScenarios
} from '../../reducers/userSlice';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import constants from '../../utils/constants';
import showValidationErrors from '../../utils/showValidationErrors';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import ToggleInput from '../../components/ToggleInput';
import DoubleFieldedInput from '../../components/DoubleFieldedInput';

const InputWithCustomOnChange = ({ ...props }) => {
    const [value, setValue] = useState(props.value);
    return (
        <Input
            ref={props.ref}
            {...props}
            onChange={(value, e) => {
                setValue(value);
                props.onChange(value, e);
            }}
            value={value}
        />
    );
};

const ScenarioDataPage = ({
    openScenarioPage,
    setOpenScenarioPage,
    forecastDataComplete,
    activeScenarios,
    saveTriggered
}) => {
    // Helper to avoid useEffect on initial mount
    const [initializingNew, setInitializingNew] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const forecastData = useSelector((state) => state.user.forecastData);
    const properties = useSelector((state) => state.user.properties);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const historicalData = useSelector((state) => state.user.historicalData);
    const scenarios = useSelector((state) => state.user.scenarios);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dataSaved, setDataSaved] = useState(true);

    const validationSchema = getValidationSchemas(t).scenarioDataPage.objectTypeSchemaSpec;
    const active = activeScenarios.includes(
        openScenarioPage.scenario_name +
            (openScenarioPage.scenario_name !== 'Prognoserechnung' &&
            openScenarioPage.scenario_name !== 'Ist Daten'
                ? openScenarioPage?._id
                    ? openScenarioPage._id
                    : ''
                : '')
    );
    let changedByHistorical = false;
    let hasError = false;

    useEffect(() => {
        if (openScenarioPage.scenario_name === 'Ist Daten')
            dispatch(getHistoricalData(properties[openedPropertyForecast]));

        // If this is a new scenario, save it and then fetch it to get the id for further edits
        if (
            !openScenarioPage._id &&
            openScenarioPage.scenario_name !== 'Prognoserechnung' &&
            openScenarioPage.scenario_name !== 'Ist Daten'
        ) {
            save();
            setTimeout(() => {
                setInitializingNew(false);
                dispatch(getScenarios(forecastData._id));
            }, 500);
        } else {
            setInitializingNew(false);
        }
    }, []);

    const last_historical_year = useMemo(
        () =>
            historicalData
                ? Object.keys(historicalData)[Object.keys(historicalData).length - 1]
                : undefined,
        [historicalData]
    );

    const data = useRef(
        Object.keys(openScenarioPage).length > 2
            ? openScenarioPage
            : { ...forecastData.forecastsData, ...openScenarioPage }
    );
    const formRef = useRef();
    useEffect(() => {
        if (
            openScenarioPage.scenario_name === 'Ist Daten' &&
            Object.keys(historicalData).length > 0
        ) {
            data.current = {
                ...data.current,
                ...historicalData?.[last_historical_year]
            };
            changedByHistorical = true;
        }
    }, [historicalData]);

    useEffect(() => {
        if (openScenarioPage.scenario_name === 'Prognoserechnung') {
            data.current = { ...data.current, ...forecastData.forecastsData };
            setOpenScenarioPage(data.current);
        }
    }, [forecastData]);

    useEffect(() => {
        // Skip this on initial mount, to only check for a new scenarios ID after save
        if (!initializingNew) {
            // If this is a new scenario, we'll use the ID of the latest added scenario for further edits
            if (!openScenarioPage._id && scenarios && scenarios.length > 0) {
                openScenarioPage._id = scenarios[scenarios.length - 1]._id;
                data.current._id = openScenarioPage._id;
                dispatch(
                    setActiveScenarios([
                        ...activeScenarios,
                        openScenarioPage.scenario_name +
                            (openScenarioPage?._id ? openScenarioPage._id : '')
                    ])
                );
            }
        }
    }, [scenarios]);

    const timeout = useRef();

    const save = (hasError) => {
        if (hasError) {
            showValidationErrors(formRef.current.root);
            return;
        }
        if (timeout.current) clearTimeout(timeout.current);
        if (openScenarioPage.scenario_name === 'Prognoserechnung')
            dispatch(
                setForecastData({
                    ...forecastData,
                    forecastsData: { ...forecastData.forecastsData, ...data.current }
                })
            );
        else if (openScenarioPage.scenario_name === 'Ist Daten')
            dispatch(
                setHistoricalData({
                    ...properties[openedPropertyForecast],
                    historicalData: {
                        ...historicalData,
                        [last_historical_year]: {
                            ...historicalData[last_historical_year],
                            ...data.current
                        }
                    }
                })
            );
        else dispatch(setScenarios({ ...data.current, _owner: forecastData?._id }));
        setTimeout(() => saveTriggered(), 300);
        setDataSaved(true);
    };

    const handleChange = (values) => {
        data.current = { ...data.current, ...values };
        if (timeout.current) clearTimeout(timeout.current);
        if (dataSaved) setDataSaved(false);

        if (!changedByHistorical)
            timeout.current = setTimeout(() => {
                save(hasError);
            }, 1000);
        else changedByHistorical = false;
    };

    const deleteScenario = () => {
        dispatch(setScenarios({ ...data.current, deleteflag: true }));
        setOpenScenarioPage(false);
        if (active)
            dispatch(
                setActiveScenarios(
                    activeScenarios.filter(
                        (activeScenario) =>
                            activeScenario !==
                            openScenarioPage.scenario_name +
                                (openScenarioPage?._id ? openScenarioPage._id : '')
                    )
                )
            );
        setTimeout(() => dispatch(getScenarios(forecastData._id)), 500);
    };

    return (
        <>
            <Form
                formValue={data.current}
                onChange={(object) => {
                    const diffKeys = Object.keys(object)
                        .reduce((acc, key) => {
                            if (!(key in data.current) || data.current[key] !== object[key]) {
                                acc.push(key);
                            }
                            return acc;
                        }, [])
                        .filter(
                            (item) =>
                                ![
                                    'financing_amount',
                                    'financing_amount_percent',
                                    'l_financing_via_loan',
                                    'l_loan_down_payment'
                                ].includes(item)
                        );
                    if (diffKeys.length > 0) handleChange(object);
                }}
                className="scenario-form"
                onCheck={(e) => (hasError = JSON.stringify(e) !== JSON.stringify({}))}
                ref={formRef}>
                <Stack direction="column" justifyContent="space-between">
                    <Stack.Item>
                        <Stack className="scenario-form-header" justifyContent="space-between">
                            <Stack className="scenario-form-header-title">
                                <FontAwesomeIcon
                                    icon={active ? faCircle : emptyCircle}
                                    style={{ color: data.current.color }}
                                    onClick={() =>
                                        dispatch(
                                            setActiveScenarios(
                                                active
                                                    ? activeScenarios.filter(
                                                          (activeScenario) =>
                                                              activeScenario !==
                                                              openScenarioPage.scenario_name +
                                                                  (openScenarioPage?._id
                                                                      ? openScenarioPage._id
                                                                      : '')
                                                      )
                                                    : [
                                                          ...activeScenarios,
                                                          openScenarioPage.scenario_name +
                                                              (openScenarioPage?._id
                                                                  ? openScenarioPage._id
                                                                  : '')
                                                      ]
                                            )
                                        )
                                    }
                                />
                                <Form.Group data-testid="form-group">
                                    <Form.Control
                                        name="scenario_name"
                                        type="text"
                                        autoFocus
                                        className="scenario-name-input"
                                        defaultValue={data.current.scenario_name}
                                        rule={validationSchema.scenario_name}
                                        errorPlacement="rightEnd"
                                        accepter={InputWithCustomOnChange}
                                        readOnly={
                                            openScenarioPage.scenario_name === 'Prognoserechnung' ||
                                            openScenarioPage.scenario_name === 'Ist Daten'
                                        }></Form.Control>
                                </Form.Group>
                            </Stack>
                            {openScenarioPage.scenario_name !== 'Prognoserechnung' &&
                                openScenarioPage.scenario_name !== 'Ist Daten' && (
                                    <IconButton
                                        onClick={() => setDialogOpen(true)}
                                        icon={<FontAwesomeIcon icon={faTrash} />}></IconButton>
                                )}
                        </Stack>
                        {forecastDataComplete && (
                            <div className="scrollable-area">
                                <Panel
                                    header={t('create_forecast.n_financing')}
                                    collapsible
                                    defaultExpanded>
                                    <ToggleInput
                                        label={t('create_forecast.l_financing_via_loan')}
                                        infoText="tooltip"
                                        className="label-on-top"
                                        hideInfoIcon
                                        defaultChecked={data.current.l_financing_via_loan}
                                        onToggle={(value) =>
                                            handleChange({
                                                ...data.current,
                                                l_financing_via_loan: value
                                            })
                                        }
                                    />
                                    {data.current?.l_financing_via_loan !== false && (
                                        <>
                                            <DoubleFieldedInput
                                                label="Kreditbetrag (in % vom Kaufpreis)" //{t('create_forecast.l_loan_down_payment')}
                                                infoText={t('create_forecast.l_loan_down_payment')}
                                                name="financing_amount"
                                                fullValue={data.current?.l_purchase_price}
                                                className="label-on-top"
                                                hideInfoIcon
                                                basePercent={
                                                    data.current?.financing_amount_percent !==
                                                    undefined
                                                        ? data.current.financing_amount_percent
                                                        : 90
                                                }
                                                onChange={(item1, item2) => {
                                                    if (
                                                        data.current.financing_amount !== +item1 &&
                                                        data.current.financing_amount_percent !==
                                                            +item2
                                                    )
                                                        handleChange({
                                                            ...data.current,
                                                            financing_amount: +item1,
                                                            financing_amount_percent: +item2,
                                                            l_financing_via_loan: true,
                                                            l_loan_down_payment:
                                                                data.current?.l_total_loan_value -
                                                                item1
                                                        });
                                                }}
                                                percentRule={
                                                    validationSchema.l_loan_down_payment_percent
                                                }
                                                valueRule={validationSchema.l_loan_down_payment}
                                            />
                                            <InputWithLabelOnTop
                                                label="Zinssatz" //{t('create_forecast.l_loan_adjustable_rate')}
                                                tooltipMessage="Tooltip"
                                                type="number"
                                                symbolIcon="%"
                                                name="l_loan_adjustable_rate"
                                                rule={validationSchema.flexible_interest_rate}
                                            />
                                            <InputWithLabelOnTop
                                                label={t('create_forecast.l_loan_term')}
                                                tooltipMessage={t('create_forecast.i_loan_term')}
                                                type="number"
                                                symbolIcon="Monate"
                                                name="l_loan_term"
                                                rule={validationSchema.l_loan_term}
                                            />
                                        </>
                                    )}
                                </Panel>
                                <Panel header="ERLÖSE" collapsible defaultExpanded>
                                    <InputWithLabelOnTop
                                        label={t('forecast.l_vacancy')}
                                        tooltipMessage="Tooltip"
                                        type="number"
                                        symbolIcon="%"
                                        name="l_vacancy_rate"
                                        rule={validationSchema.loss_of_rent_risk}
                                    />
                                    <InputWithLabelOnTop
                                        label={t('create_forecast.l_expected_monthly_rent')}
                                        tooltipMessage="Tooltip"
                                        type="number"
                                        symbolIcon="€"
                                        name="l_expected_monthly_rent"
                                        rule={validationSchema.monthly_net_rent}
                                    />
                                </Panel>
                                <Panel header="INDEXIERUNG" collapsible defaultExpanded>
                                    <InputWithLabelOnTop
                                        label={t('create_forecast.l_inflation_rate')}
                                        tooltipMessage="Tooltip"
                                        type="number"
                                        symbolIcon="%"
                                        name="l_inflation_rate"
                                        rule={validationSchema.increase_rate_expenses}
                                    />
                                    <InputWithLabelOnTop
                                        label={t('create_forecast.l_rent_increase')}
                                        tooltipMessage="Tooltip"
                                        type="number"
                                        symbolIcon="%"
                                        name="l_rent_increase"
                                        rule={validationSchema.increase_rate_income}
                                    />
                                    <InputWithLabelOnTop
                                        label={t('create_forecast.l_value_development_rate')}
                                        tooltipMessage="Tooltip"
                                        type="number"
                                        symbolIcon="%"
                                        name="l_value_development_rate"
                                        rule={validationSchema.increase_in_value}
                                    />
                                </Panel>
                                {openScenarioPage.scenario_name === 'Prognoserechnung' && (
                                    <Button
                                        className="forecast-edit-btn"
                                        onClick={() => {
                                            const analyticsLabel = forecastDataComplete
                                                ? 'edit_forecast'
                                                : 'create_forecast';
                                            analytics.trackClick(
                                                analyticsLabel,
                                                analyticsConstants.CATEGORIES.FORECASTS
                                            );
                                            navigate(
                                                constants.ROUTES.FORECAST_CALCULATION.FORECAST
                                            );
                                            setOpenScenarioPage(data.current);
                                            dispatch(getScenarios(forecastData._id));
                                        }}
                                        disabled={!dataSaved}>
                                        {forecastDataComplete
                                            ? t('forecast.l_edit_forecast_calculation')
                                            : 'Prognoserechnung erstellen'}
                                    </Button>
                                )}
                            </div>
                        )}
                    </Stack.Item>
                    <Stack.Item className="forecast-sidebar-footer">
                        {openedPropertyForecast && openedPropertyForecast !== '' && (
                            <>
                                <Divider />
                                <Button
                                    appearance="primary"
                                    disabled={initializingNew}
                                    onClick={async () => {
                                        if (
                                            !(await formRef.current
                                                .checkAsync()
                                                .then((resp) => resp.hasError))
                                        ) {
                                            if (
                                                openScenarioPage.scenario_name !==
                                                    'Prognoserechnung' &&
                                                openScenarioPage.scenario_name !== 'Ist Daten'
                                            )
                                                save();
                                            setOpenScenarioPage(false);
                                            setTimeout(
                                                () => dispatch(getScenarios(forecastData._id)),
                                                500
                                            );
                                        }
                                    }}>
                                    {t('global.l_done')}
                                </Button>
                            </>
                        )}
                    </Stack.Item>
                </Stack>
            </Form>
            <ConfirmDialog
                cancelBtnText={t('global.cancel')}
                okBtnText={t('global.l_button_delete')}
                onCancelClicked={() => setDialogOpen(false)}
                onOkClicked={deleteScenario}
                openDialog={dialogOpen}
                titleLabel={t('forecast.h_delete_scenario')}>
                {t('forecast.l_delete_scenario')}
            </ConfirmDialog>
        </>
    );
};

export default ScenarioDataPage;
