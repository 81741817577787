import React, { useEffect, useRef } from 'react';
import { InputGroup, Form, DatePicker } from 'rsuite';
import InputBase from './InputBase';
import getUTCDate from '../utils/getUTCDate';

const DatePickerInput = ({
    label,
    defaultValue,
    placeholder,
    infoText,
    infoTextTooltip,
    onChange,
    name,
    rule,
    value,
    className,
    disabled,
    helpText,
    isIndentedInput,
    hideInfoIcon,
    readOnly,
    hasCheckbox,
    autoFocus,
    checkboxChecked
}) => {
    const containerRef = useRef();
    // for browserstack testing
    React.useEffect(() => {
        containerRef.current.children[0].addEventListener('autoTest', (e) => {
            onChange(e.target.children[0].children[0].value);
        });
    }, []);

    useEffect(() => {
        autoFocus &&
            containerRef.current &&
            containerRef.current.children[0].children[0].children[0].focus();
    }, [autoFocus]);

    // hide validation error message
    onchange = () => {
        const container = containerRef.current;
        value && container?.classList.add('hide-error-msg');
    };
    return (
        <InputBase
            label={label}
            showRequired={rule?.required}
            infoText={infoText}
            className={className}
            infoTextTooltip={infoTextTooltip}
            isIndentedInput={isIndentedInput}
            hideInfoIcon={hideInfoIcon}
            hasCheckbox={hasCheckbox}
            checkboxChecked={checkboxChecked}
            readOnly={readOnly}>
            <Form.Group data-testid="form-group" className="date-picker-input">
                <InputGroup>
                    <Form.Control
                        ref={containerRef}
                        name={name}
                        className={'align-right'}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={(value) => {
                            onchange();
                            onChange(getUTCDate(value));
                        }}
                        oneTap
                        cleanable={false}
                        format="dd/MM/yyyy"
                        locale={{
                            sunday: 'So',
                            monday: 'Mo',
                            tuesday: 'Di',
                            wednesday: 'Mi',
                            thursday: 'Do',
                            friday: 'Fr',
                            saturday: 'Sa',
                            ok: 'OK'
                        }}
                        rule={rule}
                        placement="autoVerticalEnd"
                        value={value ? getUTCDate(value) : undefined}
                        errorPlacement="bottomEnd"
                        disabled={disabled}
                        accepter={DatePicker}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="off"
                        data-testid={name}
                        readOnly={readOnly}
                    />
                </InputGroup>
                {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
            </Form.Group>
        </InputBase>
    );
};

export default DatePickerInput;
