import React, { useRef, useState, useEffect } from 'react';
import { Modal, Form, Button } from 'rsuite';
import DropdownInput from './DropdownInput';
import LabeledInputWithSymbol from './LabeledInputWithSymbol';
import ContentPageBase from './ContentPageBase';
import { useTranslation } from 'react-i18next';
import sendRequest from '../utils/sendRequest';
import constants from '../utils/constants';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import showValidationErrors from '../utils/showValidationErrors';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../reducers/userSlice';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';
import CheckpickerInput from './CheckpickerInput';
import getUTCDate from '../utils/getUTCDate';

const OnboardingModal = ({ opened, setOpenConfirmDialog }) => {
    const formRef = useRef();
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).onboarding.objectTypeSchemaSpec;
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user.user);

    const [data, setData] = useState({
        userType: '',
        firstname: '',
        lastname: '',
        ...user
    });

    useEffect(() => {
        setData({ ...data, ...user });
    }, [user]);

    const dropdownOptions1 = Object.values(constants.USER_TYPES).map((type) => ({
        label: t(type),
        value: t(type)
    }));

    const dropdownOptions2 = [
        'Szenarien durchrechnen',
        'Prognoserechnung(en)',
        'Unterstützung bei E1b',
        'Marktvergleichs Analysen durchführen'
    ];
    const [open, setOpen] = useState(opened);
    const [requestMeetingPage, setRequestMeetingPage] = useState(false);

    const handleClose = async () => {
        let hasError = !formRef.current.check();
        if (hasError) {
            showValidationErrors(formRef.current.root);
            return;
        }
        const trialEndDate = getUTCDate();
        trialEndDate.setDate(trialEndDate.getDate() + 30);
        if (data.userType === t(constants.USER_TYPES.INVESTOR))
            sendRequest(`/completeOnboarding`, 'post', {
                ...data,
                onboardingPassed: true,
                trialEndDate: trialEndDate
            }).then((resp) => {
                if (resp.success) {
                    dispatch(getUser());
                    setOpen(false);
                } else {
                    console.log('server error');
                }
            });
        else {
            sendRequest(`/completeOnboarding`, 'post', { ...data, onboardingPassed: false });
            setRequestMeetingPage(true);
        }
    };

    useEffect(() => {
        setOpen(opened);
    }, [opened]);

    return (
        <Modal
            data-testid="onboarding-modal"
            size="md"
            open={open}
            backdrop="static"
            keyboard={false}
            onClose={handleClose}
            onEnter={() => {
                analytics.trackModal('onboarding_modal', analyticsConstants.CATEGORIES.USERS, true);
            }}
            onExit={() => {
                analytics.trackModal(
                    'onboarding_modal',
                    analyticsConstants.CATEGORIES.USERS,
                    false
                );
            }}>
            <ContentPageBase header={t('onboarding_dialog.h_onboaring')}>
                <Form
                    ref={formRef}
                    checkTrigger="none"
                    formDefaultValue={data}
                    onChange={(object) => {
                        setData({ ...data, ...object });
                    }}>
                    <Modal.Body>
                        {!requestMeetingPage && (
                            <>
                                <LabeledInputWithSymbol
                                    label={t('account.l_firstname')}
                                    type="text"
                                    name="firstname"
                                    rule={validationSchemas.firstname}
                                    hideInfoIcon
                                />
                                <LabeledInputWithSymbol
                                    label={t('account.l_lastname')}
                                    type="text"
                                    name="lastname"
                                    rule={validationSchemas.lastname}
                                    hideInfoIcon
                                />
                                <CheckpickerInput
                                    label={t('onboarding_dialog.l_user_goals')}
                                    alignRight
                                    dropdownOption={dropdownOptions2}
                                    name="interestInfo"
                                    value={data?.interestInfo}
                                    placeholder={t('global.l_please_select')}
                                    rule={validationSchemas.interestInfo}
                                    hideInfoIcon
                                />
                                <DropdownInput
                                    label={t('onboarding_dialog.l_user_type')}
                                    alignRight
                                    dropdownOptionReady
                                    dropdownOption={dropdownOptions1}
                                    name="userType"
                                    value={data?.userType}
                                    placeholder={t('global.l_please_select')}
                                    rule={validationSchemas.userType}
                                    hideInfoIcon
                                />
                                {data.userType === t(constants.USER_TYPES.TA) && (
                                    <div className="onboarding-text">
                                        {t('onboarding_dialog.l_tax_advisor_account_explenation')}
                                    </div>
                                )}
                            </>
                        )}
                        {requestMeetingPage && (
                            <iframe
                                style={{
                                    width: '100%',
                                    overflow: 'auto'
                                }}
                                height={690}
                                frameBorder={0}
                                scrolling="no"
                                src={`https://meetings-eu1.hubspot.com/peter-riegler?embed=true&firstname=${data.firstname}&lastname=${data.lastname}&email=${user.email.replace('+', '%2b')}`}></iframe>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            appearance="subtle"
                            style={{ marginRight: '0.5rem' }}
                            onClick={() => setOpenConfirmDialog(true)}>
                            {t('global.n_sign_out')}
                        </Button>
                        {!requestMeetingPage && (
                            <Button
                                onClick={handleClose}
                                appearance="primary"
                                style={{ marginRight: '0.5rem' }}>
                                {data.userType === t(constants.USER_TYPES.TA)
                                    ? t('onboarding_dialog.l_request_demo_call')
                                    : t('onboarding_dialog.l_start')}
                            </Button>
                        )}
                    </Modal.Footer>
                </Form>
            </ContentPageBase>
        </Modal>
    );
};

export default OnboardingModal;
