const constants = {
    MESSAGE_DURATION: 5000,
    ADMIN: 'Admin',
    USER_TYPES: {
        INVESTOR: 'onboarding_dialog.l_user_type_investor',
        TA: 'onboarding_dialog.l_user_type_tax_advisor'
    },
    PROPERTY_TYPES: {
        APARTMENT: 'global.l_property_type_flat',
        APARTMENT_BUILDING: 'global.l_property_type_house',
        GARAGE: 'global.l_property_type_garage'
    },
    RENTAL_TYPES: {
        SMALL: 'create_property.l_type_of_rental_small',
        LARGE: 'create_property.l_type_of_rental_large'
    },
    TAX_SUBJECT_TYPES: {
        private: 'Privatperson',
        one_man_business: 'Einzelunternehmen',
        partnerhip: 'Personengesellschaft',
        corporation: 'Kapitalgesellschaft'
    },
    VAT_PAYER_TYPES: {
        small_business: 1,
        not_small_business: 0
    },
    SUBSSCRIPTION_TYPE: {
        VALID_SUBSCRIPTION_TYPE: {
            basic: { value: 'basic', label: 'Investor Basic Abo' },
            pro: { value: 'pro', label: 'Investor Pro Abo' },
            manager: { value: 'investor_custom', label: 'Investor Custom/Manager' },
            advisor: { value: 'advisor', label: 'Steuerberater Advisor Abo' },
            ultimate: { value: 'ultimate', label: 'Steuerberater Ultimate Abo' },
            enterprise: { value: 'steuerberater_custom', label: 'Steuerberater Custom/Enterprise' }
        },
        INVALID_SUBSCRIPTION_TYPE: {
            trial: { value: 'Trial', label: 'Trial' },
            none: { value: 'None', label: 'None' },
            test: { value: 'Test', label: 'Test' }
        }
    },
    ROUTES: {
        HOME: '/',
        ADMIN: '/admin',
        PROFILE: '/profile',
        TAXSUBJECTS: {
            LIST: '/taxsubjects',
            DASHBOARD: '/taxsubjects/dashboard',
            CREATE: '/taxsubjects/create',
            EDIT: '/taxsubjects/edit'
        },
        PROPERTIES: {
            LIST: '/properties',
            DASHBOARD: '/properties/dashboard',
            CREATE: '/properties/create',
            EDIT: '/properties/edit'
        },
        FORECAST_CALCULATION: {
            HOME: '/forecast-calculation',
            FORECAST: '/forecast-calculation/forecast',
            HISTORICAL_DATA: '/forecast-calculation/historical-data'
        },
        E1B: '/e1b',
        PLANS: '/plans'
    },
    BACKEND_URL: 'http://localhost:5000',
    SUBSCRIPTION_EVENTS_URL: {
        subscription_data_change: '/events/subscriptionDataChange'
    },
    MANAGER_PLAN_FORM_URL: ' https://www.renteezy.com/kontakt',
    COLORS: {
        scenarioColors: [
            '#00b4c5',
            '#c44601',
            '#2546f0',
            '#5ba300',
            '#b51963',
            '#faaf90',
            '#9b8bf4',
            '#d3bb00'
        ]
    },
    VERSION: {
        number: '0.9.0',
        commit_id: 'f97ba970'
    },
    GET_SCENARIOS: {
        NONE: 'none',
        ONLY_HISTORICAL: 'historical',
        ALL: 'all'
    },
    CALCULATION_PRESETS: {
        FORECAST: 0,
        DASHBOARD: 1,
        PDF: 3,
        E1B: 4
    }
};

export default constants;
