import { createSlice } from '@reduxjs/toolkit';
export const userSlice = createSlice({
    name: 'user',
    initialState: {
        userId: undefined,
        user: {},
        users: [],
        forecastData: {},
        properties: [],
        currentProperties: [],
        currentProperty: null,
        activeE1bData: null,
        taxsubjects: [],
        taxsubjectsLoading: false,
        scenarios: [],
        currentTaxsubject: null,
        historicalData: {},
        openedPropertyForecast: null,
        loaded: false,
        openDrawer: {},
        requestFinished: false,
        requestMessage: {},
        customLogo: false,
        paymentsConfig: {},
        showLoading: false,
        activeScenarios: [true, 'Prognoserechnung', 'Ist Daten']
    },
    reducers: {
        setUserId(state, actions) {
            state.userId = actions.payload;
        },
        getUserId(state, actions) {
            state.userId = actions.payload;
            state.loaded = true;
        },
        setUser(state, actions) {
            state.user = actions.payload;
        },
        getUser(state, actions) {
            state.user = actions.payload;
        },
        setUsers(state, actions) {
            state.users = actions.payload;
        },
        getAllUsers(state, actions) {
            state.users = actions.payload;
        },
        setOpenedPropertyForecast(state, actions) {
            state.openedPropertyForecast = actions.payload;
            if (
                JSON.stringify(state.activeScenarios) !==
                JSON.stringify([true, 'Prognoserechnung', 'Ist Daten'])
            )
                state.activeScenarios = [true, 'Prognoserechnung', 'Ist Daten'];
        },
        setForecastData(state, actions) {
            state.forecastData = actions.payload;
        },
        getForecastData(state, actions) {
            state.forecastData = actions.payload;
        },
        setScenarios(state, actions) {
            state.scenarios = actions.payload;
        },
        getScenarios(state, actions) {
            state.scenarios = actions.payload;
        },
        setProperties(state, actions) {
            state.properties = actions.payload;
        },
        getProperties(state, actions) {
            state.properties = actions.payload;
        },
        setCurrentProperties(state, actions) {
            state.currentProperties = actions.payload;
        },
        setCurrentProperty(state, actions) {
            state.currentProperty = actions.payload;
        },
        setActiveE1bData(state, actions) {
            state.activeE1bData = actions.payload;
        },
        setTaxsubjects(state, actions) {
            state.taxsubjects = actions.payload;
        },
        setTaxsubjectsLoading(state, actions) {
            state.taxsubjectsLoading = actions.payload;
        },
        resetTaxsubjects(state) {
            state.taxsubjects = [];
        },
        getTaxsubjects(state, actions) {
            state.taxsubjects = actions.payload;
        },
        setCurrentTaxsubject(state, actions) {
            state.currentTaxsubject = actions.payload;
        },
        setRequestFinished(state, actions) {
            state.requestFinished = actions.payload;
        },
        setRequestMessage(state, actions) {
            state.requestMessage = actions.payload;
        },
        openHelpDrawer(state, actions) {
            state.openDrawer = { status: true, ...actions.payload };
        },
        closeHelpDrawer(state) {
            state.openDrawer = {
                status: false,
                title: state.openDrawer.title,
                message: state.openDrawer.message
            };
        },
        setHistoricalData(state, actions) {
            state.historicalData = actions.payload;
        },
        getHistoricalData(state, actions) {
            state.historicalData = actions.payload;
        },
        setCustomLogo(state, actions) {
            state.customLogo = actions.payload;
        },
        getCustomLogo(state, actions) {
            state.customLogo = actions.payload;
        },
        getPaymentsConfig(state, actions) {
            state.paymentsConfig = actions.payload;
        },
        setShowLoading(state, actions) {
            state.showLoading = actions.payload;
        },
        setActiveScenarios(state, actions) {
            state.activeScenarios = actions.payload;
        },
        resetAll(state) {
            (state.userId = undefined),
                (state.user = {}),
                (state.users = []),
                (state.forecastData = {}),
                (state.properties = []),
                (state.currentProperties = []),
                (state.currentProperty = null),
                (state.activeE1bData = null),
                (state.taxsubjects = []),
                (state.taxsubjectsLoading = false),
                (state.scenarios = []),
                (state.currentTaxsubject = null),
                (state.historicalData = {}),
                (state.openedPropertyForecast = null),
                (state.loaded = false),
                (state.openDrawer = {}),
                (state.requestFinished = false),
                (state.requestMessage = {}),
                (state.customLogo = false),
                (state.paymentsConfig = {}),
                (state.showLoading = false),
                (state.activeScenarios = [true, 'Prognoserechnung', 'Ist Daten']);
        }
    }
});
export const {
    setUserId,
    getUserId,
    setUser,
    getUser,
    setUsers,
    getAllUsers,
    setForecastData,
    getForecastData,
    setScenarios,
    getScenarios,
    setProperties,
    getProperties,
    setCurrentProperties,
    setCurrentProperty,
    setActiveE1bData,
    setOpenedPropertyForecast,
    setTaxsubjectsLoading,
    setTaxsubjects,
    resetTaxsubjects,
    getTaxsubjects,
    setCurrentTaxsubject,
    openHelpDrawer,
    closeHelpDrawer,
    setRequestFinished,
    setRequestMessage,
    setHistoricalData,
    getHistoricalData,
    setCustomLogo,
    getCustomLogo,
    getPaymentsConfig,
    setShowLoading,
    setActiveScenarios,
    resetAll
} = userSlice.actions;
export default userSlice.reducer;
