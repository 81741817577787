import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from './SimpleTable';

const InvestorTable = ({ tables, historicalYears, labels, labelClicked, colors }) => {
    const { t } = useTranslation();

    const numberFormat = Intl.NumberFormat('de-DE');
    let numberOfScenarios = colors.length;
    let customData = [];
    let l_year_of_rental = {
        ['label']: {
            label: t('forecast.l_year_of_rental')
        }
    };
    let l_expense_income_before_tax = {
        ['label']: {
            label: t('forecast.l_expense_income_before_tax')
        }
    };
    let l_income_tax_refund_or_payment = {
        ['label']: {
            label: t('forecast.l_income_tax_refund_or_payment')
        }
    };
    let l_expense_income_after_tax = {
        ['label']: {
            label: t('forecast.l_expense_income_after_tax')
        }
    };
    let l_income_after_tax_accumulated = {
        ['label']: {
            label: t('forecast.l_income_after_tax_accumulated')
        }
    };
    let l_liabilities_end_year = {
        ['label']: {
            label: t('forecast.l_liabilities_end_year')
        }
    };
    let l_property_value = {
        ['label']: {
            label: t('forecast.l_property_value')
        }
    };
    // let l_yearly_return_on_total_cash_inflow_after_tax = {
    //     ['label']: {
    //         label: t('forecast.l_yearly_return_on_total_cash_inflow_after_tax')
    //     }
    // };
    let l_net_wealth = {
        ['label']: { label: t('forecast.l_net_wealth') }
    };
    tables.map((table, index) => {
        table[Object.keys(table)[0]].map((item) => {
            l_year_of_rental = {
                ...l_year_of_rental,
                [item.year]: numberFormat.format(Math.round(item.l_year_of_rental))
            };
            l_expense_income_before_tax = {
                ...l_expense_income_before_tax,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_before_taxes))
            };
            l_income_tax_refund_or_payment = {
                ...l_income_tax_refund_or_payment,
                [item.year]: numberFormat.format(Math.round(item.l_tax_refund_or_payment))
            };
            l_expense_income_after_tax = {
                ...l_expense_income_after_tax,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_after_taxes))
            };
            l_income_after_tax_accumulated = {
                ...l_income_after_tax_accumulated,
                [item.year]: numberFormat.format(Math.round(item.l_cash_flow_after_taxes_cumulated))
            };
            l_liabilities_end_year = {
                ...l_liabilities_end_year,
                [item.year]: numberFormat.format(Math.round(item.l_liabilities_end_year))
            };
            l_property_value = {
                ...l_property_value,
                [item.year]: numberFormat.format(Math.round(item.l_property_value))
            };
            // l_yearly_return_on_total_cash_inflow_after_tax = {
            //     ...l_yearly_return_on_total_cash_inflow_after_tax,
            //     [item.year]: numberFormat.format(
            //         item.l_yearly_return_on_total_cash_inflow_after_tax
            //             ? item.l_yearly_return_on_total_cash_inflow_after_tax.toFixed(2)
            //             : item.l_yearly_return_on_total_cash_inflow_after_tax
            //     )
            // };
            l_net_wealth = {
                ...l_net_wealth,
                [item.year]: numberFormat.format(Math.round(item.l_net_wealth))
            };
        });
        let i = 0;
        customData[index + numberOfScenarios * i] = l_year_of_rental;
        i++;
        customData[index + numberOfScenarios * i] = l_expense_income_before_tax;
        i++;
        customData[index + numberOfScenarios * i] = l_income_tax_refund_or_payment;
        i++;
        customData[index + numberOfScenarios * i] = l_expense_income_after_tax;
        i++;
        customData[index + numberOfScenarios * i] = l_income_after_tax_accumulated;
        i++;
        customData[index + numberOfScenarios * i] = l_liabilities_end_year;
        i++;
        customData[index + numberOfScenarios * i] = l_property_value;
        i++;
        // customData[index + numberOfScenarios * i] = l_yearly_return_on_total_cash_inflow_after_tax;
        // i++;
        customData[index + numberOfScenarios * i] = l_net_wealth;
        i++;
        l_year_of_rental = {};
        l_expense_income_before_tax = {};
        l_income_tax_refund_or_payment = {};
        l_expense_income_after_tax = {};
        l_income_after_tax_accumulated = {};
        l_liabilities_end_year = {};
        l_property_value = {};
        // l_yearly_return_on_total_cash_inflow_after_tax = {};
        l_net_wealth = {};
    });

    return (
        <SimpleTable
            historicalYears={historicalYears}
            table={customData}
            labels={labels}
            labelClicked={labelClicked}
            colors={colors}
            lineSeparator={false}
        />
    );
};

export default InvestorTable;
