import React, { useState } from 'react';
import { Divider, ButtonGroup, Button } from 'rsuite';

const NavigationFooter = ({
    className,
    firstLabel,
    secondLabel,
    firstFunction,
    secondFunction,
    firstBtnDisabled,
    secondBtnDisabled,
    isSubmitButton
}) => {
    const [disabled, setDisabled] = useState(false);
    return (
        <div className={`page-content-footer clearfix ${className ? className : ''}`}>
            <Divider />
            <ButtonGroup>
                {firstLabel && (
                    <Button
                        onClick={firstFunction}
                        disabled={firstBtnDisabled}
                        className="footer-first-btn">
                        {firstLabel}
                    </Button>
                )}
                {secondLabel && (
                    <Button
                        appearance="primary"
                        onClick={
                            !disabled
                                ? () => {
                                      secondFunction();
                                      if (isSubmitButton) setDisabled(true);
                                  }
                                : undefined
                        }
                        disabled={secondBtnDisabled}>
                        {secondLabel}
                    </Button>
                )}
            </ButtonGroup>
        </div>
    );
};

export default NavigationFooter;
