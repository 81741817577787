import React from 'react';
import { Button } from 'rsuite';
import promoBg from '../img/promo-element-bg.png';

const PromotionCard = ({ title, body, showBgImg = true, buttonText, onButtonClick, icon }) => {
    return (
        <div className="promotion-card">
            <div>
                <h3>{title}</h3>
                <p>{body}</p>
            </div>
            <div>
                <Button onClick={onButtonClick} color="green">
                    {buttonText}
                </Button>
                {showBgImg && <img src={promoBg} alt="promo-bg" />}
            </div>
            {icon}
        </div>
    );
};

export default PromotionCard;
