import React, { useRef } from 'react';
import { InputGroup, Form } from 'rsuite';
import InputBase from './InputBase';
import { NumericFormatter } from './NumericFormatter';

const InputWithLabelOnTop = ({
    label,
    type = 'text',
    defaultValue,
    placeholder,
    symbolIcon,
    tooltipMessage,
    onChange,
    name,
    rule,
    value,
    formatNumeric = true,
    disabled,
    autoFocus,
    helpText,
    alignLeft,
    readOnly
}) => {
    const containerRef = useRef();

    // hide validation error message
    onChange = () => {
        const container = containerRef.current;
        container.classList.add('hide-error-msg');
    };
    return (
        <InputBase
            label={label}
            className={'label-on-top'}
            showRequired={rule?.required}
            tooltipMessage={tooltipMessage}
            labelOnTop>
            <Form.Group data-testid="form-group">
                <InputGroup className="custom-input-1">
                    <Form.Control
                        ref={containerRef}
                        name={name}
                        className={type === 'text' || alignLeft ? 'align-left' : 'align-right'}
                        defaultValue={defaultValue}
                        placeholder={placeholder}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        type={type === 'number' && formatNumeric ? 'text' : type}
                        rule={rule}
                        value={value}
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="off"
                        errorPlacement="bottomEnd"
                        disabled={disabled}
                        accepter={type === 'number' && formatNumeric ? NumericFormatter : undefined}
                        readOnly={readOnly}
                    />
                    {symbolIcon && (
                        <InputGroup.Addon className="custom-input-1-icon">
                            {symbolIcon}
                        </InputGroup.Addon>
                    )}
                </InputGroup>
                {helpText && <Form.HelpText>{helpText}</Form.HelpText>}
            </Form.Group>
        </InputBase>
    );
};

export default InputWithLabelOnTop;
