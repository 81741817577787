import React, { useState } from 'react';
import { InputGroup, Modal, Button } from 'rsuite';
import InputBase from './InputBase';
import LabeledInputWithSymbol from './LabeledInputWithSymbol';
import { useTranslation } from 'react-i18next';

const ReadOnlyInput = ({
    label,
    modalInputs,
    symbolIcon,
    infoText,
    infoTextTooltip,
    alignLeft,
    isIndentedInput
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation();
    return (
        <>
            <Modal open={modalOpen} onClose={() => setModalOpen(false)} size="lg">
                <Modal.Header>
                    <Modal.Title>{label}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalInputs &&
                        modalInputs.map((item) => (
                            <LabeledInputWithSymbol
                                key={item.label}
                                infoText={item.infoText}
                                defaultValue={item.defaultValue}
                                name={item.name}
                                label={item.label}
                                symbolIcon={symbolIcon}
                                type="number"
                            />
                        ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalOpen(false)}>{t('global.cancel')}</Button>
                    <Button onClick={() => setModalOpen(false)}>{t('global.submit')}</Button>
                </Modal.Footer>
            </Modal>
            <InputBase
                label={label}
                infoText={infoText}
                infoTextTooltip={infoTextTooltip}
                isIndentedInput={isIndentedInput}>
                <InputGroup
                    className="custom-input-4"
                    data-testid="input-group"
                    onClick={() => setModalOpen(true)}>
                    <div className={alignLeft ? 'align-left' : 'align-right'}>
                        {modalInputs.length > 1
                            ? modalInputs.map((item) => `${item.defaultValue}; `)
                            : modalInputs[0].defaultValue}
                    </div>
                    {symbolIcon && (
                        <InputGroup.Addon className="custom-input-1-icon">
                            {symbolIcon}
                        </InputGroup.Addon>
                    )}
                </InputGroup>
            </InputBase>
        </>
    );
};

export default ReadOnlyInput;
