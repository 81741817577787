import React, { useEffect } from 'react';
import { Form, Button, Message, useToaster } from 'rsuite';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import sendRequest from '../utils/sendRequest';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import constants from '../utils/constants';
import LoginFooterWithLink from '../components/LoginFooterWithLink';
import LoginContainer from '../components/LoginContainer';
import showValidationErrors from '../utils/showValidationErrors';
import hideValidationError from '../utils/hideValidationError';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';

const ForgottenPassw = () => {
    const [email, setEmail] = useState('');
    const [isEmailSent, setIsEmailSent] = useState(false);

    const userId = useSelector((state) => state.user.userId);
    const { t } = useTranslation();
    const validationSchema = getValidationSchemas(t).forgottenPassw.objectTypeSchemaSpec;
    const formRef = useRef();
    const toaster = useToaster();

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.FORGOTTEN_PASSWORD);
    }, []);

    const errorMessage = (message) => {
        return (
            <Message showIcon type="error" closable>
                {message}
            </Message>
        );
    };

    const handleSubmit = () => {
        let hasError = !formRef.current.check();
        if (hasError) {
            showValidationErrors(formRef.current.root);
            toaster.push(errorMessage(t('global.e_form_error')), {
                duration: constants.MESSAGE_DURATION
            });
            return;
        }
        sendRequest('/forgotPass', 'post', { email: email });
        setIsEmailSent(true);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleSubmit();
    };

    if (userId) return <Navigate replace to="/" />;
    if (isEmailSent) return <Navigate replace to={`/email-sent/${email}/password`} />;
    else
        return (
            <LoginContainer footer={<LoginFooterWithLink />}>
                <h2>{t('authentication.h_forgot_password')}</h2>
                <p>{t('authentication.l_forgot_password_info')}</p>
                <Form ref={formRef} checkTrigger="none" onKeyDown={handleKeyDown}>
                    <Form.Group controlId="email">
                        <Form.Control
                            name="email"
                            type="email"
                            autoFocus
                            placeholder={t('authentication.l_email')}
                            errorPlacement="bottomStart"
                            onChange={(value, event) => {
                                setEmail(value);
                                hideValidationError(event);
                            }}
                            rule={validationSchema.email}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button appearance="primary" onClick={handleSubmit}>
                            {t('authentication.l_request_to_reset_password')}
                        </Button>
                    </Form.Group>
                </Form>
            </LoginContainer>
        );
};

export default ForgottenPassw;
