import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { useTranslation } from 'react-i18next';
import AttachedToggleInput from '../../components/AttachedToggleInput';
import { getValidationSchemas } from '../../utils/getValidationSchemas';

const Expenses_4 = ({ data, setData }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).expenses_4.objectTypeSchemaSpec;

    return (
        <>
            <h4>{t('create_historic_forecast_data.h_expenses_4')}</h4>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_expenses_paragraph_28_2')}
                type="number"
                name="l_expenses_paragraph_28_2"
                autoFocus
                infoText={t('create_historic_forecast_data.i_expenses_paragraph_28_2')}
                symbolIcon="€"
                rule={validationSchemas.l_expenses_paragraph_28_2}
            />
            <AttachedToggleInput
                label={t('create_historic_forecast_data.l_distribution_of_expenses')}
                onChange={(state) => setData({ ...data, l_distribution_of_expenses_28_2: state })}
                defaultChecked={data.l_distribution_of_expenses_28_2 || false}
            />
            <div className="distribution-of-expenses">
                <LabeledInputWithSymbol
                    label={t('create_historic_forecast_data.l_expenses_paragraph_28_3')}
                    type="number"
                    name="l_expenses_paragraph_28_3"
                    infoText={t('create_historic_forecast_data.i_expenses_paragraph_28_3')}
                    symbolIcon="€"
                    rule={validationSchemas.l_expenses_paragraph_28_3}
                />
                <AttachedToggleInput
                    label={t('create_historic_forecast_data.l_distribution_of_expenses')}
                    onChange={(state) =>
                        setData({ ...data, l_distribution_of_l_maintenance_expenses_period: state })
                    }
                    defaultChecked={data.l_distribution_of_l_maintenance_expenses_period}
                />
                {data.l_distribution_of_l_maintenance_expenses_period && (
                    <LabeledInputWithSymbol
                        type="number"
                        name="l_maintenance_expenses_period"
                        symbolIcon="Jahre"
                        rule={validationSchemas.l_maintenance_expenses_period}
                    />
                )}
            </div>
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_expenses_paragraph_28_4')}
                type="number"
                name="l_expenses_paragraph_28_4"
                infoText={t('create_historic_forecast_data.i_expenses_paragraph_28_4')}
                symbolIcon="€"
                rule={validationSchemas.l_expenses_paragraph_28_4}
            />
            <AttachedToggleInput
                label={t('create_historic_forecast_data.l_distribution_of_expenses')}
                onChange={(state) => setData({ ...data, l_distribution_of_expenses_28_4: state })}
                defaultChecked={data.l_distribution_of_expenses_28_4 || false}
            />
            <LabeledInputWithSymbol
                label={t('create_historic_forecast_data.l_immediately_deductables')}
                type="number"
                name="l_immediately_deductables"
                symbolIcon="€"
                rule={validationSchemas.l_immediately_deductables}
            />
        </>
    );
};

export default Expenses_4;
