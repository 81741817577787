import React, { useRef } from 'react';
import { CheckPicker, Form } from 'rsuite';
import InputBase from './InputBase';

const CheckpickerInput = ({
    label,
    name,
    value,
    infoText,
    infoTextTooltip,
    dropdownOption,
    alignRight,
    hideInfoIcon,
    className,
    disabled,
    onChange,
    dropdownOptionReady,
    rule,
    isIndentedInput,
    placeholder
}) => {
    const containerRef = useRef();
    const data = dropdownOptionReady
        ? dropdownOption
        : dropdownOption.map((item) => ({ label: item, value: item }));

    //hide validation error message
    const change = (value) => {
        const container = containerRef.current;
        container.classList.add('hide-error-msg');
        onChange && onChange(value);
    };

    return (
        <InputBase
            label={label}
            showRequired={rule?.required}
            infoText={infoText}
            infoTextTooltip={infoTextTooltip}
            className={`${className ? className : ''} checkpicker-input`}
            hideInfoIcon={hideInfoIcon}
            isIndentedInput={isIndentedInput}>
            <Form.Group className={alignRight ? 'align-right' : ''} style={{ maxWidth: '95.5%' }}>
                <Form.Control
                    data-testid={name}
                    ref={containerRef}
                    name={name}
                    accepter={CheckPicker}
                    rule={rule}
                    onChange={change}
                    defaultValue={value}
                    disabled={disabled}
                    searchable={false}
                    placeholder={placeholder}
                    placement="bottomEnd"
                    data={data}
                    errorPlacement="bottomEnd"
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                />
            </Form.Group>
        </InputBase>
    );
};

export default CheckpickerInput;
