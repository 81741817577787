import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form } from 'rsuite';
import { Trans, useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import {
    setRequestFinished,
    setRequestMessage,
    setTaxsubjects,
    closeHelpDrawer,
    setCurrentTaxsubject
} from '../../reducers/userSlice';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import NavigationFooter from '../../components/NavigationFooter';
import ContentPageBase from '../../components/ContentPageBase';
import DropdownInput from '../../components/DropdownInput';
import constants from '../../utils/constants';
import showValidationErrors from '../../utils/showValidationErrors';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';

const AddEditTaxSubject = ({ editID }) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userId = useSelector((state) => state.user.userId);
    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);
    const requestFinished = useSelector((state) => state.user.requestFinished);
    const requestMessage = useSelector((state) => state.user.requestMessage);
    const taxSubjectsList = useSelector((state) => state.user.taxsubjects);
    const taxSubjectsTypes = Object.values(constants.TAX_SUBJECT_TYPES);

    const [loaded, setLoaded] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [hasError, setHasError] = useState(true);
    const [formData, setFormData] = useState({ vat_gross_system: false });
    const [showCompanyInput, setShowCompanyInput] = useState(true);

    const validationSchema = getValidationSchemas(t).addEditTaxSubject.objectTypeSchemaSpec;

    const smallBusinessSchemeDropdwnOptions = useMemo(() => [
        { label: 'Ja', value: 1 },
        { label: 'Nein', value: 0 }
    ]);

    useEffect(() => {
        return () => {
            dispatch(closeHelpDrawer());
        };
    }, []);

    useEffect(() => {
        if (requestFinished && requestMessage?.type !== 'error') {
            if (editID) {
                dispatch(setCurrentTaxsubject(taxSubjectsList.find((doc) => doc._id === editID)));
                navigate(-1);
                return;
            }
            dispatch(setCurrentTaxsubject(taxSubjectsList[taxSubjectsList.length - 1]));
            navigate(constants.ROUTES.TAXSUBJECTS.DASHBOARD);
        }
    }, [requestFinished]);

    useEffect(() => {
        formData.type === constants.TAX_SUBJECT_TYPES.private
            ? setShowCompanyInput(false)
            : setShowCompanyInput(true);
    }, [formData.type]);

    useEffect(() => {
        if (editID) {
            const currentTaxSubject = taxSubjectsList.find((doc) => doc._id === editID);
            setEditMode(true);
            setFormData(currentTaxSubject.taxsubjectsData);
            analytics.trackPage(analyticsConstants.PAGES.EDIT_TAX_SUBJECT);
        } else {
            if (currentTaxsubject) {
                setEditMode(true);
                setFormData(currentTaxsubject?.taxsubjectsData);
            }
            analytics.trackPage(analyticsConstants.PAGES.CREATE_TAX_SUBJECT);
        }
        setLoaded(true);
    }, [currentTaxsubject]);

    const handleChange = (object) => {
        setFormData({ ...formData, ...object });
    };

    const handleSubmit = async () => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        setHasError(hasError);
        const analyticsLabel = editID ? 'edit_tax_subject' : 'create_tax_subject';
        analytics.trackClick(
            analyticsLabel,
            analyticsConstants.CATEGORIES.TAX_SUBJECTS,
            '',
            hasError
        );
        if (hasError) {
            showValidationErrors(formRef.current.root);
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: t('global.e_form_error')
                })
            );
            return;
        }
        let saveRequest = editID
            ? {
                  _id: editID,
                  taxsubjectsData: formData,
                  _owner: userId
              }
            : {
                  taxsubjectsData: formData,
                  _owner: userId
              };
        dispatch(setTaxsubjects({ data: saveRequest }));
    };

    const handleCancel = () => {
        const analyticsLabel = editID ? 'cancel_edit_tax_subject' : 'cancel_create_tax_subject';
        analytics.trackClick(analyticsLabel, analyticsConstants.CATEGORIES.TAX_SUBJECTS);
        navigate(-1);
    };

    return (
        <ContentPageBase
            header={
                editMode
                    ? t('create_tax_subject.h_edit_tax_subject')
                    : t('create_tax_subject.h_create_tax_subject')
            }
            footer={
                <NavigationFooter
                    firstLabel={t('global.cancel')}
                    secondLabel={t('global.submit')}
                    firstFunction={handleCancel}
                    secondFunction={handleSubmit}
                    isSubmitButton={!hasError}
                />
            }>
            {loaded && (
                <Form ref={formRef} onChange={handleChange} formDefaultValue={formData}>
                    <DropdownInput
                        label={t('create_tax_subject.l_tax_subject_type')}
                        alignRight
                        dropdownOption={taxSubjectsTypes}
                        name="type"
                        autoFocus
                        value={formData?.type}
                        rule={validationSchema.type}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_firstname')}
                        name={'l_firstname'}
                        rule={
                            formData?.type === constants.TAX_SUBJECT_TYPES.private
                                ? validationSchema.l_firstname
                                : undefined
                        }
                    />

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_lastname')}
                        name={'l_lastname'}
                        rule={
                            formData?.type === constants.TAX_SUBJECT_TYPES.private
                                ? validationSchema.l_lastname
                                : undefined
                        }
                    />

                    <br />

                    {showCompanyInput && (
                        <LabeledInputWithSymbol
                            label={t('create_tax_subject.l_company')}
                            name={'l_company'}
                            rule={validationSchema.l_company}
                        />
                    )}

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_address_line')}
                        name={'l_address_line'}
                        rule={validationSchema.l_address_line}
                    />

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_zip')}
                        name={'l_zip'}
                        type="number"
                        formatNumeric={false}
                        alignLeft={true}
                        rule={validationSchema.l_zip}
                    />

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_city')}
                        name={'l_city'}
                        rule={validationSchema.l_city}
                    />

                    <br />

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_tax_id')}
                        name="tax_number"
                        infoText={t('create_tax_subject.i_tax_id')}
                    />

                    <DropdownInput
                        label={t('create_tax_subject.l_small_business_scheme')}
                        infoText={t('create_forecast.i_small_business_scheme')}
                        alignRight
                        dropdownOptionReady
                        dropdownOption={smallBusinessSchemeDropdwnOptions}
                        name="l_small_business_scheme"
                        value={formData?.l_small_business_scheme}
                        rule={validationSchema.l_small_business_scheme}
                    />
                    {formData?.l_small_business_scheme !== undefined && (
                        <div className="small-business-info">
                            <Trans
                                i18nKey={
                                    formData?.l_small_business_scheme === 1
                                        ? 'create_tax_subject.l_small_business_scheme_info_text'
                                        : 'create_tax_subject.l_non_small_business_scheme_info_text'
                                }
                                components={{
                                    b: <b />
                                }}
                            />
                        </div>
                    )}

                    <LabeledInputWithSymbol
                        label={t('create_tax_subject.l_tax_rate')}
                        name="tax_progression"
                        infoText={t('create_forecast.i_tax_rate')}
                        type="number"
                        symbolIcon="%"
                        rule={validationSchema.tax_progression}
                    />
                </Form>
            )}
        </ContentPageBase>
    );
};

export default AddEditTaxSubject;
