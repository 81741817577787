import { useSelector, useDispatch } from 'react-redux';
import { setRequestFinished, setRequestMessage } from '../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Chart } from 'chart.js';
import logo from '../img/renteezy-logo-temp.png';
import sendRequest from './sendRequest';
import { formatNumber2Dec } from '../components/NumericFormatter';
import constants from './constants';
import getTaxsubjectName from './getTaxsubjectName';
import getUTCDate from './getUTCDate';

export const exportReportToPdf = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const properties = useSelector((state) => state.user.properties);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);
    const user = useSelector((state) => state.user.user);

    // helpers
    // TODO: replace with translation strings
    const months = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember'
    ];

    const addPDFPagination = (doc) => {
        let pageCount = doc.getNumberOfPages(); // Total number of pages
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.text(
                'Seite ' + i + ' von ' + pageCount,
                doc.internal.pageSize.width / 2,
                doc.internal.pageSize.height - 10,
                { align: 'center' }
            );
        }
    };

    const pdfPointsToPixels = (points) => {
        return (points * 96) / 72;
    };

    const addPDFWatermark = (doc) => {
        doc.setTextColor(220, 220, 220);
        doc.setFontSize(80);
        doc.setFont('helvetica', 'bold');
        doc.text('TEST ZUGANG', 40, 220, null, 45);
        // reset styles
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(12);
    };

    const addPDFLogo = (doc) => {
        let pageCount = doc.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.addImage(logo, 'PNG', doc.internal.pageSize.width - 50, 5, 40, 15);
        }
    };

    return (propertyId) => {
        // Get property data
        const property = properties.find((property) => property._id === propertyId);
        const isTrialVersion = user?.subscriptionData?.plan_name === 'Trial' || property?.isTest;
        if (!property) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: t('global.e_server_error')
                })
            );
            return;
        }
        const propertyAddress =
            property?.propertiesData?.l_street +
            ' ' +
            property?.propertiesData?.l_house_number +
            property?.propertiesData?.l_door_number
                ? '/' + property?.propertiesData?.l_door_number
                : '';
        const propertyCity =
            (property?.propertiesData?.l_zip || '') +
            ' ' +
            (property?.propertiesData?.l_city || '');

        // Get taxsubject data
        const taxsubject = taxsubjects.find((taxsubject) => taxsubject._id === property._owner);
        if (!taxsubject) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: t('global.e_server_error')
                })
            );
            return;
        }

        const taxsubjectName = getTaxsubjectName(taxsubject);
        const company =
            taxsubject?.taxsubjectsData?.l_company && taxsubject?.taxsubjectsData?.l_company;
        const taxsubjectAddress = taxsubject?.taxsubjectsData?.l_address_line;
        const taxsubjectCity = taxsubject?.taxsubjectsData?.l_city;

        // get forecast data
        let forecastData = null;
        const fetchForecastData = async () => {
            await sendRequest('/queryDB/forecasts', 'post', { IDs: propertyId }).then((resp) => {
                if (resp && resp.length > 0) {
                    forecastData = resp[0].forecastsData;
                }
            });
        };
        (async () => {
            await fetchForecastData();
            if (!forecastData) {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('global.e_server_error')
                    })
                );
                return;
            }

            const firstFullYear = getUTCDate(forecastData?.l_rent_start_date).getUTCFullYear() + 1;

            // Get forecast calculations
            sendRequest('/forecastCalculations', 'post', {
                ID: propertyId,
                isSmallBusiness: taxsubject.taxsubjectsData.l_small_business_scheme,
                tax_rate: taxsubject?.taxsubjectsData.tax_progression,
                preset: constants.CALCULATION_PRESETS.PDF
            }).then((resp) => {
                if (resp?.error) {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: t('global.e_server_error')
                        })
                    );
                    return;
                } else {
                    let forecastCalculations = resp.result[0].Prognoserechnung;

                    let kitchenAndMiscPrice = 0;
                    if (forecastData?.l_purchase_price_kitchen)
                        kitchenAndMiscPrice += +forecastData?.l_purchase_price_kitchen;
                    if (forecastData?.l_purchase_price_misc)
                        kitchenAndMiscPrice += +forecastData?.l_purchase_price_misc;
                    if (kitchenAndMiscPrice === 0) kitchenAndMiscPrice = '-';
                    if (kitchenAndMiscPrice > 0)
                        kitchenAndMiscPrice = '€ ' + formatNumber2Dec(kitchenAndMiscPrice);

                    let otherAncillaryCosts = 0;
                    forecastData?.otherAncillaryCostPages.map(
                        (page) =>
                            (otherAncillaryCosts +=
                                +forecastData?.['l_anc_cost_item_value_' + page.id] || 0)
                    );

                    const specialRepaymentsPages = forecastData?.specialRepaymentsPages.map(
                        (page) => {
                            const fullDate = getUTCDate(
                                forecastData?.['l_prepayment_date_' + page.id]
                            );
                            return {
                                date:
                                    months[fullDate.getUTCMonth()] +
                                    ' ' +
                                    fullDate.getUTCFullYear(),
                                value: forecastData?.['l_prepayment_value_' + page.id]
                            };
                        }
                    );

                    const yearlyDepreciationFull = -forecastCalculations.find(
                        (item) => item.year === firstFullYear
                    )?.yearly_depreciation_full;
                    const yearlyDepreciationFullGarage = -forecastCalculations.find(
                        (item) => item.year === firstFullYear
                    )?.yearly_depreciation_full_garage;
                    const yearlyDepreciationFullKitchen = -forecastCalculations.find(
                        (item) => item.year === firstFullYear
                    )?.yearly_depreciation_full_kitchen;
                    const yearlyDepreciationFullMisc = -forecastCalculations.find(
                        (item) => item.year === firstFullYear
                    )?.yearly_depreciation_full_misc;
                    const yearlyDepreciationFullRenovation = -forecastCalculations.find(
                        (item) => item.year === firstFullYear
                    )?.yearly_depreciation_full_renovation;

                    // Create PDF
                    const doc = new jsPDF();
                    const pageWidth = doc.internal.pageSize.width;
                    const pageHeight = doc.internal.pageSize.height;
                    const pageMargin = 10;
                    const maxContentWidth = pageWidth - 2 * pageMargin;
                    const pageTopMargin = 30;

                    // Cover page
                    isTrialVersion && addPDFWatermark(doc);

                    doc.setFontSize(36);
                    doc.setFont('helvetica', 'bold');
                    doc.text(t('global.forecast_calculation'), 20, 80);

                    doc.setFontSize(12);
                    doc.text(t('pdf.l_for'), 20, 100);
                    doc.setFont('helvetica', 'normal');
                    taxsubjectName &&
                        taxsubjectName !== company &&
                        doc.text(taxsubjectName, 20, 110);
                    company && doc.text(company, 20, taxsubjectName !== company ? 120 : 110);
                    taxsubjectAddress &&
                        doc.text(taxsubjectAddress, 20, taxsubjectName !== company ? 126 : 116);
                    taxsubjectCity &&
                        doc.text(taxsubjectCity, 20, taxsubjectName !== company ? 132 : 122);
                    doc.text('Österreich', 20, taxsubjectName !== company ? 138 : 128);

                    doc.setFont('helvetica', 'bold');
                    doc.text(t('pdf.l_property'), 20, 153);
                    doc.setFont('helvetica', 'normal');
                    propertyAddress && doc.text(propertyAddress, 20, 163);
                    propertyCity !== ' ' && doc.text(propertyCity, 20, 169);
                    doc.text('Österreich', 20, propertyCity !== ' ' ? 175 : 169);

                    // First page
                    doc.addPage();
                    isTrialVersion && addPDFWatermark(doc);

                    // Calculate the width of the columns
                    let middleColumnWidth = 6; // Width of the middle column, gap between the other columns
                    let otherColumnWidth = (pageWidth - 2 * pageMargin - middleColumnWidth) / 4;

                    const createRow = (col1, col2, col4, col5) => ({
                        col1,
                        col2,
                        col3: '',
                        col4,
                        col5
                    });
                    const createTable = (starty, columns, body) => {
                        return doc.autoTable({
                            startY: starty,
                            columns: columns,
                            body: body,
                            theme: 'plain',
                            margin: { left: 10, right: 10 },
                            styles: { fontSize: 10 },
                            columnStyles: {
                                col1: { cellWidth: otherColumnWidth + 10, halign: 'left' },
                                col2: { cellWidth: otherColumnWidth - 10, halign: 'right' },
                                col3: {
                                    cellWidth: middleColumnWidth,
                                    halign: 'center'
                                },
                                col4: { cellWidth: otherColumnWidth + 10, halign: 'left' },
                                col5: { cellWidth: otherColumnWidth - 10, halign: 'right' }
                            }
                        });
                    };

                    // first table data
                    let propertyDetailsTableColumns = [
                        { header: t('pdf.h_property_details'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' },
                        { header: '', dataKey: 'col3' },
                        { header: t('pdf.l_purchase_details'), dataKey: 'col4' },
                        { header: '', dataKey: 'col5' }
                    ];
                    let propertyDetailsTableRows = [
                        createRow(
                            t('pdf.l_construction_year'),
                            property.propertiesData?.l_construction_year,
                            t('pdf.l_purchase_price_building'),
                            '€ ' + formatNumber2Dec(forecastData?.l_purchase_price_building)
                        ),
                        createRow(
                            t('pdf.l_year_of_geneal_renovation'),
                            property.propertiesData?.last_general_renovation || '-',
                            t('pdf.l_purchase_price_kitchen'),
                            forecastData?.l_purchase_price_kitchen
                                ? '€ ' + formatNumber2Dec(forecastData.l_purchase_price_kitchen)
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_number_of_garages'),
                            property.propertiesData?.l_number_of_garages || '0',
                            t('pdf.l_purchase_price_garage'),
                            forecastData?.l_purchase_price_garage
                                ? '€ ' + formatNumber2Dec(forecastData.l_purchase_price_garage)
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_share_of_land'),
                            property.propertiesData?.l_share_of_land + '%',
                            t('pdf.l_purchase_price_other'),
                            forecastData?.l_purchase_price_misc
                                ? '€ ' + formatNumber2Dec(forecastData.l_purchase_price_misc)
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_type_of_rental'),
                            t(
                                property.propertiesData?.l_type_of_rental ===
                                    'create_property.l_type_of_rental_small'
                                    ? 'pdf.l_small_rental'
                                    : 'pdf.l_large_rental'
                            ),
                            t('pdf.l_purchase_price_renovation'),
                            forecastData?.l_purchase_price_renovation
                                ? '€ ' + formatNumber2Dec(forecastData.l_purchase_price_renovation)
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_area_of_living_space'),
                            property.propertiesData.l_area_of_living_space
                                ? formatNumber2Dec(property.propertiesData.l_area_of_living_space) +
                                      'm²'
                                : '-',
                            t('pdf.l_purchase_price'),
                            forecastData?.l_purchase_price
                                ? '€ ' + formatNumber2Dec(forecastData.l_purchase_price)
                                : '-'
                        ),
                        createRow(
                            '',
                            '',
                            t('pdf.l_purchase_date'),
                            getUTCDate(forecastData?.l_purchase_date).toLocaleDateString()
                        ),
                        createRow(
                            '',
                            '',
                            forecastData?.l_purchase_type ===
                                constants.VAT_PAYER_TYPES.not_small_business
                                ? t('pdf.l_purchase_type_1')
                                : t('pdf.l_purchase_type_2'),
                            ''
                        )
                    ];
                    createTable(
                        pageTopMargin,
                        propertyDetailsTableColumns,
                        propertyDetailsTableRows
                    );

                    // second table data
                    let additionalCostsTableColumns = [
                        { header: t('pdf.h_purchase_ancillary_costs'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' },
                        { header: '', dataKey: 'col3' },
                        { header: t('pdf.h_financing_ancillary_costs'), dataKey: 'col4' },
                        { header: '', dataKey: 'col5' }
                    ];
                    let additionalCostsTableRows = [
                        createRow(
                            t('pdf.l_real_estate_transfer_tax'),
                            '€ ' + formatNumber2Dec(forecastData?.l_real_estate_transfer_tax),
                            t('pdf.l_bank_processing_fee'),
                            forecastData?.l_financing_via_loan === undefined ||
                                forecastData?.l_financing_via_loan === true
                                ? '€ ' + formatNumber2Dec(forecastData?.l_bank_processing_fee)
                                : '-'
                        ),

                        createRow(
                            t('pdf.l_land_register_registration_fee'),
                            '€ ' + formatNumber2Dec(forecastData?.l_land_register_registration_fee),
                            t('pdf.l_costs_land_register_entry_lien'),
                            forecastData?.l_financing_via_loan === undefined ||
                                forecastData?.l_financing_via_loan === true
                                ? '€ ' +
                                      formatNumber2Dec(
                                          forecastData?.l_costs_land_register_entry_lien
                                      )
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_trust_and_contract_establishment_fee'),
                            forecastData?.l_trust_and_contract_establishment_fee
                                ? '€ ' +
                                      formatNumber2Dec(
                                          forecastData.l_trust_and_contract_establishment_fee
                                      )
                                : '-',
                            '',
                            ''
                        ),
                        createRow(
                            t('pdf.l_notary_cash_expenses'),
                            forecastData?.l_notary_cash_expenses
                                ? '€ ' + formatNumber2Dec(forecastData.l_notary_cash_expenses)
                                : '-',
                            '',
                            ''
                        ),
                        createRow(
                            t('pdf.l_real_estate_broker_fee'),
                            forecastData?.l_real_estate_broker_fee
                                ? '€ ' + formatNumber2Dec(forecastData.l_real_estate_broker_fee)
                                : '-',
                            '',
                            ''
                        ),
                        createRow(
                            t('pdf.l_other_ancillary_purchase_costs'),
                            '€ ' + formatNumber2Dec(otherAncillaryCosts),
                            '',
                            ''
                        ),
                        createRow(
                            t('pdf.l_ancillary_purchase_costs'),
                            '€ ' +
                                formatNumber2Dec(
                                    +forecastData?.l_real_estate_transfer_tax +
                                        +forecastData?.l_land_register_registration_fee +
                                        +forecastData?.l_trust_and_contract_establishment_fee +
                                        +forecastData?.l_notary_cash_expenses +
                                        +forecastData?.l_real_estate_broker_fee +
                                        otherAncillaryCosts
                                ),
                            '',
                            ''
                        )
                    ];
                    createTable(105, additionalCostsTableColumns, additionalCostsTableRows);

                    let financingTableColumns = [
                        { header: t('pdf.h_financing'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' }
                    ];

                    let specialRepaymentsTableColumns = [
                        { header: t('pdf.h_special_repayments'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' }
                    ];

                    let specialRepaymentsTableRows = specialRepaymentsPages.map((page) => {
                        return [page.date, '€ ' + formatNumber2Dec(page.value)];
                    });

                    if (specialRepaymentsPages.length === 0)
                        specialRepaymentsTableRows = [
                            [t('pdf.l_no_special_repayments_planned'), '']
                        ];
                    console.log(specialRepaymentsTableRows, specialRepaymentsPages);

                    const financingTableRows = [
                        {
                            col1: t('pdf.l_loan_term'),
                            col2:
                                forecastData?.l_financing_via_loan === undefined ||
                                forecastData?.l_financing_via_loan === true
                                    ? forecastData?.l_loan_term
                                    : '-'
                        },
                        {
                            col1: t('pdf.l_loan_duration_fixed_rate'),
                            col2:
                                (forecastData?.l_financing_via_loan === undefined ||
                                    forecastData?.l_financing_via_loan === true) &&
                                forecastData?.l_loan_type === 0
                                    ? forecastData?.l_loan_duration_fixed_rate
                                    : '-'
                        },
                        {
                            col1: t('pdf.l_loan_fixed_rate'),
                            col2:
                                (forecastData?.l_financing_via_loan === undefined ||
                                    forecastData?.l_financing_via_loan === true) &&
                                forecastData?.l_loan_type === 0
                                    ? forecastData?.l_loan_fixed_rate + '%'
                                    : '-',
                            col3: ''
                        },
                        {
                            col1: t('pdf.l_loan_adjustable_rate'),
                            col2:
                                forecastData?.l_financing_via_loan === undefined ||
                                forecastData?.l_financing_via_loan === true
                                    ? forecastData?.l_loan_adjustable_rate + '%'
                                    : '-',
                            col3: ''
                        },
                        {
                            col1: t('pdf.l_loan_down_payment'),
                            col2:
                                (forecastData?.l_financing_via_loan === undefined ||
                                    forecastData?.l_financing_via_loan === true) &&
                                '€ ' + formatNumber2Dec(forecastData?.l_loan_down_payment),
                            col3: ''
                        },
                        {
                            col1: t('pdf.l_total_loan_value'),
                            col2:
                                forecastData?.l_financing_via_loan === undefined ||
                                forecastData?.l_financing_via_loan === true
                                    ? '€ ' + formatNumber2Dec(forecastData?.l_total_loan_value)
                                    : '-',
                            col3: ''
                        }
                    ];

                    doc.autoTable({
                        startY: 180,
                        columns: financingTableColumns,
                        body: financingTableRows,
                        theme: 'plain',
                        margin: { left: 10, right: 10 },
                        styles: { fontSize: 10 },
                        columnStyles: {
                            col1: { cellWidth: otherColumnWidth + 13, halign: 'left' },
                            col2: { cellWidth: otherColumnWidth - 13, halign: 'right' }
                        }
                    });
                    doc.autoTable({
                        startY: 180,
                        columns: specialRepaymentsTableColumns,
                        body: specialRepaymentsTableRows,
                        theme: 'plain',
                        margin: {
                            right: 10,
                            left: pageMargin + otherColumnWidth * 2 + middleColumnWidth
                        },
                        styles: { fontSize: 10 },
                        columnStyles: {
                            col1: { cellWidth: otherColumnWidth + 13, halign: 'left' },
                            col2: { cellWidth: otherColumnWidth - 13, halign: 'right' }
                        }
                    });

                    doc.addPage();
                    isTrialVersion && addPDFWatermark(doc);
                    const repairCostsMethod =
                        forecastData?.l_repair_costs_method === 0
                            ? `${t('pdf.l_repair_costs_method1')} (${
                                  forecastData?.l_maintenance_reserves_percent
                              }%)`
                            : t('pdf.l_repair_costs_method2');

                    const planedIncomeTableColumns = [
                        { header: t('pdf.h_income'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' },
                        { header: '', dataKey: 'col3' },
                        { header: t('pdf.h_expenses'), dataKey: 'col4' },
                        { header: '', dataKey: 'col5' }
                    ];
                    const planedIncomeTableRows = [
                        createRow(
                            t('pdf.l_rent_start_date'),
                            getUTCDate(forecastData?.l_rent_start_date).toLocaleDateString(),
                            t('pdf.l_costs_tax_advisor'),
                            '€ ' + formatNumber2Dec(forecastData?.l_costs_tax_advisor)
                        ),
                        createRow(
                            t('pdf.l_expected_monthly_rent'),
                            '€ ' + formatNumber2Dec(forecastData?.l_expected_monthly_rent),
                            t('pdf.l_costs_housing_management'),
                            '€ ' + formatNumber2Dec(forecastData?.l_costs_housing_management)
                        ),
                        createRow(
                            t('pdf.l_expected_monthly_rent_kitchen'),
                            forecastData.l_expected_monthly_rent_kitchen
                                ? '€ ' +
                                      formatNumber2Dec(forecastData.l_expected_monthly_rent_kitchen)
                                : '-',
                            repairCostsMethod,
                            t('pdf.l_repair_costs_method')
                        ),
                        createRow(
                            t('pdf.l_expected_monthly_rent_garage'),
                            forecastData.l_expected_monthly_rent_garage
                                ? '€ ' +
                                      formatNumber2Dec(forecastData.l_expected_monthly_rent_garage)
                                : '-',
                            t('pdf.l_costs_advertising_misc'),
                            forecastData?.l_costs_advertising_misc
                                ? '€ ' + formatNumber2Dec(forecastData.l_costs_advertising_misc)
                                : '-'
                        ),
                        createRow(
                            t('pdf.l_expected_monthly_rent_misc'),
                            forecastData.l_expected_monthly_rent_misc
                                ? '€ ' + formatNumber2Dec(forecastData.l_expected_monthly_rent_misc)
                                : '-',
                            '',
                            ''
                        ),
                        createRow(
                            t('pdf.l_vacancy_rate'),
                            forecastData?.l_vacancy_rate + '%',
                            '',
                            ''
                        )
                    ];
                    createTable(pageTopMargin, planedIncomeTableColumns, planedIncomeTableRows);

                    const performanceTableColumns = [
                        { header: t('pdf.h_value_developments'), dataKey: 'col1' },
                        { header: '', dataKey: 'col2' },
                        { header: '', dataKey: 'col3' },
                        { header: t('pdf.h_depreciations'), dataKey: 'col4' },
                        { header: '', dataKey: 'col5' }
                    ];
                    const performanceTableRows = [
                        createRow(
                            t('pdf.l_rent_increase'),
                            forecastData?.l_rent_increase + '%',
                            t('pdf.l_depreciation_building'),
                            forecastData?.l_depreciation + '%'
                        ),
                        createRow(
                            t('pdf.l_inflation_rate'),
                            forecastData?.l_inflation_rate + '%',
                            t('pdf.l_yearly_depreciation_amount_building'),
                            '€ ' + formatNumber2Dec(yearlyDepreciationFull)
                        )
                    ];
                    yearlyDepreciationFullGarage &&
                        performanceTableRows.push(
                            createRow(
                                '',
                                '',
                                t('pdf.l_depreciation_garage'),
                                forecastData.l_depreciation_garage
                                    ? forecastData.l_depreciation_garage + '%'
                                    : '-'
                            )
                        );
                    yearlyDepreciationFullGarage &&
                        performanceTableRows.push(
                            createRow(
                                '',
                                '',
                                t('pdf.l_yearly_depreciation_amount_garage'),
                                '€ ' + formatNumber2Dec(yearlyDepreciationFullGarage)
                            )
                        );
                    yearlyDepreciationFullKitchen &&
                        performanceTableRows.push(
                            createRow(
                                '',
                                '',
                                t('pdf.l_depreciation_kitchen'),
                                forecastData.l_depreciation_kitchen
                                    ? forecastData.l_depreciation_kitchen + '%'
                                    : '-'
                            )
                        ),
                        yearlyDepreciationFullKitchen &&
                            performanceTableRows.push(
                                createRow(
                                    '',
                                    '',
                                    t('pdf.l_yearly_depreciation_amount_kitchen'),
                                    '€ ' + formatNumber2Dec(yearlyDepreciationFullKitchen)
                                )
                            );
                    yearlyDepreciationFullMisc !== 0 &&
                        performanceTableRows.push(
                            yearlyDepreciationFullMisc !== undefined &&
                                createRow(
                                    '',
                                    '',
                                    t('pdf.l_depreciation_misc'),
                                    forecastData.l_depreciation_misc
                                        ? forecastData.l_depreciation_misc + '%'
                                        : '-'
                                )
                        );
                    yearlyDepreciationFullMisc !== 0 &&
                        performanceTableRows.push(
                            yearlyDepreciationFullMisc !== undefined &&
                                createRow(
                                    '',
                                    '',
                                    t('pdf.l_yearly_depreciation_amount_misc'),
                                    '€ ' + formatNumber2Dec(yearlyDepreciationFullMisc)
                                )
                        );
                    yearlyDepreciationFullRenovation !== 0 &&
                        performanceTableRows.push(
                            yearlyDepreciationFullRenovation !== undefined &&
                                createRow(
                                    '',
                                    '',
                                    t('pdf.l_depreciation_renovation'),
                                    forecastData.l_depreciation_renovation
                                        ? forecastData.l_depreciation_renovation + '%'
                                        : '-'
                                )
                        );
                    yearlyDepreciationFullRenovation !== 0 &&
                        performanceTableRows.push(
                            yearlyDepreciationFullRenovation !== undefined &&
                                createRow(
                                    '',
                                    '',
                                    t('pdf.l_yearly_depreciation_amount_renovation'),
                                    '€ ' + formatNumber2Dec(yearlyDepreciationFullRenovation)
                                )
                        );
                    createTable(100, performanceTableColumns, performanceTableRows);

                    doc.setFont('helvetica', 'bold');
                    doc.text(t('pdf.h_methodology'), 10, 190);
                    doc.setFont('helvetica', 'normal');

                    let text = t('pdf.l_methodology');
                    let maxLength = pageWidth - 20; // Subtract margins
                    let splitText = doc.splitTextToSize(text, maxLength);
                    doc.text(splitText, 10, 200);

                    // ADD GRAPH
                    const graphWidth = pageWidth - 2 * pageMargin;
                    const graphHeight = 60;
                    // Create a canvas element
                    const canvas = document.createElement('canvas');
                    canvas.width = 3 * pdfPointsToPixels(graphWidth); // 3 times the size of the graph to make it sharper
                    canvas.height = 3 * pdfPointsToPixels(graphHeight);
                    canvas.style.display = 'none';

                    let yearsUntilProfit = null;
                    if (forecastCalculations.length === 0) yearsUntilProfit = '-';
                    else yearsUntilProfit = forecastCalculations[0].yearsUntilProfit;

                    // Chart data
                    let labels = forecastCalculations.map((item) => item.year);
                    let data = {
                        labels,
                        datasets: [
                            {
                                label: 'Prognoserechnung',
                                data: forecastCalculations.map(
                                    (item) => item.adjusted_cumulative_tax_result
                                ),

                                borderColor: '#73dea8',
                                backgroundColor: '#73dea8',
                                tension: 0.4,
                                pointRadius: 0,
                                borderDash: [15, 5]
                            }
                        ]
                    };

                    // Create a chart
                    const ctx = canvas.getContext('2d');
                    new Promise((resolve) => {
                        new Chart(ctx, {
                            type: 'line',
                            data: data,
                            options: {
                                responsive: false,
                                plugins: {
                                    legend: {
                                        display: false
                                    }
                                },
                                animation: {
                                    onComplete: function () {
                                        const graph = canvas.toDataURL('image/png');
                                        resolve(graph);
                                    }
                                }
                            }
                        });
                    }).then((graph) => {
                        doc.addPage();
                        isTrialVersion && addPDFWatermark(doc);
                        doc.setFont('helvetica', 'bold');
                        doc.text(t('pdf.h_forecast_calculation'), 10, pageTopMargin);
                        doc.setFont('helvetica', 'normal');

                        // Add the graph to the PDF
                        doc.addImage(graph, 'PNG', 10, 35, graphWidth, graphHeight);

                        doc.setFont('helvetica', 'bold');
                        doc.text(
                            t('pdf.l_total_surplus_after_year_x').replace(
                                '{{year}}',
                                yearsUntilProfit
                            ),
                            10,
                            105
                        );
                        doc.setFont('helvetica', 'normal');

                        // ADD FORECAST TABLE
                        const isKitchenPurchased =
                            forecastCalculations.filter((item) => item.yearly_rent_kitchen !== 0)
                                .length > 0;
                        const isGaragePurchased =
                            forecastCalculations.filter((item) => item.yearly_rent_garage !== 0)
                                .length > 0;
                        const isMiscPurchased =
                            forecastCalculations.filter((item) => item.yearly_rent_misc !== 0)
                                .length > 0;
                        const isRenovation =
                            forecastCalculations.filter(
                                (item) => item.yearly_depreciation_full_renovation !== 0
                            ).length > 0;
                        const isAcceleratedDepr =
                            forecastCalculations.filter(
                                (item) => item.additional_accelerated_depreciation !== 0
                            ).length > 0;
                        const addTableRow = (label, value) => {
                            const yearsRow = [label];
                            const restCols = forecastCalculations.map((item) => {
                                return formatNumber2Dec(Math.round(item[value]));
                            });
                            yearsRow.push(...restCols);
                            const result = yearsRow.reduce((acc, item, index) => {
                                acc[`col${index + 1}`] = item;
                                return acc;
                            }, {});
                            return result;
                        };

                        const forecastColumns = [
                            { header: '', dataKey: 'col1' },
                            ...forecastCalculations.map((item, index) => {
                                return {
                                    header: item.year,
                                    dataKey: `col${index + 2}` // Start from 2 because the first column is manually inserted
                                };
                            })
                        ];

                        let forecastRows = [addTableRow(t('forecast.l_flat_rent'), 'yearly_rent')];

                        isKitchenPurchased &&
                            forecastRows.push(
                                addTableRow(t('forecast.l_kitchen_rent'), 'yearly_rent_kitchen')
                            );

                        isGaragePurchased &&
                            forecastRows.push(
                                addTableRow(t('forecast.l_garage_rent'), 'yearly_rent_garage')
                            );

                        isMiscPurchased &&
                            forecastRows.push(
                                addTableRow('Mieterträge Sonstiges', 'yearly_rent_misc')
                            );

                        forecastRows.push(
                            addTableRow(t('forecast.l_vacancy'), 'vacancy'),
                            addTableRow(
                                t('forecast.l_housing_management_costs'),
                                'costs_housing_management'
                            ),
                            addTableRow(t('forecast.l_maintenance'), 'xxx'),
                            addTableRow(t('forecast.l_financing_costs'), 'financing'),
                            addTableRow(
                                t('create_forecast.l_costs_advertising_misc'),
                                'advertising_cost'
                            ),
                            addTableRow(
                                t('forecast.l_depreciation_building'),
                                'yearly_depreciation_full'
                            )
                        );
                        isAcceleratedDepr &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_additional_accelerated_depreciation'),
                                    'additional_accelerated_depreciation'
                                )
                            );
                        isKitchenPurchased &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_depreciation_kitchen'),
                                    'yearly_depreciation_full_kitchen'
                                )
                            );

                        isGaragePurchased &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_depreciation_garage'),
                                    'yearly_depreciation_full_garage'
                                )
                            );

                        isMiscPurchased &&
                            forecastRows.push(
                                addTableRow(
                                    'Abschreibung Sonstiges',
                                    'yearly_depreciation_full_misc'
                                )
                            );
                        isRenovation &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_depreciation_renovation'),
                                    'yearly_depreciation_full_renovation'
                                )
                            );

                        forecastRows.push(
                            // addTableRow('Abschreibung Makler', 'yearly_depreciation_full_broker'),
                            addTableRow(
                                t('forecast.l_costs_tax_advisor_and.other'),
                                'tax_consultancy_and_other_advisors'
                            ),
                            addTableRow(t('forecast.l_interest_expense'), 'interest_amount_full'),
                            addTableRow(t('forecast.l_tax_result'), 'tax_result')
                        );
                        isAcceleratedDepr &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_accumulated_earnings'),
                                    'cumulative_tax_result'
                                )
                            );
                        isAcceleratedDepr &&
                            forecastRows.push(
                                addTableRow(
                                    t('forecast.l_depreciation_transition'),
                                    'depreciation_transfer'
                                )
                            );
                        forecastRows.push(
                            addTableRow(
                                t('forecast.l_accumulated_earnings_adjusted'),
                                'adjusted_cumulative_tax_result'
                            )
                        );

                        const maxColumns = 11; //max columns + 1 (first one is the labels column)
                        const firstColumnWidth = 36;
                        const otherColumnsWidth =
                            (pageWidth - 2 * pageMargin - firstColumnWidth) / (maxColumns - 1);

                        const addTable = (startY, columns, rows) => {
                            const columnStyles = columns.reduce((styles, column, index) => {
                                styles[column.dataKey] = {
                                    halign: index === 0 ? 'left' : 'right',
                                    cellWidth: index === 0 ? firstColumnWidth : otherColumnsWidth
                                };
                                return styles;
                            }, {});

                            doc.autoTable({
                                startY: startY,
                                columns: columns,
                                body: rows,
                                theme: 'grid',
                                margin: { left: 10, right: 10 },
                                styles: {
                                    fontSize: 6,
                                    cellWidth: 'wrap',
                                    cellPadding: 1,
                                    halign: 'right',
                                    fillColor: null // make cells transparent
                                },
                                headStyles: {
                                    fillColor: [0, 0, 0], // header background color
                                    textColor: [200, 200, 200] // header text color
                                },
                                alternateRowStyles: {
                                    fillColor: null // make alternate rows transparent
                                },
                                columnStyles: columnStyles,
                                // Styles for the last row
                                willDrawCell: function (data) {
                                    if (
                                        data.row.index === rows.length - 1 &&
                                        data.section === 'body'
                                    ) {
                                        data.cell.text = '';
                                    }
                                },
                                didDrawCell: function (data) {
                                    if (
                                        data.row.index === rows.length - 1 &&
                                        data.section === 'body'
                                    ) {
                                        doc.setFont('helvetica', 'bold');
                                        var textWidth =
                                            (doc.getStringUnitWidth(data.cell.raw) *
                                                doc.internal.getFontSize()) /
                                            doc.internal.scaleFactor;
                                        var textOffset =
                                            data.column.index === 0
                                                ? 1
                                                : data.cell.width - textWidth - 1;
                                        doc.text(
                                            data.cell.raw,
                                            data.cell.x + textOffset,
                                            data.cell.y + data.cell.height - 1.5
                                        );

                                        if (data.column.index === data.table.columns.length - 1) {
                                            doc.setLineWidth(0.3); // make the top border thicker
                                            var totalTableWidth = data.table.columns.reduce(
                                                function (sum, column) {
                                                    return sum + column.width;
                                                },
                                                0
                                            );
                                            doc.setDrawColor(0, 0, 0); // set the color to black
                                            doc.line(
                                                data.cell.x - totalTableWidth + data.cell.width,
                                                data.cell.y,
                                                data.cell.x + data.cell.width,
                                                data.cell.y
                                            );
                                        }
                                    } else {
                                        doc.setFont('helvetica', 'normal'); // make the text normal for other rows
                                    }
                                }
                            });
                        };

                        let startY = 115;
                        let tableHeight = 0;
                        // render the table in chunks of desired number of columns
                        for (let i = 0; i < forecastColumns.length - 1; i += maxColumns - 1) {
                            const columns =
                                i === 0
                                    ? forecastColumns.slice(i, i + maxColumns)
                                    : [
                                          { header: '', dataKey: 'col1' },
                                          ...forecastColumns.slice(i + 1, i + maxColumns)
                                      ];
                            const rows = forecastRows.map((row) => {
                                const newRow = {};
                                columns.forEach((column) => {
                                    newRow[column.dataKey] = row[column.dataKey];
                                });
                                return newRow;
                            });
                            if (i === 0) {
                                addTable(startY, columns, rows);
                                tableHeight = doc.previousAutoTable.finalY - startY;
                                startY = doc.previousAutoTable.finalY + 7;
                            }
                            if (i > 0) {
                                if (startY + tableHeight > pageHeight - (pageTopMargin + 0)) {
                                    doc.addPage();
                                    isTrialVersion && addPDFWatermark(doc);
                                    startY = pageTopMargin;
                                    addTable(startY, columns, rows);
                                    startY = doc.previousAutoTable.finalY + 7;
                                } else {
                                    addTable(startY, columns, rows);
                                    startY = doc.previousAutoTable.finalY + 7;
                                }
                            }
                        }

                        // Add link to documents
                        // if (forecastData?.l_link_to_documents) {
                        //     const splitText = doc.splitTextToSize(
                        //         'Link zu relevanten Dokumenten: ' +
                        //             forecastData?.l_link_to_documents,
                        //         maxContentWidth
                        //     );
                        //     // Calculate text height
                        //     const lineHeight = doc.internal.getLineHeight();
                        //     const textHeight = splitText.length * lineHeight;

                        //     if (startY + textHeight > pageHeight - pageTopMargin) {
                        //         doc.addPage();
                        //         isTrialVersion && addPDFWatermark(doc);
                        //         startY = pageTopMargin;
                        //         doc.text(splitText, 10, startY);
                        //         startY += textHeight;
                        //     } else {
                        //         doc.text(splitText, 10, startY);
                        //         startY += textHeight;
                        //     }
                        // }

                        // Add comments
                        if (forecastData?.l_comment) {
                            splitText = doc.splitTextToSize(
                                t('pdf.l_comment') + ' ' + forecastData?.l_comment,
                                maxContentWidth
                            );
                            // Calculate text height
                            const lineHeight = doc.internal.getLineHeight();
                            const textHeight = splitText.length * lineHeight;

                            if (startY + textHeight > pageHeight - pageTopMargin) {
                                doc.addPage();
                                isTrialVersion && addPDFWatermark(doc);
                                startY = pageTopMargin;
                                doc.text(splitText, 10, startY);
                                startY += textHeight;
                            } else {
                                doc.text(splitText, 10, startY + 10);
                                startY += textHeight;
                            }
                        }

                        // Add disclaimer
                        splitText = doc.splitTextToSize(t('pdf.l_disclaimer'), maxContentWidth);
                        // Calculate text height
                        const lineHeight = doc.internal.getLineHeight();
                        const textHeight = splitText.length * lineHeight;
                        doc.text(splitText, 10, startY + 10);
                        startY += textHeight;

                        // Add logo and pagination to all pages
                        addPDFLogo(doc);
                        addPDFPagination(doc);

                        //OPEN  IN NEW TAB
                        const pdfBlob = new Blob([doc.output('blob')], { type: 'application/pdf' });
                        const pdfUrl = URL.createObjectURL(pdfBlob);
                        window.open(pdfUrl, '_blank');

                        // OR DOWNLOAD
                        // doc.save('Prognoserechnung.pdf');
                    });
                }
            });
        })();
    };
};
