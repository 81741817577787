import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TaxSubjectsList from '../taxSubjectsPages/TaxSubjectsList';
import AddEditTaxSubject from '../taxSubjectsPages/AddEditTaxSubject';
import TaxSubjectDashboard from '../taxSubjectsPages/TaxSubjectDashboard';
import { useLocation, useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';

const TaxSubjects = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);

    const [editID, setEditID] = useState(currentTaxsubject?._id);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (location.pathname === constants.ROUTES.TAXSUBJECTS.LIST) {
            setContent(
                <TaxSubjectsList
                    editTaxSubject={(currentID) => {
                        setEditID(currentID);
                        navigate(constants.ROUTES.TAXSUBJECTS.EDIT);
                    }}
                />
            );
        }
        if (location.pathname === constants.ROUTES.TAXSUBJECTS.DASHBOARD) {
            setContent(
                <TaxSubjectDashboard
                    editTaxSubject={(currentID) => {
                        setEditID(currentID);
                        navigate(constants.ROUTES.TAXSUBJECTS.EDIT);
                    }}
                />
            );
        }
        if (location.pathname === constants.ROUTES.TAXSUBJECTS.CREATE) {
            setContent(<AddEditTaxSubject editID={null} />);
        }
        if (location.pathname === constants.ROUTES.TAXSUBJECTS.EDIT) {
            setContent(<AddEditTaxSubject editID={editID} />);
        }
    }, [location]);
    return content;
};

export default TaxSubjects;
