import React from 'react';
import { Loader } from 'rsuite';
import ContentPageBase from '../../components/ContentPageBase';

const Loading = () => {
    return (
        <ContentPageBase>
            <div className="loading">
                <Loader speed="slow" backdrop content="loading..." vertical />
            </div>
        </ContentPageBase>
    );
};

export default Loading;
