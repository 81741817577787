import constants from './constants';

export default (subscriptionType, user) => {
    let value = 100;
    switch (subscriptionType) {
        case constants.SUBSSCRIPTION_TYPE.INVALID_SUBSCRIPTION_TYPE.trial.value:
            value = { properties: 5, taxsubjects: 2 };
            break;
        case constants.SUBSSCRIPTION_TYPE.INVALID_SUBSCRIPTION_TYPE.none.value:
            value = { properties: 0, taxsubjects: 0 };
            break;
        case constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.basic.value:
            value = { properties: 1, taxsubjects: 1 };
            break;
        case constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.pro.value:
            value = { properties: 5, taxsubjects: 2 };
            break;
        case constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.manager.value:
            value = { properties: user.l_property_limit, taxsubjects: user.l_tax_subject_limit };
            break;
        case constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.advisor.value:
            value = { properties: 50, taxsubjects: Infinity };
            break;
        case constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.ultimate.value:
            value = { properties: 100, taxsubjects: Infinity };
            break;
    }
    return value;
};
