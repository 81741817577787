import Analytics from 'analytics';
import amplitudePlugin from '@analytics/amplitude';

const analyticsConfig = Analytics({
    app: 'PIA',
    plugins: [
        amplitudePlugin({
            apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY || '03648dad96416839df443cadf7b9f3b2',
            options: {
                // See options at https://bit.ly/3dRdZnE
                apiEndpoint: 'api.eu.amplitude.com', //EU server
                trackingOptions: {
                    ip_address: false
                }
            }
        })
    ]
});

export default analyticsConfig;
