import React from 'react';
import { Whisper, Tooltip } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openHelpDrawer } from '../reducers/userSlice';

const E1bSection = ({
    title,
    children,
    infoText,
    infoTextTooltip,
    hasBottomMargin,
    isDisabled,
    hasBoldLabel,
    titleInfoText
}) => {
    const dispatch = useDispatch();
    const hasBottomMarginClass = hasBottomMargin ? 'has-bottom-margin' : '';
    const isDisabledClass = isDisabled ? 'is-disabled' : '';
    const hasBoldLabelClass = hasBoldLabel ? 'has-bold-label' : '';

    return (
        <div
            className={`e1b-section ${hasBottomMarginClass} ${isDisabledClass} ${hasBoldLabelClass}`}>
            {title && (
                <div className="e1b-section-header">
                    {title}
                    {titleInfoText && (
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={`info-icon`}
                            onClick={() =>
                                dispatch(openHelpDrawer({ title: title, message: infoText }))
                            }
                        />
                    )}
                </div>
            )}
            <div className="e1b-section-body">
                {children}
                {infoText &&
                    (infoTextTooltip ? (
                        <Whisper
                            placement="bottomStart"
                            trigger="click"
                            speaker={<Tooltip>{infoText}</Tooltip>}>
                            <FontAwesomeIcon icon={faCircleInfo} className={`info-icon`} />
                        </Whisper>
                    ) : (
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={`info-icon`}
                            onClick={() =>
                                dispatch(openHelpDrawer({ title: title, message: infoText }))
                            }
                        />
                    ))}
            </div>
        </div>
    );
};

export default E1bSection;
