import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleTable from './SimpleTable';

const ForecastTable = ({
    tables,
    historicalYears,
    labels,
    labelClicked,
    e1bClicked,
    colors,
    graphOpen
}) => {
    const { t } = useTranslation();

    const tableValues = Object.values(tables.length > 0 ? tables?.[0] : []);
    const numberFormat = Intl.NumberFormat('de-DE');
    let numberOfScenarios = colors.length;
    let isKitchenPurchased =
        tableValues[0] &&
        tableValues[0].filter((item) => item.yearly_rent_kitchen !== 0).length > 0;
    let isGaragePurchased =
        tableValues[0] && tableValues[0].filter((item) => item.yearly_rent_garage !== 0).length > 0;
    let isMiscPurchased =
        tableValues[0] && tableValues[0].filter((item) => item.yearly_rent_misc !== 0).length > 0;
    let isRenovation =
        tableValues[0] &&
        tableValues[0].filter((item) => item.yearly_depreciation_full_renovation !== 0).length > 0;
    let customData = [];
    let isAcceleratedDepr =
        tableValues[0] &&
        tableValues[0].filter((item) => item.additional_accelerated_depreciation !== 0).length > 0;
    let yearly_income = {
        ['label']: { label: t('forecast.l_flat_rent'), tooltip: t('forecast.i_flat_rent') }
    };
    let yearly_rent_kitchen = {
        ['label']: { label: t('forecast.l_kitchen_rent'), tooltip: t('forecast.i_kitchen_rent') }
    };
    let yearly_rent_garage = {
        ['label']: { label: t('forecast.l_garage_rent'), tooltip: t('forecast.i_garage_rent') }
    };
    let yearly_rent_misc = {
        ['label']: {
            label: 'Mieterträge Sonstiges',
            tooltip: t('forecast.i_misc_rent')
        }
    };
    let vacancy = {
        ['label']: { label: t('forecast.l_vacancy'), tooltip: t('forecast.i_vacancy') }
    };
    let costs_housing_management = {
        ['label']: {
            label: t('forecast.l_housing_management_costs'),
            tooltip: t('forecast.i_housing_management_costs')
        }
    };
    let xxx = {
        ['label']: { label: t('forecast.l_maintenance'), tooltip: t('forecast.i_maintenance') }
    };
    let financing = {
        ['label']: {
            label: t('forecast.l_financing_costs'),
            tooltip: t('forecast.i_financing_costs')
        }
    };
    let advertising_cost = {
        ['label']: {
            label: t('create_forecast.l_costs_advertising_misc'),
            tooltip: t('create_forecast.i_costs_advertising_misc')
        }
    };
    let l_costs_tax_advisor_and_other = {
        ['label']: {
            label: t('forecast.l_costs_tax_advisor_and.other'),
            tooltip: t('forecast.i_costs_tax_advisor_and.other')
        }
    };
    let yearly_depreciation_full = {
        ['label']: {
            label: t('forecast.l_depreciation_building'),
            tooltip: t('forecast.i_depreciation_building')
        }
    };
    let yearly_depreciation_full_kitchen = {
        ['label']: {
            label: t('forecast.l_depreciation_kitchen'),
            tooltip: t('forecast.i_depreciation_kitchen')
        }
    };
    let yearly_depreciation_full_garage = {
        ['label']: {
            label: t('forecast.l_depreciation_garage'),
            tooltip: t('forecast.i_depreciation_garage')
        }
    };
    let yearly_depreciation_full_misc = {
        ['label']: {
            label: t('forecast.l_depreciation_misc')
        }
    };
    let yearly_depreciation_full_renovation = {
        ['label']: {
            label: t('forecast.l_depreciation_renovation'),
            tooltip: t('forecast.i_depreciation_renovation')
        }
    };
    // let yearly_depreciation_full_broker = {
    //     ['label']: {
    //         label: t('create_forecast.l_depreciation_broker'),
    //         tooltip: t('create_forecast.i_depreciation_broker')
    //     }
    // };
    let interest_amount_full = {
        ['label']: {
            label: t('forecast.l_interest_expense'),
            tooltip: t('forecast.i_interest_expense')
        }
    };
    let tax_result = {
        ['label']: { label: t('forecast.l_tax_result'), tooltip: t('forecast.i_tax_result') }
    };
    let cumulative_tax_result = {
        ['label']: {
            label: t('forecast.l_accumulated_earnings'),
            tooltip: t('forecast.i_accumulated_earnings')
        }
    };
    let additional_accelerated_depreciation = {
        ['label']: {
            label: t('forecast.l_additional_accelerated_depreciation'),
            tooltip: t('forecast.i_additional_accelerated_depreciation')
        }
    };
    let depreciation_transfer = {
        ['label']: {
            label: t('forecast.l_depreciation_transition'),
            tooltip: t('forecast.i_depreciation_transition')
        }
    };
    let adjusted_cumulative_tax_result = {
        ['label']: {
            label: t('forecast.l_accumulated_earnings_adjusted'),
            tooltip: t('forecast.i_accumulated_earnings_adjusted')
        }
    };

    tables.map((table, index) => {
        table[Object.keys(table)[0]].map((item) => {
            yearly_income = {
                ...yearly_income,
                [item.year]: numberFormat.format(Math.round(item.yearly_rent))
            };
            yearly_rent_kitchen = {
                ...yearly_rent_kitchen,
                [item.year]: numberFormat.format(Math.round(item.yearly_rent_kitchen))
            };
            yearly_rent_garage = {
                ...yearly_rent_garage,
                [item.year]: numberFormat.format(Math.round(item.yearly_rent_garage))
            };
            yearly_rent_misc = {
                ...yearly_rent_misc,
                [item.year]: numberFormat.format(Math.round(item.yearly_rent_misc))
            };
            vacancy = {
                ...vacancy,
                [item.year]: numberFormat.format(Math.round(item.vacancy))
            };
            costs_housing_management = {
                ...costs_housing_management,
                [item.year]: numberFormat.format(Math.round(item.costs_housing_management))
            };
            tax_result = {
                ...tax_result,
                [item.year]: numberFormat.format(Math.round(item.tax_result))
            };
            xxx = { ...xxx, [item.year]: numberFormat.format(Math.round(item.xxx)) };
            financing = {
                ...financing,
                [item.year]: numberFormat.format(Math.round(item.financing))
            };
            advertising_cost = {
                ...advertising_cost,
                [item.year]: numberFormat.format(Math.round(item.advertising_cost))
            };
            l_costs_tax_advisor_and_other = {
                ...l_costs_tax_advisor_and_other,
                [item.year]: numberFormat.format(
                    Math.round(item.tax_consultancy_and_other_advisors)
                )
            };
            yearly_depreciation_full = {
                ...yearly_depreciation_full,
                [item.year]: numberFormat.format(Math.round(item.yearly_depreciation_full))
            };
            yearly_depreciation_full_kitchen = {
                ...yearly_depreciation_full_kitchen,
                [item.year]: numberFormat.format(Math.round(item.yearly_depreciation_full_kitchen))
            };
            yearly_depreciation_full_garage = {
                ...yearly_depreciation_full_garage,
                [item.year]: numberFormat.format(Math.round(item.yearly_depreciation_full_garage))
            };
            yearly_depreciation_full_misc = {
                ...yearly_depreciation_full_misc,
                [item.year]: numberFormat.format(Math.round(item.yearly_depreciation_full_misc))
            };
            yearly_depreciation_full_renovation = {
                ...yearly_depreciation_full_renovation,
                [item.year]: numberFormat.format(
                    Math.round(item.yearly_depreciation_full_renovation)
                )
            };
            // yearly_depreciation_full_broker = {
            //     ...yearly_depreciation_full_broker,
            //     [item.year]: numberFormat.format(Math.round(item.yearly_depreciation_full_broker))
            // };
            interest_amount_full = {
                ...interest_amount_full,
                [item.year]: numberFormat.format(Math.round(item.interest_amount_full))
            };
            if (isAcceleratedDepr)
                cumulative_tax_result = {
                    ...cumulative_tax_result,
                    [item.year]: numberFormat.format(Math.round(item.cumulative_tax_result))
                };
            if (isAcceleratedDepr)
                additional_accelerated_depreciation = {
                    ...additional_accelerated_depreciation,
                    [item.year]: numberFormat.format(
                        Math.round(item.additional_accelerated_depreciation)
                    )
                };
            if (isAcceleratedDepr)
                depreciation_transfer = {
                    ...depreciation_transfer,
                    [item.year]: numberFormat.format(Math.round(item.depreciation_transfer))
                };
            adjusted_cumulative_tax_result = {
                ...adjusted_cumulative_tax_result,
                [item.year]: numberFormat.format(Math.round(item.adjusted_cumulative_tax_result))
            };
        });
        let i = 0;
        customData[index + numberOfScenarios * i] = yearly_income;
        i++;
        if (isKitchenPurchased) {
            customData[index + numberOfScenarios * i] = yearly_rent_kitchen;
            i++;
        }
        if (isGaragePurchased) {
            customData[index + numberOfScenarios * i] = yearly_rent_garage;
            i++;
        }
        if (isMiscPurchased) {
            customData[index + numberOfScenarios * i] = yearly_rent_misc;
            i++;
        }
        customData[index + numberOfScenarios * i] = vacancy;
        i++;
        customData[index + numberOfScenarios * i] = costs_housing_management;
        i++;
        customData[index + numberOfScenarios * i] = xxx;
        i++;
        customData[index + numberOfScenarios * i] = financing;
        i++;
        customData[index + numberOfScenarios * i] = advertising_cost;
        i++;
        customData[index + numberOfScenarios * i] = yearly_depreciation_full;
        i++;
        if (isAcceleratedDepr) {
            customData[index + numberOfScenarios * i] = additional_accelerated_depreciation;
            i++;
        }
        if (isKitchenPurchased) {
            customData[index + numberOfScenarios * i] = yearly_depreciation_full_kitchen;
            i++;
        }
        if (isGaragePurchased) {
            customData[index + numberOfScenarios * i] = yearly_depreciation_full_garage;
            i++;
        }
        if (isMiscPurchased) {
            customData[index + numberOfScenarios * i] = yearly_depreciation_full_misc;
            i++;
        }
        if (isRenovation) {
            customData[index + numberOfScenarios * i] = yearly_depreciation_full_renovation;
            i++;
        }
        // customData[index + numberOfScenarios * i] = yearly_depreciation_full_broker;
        // i++;
        customData[index + numberOfScenarios * i] = l_costs_tax_advisor_and_other;
        i++;
        customData[index + numberOfScenarios * i] = interest_amount_full;
        i++;
        customData[index + numberOfScenarios * i] = tax_result;
        i++;
        if (isAcceleratedDepr) {
            customData[index + numberOfScenarios * i] = cumulative_tax_result;
            i++;
            customData[index + numberOfScenarios * i] = depreciation_transfer;
            i++;
        }
        customData[index + numberOfScenarios * i] = adjusted_cumulative_tax_result;
        i++;
        yearly_income = {};
        yearly_rent_kitchen = {};
        yearly_rent_garage = {};
        yearly_rent_misc = {};
        vacancy = {};
        costs_housing_management = {};
        xxx = {};
        financing = {};
        advertising_cost = {};
        yearly_depreciation_full = {};
        yearly_depreciation_full_kitchen = {};
        yearly_depreciation_full_garage = {};
        yearly_depreciation_full_misc = {};
        yearly_depreciation_full_renovation = {};
        // yearly_depreciation_full_broker = {};
        interest_amount_full = {};
        tax_result = {};
        cumulative_tax_result = {};
        additional_accelerated_depreciation = {};
        depreciation_transfer = {};
        adjusted_cumulative_tax_result = {};
        l_costs_tax_advisor_and_other = {};
    });

    return (
        <SimpleTable
            colors={colors}
            labels={labels}
            graphOpen={graphOpen}
            table={customData}
            historicalYears={historicalYears}
            e1bClicked={e1bClicked}
            labelClicked={labelClicked}
            isAcceleratedDepr={isAcceleratedDepr}
        />
    );
};

export default ForecastTable;
