import React, { Fragment } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import DoubleFieldedInput from '../../components/DoubleFieldedInput';
import { useTranslation } from 'react-i18next';
import { Divider, Stack, IconButton } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { SchemaModel, StringType, NumberType } from 'schema-typed';
import { formatNumber } from '../../components/NumericFormatter';

const AncillaryPurchaseCost = ({
    data,
    setData,
    children,
    addCostPages,
    pages,
    setPages,
    isSmallBusiness
}) => {
    const { t } = useTranslation();

    const validationSchema = getValidationSchemas(t).ancillaryPurchaseCost.objectTypeSchemaSpec;
    let models = pages.map(() => {
        return SchemaModel({
            l_cost_item_value: NumberType()
                .min(0, t('global.e_positive_number'))
                .isRequired(t('global.e_required')),
            l_cost_item_name: StringType().isRequired(t('global.e_required'))
        });
    });
    let localData = data;
    let l_real_estate_transfer_tax_base = data?.l_purchase_price;
    addCostPages.map((item) => {
        if (data['included_in_l_real_estate_transfer_tax_' + item.type] === false) {
            l_real_estate_transfer_tax_base -= data['l_purchase_price_' + item.type];
        }
    });
    const pop = (id) => {
        setPages(pages.filter((item) => item.id !== id));
    };

    return (
        <>
            <h5>{t('create_forecast.h_advertising_costs_in_first_year')}</h5>
            <DoubleFieldedInput
                label={t('create_forecast.l_trust_and_contract_establishment_fee')}
                name="l_trust_and_contract_establishment_fee"
                infoText={t('create_forecast.i_trust_and_contract_establishment_fee')}
                valueRule={validationSchema.l_trust_and_contract_establishment_fee}
                percentRule={validationSchema.l_trust_and_contract_establishment_fee_percent}
                fullValue={data?.l_purchase_price}
                autoFocus
                basePercent={
                    data?.l_trust_and_contract_establishment_fee_percent !== undefined
                        ? data?.l_trust_and_contract_establishment_fee_percent
                        : 1
                }
                onChange={(item1, item2) => {
                    localData = {
                        ...localData,
                        l_trust_and_contract_establishment_fee: item1,
                        l_trust_and_contract_establishment_fee_percent: item2
                    };
                    setData(localData);
                }}
            />
            <DoubleFieldedInput
                label={t('create_forecast.l_real_estate_transfer_tax')}
                infoText={t('create_forecast.i_real_estate_transfer_tax')}
                name="l_real_estate_transfer_tax"
                fullValue={l_real_estate_transfer_tax_base}
                basePercent={
                    data?.l_real_estate_transfer_tax_percent !== undefined
                        ? data.l_real_estate_transfer_tax_percent
                        : 3.5
                }
                onChange={(item1, item2) => {
                    localData = {
                        ...localData,
                        l_real_estate_transfer_tax: item1,
                        l_real_estate_transfer_tax_percent: item2
                    };
                    setData(localData);
                }}
                percentRule={validationSchema.l_real_estate_transfer_tax_percent}
                valueRule={validationSchema.l_real_estate_transfer_tax}
            />
            <DoubleFieldedInput
                label={t('create_forecast.l_land_register_registration_fee')}
                infoText={t('create_forecast.i_land_register_registration_fee')}
                name="l_land_register_registration_fee"
                fullValue={data?.l_purchase_price}
                basePercent={
                    data?.l_real_estate_transfer_tax_percent !== undefined
                        ? data.l_land_register_registration_fee_percent
                        : 1.1
                }
                onChange={(item1, item2) => {
                    localData = {
                        ...localData,
                        l_land_register_registration_fee: item1,
                        l_land_register_registration_fee_percent: item2
                    };
                    setData(localData);
                }}
                percentRule={validationSchema.l_land_register_registration_fee_percent}
                valueRule={validationSchema.l_land_register_registration_fee}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_notary_cash_expenses')}
                type="number"
                infoText={t('create_forecast.i_notary_cash_expenses')}
                symbolIcon="€"
                name="l_notary_cash_expenses"
                rule={validationSchema.l_notary_cash_expenses}
            />
            <DoubleFieldedInput
                label={t('create_forecast.l_real_estate_broker_fee')}
                infoText={t('create_forecast.i_real_estate_broker_fee')}
                name="l_real_estate_broker_fee"
                fullValue={data?.l_purchase_price}
                basePercent={
                    data?.l_real_estate_transfer_tax_percent !== undefined
                        ? data.l_real_estate_broker_fee_percent
                        : isSmallBusiness
                          ? 3.5
                          : 3
                }
                onChange={(item1, item2) => {
                    localData = {
                        ...localData,
                        l_real_estate_broker_fee: item1,
                        l_real_estate_broker_fee_percent: item2
                    };
                    setData(localData);
                }}
                helpText={
                    isSmallBusiness
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_real_estate_broker_fee
                                  ? data.l_real_estate_broker_fee -
                                        data.l_real_estate_broker_fee / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
                percentRule={validationSchema.l_real_estate_broker_fee_percent}
                valueRule={validationSchema.l_real_estate_broker_fee}
            />
            {pages &&
                pages?.map((page, index) => (
                    <React.Fragment key={index}>
                        <Divider />
                        <Stack className="additional-cost-item">
                            <h3 className="page-subtitle">
                                {t('create_forecast.n_ancillary_purchase_costs')}
                            </h3>
                            <IconButton
                                icon={<FontAwesomeIcon className="rs-icon" icon={faTrash} />}
                                onClick={() => pop(page.id)}
                            />
                        </Stack>
                        <LabeledInputWithSymbol
                            label={t('create_forecast.l_cost_item_name')}
                            type="text"
                            infoText={t('create_forecast.i_cost_item_name')}
                            name={'l_cost_item_name_' + page.id}
                            rule={
                                models[index]?.spec?.l_cost_item_name ||
                                models[index]?.$spec?.l_cost_item_name
                            }
                        />
                        <LabeledInputWithSymbol
                            label={t('create_forecast.l_cost_item_value')}
                            type="number"
                            infoText={t('create_forecast.i_cost_item_value')}
                            symbolIcon="€"
                            name={'l_anc_cost_item_value_' + page.id}
                            rule={
                                models[index]?.spec?.l_cost_item_value ||
                                models[index]?.$spec?.l_cost_item_value
                            }
                            helpText={
                                isSmallBusiness
                                    ? t('create_forecast.l_contains_vat20') +
                                      formatNumber(
                                          data?.['l_anc_cost_item_value_' + page.id]
                                              ? data['l_anc_cost_item_value_' + page.id] -
                                                    data['l_anc_cost_item_value_' + page.id] / 1.2
                                              : 0
                                      ) +
                                      '€'
                                    : undefined
                            }
                        />
                    </React.Fragment>
                ))}
            {children}
            <Divider />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_costs_advertising_misc')}
                type="number"
                infoText={t('create_forecast.i_costs_advertising_misc')}
                symbolIcon="€"
                name="l_costs_advertising_misc"
                rule={validationSchema.l_costs_advertising_misc}
                helpText={
                    isSmallBusiness
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_costs_advertising_misc
                                  ? data.l_costs_advertising_misc -
                                        data.l_costs_advertising_misc / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
            />
        </>
    );
};

export default AncillaryPurchaseCost;
