import { createBrowserRouter } from 'react-router-dom';
import React from 'react';
import Login from '../pages/Login';

export default () => {
    const router = createBrowserRouter([
        {
            path: '/login/:message?',
            element: <Login />
        }
    ]);
    router.navigate('/login/unauthorized');
    // temporary solution, navigate(0) refreshes the page
    // the first navigate changes the url but not the page
    router.navigate(0);
};
