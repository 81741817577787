import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Carousel } from 'rsuite';

const CarouselWithStepper = ({ children, length, setOutsideIndex }) => {
    const [index, setIndex] = useState(0);
    const stepper = (index) => {
        setIndex(index);
        setOutsideIndex(index);
    };
    return (
        <div className="carousel-with-stepper">
            <FontAwesomeIcon
                icon={faChevronLeft}
                onClick={() => +index - 1 >= 0 && stepper(+index - 1)}
            />
            <Carousel activeIndex={index} onSelect={stepper}>
                {children}
            </Carousel>
            <FontAwesomeIcon
                icon={faChevronRight}
                onClick={() => +index + 1 < length && stepper(+index + 1)}
            />
        </div>
    );
};

export default CarouselWithStepper;
