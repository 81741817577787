import React, { useEffect } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import ToggleInput from '../../components/ToggleInput';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';

const TaxesAndValueDevelopment = ({
    addCostPages,
    data,
    setData,
    isBusiness,
    constructionYear,
    renting_to_privat
}) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).taxesAndValueDevelopment.objectTypeSchemaSpec;
    useEffect(() => {
        if (!data?.l_depreciation)
            setData((prev) => {
                return {
                    ...prev,
                    l_depreciation:
                        data?.l_depreciation ||
                        (!isBusiness
                            ? constructionYear < 1915
                                ? 2
                                : 1.5
                            : renting_to_privat === undefined || renting_to_privat === true
                              ? 1.5
                              : constructionYear < 1915
                                ? 2
                                : 2.5)
                };
            });
    }, [data?.l_depreciation]);
    return (
        <>
            <h4>{t('create_forecast.h_value_developments')}</h4>
            <LabeledInputWithSymbol
                label={t('create_forecast.l_value_development_rate')}
                type="number"
                autoFocus
                infoText={t('create_forecast.i_value_development_rate')}
                symbolIcon="%"
                name="l_value_development_rate"
                rule={validationSchemas.l_value_development_rate}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_inflation_rate')}
                type="number"
                name="l_inflation_rate"
                infoText={t('create_forecast.i_inflation_rate')}
                rule={validationSchemas.l_inflation_rate}
                symbolIcon="%"
            />
            <h4>{t('create_forecast.h_depreciations')}</h4>
            <LabeledInputWithSymbol
                label={t('create_forecast.l_depreciation_building')}
                type="number"
                name="l_depreciation"
                symbolIcon="%"
                infoText={t('create_forecast.i_depreciation_building')}
                rule={validationSchemas.l_depreciation}
            />
            {addCostPages && addCostPages.filter((item) => item.type === 'garage').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_depreciation_garage')}
                    type="number"
                    symbolIcon="%"
                    name="l_depreciation_garage"
                    infoText={t('create_forecast.i_depreciation_garage')}
                    rule={validationSchemas.l_depreciation_kitchen}
                />
            )}
            {addCostPages && addCostPages.filter((item) => item.type === 'kitchen').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_depreciation_kitchen')}
                    type="number"
                    symbolIcon="%"
                    name="l_depreciation_kitchen"
                    infoText={t('create_forecast.i_depreciation')}
                    rule={validationSchemas.l_depreciation_garage}
                />
            )}
            {addCostPages && addCostPages.filter((item) => item.type === 'misc').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_depreciation_misc')}
                    type="number"
                    symbolIcon="%"
                    name="l_depreciation_misc"
                    infoText={t('create_forecast.i_depreciation_misc')}
                    rule={validationSchemas.l_depreciation_misc}
                />
            )}
            {addCostPages &&
                addCostPages.filter((item) => item.type === 'renovation').length > 0 && (
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_depreciation_renovation')}
                        type="number"
                        symbolIcon="%"
                        infoText={t('forecast.i_depreciation_renovation')}
                        name="l_depreciation_renovation"
                        rule={validationSchemas.l_depreciation_misc}
                    />
                )}
            <ToggleInput
                label={t('create_forecast.l_accelerated_depreciation')}
                infoText={t('create_forecast.i_accelerated_depreciation')}
                defaultChecked={data?.l_accelerated_depreciation}
                onToggle={(state) => setData({ ...data, l_accelerated_depreciation: state })}
            />
        </>
    );
};

export default TaxesAndValueDevelopment;
