import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

import TableAndGraphContainer from '../../components/TableAndGraphContainer';
import CashflowTable from '../../components/CashflowTable';
import InvestorTable from '../../components/InvestorTable';
import renteezyLogo from '../../img/renteezy-logo-without-text-temp.png';
import ForecastTable from '../../components/ForecastTable';
import constants from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getHistoricalData,
    setActiveE1bData,
    setForecastData,
    setHistoricalData,
    setRequestFinished,
    setRequestMessage
} from '../../reducers/userSlice';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../../components/dialogs/ConfirmDialog';
import quickCheckIcon from '../../img/quick-check-icon.png';

const ForecastTablesAndGraphs = ({ forecastTables, setForecastTables, setHistoricalDataYear }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const properties = useSelector((state) => state.user.properties);
    const scenarios = useSelector((state) => state.user.scenarios);
    const activeScenarios = useSelector((state) => state.user.activeScenarios);
    const openedPropertyForecast = useSelector((state) => state.user.openedPropertyForecast);
    const historicalData = useSelector((state) => state.user.historicalData);
    const forecastData = useSelector((state) => state.user.forecastData);

    const [forecastYearInterval, setForecastYearInterval] = useState(30);
    const [cashflowYearInterval, setCashflowYearInterval] = useState(30);
    const [investorYearInterval, setInvestorYearInterval] = useState(30);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        if (
            properties &&
            openedPropertyForecast &&
            openedPropertyForecast !== '' &&
            properties[openedPropertyForecast]
        )
            dispatch(getHistoricalData(properties[openedPropertyForecast]));
    }, [openedPropertyForecast]);

    let historicalDataYears = useMemo(() => {
        if (openedPropertyForecast && openedPropertyForecast !== '')
            return Object.keys(historicalData ? historicalData : {});
        else return [];
    }, [historicalData, openedPropertyForecast]);

    const addHistoricalData = useCallback((year) => {
        setHistoricalDataYear(year);
        navigate(constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA);
    }, []);

    const openE1bForm = useCallback(
        (year) => {
            const selectedProperty = properties[openedPropertyForecast];
            if (!selectedProperty) {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('global.i_message_error')
                    })
                );
                return;
            }
            const activeE1bData = {
                property: selectedProperty,
                year
            };
            dispatch(setActiveE1bData(activeE1bData));
            navigate(constants.ROUTES.E1B);
        },
        [properties, openedPropertyForecast]
    );

    const getLabels = useCallback(
        (interval) => {
            if (forecastTables.length === 0) return [];
            return Array(interval)
                .fill()
                .map((_, i) => parseInt(forecastTables?.[0]?.Prognoserechnung?.[0]?.year) + i);
        },
        [forecastTables]
    );
    const deleteForecast = useCallback(() => {
        let localData = { ...forecastData };
        localData.deleteflag = true;
        dispatch(setForecastData(localData));
        dispatch(setHistoricalData({ ...properties[openedPropertyForecast], historicalData: {} }));
        setForecastTables([]);
        setDialogOpen(false);
    }, [forecastData]);

    let forecastLabels = useMemo(
        () => getLabels(forecastYearInterval),
        [forecastYearInterval, forecastTables]
    );
    let cashflowLabels = useMemo(
        () => getLabels(cashflowYearInterval),
        [cashflowYearInterval, forecastTables]
    );
    let investorLabels = useMemo(
        () => getLabels(investorYearInterval),
        [investorYearInterval, forecastTables]
    );

    const getGraphData = useCallback(
        (labelArray, dataKey) => {
            return {
                labels: labelArray,
                datasets: [
                    {
                        label: 'Prognoserechnung',
                        data:
                            activeScenarios.includes('Prognoserechnung') &&
                            forecastTables?.[0]?.Prognoserechnung &&
                            !forecastTables?.[0]?.Prognoserechnung?.error
                                ? forecastTables?.[0]?.Prognoserechnung.map(
                                      (item) => item[dataKey]
                                  ).slice(0, labelArray.length)
                                : [],
                        borderColor: '#73dea8',
                        backgroundColor: '#73dea8',
                        firstRent:
                            activeScenarios.includes('Prognoserechnung') &&
                            forecastTables?.[0]?.Prognoserechnung &&
                            !forecastTables?.[0]?.Prognoserechnung?.error
                                ? forecastTables?.[0]?.Prognoserechnung.findIndex(
                                      (item) => item.yearly_rent !== 0
                                  )
                                : -1,
                        limit:
                            forecastData?.forecastsData?.l_purchase_date &&
                            new Date(forecastData?.forecastsData?.l_purchase_date).getFullYear() >=
                                2024
                                ? properties[openedPropertyForecast]?.propertiesData
                                      ?.l_type_of_rental === constants.RENTAL_TYPES.LARGE
                                    ? 30
                                    : 25
                                : properties[openedPropertyForecast]?.propertiesData
                                        ?.l_type_of_rental === constants.RENTAL_TYPES.LARGE
                                  ? 25
                                  : 20,
                        tension: 0.4,
                        pointRadius: 0,
                        borderDash: [15, 5]
                    },
                    {
                        label: 'Ist Daten',
                        data:
                            activeScenarios.includes('Ist Daten') &&
                            forecastTables?.[1]?.['Ist Daten']
                                ? forecastTables?.[1]?.['Ist Daten']
                                      .filter(
                                          (item) =>
                                              historicalDataYears.indexOf(item.year.toString()) !==
                                              -1
                                      )
                                      .map((item) => item[dataKey])
                                      .slice(0, labelArray.length)
                                : [],
                        borderColor: '#3b365c',
                        backgroundColor: '#3b365c',
                        tension: 0.4
                    },
                    {
                        label: 'Ist Daten',
                        data:
                            activeScenarios.includes('Ist Daten') &&
                            forecastTables?.[1]?.['Ist Daten']
                                ? forecastTables?.[1]?.['Ist Daten']
                                      .map((item) =>
                                          historicalDataYears.indexOf(item.year.toString()) !==
                                              -1 &&
                                          historicalDataYears.indexOf(item.year.toString()) !==
                                              historicalDataYears.length - 1
                                              ? undefined
                                              : item[dataKey]
                                      )
                                      .slice(0, labelArray.length)
                                : [],
                        borderColor: '#3b365c',
                        backgroundColor: '#3b365c',
                        borderDash: [15, 5],
                        tension: 0.4,
                        pointRadius: 0
                    },
                    ...scenarios
                        .filter(
                            (scenario) =>
                                scenario.scenario_name !== 'Prognoserechnung' &&
                                scenario.scenario_name !== 'Ist Daten'
                        )
                        .map((scenario, index) => {
                            return {
                                data: activeScenarios.includes(
                                    scenario.scenario_name + scenario._id
                                )
                                    ? forecastTables
                                          .filter(
                                              (table) =>
                                                  Object.keys(table)[0] === scenario.scenario_name
                                          )
                                          .map((item) =>
                                              item[Object.keys(item)[0]]
                                                  .map((value) =>
                                                      historicalDataYears.indexOf(
                                                          value.year.toString()
                                                      ) !== -1 &&
                                                      historicalDataYears.indexOf(
                                                          value.year.toString()
                                                      ) !==
                                                          historicalDataYears.length - 1
                                                          ? undefined
                                                          : value[dataKey]
                                                  )
                                                  .slice(0, labelArray.length)
                                          )[0]
                                    : [],
                                borderColor: constants.COLORS.scenarioColors[index],
                                backgroundColor: constants.COLORS.scenarioColors[index],
                                tension: 0.4,
                                pointRadius: 0,
                                borderDash: [15, 5]
                            };
                        })
                        .map((item) => item)
                ]
            };
        },
        [activeScenarios, forecastTables, historicalDataYears]
    );

    let forecastGraphData = useMemo(
        () => getGraphData(forecastLabels, 'adjusted_cumulative_tax_result'),
        [forecastTables, activeScenarios, forecastLabels]
    );
    let cashflowGraphData = useMemo(
        () => getGraphData(cashflowLabels, 'l_cash_flow_after_taxes_cumulated'),
        [forecastTables, activeScenarios, cashflowLabels]
    );
    let investorGraphData = useMemo(
        () => getGraphData(investorLabels, 'l_net_wealth'),
        [forecastTables, activeScenarios, investorLabels]
    );

    const tablesToDisplay = useMemo(
        () =>
            forecastTables.filter((table, i) =>
                activeScenarios.includes(
                    Object.keys(table)[0] +
                        (Object.keys(table)[0] === 'Prognoserechnung'
                            ? ''
                            : Object.keys(table)[0] === 'Ist Daten'
                              ? ''
                              : scenarios[
                                    forecastTables.length -
                                        scenarios.length +
                                        i -
                                        2 * (forecastTables.length - scenarios.length)
                                ]?._id)
                )
            ),
        [activeScenarios, forecastTables, scenarios]
    );

    const colorsToDisplay = useMemo(
        () =>
            forecastTables
                .map((table, i) => {
                    if (
                        activeScenarios.includes(
                            Object.keys(table)[0] +
                                (Object.keys(table)[0] === 'Prognoserechnung'
                                    ? ''
                                    : Object.keys(table)[0] === 'Ist Daten'
                                      ? ''
                                      : scenarios[
                                            forecastTables.length -
                                                scenarios.length +
                                                i -
                                                2 * (forecastTables.length - scenarios.length)
                                        ]?._id)
                        )
                    )
                        return Object.keys(table)[0] === 'Prognoserechnung'
                            ? '#73dea8'
                            : Object.keys(table)[0] === 'Ist Daten'
                              ? '#3b365c'
                              : constants.COLORS.scenarioColors[
                                    forecastTables.length -
                                        scenarios.length +
                                        i -
                                        2 * (forecastTables.length - scenarios.length)
                                ];
                })
                .filter((item) => item),
        [activeScenarios, forecastTables, scenarios]
    );

    return properties.length > 0 ? (
        forecastTables.length > 0 ? (
            <>
                <TableAndGraphContainer
                    header={t('forecast.h_forecast_tax')}
                    tableOpenName="forecastTableOpen"
                    chartOpenName="forecastChartOpen"
                    yearInterval={forecastYearInterval}
                    setYearInterval={setForecastYearInterval}
                    deleteForecast={() => setDialogOpen(true)}
                    data={forecastGraphData}>
                    <ForecastTable
                        labels={forecastLabels}
                        historicalYears={historicalDataYears}
                        labelClicked={addHistoricalData}
                        e1bClicked={openE1bForm}
                        tables={tablesToDisplay}
                        colors={colorsToDisplay}
                    />
                </TableAndGraphContainer>
                <TableAndGraphContainer
                    header="Cash-Flow Rechnung" //{t('forecast.h_cash_flow_calculation')}
                    tableOpenName="cashflowTableOpen"
                    chartOpenName="cashflowChartOpen"
                    yearInterval={cashflowYearInterval}
                    setYearInterval={setCashflowYearInterval}
                    data={cashflowGraphData}>
                    <CashflowTable
                        labels={cashflowLabels}
                        tables={tablesToDisplay}
                        colors={colorsToDisplay}
                    />
                </TableAndGraphContainer>
                <TableAndGraphContainer
                    header="Investoren Ansicht" //{t('forecast.h_cash_flow_calculation')}
                    tableOpenName="investorTableOpen"
                    chartOpenName="investorChartOpen"
                    yearInterval={investorYearInterval}
                    setYearInterval={setInvestorYearInterval}
                    data={investorGraphData}>
                    <InvestorTable
                        labels={investorLabels}
                        tables={tablesToDisplay}
                        colors={colorsToDisplay}
                    />
                </TableAndGraphContainer>
                <ConfirmDialog
                    openDialog={dialogOpen}
                    cancelBtnText={t('global.cancel')}
                    onCancelClicked={() => setDialogOpen(false)}
                    titleLabel={t('forecast.h_delete_forecast')}
                    okBtnText={t('forecast.l_delete_forecast')}
                    onOkClicked={deleteForecast}>
                    {t('forecast.l_delete_forecast_warning')}
                </ConfirmDialog>
            </>
        ) : (
            <div className="empty-forecast-page">
                {openedPropertyForecast && openedPropertyForecast !== '' && (
                    <>
                        <img src={quickCheckIcon} alt="quick-check-icon" />
                        <p>{t('forecast.l_placeholder_no_forecast_yet')}</p>
                    </>
                )}
            </div>
        )
    ) : (
        <div className="empty-forecast-page">
            <img src={renteezyLogo} />
            <p>
                Erstellen sie <b>ein Objekt</b> um anschließend eine Prognoserechnung anlegen zu
                können!
            </p>
        </div>
    );
};

export default ForecastTablesAndGraphs;
