import hexToRGB from './hexToRGBA';

export default {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
        duration: 0
    },
    plugins: {
        legend: {
            display: false
        },
        customCanvasBackgroundColor: { color: hexToRGB('#DC1F1F', 0.2) },
        tooltip: {
            boxPadding: 5,
            padding: 10,
            bodySpacing: 5,
            titleFont: {
                size: 13
            },
            bodyFont: {
                size: 13
            },
            //Use the default tooltip for mobile
            enabled: window.innerWidth <= 1024,

            external: function (context) {
                if (window.innerWidth < 1024) return;
                // Tooltip Element
                let tooltipEl = document.getElementById('chartjs-tooltip');

                // Create element on first render
                if (!tooltipEl) {
                    tooltipEl = document.createElement('div');
                    tooltipEl.id = 'chartjs-tooltip';
                    tooltipEl.innerHTML =
                        '<div class="chartjs-tooltip-custom"><table></table></div>';
                    document.body.appendChild(tooltipEl);
                }

                // Hide if no tooltip
                const tooltipModel = context.tooltip;
                if (tooltipModel.opacity === 0) {
                    tooltipEl.style.opacity = 0;
                    return;
                }

                // Set caret Position
                tooltipEl.classList.remove('above', 'below', 'no-transform');
                if (tooltipModel.yAlign) {
                    tooltipEl.classList.add(tooltipModel.yAlign);
                } else {
                    tooltipEl.classList.add('no-transform');
                }

                // Set Text
                if (tooltipModel.body) {
                    let innerHtml =
                        '<thead><tr><th style="color: #333">' +
                        tooltipModel.title[0].replace(',', ' ') +
                        '</th></tr></thead><tbody>';
                    let historicDone = false;

                    for (let i = 0; i < tooltipModel.dataPoints.length; i++) {
                        if (
                            !(
                                tooltipModel.dataPoints[i].dataset.label === 'Ist Daten' &&
                                historicDone
                            )
                        ) {
                            let body = Intl.NumberFormat('de-DE').format(
                                Math.round(tooltipModel.dataPoints[i].raw)
                            );
                            const colors = tooltipModel.labelColors[i];
                            let style = 'background:' + colors.borderColor;
                            style += '; display: inline-block';
                            style += '; width: 12px';
                            style += '; height: 12px';
                            style += '; border-radius: 6px';
                            style += '; margin-left: 10px';
                            const circle = '<span style="' + style + '"></span>';
                            const span = '<span style="color: #333">' + body + ' &euro;</span>';
                            innerHtml += '<tr><td>' + span + circle + '</td></tr>';
                            if (tooltipModel.dataPoints[i].dataset.label === 'Ist Daten') {
                                historicDone = true;
                            }
                        }
                    }

                    innerHtml += '</tbody>';

                    let tableRoot = tooltipEl.querySelector('table');
                    tableRoot.innerHTML = innerHtml;
                }

                const position = context.chart.canvas.getBoundingClientRect();

                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.left =
                    position.left +
                    window.pageXOffset +
                    tooltipModel.caretX -
                    10 -
                    tooltipEl.offsetWidth +
                    'px';
                tooltipEl.style.top =
                    Math.max(
                        position.top + window.pageYOffset,
                        position.top +
                            window.pageYOffset +
                            tooltipModel.caretY -
                            tooltipEl.offsetHeight / 2
                    ) + 'px';
                tooltipEl.style.padding =
                    tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                tooltipEl.style.pointerEvents = 'none';
            }
        }
    },
    interaction: {
        mode: 'index',
        intersect: false
    },
    scales: {
        x: {
            border: {
                color: '#f2f2f5'
            }
        },
        y: {
            beginAtZero: true,
            title: {
                display: window.innerWidth > 1024,
                text: 'EUR',
                align: 'start'
            },
            border: {
                color: '#f2f2f5'
            },
            grid: {
                color: '#f2f2f5',
                lineWidth: (context) => (context.tick.value === 0 ? 5 : 0.5)
            }
        }
    }
};
