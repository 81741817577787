import React from 'react';
import { Divider } from 'rsuite';
import { setCurrentTaxsubject } from '../../reducers/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import ToggleInput from '../../components/ToggleInput';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../../components/DropdownInput';
import constants from '../../utils/constants';
import getTaxsubjectName from '../../utils/getTaxsubjectName';

const BasicPropertyInformation = ({ ownerId, data, setData, isEdit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const validationSchema = getValidationSchemas(t).basicPropertyInformation.objectTypeSchemaSpec;

    const taxSubjectsList = useSelector((state) => state.user.taxsubjects);
    const currentTaxsubject = useSelector((state) => state.user.currentTaxsubject);

    if (!currentTaxsubject || (ownerId && currentTaxsubject?._id !== ownerId)) {
        const selectedTaxSubject = taxSubjectsList.find((taxSubject) => taxSubject._id === ownerId);
        dispatch(setCurrentTaxsubject(selectedTaxSubject));
    }

    const taxSubjectsDropdownOptions = [];
    taxSubjectsList.forEach((taxSubject) => {
        taxSubjectsDropdownOptions.push({
            label: getTaxsubjectName(taxSubject),
            value: taxSubject._id
        });
    });

    const propertyTypesDropdownOptions = Object.values(constants.PROPERTY_TYPES).map((type) => ({
        label: t(type),
        value: type
    }));

    return (
        <>
            <DropdownInput
                className={'property-type'}
                dropdownOption={taxSubjectsDropdownOptions}
                label={t('create_property.l_belongs_to_tax_subject')}
                name="_owner"
                alignRight
                dropdownOptionReady
                placeholder={t('global.select')}
                value={ownerId || currentTaxsubject?._id}
                autoFocus={!isEdit}
                disabled={isEdit}
                onChange={(value) => {
                    const selectedTaxSubject = taxSubjectsList.find(
                        (taxSubject) => taxSubject._id === value
                    );
                    dispatch(setCurrentTaxsubject(selectedTaxSubject));
                }}
                rule={validationSchema._owner_id}
            />
            <DropdownInput
                className={'property-type'}
                dropdownOptionReady
                dropdownOption={propertyTypesDropdownOptions}
                label={t('create_property.l_property_type')}
                name="l_property_type"
                placeholder={t('global.select')}
                infoText={t('create_property.i_property_type')}
                alignRight
                disabled={isEdit}
                value={data?.l_property_type}
                rule={validationSchema.l_property_type}
            />
            {currentTaxsubject?.taxsubjectsData?.type !== constants.TAX_SUBJECT_TYPES.private && (
                <ToggleInput
                    defaultChecked={data?.renting_to_privat || false}
                    onToggle={(state) =>
                        setData((prev) => {
                            return { ...prev, renting_to_privat: state };
                        })
                    }
                    label={t('create_property.l_company_property_used_for_housing')}
                    infoText={t('create_property.i_company_property_used_for_housing')}
                />
            )}
            <Divider />

            <LabeledInputWithSymbol
                label={t('create_property.l_street')}
                name="l_street"
                autoFocus={isEdit}
                rule={validationSchema.l_street}
            />

            <LabeledInputWithSymbol
                label={t('create_property.l_house_number')}
                name="l_house_number"
                rule={validationSchema.l_house_number}
            />

            <LabeledInputWithSymbol
                label={t('create_property.l_block')}
                name="l_stair"
                rule={validationSchema.l_stair}
            />

            <LabeledInputWithSymbol
                label={t('create_property.l_door_number')}
                name="l_door_number"
                rule={validationSchema.l_door_number}
            />

            <LabeledInputWithSymbol
                label={t('create_property.l_zip')}
                name="l_zip"
                infoText={t('create_property.i_zip')}
                rule={validationSchema.l_zip}
            />
            <LabeledInputWithSymbol
                label={t('create_property.l_city')}
                name="l_city"
                infoText={t('create_property.i_city')}
            />
        </>
    );
};

export default BasicPropertyInformation;
