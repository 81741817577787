import React, { useEffect, useState, Fragment } from 'react';
import {
    Input,
    InputGroup,
    Stack,
    Divider,
    Placeholder,
    Dropdown,
    IconButton,
    Pagination
} from 'rsuite';
import DropdownItem from 'rsuite/esm/Dropdown/DropdownItem';
import { useSelector, useDispatch } from 'react-redux';
import ContentPageBase from '../../components/ContentPageBase';
import {
    getAllUsers,
    setRequestFinished,
    setRequestMessage,
    getTaxsubjects
} from '../../reducers/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash,
    faSearch,
    faTimes,
    faBan,
    faEllipsisH,
    faUnlock
} from '@fortawesome/pro-solid-svg-icons';
import constants from '../../utils/constants';
import AlertDialog from '../../components/dialogs/AlertDialog';
import sendRequest from '../../utils/sendRequest';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import EditUserDialog from '../../components/EditUserDialog';
import getUTCDate from '../../utils/getUTCDate';

const Admin = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const users = useSelector((state) => state.user.users);

    const [currentUsers, setCurrentUsers] = useState([]);
    const [editUserPageState, setEditUserPageState] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [loaded, setLoaded] = useState(false);

    //Pagination
    const [activePage, setActivePage] = useState(1);
    const usersPerPage = Math.floor((window.innerHeight - 260) / 55);
    const usersOnCurrentPage = currentUsers.slice(
        (activePage - 1) * usersPerPage,
        activePage * usersPerPage
    );

    //Dialogs data
    const [dialogTitle, setDialogTitle] = useState('');
    const [okBtnTxt, setOkBtnTxt] = useState();
    const [okClicked, setOkClicked] = useState(null);
    const [dialogText, setDialogText] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        dispatch(getAllUsers());
        analytics.trackPage(analyticsConstants.PAGES.ADMIN);
    }, []);

    useEffect(() => {
        if (!users || users.length === 0) return;
        setCurrentUsers(users);
        filterUsers(searchValue);
        setLoaded(true);
    }, [users]);

    const resetDialogData = () => {
        setDialogTitle('');
        setDialogText('');
        setOkClicked(null);
    };

    const handleDelete = (id) => {
        analytics.trackClick('delete_user', analyticsConstants.CATEGORIES.USERS);
        setDialogText(t('admin_area.l_delete_confirmation_message'));
        setOkBtnTxt();
        setOkClicked(() => () => deleteUser(id));
        setOpenDialog(true);
    };

    const deleteUser = (deleteId) => {
        sendRequest('/deleteUser', 'post', { deleteId }).then((resp) => {
            if (resp.success) {
                resetSearch();
                dispatch(getAllUsers());
                dispatch(getTaxsubjects());
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: 'User deleted successfully'
                    })
                );
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: 'Error deleting user'
                    })
                );
            }
            analytics.trackDeleteUser(deleteId, resp.success);
        });
        setOpenDialog(false);
        resetDialogData();
    };

    const saveUserInfo = (data) => {
        sendRequest('/updateDB/users', 'post', { data: data }).then((resp) => {
            if (resp.success) {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('admin_area.l_user_updated_message')
                    })
                );
                dispatch(getAllUsers());
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('admin.e_user_updated_message')
                    })
                );
            }
        });
    };

    const handleCancelPlan = (subscriptionId, userId, type) => {
        setDialogText(t('admin_area.l_cancel_confirmation_message'));
        setDialogTitle(`Are you sure you want to cancel this user's plan?`);
        setOkBtnTxt(t('plan_overview.l_cancel_plan'));
        setOkClicked(() => () => cancelUserPlan(subscriptionId, userId, type));
        setOpenDialog(true);
    };

    const cancelUserPlan = (subscriptionId, userId, type) => {
        if (subscriptionId)
            sendRequest('/cancelSubscription', 'post', {
                subscriptionId: subscriptionId,
                effective_from: type === 1 ? 'immediately' : 'next_billing_period',
                userId: userId
            }).then((resp) => {
                if (resp.success) {
                    setLoaded(false);
                    setTimeout(() => {
                        dispatch(setRequestFinished(true));
                        dispatch(
                            setRequestMessage({
                                type: 'success',
                                message: `This user's plan is cancelled`
                            })
                        );
                        dispatch(getAllUsers());
                    }, [5000]);
                } else {
                    dispatch(setRequestFinished(true));
                    dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: `Cancelling this user's plan is failed`
                        })
                    );
                }
            });
        else {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: `Cancelling this user's plan is failed`
                })
            );
        }
        setOpenDialog(false);
        resetDialogData();
    };

    const handleRowClicked = (user) => {
        setEditUserPageState({ user: user, open: true });
    };

    const filterUsers = (value) => {
        setSearchValue(value);
        if (!value) {
            setCurrentUsers(users);
            return;
        }
        analytics.trackSearchUser(value);
        setActivePage(1);
        const filteredUsers = users.filter((user) => {
            return (
                (user.firstname && user.firstname.toLowerCase().includes(value.toLowerCase())) ||
                (user.lastname && user.lastname.toLowerCase().includes(value.toLowerCase())) ||
                (user.email && user.email.toLowerCase().includes(value.toLowerCase()))
            );
        });
        setCurrentUsers(filteredUsers);
    };

    const resetSearch = () => {
        analytics.trackClick('reset_search', analyticsConstants.CATEGORIES.USERS);
        setSearchValue('');
        setCurrentUsers(users);
    };

    return (
        <ContentPageBase header={t('admin_area.h_user_management')} className="admin-page">
            <InputGroup inside className="search-user">
                <Input
                    disabled={!loaded}
                    placeholder="Suche..."
                    onChange={filterUsers}
                    value={searchValue}
                />
                <InputGroup.Button onClick={resetSearch}>
                    {searchValue ? (
                        <FontAwesomeIcon className="rs-icon" icon={faTimes} />
                    ) : (
                        <FontAwesomeIcon
                            className="rs-icon"
                            icon={faSearch}
                            onClick={resetSearch}
                        />
                    )}
                </InputGroup.Button>
            </InputGroup>
            {loaded ? (
                <Stack className="admin-footer" justifyContent="space-between">
                    <Stack.Item flex={1}>
                        <p>Benutzer: {users.length}</p>
                    </Stack.Item>
                    <Stack.Item flex={3}>
                        {currentUsers.length > 0 && (
                            <Pagination
                                boundaryLinks={true}
                                ellipsis
                                prev
                                last
                                next
                                first
                                size="xs"
                                total={currentUsers.length}
                                limit={usersPerPage}
                                maxButtons={window.innerWidth < 1200 ? 1 : 5}
                                activePage={activePage}
                                onChangePage={setActivePage}
                            />
                        )}
                    </Stack.Item>
                </Stack>
            ) : (
                <Placeholder.Paragraph active rows={1} style={{ marginTop: 40 }} />
            )}
            <div className="entity-list-header">
                <Stack>
                    <Stack.Item flex={2}>{t('admin_area.l_name')}</Stack.Item>
                    <Stack.Item flex={3}>{t('admin_area.l_email')}</Stack.Item>
                    <Stack.Item flex={1.5}>{t('admin_area.l_type')}</Stack.Item>
                    <Stack.Item flex={1}>{t('admin_area.l_subscription')}</Stack.Item>
                    <Stack.Item flex={1}></Stack.Item>
                </Stack>
            </div>
            <Divider className="entity-list-divider" />
            {loaded ? (
                currentUsers.length > 0 ? (
                    usersOnCurrentPage.map((user, index) => (
                        <Fragment key={index}>
                            <Stack
                                className="entity-list-row"
                                onClick={() => handleRowClicked(user)}>
                                <Stack.Item flex={2}>
                                    {user.firstname ? user.firstname : ''}{' '}
                                    {user.lastname ? user.lastname : ''}
                                </Stack.Item>
                                <Stack.Item flex={3}>{user.email}</Stack.Item>
                                <Stack.Item flex={1.5}>{t(user.userType)}</Stack.Item>
                                <Stack.Item flex={1}>
                                    {(!user?.subscriptionData?.subscription_id &&
                                    user?.userType === t(constants.USER_TYPES.TA)
                                        ? '(Cancelled) '
                                        : '') + user?.subscriptionData?.plan_name}
                                </Stack.Item>
                                <Stack.Item flex={1}>
                                    {user.userType !== constants.ADMIN && (
                                        <Dropdown
                                            renderToggle={(props, ref) => (
                                                <IconButton
                                                    {...props}
                                                    ref={ref}
                                                    icon={<FontAwesomeIcon icon={faEllipsisH} />}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            )}
                                            className="actions-dropdown"
                                            placement="bottomEnd"
                                            trigger="hover"
                                            noCaret>
                                            <DropdownItem
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(user?._id);
                                                }}>
                                                <FontAwesomeIcon
                                                    className="rs-icon"
                                                    icon={faTrash}
                                                />
                                                Löschen
                                            </DropdownItem>
                                            {user?.subscriptionData?.plan_name &&
                                                user?.subscriptionData?.plan_name !== 'None' &&
                                                user?.subscriptionData?.plan_name !== 'Trial' && (
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCancelPlan(
                                                                user?.subscriptionData
                                                                    ?.subscription_id,
                                                                user?._id,
                                                                1
                                                            );
                                                        }}>
                                                        <FontAwesomeIcon
                                                            className="rs-icon"
                                                            icon={faBan}
                                                        />
                                                        Plan stornieren
                                                    </DropdownItem>
                                                )}
                                            {user?.onboardingPassed === false &&
                                                user?.subscriptionData?.plan_name &&
                                                user?.subscriptionData?.plan_name !== 'None' &&
                                                user?.subscriptionData?.plan_name !== 'Trial' && (
                                                    <DropdownItem
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const trialEndDate = getUTCDate();
                                                            trialEndDate.setDate(
                                                                trialEndDate.getDate() + 7
                                                            );
                                                            saveUserInfo({
                                                                ...user,
                                                                onboardingPassed: true,
                                                                trialEndDate
                                                            });
                                                        }}>
                                                        <FontAwesomeIcon
                                                            className="rs-icon"
                                                            icon={faUnlock}
                                                        />
                                                        Freischalten
                                                    </DropdownItem>
                                                )}
                                        </Dropdown>
                                    )}
                                </Stack.Item>
                            </Stack>
                            <Divider className="entity-list-divider" />
                        </Fragment>
                    ))
                ) : (
                    <p className="no-entries">Keine Einträge gefunden</p>
                )
            ) : (
                <Placeholder.Grid rows={3} columns={4} active style={{ marginTop: 40 }} />
            )}
            <AlertDialog
                titleLabel={dialogTitle}
                openDialog={openDialog}
                onOkClicked={okClicked}
                okBtnText={okBtnTxt}
                onCancelClicked={() => {
                    setOpenDialog(false);
                }}>
                <p>{dialogText}</p>
            </AlertDialog>
            <EditUserDialog
                state={editUserPageState}
                setState={setEditUserPageState}
                saveUserInfo={saveUserInfo}
                handleDelete={deleteUser}
                handleCancel={handleCancelPlan}
            />
        </ContentPageBase>
    );
};

export default Admin;
