import React, { useRef } from 'react';
import { Form, Input } from 'rsuite';
import InputBase from './InputBase';

const LabeledTextarea = ({
    label,
    rule,
    infoText,
    infoTextTooltip,
    onChange,
    autoFocus,
    value,
    hideInfoIcon
}) => {
    const containerRef = useRef();

    // hide validation error message
    const handleOnChange = (value) => {
        const container = containerRef.current;
        container.classList.add('hide-error-msg');
        onChange(value);
    };

    return (
        <InputBase
            label={label}
            showRequired={rule?.required}
            infoText={infoText}
            infoTextTooltip={infoTextTooltip}
            hideInfoIcon={hideInfoIcon}>
            <Form.Group data-testid="form-group">
                <Input
                    as="textarea"
                    rows={3}
                    ref={containerRef}
                    autoFocus={autoFocus}
                    onChange={(value) => handleOnChange(value)}
                    value={value}
                    rule={rule}
                />
            </Form.Group>
        </InputBase>
    );
};

export default LabeledTextarea;
