import React, { useEffect, useMemo } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { Divider } from 'rsuite';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import DropdownInput from '../../components/DropdownInput';
import DatePickerInput from '../../components/DatePickerInput';
import getUTCDate from '../../utils/getUTCDate';

const Revenues = ({ addCostPages, data, setData }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).revenues.objectTypeSchemaSpec;

    let firstRentingYear = useMemo(
        () =>
            getUTCDate(
                data?.l_rent_start_date ? data.l_rent_start_date : data?.l_purchase_date
            ).getUTCFullYear(),
        [data?.l_rent_start_date]
    );
    let dropdownOption = useMemo(
        () => Array.from({ length: 6 }, (_, i) => i + firstRentingYear + 1),
        [firstRentingYear]
    );
    let total_monthly_rent = useMemo(
        () =>
            (data?.l_expected_monthly_rent ? +data.l_expected_monthly_rent : 0) +
            (data?.l_expected_monthly_rent_kitchen ? +data.l_expected_monthly_rent_kitchen : 0) +
            (data?.l_expected_monthly_rent_garage ? +data.l_expected_monthly_rent_garage : 0) +
            (data?.l_expected_monthly_rent_misc ? +data.l_expected_monthly_rent_misc : 0) +
            (data?.l_operating_costs_20 ? +data.l_operating_costs_20 : 0) +
            (data?.l_operating_costs_10 ? +data.l_operating_costs_10 : 0),
        [
            data?.l_expected_monthly_rent,
            data?.l_expected_monthly_rent_kitchen,
            data?.l_expected_monthly_rent_garage,
            data?.l_expected_monthly_rent_misc,
            data?.l_operating_costs_20,
            data?.l_operating_costs_10
        ]
    );
    useEffect(() => {
        if (!data?.l_total_monthly_rent || data?.l_total_monthly_rent !== total_monthly_rent) {
            setData((prev) => {
                return { ...prev, l_total_monthly_rent: total_monthly_rent };
            });
        }
    }, [total_monthly_rent]);

    useEffect(() => {
        if (
            !data?.l_rent_increase_start ||
            (data?.l_rent_increase_start_date &&
                !dropdownOption.includes(data?.l_rent_increase_start_date))
        )
            setData((prev) => ({ ...prev, l_rent_increase_start: dropdownOption[0] }));
    }, [data?.l_rent_start_date]);

    const changeDate = (value) => {
        setData((prev) => {
            return { ...prev, l_rent_start_date: value, l_total_monthly_rent: total_monthly_rent };
        });
    };

    return (
        <>
            <LabeledInputWithSymbol
                label={t('create_forecast.l_expected_monthly_rent')}
                type="number"
                name="l_expected_monthly_rent"
                infoText={t('create_forecast.i_expected_monthly_rent')}
                symbolIcon="€"
                autoFocus
                rule={validationSchemas.l_expected_monthly_rent}
            />
            {addCostPages && addCostPages.filter((item) => item.type == 'kitchen').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_expected_monthly_rent_kitchen')}
                    type="number"
                    symbolIcon="€"
                    infoText={t('create_forecast.i_expected_monthly_rent_kitchen')}
                    name="l_expected_monthly_rent_kitchen"
                    rule={validationSchemas.l_expected_monthly_rent_kitchen}
                />
            )}
            {addCostPages && addCostPages.filter((item) => item.type == 'garage').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_expected_monthly_rent_garage')}
                    type="number"
                    symbolIcon="€"
                    infoText={t('create_forecast.i_expected_monthly_rent_garage')}
                    name="l_expected_monthly_rent_garage"
                    rule={validationSchemas.l_expected_monthly_rent_garage}
                />
            )}
            {addCostPages && addCostPages.filter((item) => item.type == 'misc').length > 0 && (
                <LabeledInputWithSymbol
                    label={t('create_forecast.l_expected_monthly_rent_misc')}
                    type="number"
                    symbolIcon="€"
                    infoText={t('create_forecast.i_expected_monthly_rent_misc')}
                    name="l_expected_monthly_rent_misc"
                    rule={validationSchemas.l_expected_monthly_rent_misc}
                />
            )}
            <LabeledInputWithSymbol
                label={t('create_forecast.l_operating_costs_20')}
                type="number"
                symbolIcon="€"
                infoText={t('create_forecast.i_operating_costs_20')}
                name="l_operating_costs_20"
                rule={validationSchemas.l_operating_costs_20}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_operating_costs_10')}
                type="number"
                symbolIcon="€"
                infoText={t('create_forecast.i_operating_costs_10')}
                name="l_operating_costs_10"
                rule={validationSchemas.l_operating_costs_10}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_total_monthly_rent')}
                type="number"
                symbolIcon="€"
                name="l_total_monthly_rent"
                disabled
            />
            <Divider />
            <DatePickerInput
                label={t('create_forecast.l_rent_start_date')}
                type="date"
                infoText={t('create_forecast.i_rent_start_date')}
                value={data?.l_rent_start_date}
                onChange={changeDate}
                name="l_rent_start_date"
                rule={validationSchemas.l_rent_start_date}
            />
            <DropdownInput
                label={t('create_forecast.l_rent_increase_start_date')}
                infoText={t('create_forecast.i_rent_increase_start_date')}
                alignRight
                name="l_rent_increase_start"
                rule={validationSchemas.l_rent_increase_start_date}
                dropdownOption={dropdownOption}
                placeholder={t('global.l_please_select')}
                value={data?.l_rent_increase_start}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_rent_increase')}
                type="number"
                infoText={t('create_forecast.i_rent_increase')}
                symbolIcon="%"
                name="l_rent_increase"
                rule={validationSchemas.l_rent_increase}
            />
        </>
    );
};

export default Revenues;
