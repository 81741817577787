import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginFooterWithLink = () => {
    const { t } = useTranslation();

    return (
        <div className="centered-text">
            <Link to="/login">{t('global.back')} zum Login</Link>
        </div>
    );
};

export default LoginFooterWithLink;
