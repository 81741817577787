import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import constants from '../../utils/constants';

const AreaInformation = ({ data }) => {
    const { t } = useTranslation();

    const validationSchema = getValidationSchemas(t).areaInformation.objectTypeSchemaSpec;

    return (
        <>
            <LabeledInputWithSymbol
                label={
                    data?.l_property_type !== constants.PROPERTY_TYPES.GARAGE
                        ? t('create_property.l_area_of_living_space')
                        : t('create_property.l_usable_area')
                }
                type="number"
                autoFocus
                name="l_area_of_living_space"
                infoText={t('create_property.i_area_of_living_space')}
                symbolIcon={'m\u00B2'}
                rule={validationSchema.l_area_of_living_space}
            />
            {data?.l_property_type !== constants.PROPERTY_TYPES.APARTMENT_BUILDING &&
                data?.l_property_type !== constants.PROPERTY_TYPES.GARAGE && (
                    <>
                        <LabeledInputWithSymbol
                            label={t('create_property.l_loggia')}
                            type="number"
                            formatNumeric={false}
                            name="l_loggia"
                            symbolIcon={'m\u00B2'}
                            isIndentedInput
                            rule={validationSchema.l_loggia}
                        />

                        <LabeledInputWithSymbol
                            label={t('create_property.l_balcony')}
                            type="number"
                            formatNumeric={false}
                            name="l_balcony"
                            symbolIcon={'m\u00B2'}
                            rule={validationSchema.l_balcony}
                        />
                        <LabeledInputWithSymbol
                            label={t('create_property.l_terrace')}
                            type="number"
                            formatNumeric={false}
                            name="l_terrace"
                            symbolIcon={'m\u00B2'}
                            rule={validationSchema.l_terrace}
                        />
                    </>
                )}
            {data?.l_property_type !== constants.PROPERTY_TYPES.GARAGE && (
                <>
                    <LabeledInputWithSymbol
                        label={t('create_property.l_garden')}
                        type="number"
                        formatNumeric={false}
                        name="l_garden"
                        symbolIcon={'m\u00B2'}
                        rule={validationSchema.l_garden}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_property.l_basement')}
                        type="number"
                        formatNumeric={false}
                        name="l_basement"
                        symbolIcon={'m\u00B2'}
                        rule={validationSchema.l_basement}
                    />
                </>
            )}
        </>
    );
};

export default AreaInformation;
