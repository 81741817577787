import { faLock, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Stack } from 'rsuite';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import { analytics } from '../utils/analytics';

const LockableButton = ({
    onClick,
    locked,
    buttonLabel,
    dialogTitle,
    dialogBody,
    isOpen,
    setIsOpen,
    jumpToPlansPage
}) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    return (
        <>
            <Button
                appearance="primary"
                onClick={() => {
                    analytics.trackClick(buttonLabel);
                    if (locked) setDialogOpen(true);
                    else onClick();
                }}>
                <Stack justifyContent="space-between" spacing={10}>
                    <FontAwesomeIcon icon={locked ? faLock : faPlus} />
                    <Stack.Item> {buttonLabel}</Stack.Item>
                </Stack>
            </Button>
            <ConfirmDialog
                onCancelClicked={() => {
                    setDialogOpen(false);
                    isOpen && setIsOpen(false);
                }}
                openDialog={dialogOpen || isOpen}
                titleLabel={dialogTitle}
                cancelBtnText={t('global.cancel')}
                okBtnText={t('account.l_change_plan')}
                onOkClicked={jumpToPlansPage}>
                {dialogBody}
            </ConfirmDialog>
        </>
    );
};

export default LockableButton;
