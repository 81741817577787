import React, { useRef, useEffect } from 'react';
import { Form, SelectPicker } from 'rsuite';
import InputBase from './InputBase';

const DropdownInput = ({
    label,
    name,
    value,
    infoText,
    infoTextTooltip,
    dropdownOption,
    alignRight,
    className,
    disabled,
    onChange,
    dropdownOptionReady,
    rule,
    isIndentedInput,
    placeholder,
    readOnly,
    autoFocus,
    hasCheckbox,
    checkboxChecked,
    hideInfoIcon,
    errorPlacement
}) => {
    const containerRef = useRef();
    const data = dropdownOptionReady
        ? dropdownOption
        : dropdownOption.map((item) => ({ label: item, value: item }));

    //hide validation error message
    const change = (value) => {
        const container = containerRef.current;
        container.classList.add('hide-error-msg');
        onChange && onChange(value);
    };

    useEffect(() => {
        autoFocus &&
            containerRef.current &&
            containerRef.current.children[0].children[0].children[0].focus();
    }, [autoFocus]);

    const customSelectPicker = ({ onChange, errorPlacement }) => {
        const wrapperRef = useRef();

        useEffect(() => {
            if (wrapperRef.current) {
                const triggerButton = wrapperRef.current.querySelector('.rs-picker-toggle');
                if (triggerButton) {
                    triggerButton.id = name;
                }
            }
        }, []);

        return (
            <div ref={wrapperRef}>
                <SelectPicker
                    data-testid={name}
                    name={name}
                    id={name}
                    cleanable={false}
                    searchable={false}
                    data={data}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    placement={errorPlacement}
                    labelKey="label"
                />
            </div>
        );
    };

    return (
        <InputBase
            label={label}
            showRequired={rule?.required}
            infoText={infoText}
            infoTextTooltip={infoTextTooltip}
            className={`${className ? className : ''} dropdown-input`}
            isIndentedInput={isIndentedInput}
            hasCheckbox={hasCheckbox}
            checkboxChecked={checkboxChecked}
            readOnly={readOnly}
            hideInfoIcon={hideInfoIcon}>
            <Form.Group className={alignRight ? 'align-right' : ''}>
                <Form.Control
                    ref={containerRef}
                    name={name}
                    accepter={customSelectPicker}
                    rule={rule}
                    onChange={change}
                    errorPlacement={errorPlacement || 'bottomEnd'}
                    autoComplete="off"
                    autoCorrect="off"
                    spellCheck="off"
                />
            </Form.Group>
        </InputBase>
    );
};

export default DropdownInput;
