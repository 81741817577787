import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Stack, IconButton, Divider, Placeholder, Dropdown } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import sendRequest from '../utils/sendRequest';
import LinkIcon from '@rsuite/icons/legacy/Link';
import MinusIcon from '@rsuite/icons/Minus';
import { useTranslation, Trans } from 'react-i18next';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';
import { setRequestFinished, setRequestMessage } from '../reducers/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faBan } from '@fortawesome/pro-solid-svg-icons';
import getTaxsubjectName from '../utils/getTaxsubjectName';

const CreateInvitationModal = ({ opened, setOpened, taxsubjectID, setSelectedTaxSubject }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const userId = useSelector((state) => state.user.userId);

    const [data, setData] = useState({});
    const [taxSubjectName, setTaxSubjectName] = useState('');
    const [isUserOwner, setIsUserOwner] = useState(null);
    const [headerText, setHeaderText] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!opened) return;
        sendRequest(`/invites/getPageData/${taxsubjectID}`, 'get').then((resp) => {
            if (!resp) return;
            setData(resp);
            setTaxSubjectName(getTaxsubjectName(resp.taxsubject));
            setIsUserOwner(resp.taxsubject._owner === userId);
            setLoaded(true);
        });
    }, [opened]);

    useEffect(() => {
        if (!loaded) return;
        setHeaderText(
            isUserOwner
                ? t('access_sharing.l_edit_access_description')
                : t('access_sharing.l_no_edit_access_description')
        );
    }, [loaded]);

    const createInvitation = async () => {
        analytics.trackClick('create_invite', analyticsConstants.CATEGORIES.TAX_SUBJECTS);
        if (!taxsubjectID) return;
        sendRequest('/invites/create', 'post', { taxsubjectID }).then((resp) => {
            if (resp.invite) {
                setData({ ...data, invite: resp.invite, inviteURL: resp.inviteURL });
                analytics.trackCreateInvite(resp.invite._id, true);
            } else {
                analytics.trackCreateInvite('', false);
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: t('access_sharing.m_copied_to_clipboard')
                    })
                );
            }
        });
    };

    const disableLink = () => {
        analytics.trackClick('disable_invite', analyticsConstants.CATEGORIES.TAX_SUBJECTS);
        const linkID = data.invite._id;
        if (!linkID) return;
        sendRequest('/invites/disable', 'post', { linkID }).then((resp) => {
            if (resp.success) {
                setData({ ...data, invite: null, inviteURL: null });
                analytics.trackDisableInvite(linkID, true);
            } else {
                analytics.trackDisableInvite(linkID, false);
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('access_sharing.e_server_error')
                    })
                );
            }
        });
    };

    const removeSharingRights = (userID) => {
        sendRequest('/invites/removeSharing', 'post', { userID, taxsubjectID }).then((resp) => {
            if (resp.message.success) {
                setData({ ...data, authorizedUsers: resp.authorizedUsers });
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'success',
                        message: 'sucessfully removed'
                    })
                );
            } else {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('access_sharing.e_server_error')
                    })
                );
            }
        });
    };

    return (
        <Modal
            className="invitation-modal"
            size="md"
            open={opened}
            onClose={() => {
                setOpened(false);
            }}
            onEnter={() => {
                analytics.trackModal(
                    'invites_modal',
                    analyticsConstants.CATEGORIES.TAX_SUBJECTS,
                    true
                );
            }}
            onExit={() => {
                setData({});
                setSelectedTaxSubject && setSelectedTaxSubject(null);
                analytics.trackModal(
                    'invites_modal',
                    analyticsConstants.CATEGORIES.TAX_SUBJECTS,
                    false
                );
            }}>
            <Modal.Header>
                <Modal.Title>{t('access_sharing.h_edit_access')}</Modal.Title>
                {taxSubjectName && headerText ? (
                    <p>
                        <Trans
                            i18nKey={headerText}
                            components={{ strong: <strong /> }}
                            values={{ 'tax-subject': taxSubjectName }}></Trans>
                    </p>
                ) : (
                    <Placeholder.Paragraph active rows={2} />
                )}
            </Modal.Header>

            <Modal.Body>
                <Stack className="invites-list-header">
                    <Stack.Item flex={2}>{t('access_sharing.l_name')}</Stack.Item>
                    <Stack.Item flex={3}>{t('access_sharing.l_email')}</Stack.Item>
                    <Stack.Item flex={1}>{t('access_sharing.l_status')}</Stack.Item>
                    <Stack.Item flex={1}></Stack.Item>
                </Stack>
                <Divider />
                {data.authorizedUsers ? (
                    data.authorizedUsers.map((user, key) => {
                        return (
                            <div key={key}>
                                <Stack>
                                    <Stack.Item flex={2}>
                                        {user.firstname + ' ' + user.lastname}
                                    </Stack.Item>
                                    <Stack.Item flex={3}>{user.email}</Stack.Item>
                                    <Stack.Item flex={1}>
                                        {user._id === data.taxsubject._owner
                                            ? t('access_sharing.l_owner')
                                            : t('access_sharing.l_editor')}
                                    </Stack.Item>
                                    <Stack.Item flex={1}>
                                        {isUserOwner && user._id !== data.taxsubject._owner && (
                                            <Dropdown
                                                renderToggle={(props, ref) => (
                                                    <IconButton
                                                        {...props}
                                                        ref={ref}
                                                        icon={
                                                            <FontAwesomeIcon icon={faEllipsisH} />
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                )}
                                                className="actions-dropdown"
                                                placement="bottomEnd"
                                                trigger="hover"
                                                noCaret>
                                                <Dropdown.Item
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeSharingRights(user._id);
                                                    }}>
                                                    <FontAwesomeIcon
                                                        className="rs-icon"
                                                        icon={faBan}
                                                    />
                                                    {t('access_sharing.l_revoke_access')}
                                                </Dropdown.Item>
                                            </Dropdown>
                                        )}
                                    </Stack.Item>
                                </Stack>
                                <Divider />
                            </div>
                        );
                    })
                ) : data.taxsubject ? (
                    // Fallback. According to the design, there should always be at least one authorized user -> the creator of the tax subject
                    <p className="no-accepters-message">You have no authorized users</p>
                ) : (
                    <Placeholder.Paragraph active rows={2} />
                )}

                {isUserOwner && (
                    <div className="actions-div">
                        <p>{data.inviteURL && data.inviteURL}</p>
                        <Stack>
                            {data.taxsubject ? (
                                data.inviteURL ? (
                                    <Stack spacing={20}>
                                        <Stack.Item>
                                            <CopyToClipboard
                                                text={data.inviteURL}
                                                onCopy={() => {
                                                    analytics.trackClick(
                                                        'copy_invite_to_clipboard',
                                                        analyticsConstants.CATEGORIES.TAX_SUBJECTS
                                                    );
                                                    dispatch(setRequestFinished(true));
                                                    dispatch(
                                                        setRequestMessage({
                                                            type: 'success',
                                                            message: t(
                                                                'access_sharing.m_copied_to_clipboard'
                                                            )
                                                        })
                                                    );
                                                }}>
                                                <IconButton size="sm" icon={<LinkIcon />}>
                                                    {t('access_sharing.l_copy_link')}
                                                </IconButton>
                                            </CopyToClipboard>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <IconButton
                                                size="sm"
                                                icon={<MinusIcon />}
                                                onClick={disableLink}>
                                                {t('access_sharing.l_invalidate_link')}
                                            </IconButton>
                                        </Stack.Item>
                                    </Stack>
                                ) : (
                                    <IconButton
                                        size="sm"
                                        icon={<PlusIcon />}
                                        onClick={createInvitation}>
                                        {t('access_sharing.l_create_new_link')}
                                    </IconButton>
                                )
                            ) : (
                                <Placeholder.Paragraph rows={1} active />
                            )}
                        </Stack>
                    </div>
                )}
            </Modal.Body>

            <Modal.Footer>
                <p>
                    {isUserOwner && (
                        <Trans
                            i18nKey="access_sharing.l_link_description"
                            components={{ br: <br /> }}
                        />
                    )}
                </p>

                <Button onClick={() => setOpened(false)} appearance="primary">
                    {t('access_sharing.l_close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateInvitationModal;
