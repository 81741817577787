import React, { useEffect } from 'react';
import { Form, Button, useToaster, Message } from 'rsuite';
import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import sendRequest from '../utils/sendRequest';
import { useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import constants from '../utils/constants';
import LoginContainer from '../components/LoginContainer';
import LoginFooterWithLink from '../components/LoginFooterWithLink';
import showValidationErrors from '../utils/showValidationErrors';
import hideValidationError from '../utils/hideValidationError';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';

const ResetPassw = () => {
    const [formValue, setFormValue] = useState({
        password: '',
        repeatPassword: ''
    });
    const location = useLocation();
    const navigate = useNavigate();
    const toaster = useToaster();

    const userId = useSelector((state) => state.user.userId);
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).resetPassw.objectTypeSchemaSpec;

    const token = new URLSearchParams(location.search).get('token');

    const formRef = useRef();

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.RESET_PASSWORD);
    }, []);

    const errorMessage = (message) => {
        return (
            <Message showIcon type="error" closable>
                {message}
            </Message>
        );
    };
    const successMessage = (message) => {
        return (
            <Message showIcon type="success" closable>
                {message}
            </Message>
        );
    };

    const handleSubmit = async () => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        if (hasError) {
            showValidationErrors(formRef.current.root);
            toaster.push(errorMessage(t('global.e_form_error')), {
                duration: constants.MESSAGE_DURATION
            });
        } else {
            sendRequest('/resetPass', 'post', {
                token: token,
                password: formValue.password
            }).then((resp) => {
                if (resp.success) {
                    toaster.push(successMessage(t('authentication.i_password_reset_done')), {
                        duration: constants.MESSAGE_DURATION
                    });
                    navigate('/login');
                }
            });
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    if (userId) return <Navigate replace to="/" />;
    else if (!token) return <Navigate replace to="/login" />;
    else
        return (
            <LoginContainer footer={<LoginFooterWithLink />}>
                <h2>{t('authentication.h_set_new_password')}</h2>
                <Form
                    ref={formRef}
                    onChange={setFormValue}
                    formValue={formValue}
                    checkTrigger="none"
                    onKeyDown={handleKeyDown}>
                    <Form.Group>
                        <Form.Control
                            name="password"
                            type="password"
                            placeholder={t('authentication.l_new_password')}
                            onChange={(value, event) => hideValidationError(event)}
                            rule={validationSchemas.password}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            name="repeatPassword"
                            type="password"
                            placeholder={t('authentication.l_repeat_new_password')}
                            onChange={(value, event) => hideValidationError(event)}
                            rule={validationSchemas.repeatPassword}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Button appearance="primary" onClick={handleSubmit}>
                            {t('global.submit')}
                        </Button>
                    </Form.Group>
                </Form>
            </LoginContainer>
        );
};

export default ResetPassw;
