import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { Divider } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { formatNumber } from '../../components/NumericFormatter';

const Expenses_3 = ({ forecastData, data, historicalData, year }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).expenses_3.objectTypeSchemaSpec;

    return (
        <>
            <h4>{t('create_historic_forecast_data.h_expenses_3')}</h4>
            {forecastData.l_financing_via_loan && (
                <>
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_balance_beginning_of_year')}
                        type="number"
                        name="l_balance_beginning_of_year"
                        symbolIcon="€"
                        autoFocus
                        rule={validationSchemas.l_balance_beginning_of_year}
                        helpText={t(
                            'create_historic_forecast_data.l_planned_based_on_forecast'
                        ).replace(
                            '{{value}}',
                            formatNumber(
                                historicalData?.[year - 1]
                                    ? historicalData[year - 1]?.l_balance_end_of_year
                                    : forecastData?.remaining_loan_full
                            )
                        )}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_balance_end_of_year')}
                        type="number"
                        name="l_balance_end_of_year"
                        symbolIcon="€"
                        rule={validationSchemas.l_balance_end_of_year}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_interest_expense')}
                        type="number"
                        name="l_interest_expense"
                        symbolIcon="€"
                        rule={validationSchemas.l_interest_expense}
                        helpText={t(
                            'create_historic_forecast_data.l_planned_based_on_forecast'
                        ).replace('{{value}}', formatNumber(-forecastData?.interest_amount_full))}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_bank_account_fees')}
                        type="number"
                        name="l_bank_account_fees"
                        infoText={t('create_forecast.i_bank_account_fees')}
                        symbolIcon="€"
                        rule={validationSchemas.l_bank_account_fees}
                        helpText={t(
                            'create_historic_forecast_data.l_planned_based_on_forecast'
                        ).replace('{{value}}', formatNumber(-forecastData?.l_bank_account_fees))}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_repayment')}
                        type="number"
                        name="l_repayment"
                        symbolIcon="€"
                        disabled
                    />
                    <Divider />
                    <h4>{t('create_historic_forecast_data.h_change_forecast_parameters')}</h4>

                    {data.l_loan_fixed_rate && (
                        <LabeledInputWithSymbol
                            label={t('create_historic_forecast_data.l_loan_fixed_rate')}
                            type="number"
                            name="l_loan_fixed_rate"
                            symbolIcon="%"
                            rule={validationSchemas.l_loan_fixed_rate}
                        />
                    )}
                    {data.l_loan_fixed_rate && (
                        <LabeledInputWithSymbol
                            label={t('create_forecast.l_loan_duration_fixed_rate')}
                            type="number"
                            name="l_loan_duration_fixed_rate"
                            infoText={t('create_forecast.i_loan_duration_fixed_rate')}
                            symbolIcon="Monate"
                            rule={validationSchemas.l_loan_duration_fixed_rate}
                        />
                    )}
                    <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_loan_adjustable_rate')}
                        type="number"
                        name="l_loan_adjustable_rate"
                        symbolIcon="%"
                        rule={validationSchemas.l_loan_adjustable_rate}
                    />
                    {/* <LabeledInputWithSymbol
                        label={t('create_historic_forecast_data.l_calculated_interest_expense')}
                        type="number"
                        name="l_calculated_interest_expense"
                        disabled
                        defaultValue={forecastData.interest_amount_full}
                        infoText="tooltip"
                        symbolIcon="€"
                    /> */}
                </>
            )}
        </>
    );
};

export default Expenses_3;
