import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Icon } from 'leaflet';
import pinImage from '../img/map-pin.svg';

const Map = ({ position, interactive }) => {
    const pinIcon = new Icon({
        iconUrl: pinImage,
        iconSize: [26, 25],
        iconAnchor: [13, 30]
    });
    return (
        <MapContainer
            center={position}
            zoom={17}
            dragging={interactive}
            zoomControl={interactive}
            scrollWheelZoom={interactive}
            doubleClickZoom={interactive}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position} icon={pinIcon}></Marker>
        </MapContainer>
    );
};

export default Map;
