import React from 'react';
import { Whisper, Tooltip, Checkbox } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { openHelpDrawer } from '../reducers/userSlice';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const InputBase = ({
    label,
    children,
    showRequired,
    infoText,
    infoTextTooltip,
    className,
    isIndentedInput,
    labelOnTop,
    hideInfoIcon,
    hasCheckbox,
    checkboxChecked,
    readOnly
}) => {
    const dispatch = useDispatch();

    const indentClass = isIndentedInput ? 'indented-input' : '';

    return (
        <div
            className={`input-base ${indentClass} ${label && label.length > 45 && className !== 'toggle-input' ? 'long-label' : ''} ${className ? className : ''}`}>
            <div className="label-container">
                {hasCheckbox && (
                    <Checkbox defaultChecked={checkboxChecked} readOnly={readOnly}></Checkbox>
                )}
                {isIndentedInput && <FontAwesomeIcon className="indent-icon" icon={faArrowRight} />}
                <label className="input-base-label">
                    {label}
                    {showRequired && label && <span className="required-symbol">*</span>}
                </label>
                {labelOnTop && (
                    <Whisper
                        placement="bottomStart"
                        trigger="click"
                        speaker={<Tooltip>{infoText}</Tooltip>}>
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={infoText ? '' : 'invisible'}
                        />
                    </Whisper>
                )}
            </div>
            <div className="input-base-item">
                {children}
                {!labelOnTop &&
                    !hideInfoIcon &&
                    (infoTextTooltip && window.innerWidth > 1024 ? (
                        <Whisper
                            placement="bottomStart"
                            trigger="click"
                            speaker={<Tooltip>{infoText}</Tooltip>}>
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                className={`info-icon ${infoText ? '' : 'invisible'}`}
                            />
                        </Whisper>
                    ) : (
                        <FontAwesomeIcon
                            icon={faCircleInfo}
                            className={`info-icon ${infoText ? '' : 'invisible'}`}
                            onClick={() =>
                                dispatch(openHelpDrawer({ title: label, message: infoText }))
                            }
                        />
                    ))}
            </div>
        </div>
    );
};

export default InputBase;
