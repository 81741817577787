import React from 'react';
import { Nav } from 'rsuite';
import { TbExternalLink } from 'react-icons/tb';

const SidebarLink = ({ linkTo, text }) => {
    return (
        <Nav.Item href={linkTo} target="_blank">
            {text} <TbExternalLink size="1.2em" data-testid="external-link-icon" />
        </Nav.Item>
    );
};

export default SidebarLink;
