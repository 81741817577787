import { faCircleInfo, faPencil, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Popover, Stack, Whisper, Tooltip } from 'rsuite';
import hexToRGB from '../utils/hexToRGBA';
import { useDispatch } from 'react-redux';
import { setRequestFinished, setRequestMessage } from '../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import getUTCDate from '../utils/getUTCDate';

const SimpleTable = ({
    table,
    labels,
    colors,
    lineSeparator = true,
    historicalYears,
    graphOpen,
    labelClicked,
    isAcceleratedDepr = true
}) => {
    let thisYear = getUTCDate().getUTCFullYear();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleLabelClick = (label) => {
        let lastHistoricalYear =
            historicalYears && historicalYears.length > 0
                ? historicalYears[historicalYears.length - 1]
                : -1;
        let nextYearToFill = lastHistoricalYear === -1 ? +labels[0] : +lastHistoricalYear + 1;
        if (+label > +nextYearToFill) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: t('forecast.e_enter_data_for_first_available_year').replace(
                        '!',
                        '(' + nextYearToFill + ')!'
                    )
                })
            );
        } else labelClicked(label);
    };
    return table && table.length > 0 ? (
        <>
            <div className="simple-table">
                {labelClicked && (
                    <>
                        {labels
                            .filter((label) => label < thisYear)
                            .map((label, i) => (
                                <Button
                                    key={label}
                                    style={{
                                        left:
                                            (window.innerWidth < 850
                                                ? 8.4375 + 5.125 * i
                                                : 13.15 + 6.25 * i) + 'rem'
                                    }}
                                    className={`historical-data-button ${
                                        historicalYears &&
                                        historicalYears.includes(label.toString())
                                            ? ''
                                            : 'dark'
                                    }`}
                                    onClick={() => handleLabelClick(label)}>
                                    {historicalYears &&
                                    historicalYears.includes(label.toString()) ? (
                                        <FontAwesomeIcon icon={faPencil} />
                                    ) : (
                                        <>
                                            <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                                            Ist-Daten
                                        </>
                                    )}
                                </Button>
                            ))}
                    </>
                )}
                <table className={labelClicked ? 'margin-on-top' : ''}>
                    <colgroup>
                        <col />
                        {labels.map((label) => (
                            <col key={label} />
                        ))}
                    </colgroup>
                    <thead>
                        <tr>
                            {table.length > 0 && (
                                <th>
                                    <div className="label-container" />
                                </th>
                            )}
                            {labels.map((label, i) => (
                                <th key={i}>{`${label} (${i + 1})`}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.map((row, i) => {
                            return (
                                <tr
                                    key={i}
                                    style={{
                                        borderTop:
                                            lineSeparator &&
                                            ((isAcceleratedDepr &&
                                                (i === table.length - colors.length ||
                                                    i === table.length - 4 * colors.length)) ||
                                                (!isAcceleratedDepr &&
                                                    (i === table.length - colors.length ||
                                                        i === table.length - 2 * colors.length)))
                                                ? '4px solid grey'
                                                : lineSeparator &&
                                                    isAcceleratedDepr &&
                                                    i === table.length - 3 * colors.length
                                                  ? '2px solid grey'
                                                  : '',
                                        fontWeight:
                                            i > table.length - colors.length - 1 ? 800 : undefined
                                    }}>
                                    {Object.keys(row)
                                        .filter((data) => data === 'label')
                                        .map((data, j) => {
                                            return (
                                                <th rowSpan={colors.length} key={j}>
                                                    <div
                                                        className={`label-container ${
                                                            colors.length === 1 ? 'single-row' : ''
                                                        }`}>
                                                        <Stack
                                                            style={{
                                                                background: 'transparent'
                                                            }}
                                                            justifyContent="space-between">
                                                            <Whisper
                                                                trigger={
                                                                    row[data]?.label?.length >= 23
                                                                        ? 'hover'
                                                                        : 'none'
                                                                }
                                                                placement="auto"
                                                                speaker={
                                                                    <Popover visible={false}>
                                                                        {row[data].label}
                                                                    </Popover>
                                                                }>
                                                                <div
                                                                    className="row-label"
                                                                    style={{
                                                                        fontWeight:
                                                                            i >
                                                                            table.length -
                                                                                colors.length -
                                                                                1
                                                                                ? 800
                                                                                : undefined
                                                                    }}>
                                                                    {row[data].label}
                                                                </div>
                                                            </Whisper>
                                                            <Whisper
                                                                placement="right"
                                                                trigger={
                                                                    row[data].tooltip
                                                                        ? 'click'
                                                                        : 'none'
                                                                }
                                                                speaker={
                                                                    <Tooltip>
                                                                        {row[data].tooltip}
                                                                    </Tooltip>
                                                                }>
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    color={
                                                                        row[data].tooltip
                                                                            ? undefined
                                                                            : 'transparent'
                                                                    }
                                                                    height={30}
                                                                    width={30}
                                                                />
                                                            </Whisper>
                                                            {colors && colors.length > 1 && (
                                                                <Stack direction="column">
                                                                    {colors.map((color) => (
                                                                        <div
                                                                            key={color}
                                                                            className="color-indicator"
                                                                            style={{
                                                                                backgroundColor:
                                                                                    colors.length ===
                                                                                    1
                                                                                        ? 'transparent'
                                                                                        : color
                                                                            }}></div>
                                                                    ))}
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </div>
                                                </th>
                                            );
                                        })}
                                    {Object.keys(row)
                                        .filter(
                                            (data) => data !== 'label' && labels.includes(+data)
                                        )
                                        .map((data, j) => {
                                            return (
                                                <td
                                                    key={j}
                                                    style={{
                                                        background:
                                                            colors.length === 1
                                                                ? 'transparent'
                                                                : colors[i % colors.length]
                                                                  ? colors[i % colors.length] ===
                                                                        '#3b365c' &&
                                                                    !historicalYears?.includes(
                                                                        data.toString()
                                                                    )
                                                                      ? 'transparent'
                                                                      : hexToRGB(
                                                                            colors[
                                                                                i % colors.length
                                                                            ],
                                                                            0.075
                                                                        )
                                                                  : 'transparent',
                                                        color:
                                                            !lineSeparator &&
                                                            i >= table.length - 2 * colors.length &&
                                                            i < table.length - colors.length &&
                                                            +row[data] < 0
                                                                ? 'red'
                                                                : ''
                                                    }}>
                                                    {
                                                        row[data]
                                                        // +(!lineSeparator &&
                                                        // i >= table.length - 2 * colors.length &&
                                                        // i < table.length - colors.length
                                                        //     ? '%'
                                                        //     : '')
                                                    }
                                                </td>
                                            );
                                        })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {labelClicked && (
                <>
                    <div
                        className={`button-cover ${window.innerWidth < 1024 ? (window.innerWidth < 850 ? 'mobile' : 'small-screen') : ''} ${graphOpen ? '' : 'closed-graph'}`}></div>
                    <div
                        className={`border-cover ${window.innerWidth < 1024 ? (window.innerWidth < 850 ? 'mobile' : 'small-screen') : ''} ${graphOpen ? '' : 'closed-graph'}`}></div>
                </>
            )}
        </>
    ) : (
        <div></div>
    );
};

export default SimpleTable;
