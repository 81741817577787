import { useTranslation } from 'react-i18next';
import constants from './constants';

const plansOverviewData = () => {
    const { t } = useTranslation();

    const data = [
        {
            id: '1',
            name: t('plan_overview.l_plan_basic'),
            handle: constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.basic.value,
            primaryFeatures: [
                {
                    key: 'feature1',
                    description: '1 Objekt'
                },
                {
                    key: 'feature2',
                    description: '1 Steuersubjekt'
                }
            ],
            secondaryFeatures: [
                {
                    key: 'feature1',
                    description: t('plan_overview.l_feature_1'),
                    enabled: true
                },
                {
                    key: 'feature2',
                    description: t('plan_overview.l_feature_2'),
                    enabled: true
                },
                {
                    key: 'feature3',
                    description: t('plan_overview.l_feature_3'),
                    enabled: true
                },
                {
                    key: 'feature4',
                    description: t('plan_overview.l_feature_4'),
                    enabled: false
                },
                {
                    key: 'feature5',
                    description: t('plan_overview.l_feature_5'),
                    enabled: false
                }
            ],
            price: '€49',
            priceSuffix: '/ Jahr',
            buttonText: t('plan_overview.l_upgrade'),
            footerText: '',
            priceId: '',
            highlight: true,
            enabled: false
        },

        {
            id: '2',
            name: t('plan_overview.l_plan_pro'),
            handle: constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.pro.value,
            primaryFeatures: [
                {
                    key: 'feature1',
                    description: '5 Objekte'
                },
                {
                    key: 'feature2',
                    description: '2 Steuersubjekte'
                }
            ],
            secondaryFeatures: [
                {
                    key: 'feature1',
                    description: t('plan_overview.l_feature_1'),
                    enabled: true
                },
                {
                    key: 'feature2',
                    description: t('plan_overview.l_feature_2'),
                    enabled: true
                },
                {
                    key: 'feature3',
                    description: t('plan_overview.l_feature_3'),
                    enabled: true
                },
                {
                    key: 'feature4',
                    description: t('plan_overview.l_feature_4'),
                    enabled: true
                },
                {
                    key: 'feature5',
                    description: t('plan_overview.l_feature_5'),
                    enabled: false
                }
            ],
            price: '€199',
            priceSuffix: '/ Jahr',
            buttonText: t('plan_overview.l_upgrade'),
            footerText: '',
            priceId: '',
            highlight: true,
            enabled: false
        },

        {
            id: '3',
            name: t('plan_overview.l_plan_manager'),
            handle: constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.manager.value,
            primaryFeatures: [
                {
                    key: 'feature1',
                    description: '10+ Objekte'
                },
                {
                    key: 'feature2',
                    description: '3+ Steuersubjekte'
                }
            ],
            secondaryFeatures: [
                {
                    key: 'feature1',
                    description: t('plan_overview.l_feature_1'),
                    enabled: true
                },
                {
                    key: 'feature2',
                    description: t('plan_overview.l_feature_2'),
                    enabled: true
                },
                {
                    key: 'feature3',
                    description: t('plan_overview.l_feature_3'),
                    enabled: true
                },
                {
                    key: 'feature4',
                    description: t('plan_overview.l_feature_4'),
                    enabled: true
                },
                {
                    key: 'feature5',
                    description: t('plan_overview.l_feature_5'),
                    enabled: true
                }
            ],
            price: t('plan_overview.l_on_request'),
            buttonText: t('plan_overview.l_request'),
            footerText: '',
            priceId: '',
            highlight: false,
            enabled: false
        }
    ];

    return data;
};

export default plansOverviewData;
