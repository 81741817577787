import constants from './constants';
export default (taxsubject) => {
    const name =
        (taxsubject?.isTest ? '(TEST) ' : '') +
        (taxsubject?.taxsubjectsData?.type === constants.TAX_SUBJECT_TYPES.private ||
        taxsubject?.taxsubjectsData?.type === 'Privatperson'
            ? `${taxsubject?.taxsubjectsData?.l_firstname} ${taxsubject?.taxsubjectsData?.l_lastname}`
            : taxsubject?.taxsubjectsData?.l_company);

    return name;
};
