import React from 'react';
import { useTranslation } from 'react-i18next';
import { Placeholder } from 'rsuite';
import ClickInput from './ClickInput';
import getTaxsubjectName from '../utils/getTaxsubjectName';
import constants from '../utils/constants';

const TaxSubjectDashboardSection = ({ taxsubject, onEditTaxsubject }) => {
    const { t } = useTranslation();
    return (
        <div className="dashboard-section taxsubject">
            <ClickInput
                label={t('property_dashboard.h_tax_subject')}
                labelClass="section-label"
                actionName={t('property_dashboard.l_edit_tax_subject')}
                actionClass="align-right"
                onClick={onEditTaxsubject}
            />
            {taxsubject ? (
                <div className="taxsubject-info">
                    <div className="info-block">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="bold">
                                        {taxsubject?.taxsubjectsData?.type ===
                                            constants.TAX_SUBJECT_TYPES.private ||
                                        taxsubject?.taxsubjectsData?.type === 'Privatperson'
                                            ? t('tax_subject_dashboard.l_name')
                                            : t('tax_subject_dashboard.l_company')}
                                    </td>
                                    <td>{getTaxsubjectName(taxsubject)}</td>
                                </tr>
                                {taxsubject.taxsubjectsData?.tax_number && (
                                    <tr>
                                        <td className="bold">
                                            {t('tax_subject_dashboard.l_tax_id')}
                                        </td>
                                        <td>{taxsubject.taxsubjectsData?.tax_number}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="bold">
                                        {t('tax_subject_dashboard.l_small_business')}
                                    </td>
                                    <td>
                                        {taxsubject?.taxsubjectsData?.l_small_business_scheme
                                            ? 'Ja'
                                            : 'Nein'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        {t('tax_subject_dashboard.l_tax_rate')}
                                    </td>
                                    <td>{taxsubject.taxsubjectsData?.tax_progression} %</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="info-block">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="bold">
                                        {t('tax_subject_dashboard.l_address_line')}
                                    </td>
                                    <td>{taxsubject.taxsubjectsData?.l_address_line}</td>
                                </tr>
                                <tr>
                                    <td className="bold">
                                        {t('tax_subject_dashboard.l_zip_code')}
                                    </td>
                                    <td>{taxsubject.taxsubjectsData?.l_zip}</td>
                                </tr>
                                <tr>
                                    <td className="bold">{t('tax_subject_dashboard.l_city')}</td>
                                    <td>{taxsubject?.taxsubjectsData?.l_city}</td>
                                </tr>
                                <tr></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <Placeholder.Grid rows={4} columns={2} active />
            )}
        </div>
    );
};

export default TaxSubjectDashboardSection;
