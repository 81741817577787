import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarController,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Panel, Stack, Placeholder, SelectPicker } from 'rsuite';
import {
    setCurrentTaxsubject,
    setOpenedPropertyForecast,
    getForecastData,
    setActiveE1bData,
    setRequestFinished,
    setRequestMessage,
    setCurrentProperty
} from '../../reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import ContentPageBase from '../../components/ContentPageBase';
import sendRequest from '../../utils/sendRequest';
import ClickInput from '../../components/ClickInput';
import Map from '../../components/Map';
import KpiItem from '../../components/KpiItem';
import constants from '../../utils/constants';
import TaxSubjectDashboardSection from '../../components/TaxSubjectDashboardSection';
import { exportReportToPdf } from '../../utils/exportReportToPdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { analytics } from '../../utils/analytics';
import analyticsConstants from '../../utils/analyticsConstants';
import propertyBg from '../../img/property-bg.png';
import getUTCDate from '../../utils/getUTCDate';
import getTaxsubjectName from '../../utils/getTaxsubjectName';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarController,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const forecastOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false
    },
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            ticks: {
                maxRotation: 0,
                minRotation: 0
            }
        }
    }
};

const PropertyDashboard = ({ handleEdit }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const generatePdf = exportReportToPdf();

    const forecastData = useSelector((state) => state.user.forecastData);
    const property = useSelector((state) => state.user.currentProperty);
    const properties = useSelector((state) => state.user.properties);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);

    const [isHistoricalData, setIsHistoricalData] = useState(true);
    const [fullAddress, setFullAddress] = useState(null);
    const [propertyPosition, setPropertyPosition] = useState(null); // [lat, lng]
    const [locationNotFound, setLocationNotFound] = useState(false);
    const [taxsubject, setTaxsubject] = useState(null);
    const [propertyForecastData, setPropertyForecastData] = useState(null);
    const [propertyForecastCalculation, setPropertyForecastCalculation] = useState(null);
    const [purchaseYear, setPurchaseYear] = useState(null);
    const [rentStartYear, setRentStartYear] = useState(null);
    const [yearsUntilProfit, setYearsUntilProfit] = useState(null);
    const [fullExpectedMontlyRent, setFullExpectedMontlyRent] = useState(null);
    const [rentPerSquareMeter, setRentPerSquareMeter] = useState(null);
    const [propertyValueData, setPropertyValueData] = useState(null);
    const [isHobby, setIsHobby] = useState(null);
    const [adjustedCumulativeTaxData, setAdjustedCumulativeTaxData] = useState(null);
    // const [planedNetRentalYield, setPlanedNetRentalYield] = useState(null);
    const [expenseIncomeBeforeTaxData, setExpenseIncomeBeforeTaxData] = useState(null);
    const [netProfitData, setNetProfitData] = useState(null);
    const [paymentsData, setPaymentsData] = useState(null);
    const [cashflowAfterTax, setCashflowAfterTax] = useState(null);
    const [remainigLoanData, setRemainigLoanData] = useState(null);
    const [showAddHistoricalData, setShowAddHistoricalData] = useState(false);
    const [historicalDataYears, setHistoricalDataYears] = useState([]);
    const [showExportToPdf, setShowExportToPdf] = useState(false);
    const [showCreateForecast, setShowCreateForecast] = useState(false);

    const propertyOptions = useMemo(() => {
        const tsList = taxsubjects.map((item) => ({ id: item._id, name: getTaxsubjectName(item) }));
        return properties.map((item) => ({
            label:
                (item?.isTest ? '(TEST) ' : '') +
                (item.propertiesData?.l_zip ? item.propertiesData?.l_zip + ', ' : '') +
                (item.propertiesData.l_address_line
                    ? item.propertiesData.l_address_line
                    : item.propertiesData.l_street) +
                ' ' +
                (item.propertiesData?.l_house_number ? item.propertiesData?.l_house_number : '') +
                `${item.propertiesData?.l_stair ? `/${item.propertiesData.l_stair}` : ''}` +
                `${
                    item.propertiesData?.l_door_number
                        ? `/${item.propertiesData.l_door_number}`
                        : ''
                }`,
            value: item,
            owner: tsList.find((ts) => ts.id === item._owner).name
        }));
    }, [taxsubjects, properties]);

    // get property data
    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.PROPERTY_DASHBOARD);
        if (!property) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: 'No selected property found'
                })
            );
            navigate(constants.ROUTES.PROPERTIES.LIST);
        }
    }, []);

    useEffect(() => {
        if (!property) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: 'No selected property found'
                })
            );
            navigate(constants.ROUTES.PROPERTIES.LIST);
            return;
        }
        // get forecast data
        dispatch(getForecastData(property._id));

        // get taxsubject data
        sendRequest('/getTaxsubjectByPropertyId', 'post', {
            owner: property._owner
        }).then((resp) => {
            if (resp?.error) {
                return;
            }
            setTaxsubject(resp);
        });

        const fullAdress = property.propertiesData?.l_address_line
            ? `${property.propertiesData?.l_address_line}, ${property.propertiesData?.l_zip} ${property.propertiesData?.l_city}`
            : `${property.propertiesData?.l_street} ${property.propertiesData?.l_house_number}, ${property.propertiesData?.l_zip} ${property.propertiesData?.l_city}`;

        setFullAddress(fullAdress);
    }, [property]);

    useEffect(() => {
        if (forecastData) {
            setPropertyForecastData(forecastData);
        }
    }, [forecastData]);

    // get forecast calculation
    useEffect(() => {
        if (!property || !taxsubject) return;
        dispatch(setCurrentTaxsubject(taxsubject));
        sendRequest('/forecastCalculations', 'post', {
            ID: property._id,
            isSmallBusiness: taxsubject?.taxsubjectsData?.l_small_business_scheme,
            tax_rate: taxsubject?.taxsubjectsData.tax_progression,
            preset: constants.CALCULATION_PRESETS.DASHBOARD
        }).then((resp) => {
            if (resp?.error) {
                setPropertyForecastCalculation(null);
                setShowCreateForecast(true);
                showExportToPdf && setShowExportToPdf(false);
            } else {
                if (resp.result[0]?.Prognoserechnung) {
                    setPropertyForecastCalculation(resp.result[0].Prognoserechnung);
                    showCreateForecast && setShowCreateForecast(false);
                    setIsHistoricalData(false);
                } else {
                    setPropertyForecastCalculation(resp.result[0]['Ist Daten']);
                    showCreateForecast && setShowCreateForecast(false);
                    setIsHistoricalData(true);
                }
                setShowExportToPdf(true);
            }
        });
    }, [property, taxsubject]);

    //get kpi & graphs data
    useEffect(() => {
        if (!property || !propertyForecastCalculation || !propertyForecastData) return;
        const labels = Array.from(propertyForecastCalculation, (item) => item.year);

        const currentYear = getUTCDate().getUTCFullYear();
        const purchaseDate = getUTCDate(propertyForecastData.forecastsData?.l_purchase_date);
        const purchaseYear = purchaseDate?.getUTCFullYear();
        const share_of_land = property?.propertiesData?.l_share_of_land;
        if (purchaseYear) setPurchaseYear(purchaseYear);
        const rentStartYear = getUTCDate(
            propertyForecastData.forecastsData?.l_rent_start_date
        ).getUTCFullYear();
        if (rentStartYear) setRentStartYear(rentStartYear);

        // show / hide add historical data action items
        if (property.historicalData) {
            const historicalDataYears = Object.keys(property.historicalData);
            const lastHitoricalYear = historicalDataYears[historicalDataYears.length - 1];

            setShowAddHistoricalData(lastHitoricalYear < currentYear - 1);
            setHistoricalDataYears(historicalDataYears);
        } else {
            historicalDataYears.length > 0 && setHistoricalDataYears([]);
            setShowAddHistoricalData(purchaseYear < currentYear - 1);
        }

        const totalPurchasePriceBrutto = +propertyForecastData.forecastsData?.l_purchase_price;
        const purchase_price_kitchen =
            propertyForecastData?.forecastsData?.l_purchase_price_kitchen || 0;

        const purchasePriceNetInclAncillaryPurchaseCosts =
            -propertyForecastCalculation[0].purchase_price_net_incl_anchilliary_purchase_costs;

        let fullExpectedMontlyRent = +propertyForecastData.forecastsData?.l_expected_monthly_rent;
        if (propertyForecastData.forecastsData?.l_expected_monthly_rent_kitchen) {
            fullExpectedMontlyRent +=
                +propertyForecastData.forecastsData.l_expected_monthly_rent_kitchen;
        }
        if (propertyForecastData.forecastsData?.l_expected_monthly_rent_garage) {
            fullExpectedMontlyRent +=
                +propertyForecastData.forecastsData?.l_expected_monthly_rent_garage;
        }
        if (propertyForecastData.forecastsData?.l_expected_monthly_rent_misc) {
            fullExpectedMontlyRent +=
                +propertyForecastData.forecastsData?.l_expected_monthly_rent_misc;
        }
        setFullExpectedMontlyRent(
            isNaN(fullExpectedMontlyRent) ? null : fullExpectedMontlyRent.toFixed(2)
        );

        const rentPerSquareMeter =
            +fullExpectedMontlyRent / +property.propertiesData?.l_area_of_living_space;
        setRentPerSquareMeter(isNaN(rentPerSquareMeter) ? null : rentPerSquareMeter.toFixed(2));
        const propertyHistory = propertyForecastCalculation.filter(
            (item) => item.year <= currentYear
        );

        //get profit / loss data (Gewinn/Verlust) --- New Immobiliengesamtwert
        const property_value = propertyHistory.map((item) => item.l_property_value);
        setPropertyValueData([
            {
                labels: propertyHistory.map((item) => item.year),
                datasets: [
                    {
                        data: property_value,
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            property_value[property_value.length - 1]
        ]);

        let specialRepayments = {};
        forecastData?.forecastsData?.specialRepaymentsPages &&
            forecastData?.forecastsData?.specialRepaymentsPages.map((item) => {
                let year = getUTCDate(
                    forecastData?.forecastsData?.['l_prepayment_date_' + item.id]
                ).getUTCFullYear();
                if (year <= currentYear) {
                    if (specialRepayments?.[year])
                        specialRepayments[year] +=
                            forecastData?.forecastsData?.['l_prepayment_value_' + item.id];
                    else
                        specialRepayments = {
                            ...specialRepayments,
                            [year]: forecastData?.forecastsData?.['l_prepayment_value_' + item.id]
                        };
                }
            });
        const down_payment = +(forecastData?.forecastsData?.l_loan_down_payment || 0);
        let fullPayment = down_payment;
        Object.values(specialRepayments).map((item) => (fullPayment += +item));
        setPaymentsData([
            {
                labels: propertyHistory.map((item) => item.year),
                datasets: [
                    {
                        data: Object.values(specialRepayments).map(
                            (item) => +item + +down_payment
                        ) || [down_payment],
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            fullPayment
        ]);

        //get remaining loan data (Offener Saldo)
        const remainigLoanValues = propertyHistory.map((item) => -item.remaining_loan_full);
        setRemainigLoanData([
            {
                labels: propertyHistory.map((item) => item.year),
                datasets: [
                    {
                        data: remainigLoanValues,
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            remainigLoanValues?.[remainigLoanValues.length - 1]
        ]);

        // planned net rental yield (Mietrendite)
        // const plannedNetRentalYieldValues = propertyHistory.map(
        //     (item) => item.l_yearly_return_on_total_cash_inflow_after_tax
        // );
        // setPlanedNetRentalYield([
        //     {
        //         labels: propertyHistory.map((item) => item.year),
        //         datasets: [
        //             {
        //                 data: plannedNetRentalYieldValues,
        //                 backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
        //                 borderSkipped: true,
        //                 barThickness: 5
        //             }
        //         ]
        //     },
        //     plannedNetRentalYieldValues?.[plannedNetRentalYieldValues.length - 1]
        // ]);

        // l_expense_income_after_tax
        const expenseValues = propertyHistory.map((item) => item.l_expense_income_before_tax);
        setExpenseIncomeBeforeTaxData([
            {
                labels: propertyHistory.map((item) => item.year),
                datasets: [
                    {
                        data: propertyHistory.map((item) => item.l_expense_income_before_tax),
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            expenseValues?.[expenseValues.length - 1]
        ]);

        //cashflow
        const cashflowValues = propertyHistory.map((item) => item.l_cash_flow_after_taxes);
        setCashflowAfterTax([
            {
                labels: propertyHistory.map((item) => item.year),
                datasets: [
                    {
                        data: cashflowValues,
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            cashflowValues?.[cashflowValues.length - 1]
        ]);

        // net profit
        const proceeds = Array(currentYear - purchaseYear + 1)
            .fill(0)
            .map((_, i) => {
                return (
                    +totalPurchasePriceBrutto *
                    (1 + +forecastData.forecastsData?.l_value_development_rate / 100) ** i
                );
            });
        const historyYears = Object.keys(propertyHistory.map((item) => item.year));
        const vat_reduction = historyYears.map((year, i) =>
            Math.max(
                0,
                (propertyHistory[i].vat_before_rent_start / 20) * (20 - (year - purchaseYear))
            )
        );
        const proceeds_after_vat_reduction = historyYears.map(
            (_, i) => proceeds[i] - vat_reduction[i]
        );
        const depreciation_base =
            purchasePriceNetInclAncillaryPurchaseCosts -
            +purchase_price_kitchen -
            ((purchasePriceNetInclAncillaryPurchaseCosts - +purchase_price_kitchen) *
                share_of_land) /
                100;
        const purchase_price_building_and_renovation_non_subsidised =
            depreciation_base + +purchase_price_kitchen;
        let sum_accelerated_depreciation = 0;
        let sum_depreciation_building = 0;
        propertyHistory.map((item) => {
            sum_accelerated_depreciation += item.additional_accelerated_depreciation;
        });
        historyYears.map((_, i) => {
            sum_depreciation_building += Math.min(
                (purchase_price_building_and_renovation_non_subsidised / 67) * i,
                purchase_price_building_and_renovation_non_subsidised - sum_accelerated_depreciation
            );
        });
        const subsidised_depreciation = 0;
        const repair_costs_depreciation = 0;
        const used_depreciation =
            sum_accelerated_depreciation +
            sum_depreciation_building +
            subsidised_depreciation +
            repair_costs_depreciation;

        const still_available_costs = totalPurchasePriceBrutto - used_depreciation;
        setNetProfitData([
            {
                labels: historyYears,
                datasets: [
                    {
                        data: proceeds_after_vat_reduction.map(
                            (item) => item - still_available_costs
                        ),
                        backgroundColor: (data) => (data.raw > 0 ? 'green' : 'red'),
                        borderSkipped: true,
                        barThickness: 5
                    }
                ]
            },
            proceeds_after_vat_reduction[proceeds_after_vat_reduction.length - 1] -
                still_available_costs
        ]);

        //get adjustedCumulativeTaxData (Prognoserechnung)
        const adjustedCumulativeTax = Array.from(
            propertyForecastCalculation,
            (item) => item.adjusted_cumulative_tax_result
        );
        const adjustedCumulativeTaxData = {
            labels: labels,
            datasets: [
                {
                    data: adjustedCumulativeTax,
                    pointRadius: 0,
                    borderColor: '#73dea8',
                    borderWidth: 2
                }
            ]
        };
        setAdjustedCumulativeTaxData(adjustedCumulativeTaxData);

        const yearsAfterRenting = propertyForecastCalculation[0].yearsUntilProfit;
        if (
            yearsAfterRenting >
                (propertyForecastData?.forecastsData?.l_purchase_date &&
                new Date(propertyForecastData?.forecastsData?.l_purchase_date).getFullYear() >= 2024
                    ? property?.propertiesData?.l_type_of_rental === constants.RENTAL_TYPES.LARGE
                        ? 30
                        : 25
                    : property?.propertiesData?.l_type_of_rental === constants.RENTAL_TYPES.LARGE
                      ? 25
                      : 20) ||
            yearsAfterRenting === undefined
        )
            setIsHobby(true);
        else setIsHobby(false);
        setYearsUntilProfit(
            yearsAfterRenting
                ? yearsAfterRenting
                : '>' +
                      (propertyForecastData?.forecastsData?.l_purchase_date &&
                      new Date(propertyForecastData?.forecastsData?.l_purchase_date) >
                          new Date('31-12-2023')
                          ? property?.propertiesData?.l_type_of_rental ===
                            constants.RENTAL_TYPES.LARGE
                              ? 30
                              : 25
                          : property?.propertiesData?.l_type_of_rental ===
                              constants.RENTAL_TYPES.LARGE
                            ? 25
                            : 20)
        );
    }, [property, propertyForecastCalculation, propertyForecastData]);

    // get property position
    useEffect(() => {
        if (!fullAddress) return;
        sendRequest('/getGeoCoordinates', 'post', {
            address: fullAddress
        }).then((resp) => {
            if (!resp?.success) {
                setLocationNotFound(true);
                return;
            }
            setPropertyPosition(resp.coordinates);
        });
    }, [fullAddress]);

    const editProperty = () => {
        analytics.trackClick('edit_property', analyticsConstants.CATEGORIES.PROPERTIES);
        dispatch(setCurrentTaxsubject(taxsubject));
        handleEdit(property._id, taxsubject._id);
    };

    const goToForecastPage = () => {
        analytics.trackClick('go_to_forecast_page', analyticsConstants.CATEGORIES.FORECASTS);
        const currentPropertyIndex = properties.findIndex((p) => p._id === property._id);
        if (currentPropertyIndex === -1) return;
        dispatch(setOpenedPropertyForecast(currentPropertyIndex.toString()));
        navigate(constants.ROUTES.FORECAST_CALCULATION.HOME);
    };

    const goToCreateForecastPage = () => {
        analytics.trackClick('go_to_create_forecast_page', analyticsConstants.CATEGORIES.FORECASTS);
        const currentPropertyIndex = properties.findIndex((p) => p._id === property._id);
        if (currentPropertyIndex === -1) return;
        dispatch(setOpenedPropertyForecast(currentPropertyIndex.toString()));
        navigate(constants.ROUTES.FORECAST_CALCULATION.HOME);
    };

    const openE1bForm = (year) => {
        analytics.trackClick('open_e1b', analyticsConstants.CATEGORIES.FORECASTS);
        const activeE1bData = {
            property,
            year
        };
        dispatch(setActiveE1bData(activeE1bData));
        navigate(constants.ROUTES.E1B);
    };

    return (
        <ContentPageBase
            className="entity-dashboard-page"
            header
            bgIcon={propertyBg}
            altBgIcon="property-bg">
            <Stack justifyContent="space-between" spacing={10} alignItems="flex-start" wrap={true}>
                <Stack.Item>
                    {property ? (
                        <h2>
                            {property?.isTest ? '(TEST) ' : ''}
                            {property?.propertiesData?.l_address_line
                                ? property?.propertiesData?.l_address_line
                                : `${property.propertiesData?.l_street} ${property.propertiesData?.l_house_number}`}{' '}
                            <span className="title-city-span">
                                {property?.propertiesData?.l_zip} {property?.propertiesData?.l_city}
                            </span>
                        </h2>
                    ) : (
                        <Placeholder.Paragraph rows={1} active />
                    )}
                </Stack.Item>
                <Stack.Item>
                    <SelectPicker
                        className="tax-subject-dropdown"
                        cleanable={false}
                        size="md"
                        data={propertyOptions}
                        value={property}
                        placement="auto"
                        groupBy="owner"
                        searchable={false}
                        onChange={(value) => {
                            dispatch(setCurrentProperty(value));
                            analytics.trackChangeProperty(value?._id);
                        }}
                    />
                </Stack.Item>
            </Stack>

            {propertyForecastCalculation && (
                <div className="dashboard-section">
                    <ClickInput
                        label={
                            t('property_dashboard.h_kpis') +
                            ' ' +
                            '(' +
                            (isHistoricalData
                                ? t('property_dashboard.l_data_basis_for_kpi_is_historical')
                                : t('property_dashboard.l_data_basis_for_kpi_is_forecast')) +
                            ')'
                        }
                        labelClass="section-label"
                        actionClass="align-right"
                    />
                    <Stack wrap justifyContent="center" className="kpis" spacing={20}>
                        <KpiItem
                            label={t('property_dashboard.l_kpi_1')}
                            symbol="€"
                            mainValue={rentPerSquareMeter}
                            info={
                                t('property_dashboard.l_kpi_1_sub') + fullExpectedMontlyRent + '€'
                            }
                            tooltip={t('property_dashboard.i_kpi_1')}
                        />
                        <KpiItem
                            label={t('property_dashboard.l_kpi_2')}
                            symbol="€"
                            mainValue={remainigLoanData?.[1] && remainigLoanData?.[1].toFixed(2)}
                            graphData={remainigLoanData?.[0]}
                            info={t('property_dashboard.l_kpi_2_sub')}
                            tooltip={t('property_dashboard.i_kpi_2')}
                        />
                        <KpiItem
                            label={t('property_dashboard.l_kpi_3')}
                            symbol="€"
                            mainValue={
                                cashflowAfterTax?.[1] &&
                                cashflowAfterTax?.[1] !== '-' &&
                                cashflowAfterTax?.[1].toFixed(2)
                            }
                            graphData={cashflowAfterTax?.[0]}
                            info={t('property_dashboard.l_kpi_3_sub')}
                            tooltip={t('property_dashboard.i_kpi_3')}
                        />
                        <KpiItem
                            // label={t('property_dashboard.l_kpi_4')}
                            symbol="%"
                            // mainValue={
                            // planedNetRentalYield?.[1] &&
                            // planedNetRentalYield?.[1] !== '-' &&
                            // planedNetRentalYield?.[1].toFixed(2)
                            // }
                            // graphData={planedNetRentalYield?.[0]}
                            info={t('property_dashboard.l_kpi_4_sub')}
                            tooltip={t('property_dashboard.i_kpi_4')}
                        />
                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_5')}
                            symbol="€"
                            mainValue={
                                expenseIncomeBeforeTaxData?.[1] &&
                                expenseIncomeBeforeTaxData?.[1] !== '-' &&
                                expenseIncomeBeforeTaxData?.[1].toFixed(2)
                            }
                            graphData={expenseIncomeBeforeTaxData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_5_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_5')}
                        />
                        <KpiItem
                            label={t('tax_subject_dashboard.l_kpi_6')}
                            symbol="€"
                            mainValue={
                                netProfitData?.[1] &&
                                netProfitData?.[1] !== '-' &&
                                netProfitData?.[1].toFixed(2)
                            }
                            graphData={netProfitData?.[0]}
                            info={t('tax_subject_dashboard.l_kpi_6_sub')} //"Reinerlös abzüglich ImmoEst etc"
                            tooltip={t('tax_subject_dashboard.i_kpi_6')}
                        />
                        <KpiItem
                            label={t('property_dashboard.l_kpi_7')}
                            symbol="€"
                            mainValue={propertyValueData?.[1] && propertyValueData?.[1].toFixed(2)}
                            graphData={propertyValueData?.[0]}
                            info={t('property_dashboard.l_kpi_7_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_7')}
                        />
                        <KpiItem
                            label={t('tax_subject_dashboard.kpi_8')}
                            symbol="€"
                            mainValue={
                                (paymentsData?.[1] &&
                                    paymentsData?.[1] !== '-' &&
                                    paymentsData?.[1]) ||
                                '0'
                            }
                            graphData={
                                paymentsData?.[0] && paymentsData?.[0].length > 0
                                    ? paymentsData?.[0]
                                    : undefined
                            }
                            info={t('tax_subject_dashboard.l_kpi_8_sub')}
                            tooltip={t('tax_subject_dashboard.i_kpi_8')}
                        />
                    </Stack>
                </div>
            )}

            {(showAddHistoricalData ||
                historicalDataYears.length > 0 ||
                showExportToPdf ||
                showCreateForecast) && (
                <div className="dashboard-section">
                    <h3 className="section-label">{t('property_dashboard.h_actions')}</h3>
                    {showCreateForecast && (
                        <Panel className="action-panel">
                            <ClickInput
                                label={t('property_dashboard.l_add_first_forecast_message')}
                                actionName={t('property_dashboard.l_create_now')}
                                actionClass="align-right"
                                onClick={goToCreateForecastPage}
                            />
                        </Panel>
                    )}
                    {showAddHistoricalData && (
                        <Panel className="action-panel">
                            <ClickInput
                                label={t('property_dashboard.l_add_historical_data_message')}
                                actionName="Jetzt eingeben"
                                actionClass="align-right"
                                onClick={goToForecastPage}
                            />
                        </Panel>
                    )}
                    {historicalDataYears.length > 0 && (
                        <Panel className="action-panel">
                            <ClickInput
                                label={t('property_dashboard.l_open_e1b')}
                                actionName="Jetzt eingeben"
                                actionClass="align-right"
                                onClick={() =>
                                    openE1bForm(historicalDataYears[historicalDataYears.length - 1])
                                }
                            />
                        </Panel>
                    )}
                    {showExportToPdf && (
                        <Panel className="action-panel">
                            <ClickInput
                                label={t('property_dashboard.l_action_export_pdf')}
                                actionName={t('property_dashboard.l_export_pdf')}
                                actionClass="align-right"
                                onClick={() => {
                                    analytics.trackClick(
                                        'generate_pdf',
                                        analyticsConstants.CATEGORIES.FORECASTS
                                    );
                                    generatePdf(property?._id);
                                }}
                            />
                        </Panel>
                    )}
                </div>
            )}

            <div className="dashboard-section">
                <ClickInput
                    label={t('property_dashboard.h_property_data')}
                    labelClass="section-label"
                    actionName={t('property_dashboard.l_edit_property')}
                    actionClass="align-right"
                    onClick={editProperty}
                />
                <div className="object-data">
                    <div className="info-container">
                        {property ? (
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_property_type')}
                                        </td>
                                        <td>{t(property.propertiesData?.l_property_type)}</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_year_of_construction')}
                                        </td>
                                        <td>{property.propertiesData?.l_construction_year}</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_year_of_geneal_renovation')}
                                        </td>
                                        <td>
                                            {property.propertiesData?.last_general_renovation
                                                ? property.propertiesData?.last_general_renovation
                                                : '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_area_of_livingspace')}
                                        </td>
                                        <td>
                                            {property.propertiesData?.l_area_of_living_space} m²
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_basement')}
                                        </td>
                                        <td>
                                            {property.propertiesData?.l_basement
                                                ? t(property.propertiesData?.l_basement)
                                                : '-'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_number_of_garages')}
                                        </td>
                                        <td>{property.propertiesData?.l_number_of_garages}</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_share_of_land')}
                                        </td>
                                        <td>{property.propertiesData?.l_share_of_land} %</td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_elevator')}
                                        </td>
                                        <td>
                                            {property.propertiesData?.l_elevator_available
                                                ? 'Ja'
                                                : 'Nein'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="bold">
                                            {t('property_dashboard.l_type_of_rental')}
                                        </td>
                                        <td>
                                            {property.propertiesData?.l_type_of_rental
                                                ? t(property.propertiesData?.l_type_of_rental)
                                                : '-'}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <Placeholder.Grid rows={8} columns={2} active />
                        )}
                    </div>
                    <div className="map-container">
                        <div className="map">
                            {locationNotFound ? (
                                <p className="location-not-found">
                                    {t('property_dashboard.l_warning_location_not_found')}
                                </p>
                            ) : propertyPosition ? (
                                <Map position={propertyPosition} />
                            ) : (
                                <Placeholder.Graph active />
                            )}
                        </div>
                        <div className="address">
                            {property ? (
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="bold">
                                                {t('property_dashboard.l_address_line')}
                                            </td>
                                            <td>
                                                {property.propertiesData?.l_address_line
                                                    ? property.propertiesData.l_address_line
                                                    : `${property.propertiesData?.l_street} ${property.propertiesData?.l_house_number}`}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="bold">
                                                {t('property_dashboard.l_zip_code')}
                                            </td>
                                            <td>{property.propertiesData?.l_zip}</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">
                                                {t('property_dashboard.l_city')}
                                            </td>
                                            <td>{property.propertiesData?.l_city}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            ) : (
                                <Placeholder.Grid rows={3} columns={2} active />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="dashboard-section">
                <ClickInput
                    label={t('property_dashboard.h_forecast')}
                    labelClass="section-label"
                    actionName={t('property_dashboard.l_edit_forecast')}
                    actionClass="align-right"
                    onClick={goToForecastPage}
                />
                {propertyForecastCalculation ? (
                    <div className="forecast">
                        <div className="forecast-graph">
                            {adjustedCumulativeTaxData && (
                                <Line options={forecastOptions} data={adjustedCumulativeTaxData} />
                            )}
                        </div>
                        <div className="forecast-details">
                            <h3>
                                <span className="color-indicator"></span>
                                {isHistoricalData
                                    ? 'Ist Daten'
                                    : t('property_dashboard.h_forecast')}
                                {isHobby && (
                                    <FontAwesomeIcon icon={faWarning} className="warning-icon" />
                                )}
                            </h3>
                            {propertyForecastData &&
                            purchaseYear &&
                            rentStartYear &&
                            yearsUntilProfit ? (
                                <p>
                                    <Trans
                                        i18nKey="property_dashboard.l_forecast_info_message"
                                        values={{
                                            'purchae-date': purchaseYear,
                                            'rent-start-date': rentStartYear,
                                            'total-surplus-year': yearsUntilProfit
                                        }}
                                        components={{
                                            span: (
                                                <span
                                                    className={`years-until-profit${
                                                        isHobby ? ' is-hobby' : ''
                                                    }`}></span>
                                            )
                                        }}
                                    />
                                </p>
                            ) : (
                                <Placeholder.Paragraph rows={2} active />
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="no-forecast-data">
                        <p>{t('property_dashboard.l_no_forecast')}</p>
                    </div>
                )}
            </div>
            <TaxSubjectDashboardSection
                taxsubject={taxsubject}
                onEditTaxsubject={() => {
                    analytics.trackClick(
                        'edit_taxsubject',
                        analyticsConstants.CATEGORIES.FORECASTS
                    );
                    navigate(constants.ROUTES.TAXSUBJECTS.EDIT);
                }}
            />
        </ContentPageBase>
    );
};

export default PropertyDashboard;
