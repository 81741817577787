import {
    getForecastData,
    getScenarios,
    setRequestFinished,
    setRequestMessage,
    setTaxsubjectsLoading
} from '../reducers/userSlice';
import sendRequest from '../utils/sendRequest';
import i18next from 'i18next';
import { analytics } from '../utils/analytics';

const userMiddleware = (store) => (next) => (action) => {
    console.debug('Action:', action);
    let userStore = store.getState().user;

    switch (action.type) {
        case 'user/getUserId':
            sendRequest('/getUserId', 'get').then((resp) => {
                if (Object.keys(resp).length !== 0) return next({ ...action, payload: resp.id });
                else console.log('Error:', action.type);
                return next(action);
            });
            break;
        case 'user/getUser':
            sendRequest('/queryDB/users', 'post', { IDs: userStore.userId }).then((resp) => {
                if (resp) next({ ...action, payload: resp });
                else console.log('Error:', action.type);
            });
            break;
        case 'user/getAllUsers':
            sendRequest('/queryDB/users', 'post').then((resp) => {
                if (resp) return next({ ...action, payload: resp });
                else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({ type: 'error', message: 'Error loading users' })
                    );
                }
            });
            break;
        case 'user/setForecastData':
            sendRequest('/updateDB/forecasts', 'post', {
                data: action.payload,
                IDs: [action.payload._owner],
                returnList: action.payload.deleteflag ? false : true
            }).then((resp) => {
                if (action.payload.deleteflag) {
                    store.dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: i18next.t('create_forecast.l_forecast_updated')
                        })
                    );
                    store.dispatch(setRequestFinished(true));
                    return next({ ...action, payload: {} });
                }
                if (resp?.list?.length > 0) {
                    store.dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: i18next.t('create_forecast.l_forecast_updated')
                        })
                    );
                    store.dispatch(setRequestFinished(true));
                    return next({ ...action, payload: resp.list[0] });
                } else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: i18next.t('global.e_server_error')
                        })
                    );
                }
            });
            break;
        case 'user/getForecastData': {
            const prevForecastData = userStore.forecastData;
            sendRequest('/queryDB/forecasts', 'post', { IDs: action.payload }).then((resp) => {
                if (resp) {
                    if (
                        prevForecastData.length === resp.length &&
                        JSON.stringify(prevForecastData) === JSON.stringify(resp)
                    )
                        return;
                    if (
                        resp.length > 0 &&
                        userStore.openedPropertyForecast &&
                        userStore.openedPropertyForecast !== ''
                    )
                        store.dispatch(getScenarios(resp[0]._id));
                    else store.dispatch(getScenarios());
                    return next({ ...action, payload: resp.length === 1 ? resp[0] : resp });
                } else console.log('Error:', action.type);
            });
            break;
        }
        case 'user/setHistoricalData':
            sendRequest('/updateDB/properties', 'post', {
                data: action.payload,
                returnList: false
            }).then((resp) => {
                if (Object.keys(action.payload.historicalData).length === 0)
                    return next({ ...action, payload: {} });
                if (resp.success) {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: i18next.t('create_historic_forecast_data.l_data_updated')
                        })
                    );
                    if (action.payload._id) {
                        if (action.payload.deleteflag)
                            analytics.trackDeleteHistoricalData(action.payload._id, true);
                        else analytics.trackUpdateHistoricalData(action.payload._id, true);
                    } else {
                        analytics.trackCreateHistoricalData(resp.id, true);
                    }
                    return next({ ...action, payload: action.payload.historicalData });
                } else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: i18next.t('global.e_server_error')
                        })
                    );
                    if (action.payload._id) {
                        if (action.payload.deleteflag)
                            analytics.trackDeleteHistoricalData(action.payload._id, false);
                        analytics.trackUpdateHistoricalData(action.payload._id, false);
                    } else {
                        analytics.trackCreateHistoricalData('', false);
                    }
                }
            });
            break;
        case 'user/getHistoricalData': {
            const prevProperties = userStore.properties;
            sendRequest('/queryDB/properties', 'post', { IDs: action.payload._owner }).then(
                (resp) => {
                    if (resp) {
                        if (
                            Object.keys(userStore?.historicalData).length > 0 &&
                            prevProperties.length === resp.length &&
                            JSON.stringify(prevProperties) === JSON.stringify(resp)
                        )
                            return;
                        return next({
                            ...action,
                            payload:
                                resp.length > 0
                                    ? resp.filter((item) => item._id === action.payload._id)[0]
                                          .historicalData || {}
                                    : resp
                        });
                    } else console.log('Error:', action.type);
                }
            );
            break;
        }
        case 'user/setProperties': {
            const listLength = userStore.properties.length;
            sendRequest('/updateDB/properties', 'post', {
                ...action.payload,
                returnList: true
            }).then((resp) => {
                if (resp?.list?.length > 0 || resp.newId) {
                    let message;
                    let analyticsFunction;
                    switch (true) {
                        case listLength < resp.list.length:
                            message = i18next.t('create_property.l_property_saved');
                            analyticsFunction = () =>
                                analytics.trackCreateProperty(resp.newId, true);
                            break;
                        case listLength > resp.list.length:
                            message = i18next.t('create_property.l_property_deleted');
                            analyticsFunction = () =>
                                analytics.trackDeleteProperty(action.payload._id, true);
                            break;
                        default:
                            message = i18next.t('create_property.l_property_updated');
                            analyticsFunction = () =>
                                analytics.trackUpdateProperty(action.payload._id, true);
                    }

                    store.dispatch(setRequestMessage({ type: 'success', message }));
                    store.dispatch(setRequestFinished(true));
                    analyticsFunction();
                    return next({ ...action, payload: resp.list });
                } else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: i18next.t('global.e_server_error')
                        })
                    );
                    action.payload._id
                        ? action.payload.deleteflag
                            ? analytics.trackDeleteProperty(action.payload._id, false)
                            : analytics.trackUpdateProperty(action.payload._id, false)
                        : analytics.trackCreateProperty('', false);
                }
            });
            break;
        }
        case 'user/getProperties': {
            const prevProperties = userStore.properties;
            sendRequest('/queryDB/properties', 'post', {
                IDs: action.payload
            }).then((resp) => {
                if (resp) {
                    if (JSON.stringify(prevProperties) === JSON.stringify(resp)) return;
                    return next({ ...action, payload: resp });
                } else console.log('Error:', action.type);
            });
            break;
        }
        case 'user/setTaxsubjects': {
            const listLength = userStore.taxsubjects.length;
            sendRequest('/updateDB/taxsubjects', 'post', {
                ...action.payload,
                returnList: true
            }).then((resp) => {
                if (resp?.list?.length > 0 || (resp?.list?.length === 0 && resp?.newId)) {
                    let message;
                    let analyticsFunction;
                    switch (true) {
                        case listLength < resp.list.length:
                            message = i18next.t('create_tax_subject.l_tax_subject_saved');
                            analyticsFunction = () =>
                                analytics.trackCreateTaxsubject(resp.newId, true);
                            break;
                        case listLength > resp.list.length:
                            message = i18next.t('create_tax_subject.l_tax_subject_deleted');
                            analyticsFunction = () =>
                                analytics.trackDeleteTaxsubject(action.payload._id, true);
                            break;
                        default:
                            message = i18next.t('create_tax_subject.l_tax_subject_saved');
                            analyticsFunction = () =>
                                analytics.trackUpdateTaxsubject(action.payload._id, true);
                    }
                    store.dispatch(setRequestMessage({ type: 'success', message }));
                    store.dispatch(setRequestFinished(true));
                    analyticsFunction();
                    const sharedTaxsubjects = userStore.taxsubjects.filter(
                        (item) => item._owner !== action.payload?.data?._owner
                    );
                    return next({ ...action, payload: [...resp.list, ...sharedTaxsubjects] });
                } else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: i18next.t('global.e_server_error')
                        })
                    );
                    action.payload._id
                        ? action.payload.deleteflag
                            ? analytics.trackDeleteTaxsubject(action.payload._id, false)
                            : analytics.trackUpdateTaxsubject(action.payload._id, false)
                        : analytics.trackCreateTaxsubject('', false);
                }
            });
            break;
        }
        case 'user/getTaxsubjects': {
            store.dispatch(setTaxsubjectsLoading(true));
            const prevTaxsubjects = userStore.taxsubjects;
            sendRequest('/getTaxSubjects', 'get').then((resp) => {
                store.dispatch(setTaxsubjectsLoading(false));
                if (resp) {
                    if (JSON.stringify(prevTaxsubjects) === JSON.stringify(resp)) return;
                    return next({ ...action, payload: resp });
                } else console.log('Error:', action.type);
            });
            break;
        }
        case 'user/getPaymentsConfig':
            sendRequest('/getPaymentsConfig', 'get').then((resp) => {
                if (resp.data) {
                    return next({ ...action, payload: resp.data });
                } else console.log('Error:', action.type);
            });
            break;
        case 'user/getCustomLogo':
            sendRequest('/getCustomLogo', 'get').then((resp) => {
                if (resp) {
                    next({ ...action, payload: resp.customLogo });
                } else console.log('Error:', action.type);
            });
            break;
        case 'user/setScenarios':
            sendRequest('/updateDB/scenarios', 'post', {
                data: action.payload,
                returnList: false
            }).then((resp) => {
                if (resp.success) {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'success',
                            message: i18next.t('create_property.l_property_updated')
                        })
                    );
                    return;
                } else {
                    store.dispatch(setRequestFinished(true));
                    store.dispatch(
                        setRequestMessage({
                            type: 'error',
                            message: i18next.t('global.e_server_error')
                        })
                    );
                }
            });
            break;
        case 'user/getScenarios':
            if (!action.payload) return next({ ...action, payload: [] });
            sendRequest('/queryDB/scenarios', 'post', {
                IDs: action.payload
            }).then((resp) => {
                if (resp) return next({ ...action, payload: resp });
                else console.log('Error:', action.type);
            });
            break;
        case 'user/setOpenedPropertyForecast':
            next(action);
            if (action.payload && action.payload !== '' && action.payload !== 'add')
                store.dispatch(getForecastData(userStore.properties[action.payload]._id));
            return;
        case 'user/setActiveScenarios':
        case 'user/setCurrentTaxsubject':
        case 'user/resetTaxsubjects':
        case 'user/setTaxsubjectsLoading':
        case 'user/setCurrentProperties':
        case 'user/setCurrentProperty':
        case 'user/setActiveE1bData':
        case 'user/setUserId':
        case 'user/setUser':
        case 'user/openHelpDrawer':
        case 'user/closeHelpDrawer':
        case 'user/setRequestFinished':
        case 'user/setRequestMessage':
        case 'user/setShowLoading':
        case 'user/setCustomLogo':
        case 'user/resetAll':
            return next(action);
    }
};

export default userMiddleware;
