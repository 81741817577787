import React from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import ReadOnlyInput from '../../components/ReadOnlyInput';
import { formatNumber } from '../../components/NumericFormatter';
import DropdownInput from '../../components/DropdownInput';

const Expenses = ({ data, isSmallBusiness }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).expenses.objectTypeSchemaSpec;
    return (
        <>
            <LabeledInputWithSymbol
                label={t('create_forecast.l_costs_housing_management')}
                type="number"
                infoText={t('create_forecast.i_costs_housing_management')}
                symbolIcon="€"
                autoFocus
                name="l_costs_housing_management"
                rule={validationSchemas.l_costs_housing_management}
                helpText={
                    isSmallBusiness
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_costs_housing_management
                                  ? data.l_costs_housing_management -
                                        data.l_costs_housing_management / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_costs_tax_advisor')}
                type="number"
                symbolIcon="€"
                name="l_costs_tax_advisor"
                infoText={t('create_forecast.i_costs_tax_advisor')}
                rule={validationSchemas.l_costs_tax_advisor}
                helpText={
                    isSmallBusiness
                        ? t('create_forecast.l_contains_vat20') +
                          formatNumber(
                              data?.l_costs_tax_advisor
                                  ? data.l_costs_tax_advisor - data.l_costs_tax_advisor / 1.2
                                  : 0
                          ) +
                          '€'
                        : undefined
                }
            />
            <DropdownInput
                label={t('create_forecast.l_repair_costs_method')}
                alignRight
                name="l_repair_costs_method"
                dropdownOptionReady
                rule={validationSchemas.l_repair_costs_method}
                dropdownOption={[
                    { value: 0, label: t('create_forecast.l_repair_costs_method1') },
                    { value: 1, label: t('create_forecast.l_repair_costs_method2') }
                ]}
                placeholder="Bitte auswählen"
                value={data?.l_repair_costs_method}
            />
            {data?.l_repair_costs_method === 1 && (
                <ReadOnlyInput
                    label={t('create_forecast.l_maintenance_reserves')}
                    type="number"
                    infoText={t('create_forecast.i_maintenance_reserves')}
                    isIndentedInput
                    modalInputs={[
                        {
                            name: 'first_age_gap',
                            label: '1-5 Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.first_age_gap ? data.first_age_gap : 7.5
                            )
                        },
                        {
                            name: 'second_age_gap',
                            label: '6-10 Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.second_age_gap ? data.second_age_gap : 10
                            )
                        },
                        {
                            name: 'third_age_gap',
                            label: '11-15 Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.third_age_gap ? data.third_age_gap : 12
                            )
                        },
                        {
                            name: 'fourth_age_gap',
                            label: '16-20 Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.fourth_age_gap ? data.fourth_age_gap : 15
                            )
                        },
                        {
                            name: 'fifth_age_gap',
                            label: '21-30 Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.fifth_age_gap ? data.fifth_age_gap : 20
                            )
                        },
                        {
                            name: 'sixth_age_gap',
                            label: '30+ Jahre',
                            infoText: 'Tooltip',
                            defaultValue: formatNumber(
                                data?.sixth_age_gap ? data.sixth_age_gap : 25
                            )
                        }
                    ]}
                    symbolIcon={'€/m\u00B2'}
                    name="l_maintenance_reserves"
                />
            )}
            {data?.l_repair_costs_method === 0 && (
                <LabeledInputWithSymbol
                    isIndentedInput
                    label={t('create_forecast.l_maintenance_reserves')}
                    type="number"
                    symbolIcon="%"
                    name="l_maintenance_reserves_percent"
                    rule={validationSchemas.l_maintenance_reserves_percent}
                    infoText={t('create_forecast.i_maintenance_reserves')}
                />
            )}
            <LabeledInputWithSymbol
                label={t('create_forecast.l_reserves')}
                type="number"
                symbolIcon="€"
                name="l_reserves"
                rule={validationSchemas.l_reserves}
                infoText={t('create_forecast.i_reserves')}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_vacancy_rate')}
                type="number"
                symbolIcon="%"
                name="l_vacancy_rate"
                rule={validationSchemas.l_vacancy_rate}
                infoText={t('create_forecast.i_vacancy_rate')}
            />
            <LabeledInputWithSymbol
                label={t('create_forecast.l_bank_account_fees')}
                type="number"
                symbolIcon="€"
                name="l_bank_account_fees"
                rule={validationSchemas.l_bank_account_fees}
                infoText={t('create_forecast.i_bank_account_fees')}
            />
        </>
    );
};

export default Expenses;
