import React from 'react';

const ProfileSettingsContainer = ({ header, children }) => {
    return (
        <div className="profile-settings-component">
            <h3 className="page-subtitle">{header}</h3>
            {children}
        </div>
    );
};

export default ProfileSettingsContainer;
