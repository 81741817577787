import { React, useEffect, useRef, useState } from 'react';
import {
    Button,
    Container,
    Header,
    Sidenav,
    Nav,
    Stack,
    Popover,
    Whisper,
    Avatar,
    Dropdown,
    Content,
    Sidebar,
    useToaster,
    Message,
    Placeholder,
    Loader,
    Divider
} from 'rsuite';
import { useTranslation } from 'react-i18next';
import renteezyLogo from '../img/renteezy-logo-temp.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTableColumns,
    faBuildingUser,
    faChartColumn,
    faGear,
    faUser,
    faUsers,
    faRightFromBracket,
    faHammerWar,
    faTimes,
    faBars,
    faCalendarDays
} from '@fortawesome/pro-solid-svg-icons';
import sendRequest from '../utils/sendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';
import {
    setUserId,
    getTaxsubjects,
    getUser,
    setRequestFinished,
    getCustomLogo,
    closeHelpDrawer,
    setShowLoading,
    getPaymentsConfig,
    setCurrentTaxsubject,
    resetTaxsubjects
} from '../reducers/userSlice';
import Profile from './contentPages/Profile';
import Dashboard from './contentPages/Dashboard';
import TaxSubjects from './contentPages/TaxSubjects';
import Properties from './contentPages/Properties';
import ForecastCalculation from './contentPages/ForecastCalculation';
import Plans from './contentPages/Plans';
import OnboardingModal from '../components/OnboardingModal';
import SidebarLink from '../components/SidebarLink';
import AccepterModal from '../components/AccepterModal';
import constants from '../utils/constants';
import { analytics } from '../utils/analytics';
import HelpDrawer from '../components/HelpDrawer';
import AlertDialog from '../components/dialogs/AlertDialog';
import subscriptionExists from '../utils/subscriptionExists';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import E1b from './contentPages/E1b';
import Admin from './contentPages/Admin';
import Loading from './contentPages/Loading';
import analyticsConstants from '../utils/analyticsConstants';
import { TbExternalLink } from 'react-icons/tb';
import getUTCDate from '../utils/getUTCDate';

const Main = () => {
    const dispatch = useDispatch();
    const triggerRef = useRef();
    const { t } = useTranslation();
    const { inviteToken } = useParams();
    const toaster = useToaster();
    const navigate = useNavigate();
    const location = useLocation();

    const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
    const [accepterModalOpen, setAccepterModalOpen] = useState(false);

    const userId = useSelector((state) => state.user.userId);
    const user = useSelector((state) => state.user.user);
    const openDrawer = useSelector((state) => state.user.openDrawer);
    const requestFinished = useSelector((state) => state.user.requestFinished);
    const requestMessage = useSelector((state) => state.user.requestMessage);
    const customLogo = useSelector((state) => state.user.customLogo);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);
    const taxsubjectsLoading = useSelector((state) => state.user.taxsubjectsLoading);

    const showLoading = useSelector((state) => state.user.showLoading);

    const [username, setUsername] = useState('');
    const [logoSrc, setLogoSrc] = useState('');
    const [logoImgLoaded, setLogoImgLoaded] = useState(false);

    const [expand, setExpand] = useState(window.innerWidth < 1024);
    const [content, setContent] = useState(<Loading />);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [contentPadding, setContentPadding] = useState('');

    const [trialDaysRemaining, setTrialDaysRemaining] = useState(null);

    const isSubscriptionExpired = false;
    const eventSourceURL = process.env.REACT_APP_LOCAL_SERVER
        ? 'https://' +
          process.env.REACT_APP_LOCAL_SERVER +
          constants.SUBSCRIPTION_EVENTS_URL.subscription_data_change
        : constants.BACKEND_URL + constants.SUBSCRIPTION_EVENTS_URL.subscription_data_change;

    const signOut = () => {
        dispatch(resetTaxsubjects());
        setOpenConfirmDialog(false);
        sendRequest('/logout', 'post');
        dispatch(setUserId(undefined));
    };

    useEffect(() => {
        if (!userId) {
            analytics.identifyUser(null);
        } else {
            dispatch(getCustomLogo());
            analytics.identifyUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (customLogo === false) return;
        if (customLogo) {
            setLogoSrc(`data:image/png;base64, ${customLogo}`);
        } else setLogoSrc(renteezyLogo);
    }, [customLogo]);

    useEffect(() => {
        if (openDrawer.status) {
            setContentPadding('expand-padding-right');
        } else {
            setContentPadding('collapse-padding-right');
        }
    }, [openDrawer]);

    useEffect(() => {
        if (
            user?.subscriptionData?.plan_name === 'None' ||
            user?.subscriptionData?.plan_name === 'Trial' ||
            (user.userType === t(constants.USER_TYPES.TA) &&
                getUTCDate(user?.trialEndDate) > getUTCDate())
        ) {
            const currentDate = getUTCDate();
            const endDate = getUTCDate(user.trialEndDate);
            const timeDiff = endDate - currentDate;
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            setTrialDaysRemaining(daysDiff < 0 ? 0 : daysDiff);
        }
    }, [user]);

    useEffect(() => {
        dispatch(getPaymentsConfig());
    }, [dispatch, getPaymentsConfig]);

    useEffect(() => {
        if (requestFinished) {
            let message = (
                <Message showIcon type={requestMessage?.type} closable>
                    {requestMessage?.message}
                </Message>
            );
            toaster.push(message, { duration: constants.MESSAGE_DURATION });
            dispatch(setRequestFinished(false));
        }
    }, [requestFinished]);

    useEffect(() => {
        if (!userId) return;
        dispatch(getUser());
    }, [getUser]);

    useEffect(() => {
        if (!user) return;
        setOnboardingModalOpen(user?.onboardingPassed === false);
        setUsername(user?.firstname + ' ' + user?.lastname);
    }, [user]);

    useEffect(() => {
        if (!userId) return;
        dispatch(getTaxsubjects());
    }, [dispatch, getTaxsubjects, userId]);

    useEffect(() => {
        if (inviteToken) {
            if (!userId) {
                let message = (
                    <Message showIcon type="info" closable>
                        {t('invitation.l_login_before_invite_accept')}
                    </Message>
                );
                toaster.push(message, { duration: constants.MESSAGE_DURATION });
                return;
            }
            setAccepterModalOpen(true);
            return;
        }
    }, []);

    useEffect(() => {
        setOpenConfirmDialog(false);
        if (!user || Object.keys(user).length === 0 || taxsubjectsLoading) {
            setContent(<Loading />);
            return;
        }
        dispatch(closeHelpDrawer());
        if (window.innerWidth <= 820) setExpand(false);
        else
            setExpand(
                location.pathname !== constants.ROUTES.TAXSUBJECTS.CREATE &&
                    location.pathname !== constants.ROUTES.TAXSUBJECTS.EDIT &&
                    location.pathname !== constants.ROUTES.PROPERTIES.CREATE &&
                    location.pathname !== constants.ROUTES.PROPERTIES.EDIT &&
                    location.pathname !== constants.ROUTES.FORECAST_CALCULATION.HOME &&
                    location.pathname !== constants.ROUTES.FORECAST_CALCULATION.FORECAST &&
                    location.pathname !== constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA
            );
        switch (location.pathname) {
            case constants.ROUTES.PROFILE:
                setContent(<Profile />);
                triggerRef.current && triggerRef.current.close();
                break;
            case constants.ROUTES.HOME:
                if (taxsubjects.filter((taxsubject) => taxsubject?.isTest === false).length > 0) {
                    dispatch(setCurrentTaxsubject(taxsubjects[0]));
                    navigate(constants.ROUTES.TAXSUBJECTS.DASHBOARD);
                } else {
                    setContent(<Dashboard />);
                }
                break;
            case constants.ROUTES.TAXSUBJECTS.LIST:
            case constants.ROUTES.TAXSUBJECTS.DASHBOARD:
            case constants.ROUTES.TAXSUBJECTS.CREATE:
            case constants.ROUTES.TAXSUBJECTS.EDIT:
                setContent(<TaxSubjects />);
                break;
            case constants.ROUTES.PROPERTIES.LIST:
            case constants.ROUTES.PROPERTIES.DASHBOARD:
            case constants.ROUTES.PROPERTIES.CREATE:
            case constants.ROUTES.PROPERTIES.EDIT:
                setContent(<Properties />);
                break;
            case constants.ROUTES.FORECAST_CALCULATION.HOME:
            case constants.ROUTES.FORECAST_CALCULATION.FORECAST:
            case constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA:
                setContent(<ForecastCalculation />);
                break;
            case constants.ROUTES.ADMIN:
                user?.userType === constants.ADMIN
                    ? setContent(<Admin />)
                    : navigate(constants.ROUTES.HOME);
                triggerRef.current && triggerRef.current.close();
                break;
            case constants.ROUTES.E1B:
                setContent(<E1b />);
                break;
            case constants.ROUTES.PLANS:
                setContent(<Plans />);
                break;
        }
    }, [setExpand, setContent, location.pathname, user, taxsubjects, taxsubjectsLoading]);

    useEffect(() => {
        if (!userId || location.pathname !== constants.ROUTES.PLANS) return;
        let eventSource = new EventSource(eventSourceURL, { withCredentials: true });
        eventSource.addEventListener('ping', () => {});
        eventSource.onopen = () => {};
        eventSource.onerror = (e) => {
            console.debug('SSE error:', e);
            eventSource.close();
        };

        eventSource.onmessage = () => {
            dispatch(setShowLoading(false));
            dispatch(getUser());
            return () => {
                eventSource.close();
            };
        };
    }, [location.pathname]);

    if (!userId) return <Navigate replace to="/login" />;
    else
        return (
            <Container className="main-container">
                <OnboardingModal
                    opened={onboardingModalOpen}
                    setOpenConfirmDialog={setOpenConfirmDialog}
                />
                <ConfirmDialog
                    openDialog={
                        location.pathname !== constants.ROUTES.PLANS &&
                        location.pathname !== constants.ROUTES.PROFILE &&
                        (user?.subscriptionData?.plan_name === 'None' ||
                            (user.userType === t(constants.USER_TYPES.TA) &&
                                !user?.subscriptionData?.subscription_id &&
                                getUTCDate(user?.trialEndDate) < getUTCDate()))
                    }
                    titleLabel={
                        user.userType === t(constants.USER_TYPES.TA)
                            ? t('global.h_finish_plan_purchase')
                            : t('global.h_plan_inactive')
                    }
                    onOkClicked={() => navigate(constants.ROUTES.PLANS)}
                    onCancelClicked={() => setOpenConfirmDialog(true)}
                    cancelBtnText={t('global.n_sign_out')}
                    okBtnText={
                        user.userType === t(constants.USER_TYPES.TA)
                            ? t('global.l_purchase')
                            : t('list_property.l_plan_overiew')
                    }
                    backdrop="static"
                    keyboard={false}>
                    <p>
                        {user.userType === t(constants.USER_TYPES.TA)
                            ? t('global.l_finish_plan_purchase')
                            : t('global.l_trial_over')}
                    </p>
                </ConfirmDialog>
                <AccepterModal
                    token={inviteToken}
                    open={accepterModalOpen}
                    setOpen={setAccepterModalOpen}
                />
                <AlertDialog
                    openDialog={openConfirmDialog}
                    titleLabel={t('sign_out_dialog.h_sign_out')}
                    okBtnText={t('global.n_sign_out')}
                    onOkClicked={signOut}
                    onCancelClicked={() => setOpenConfirmDialog(false)}>
                    <p>{t('sign_out_dialog.l_sign_out_confirmation_message')}</p>
                </AlertDialog>
                <Header>
                    <div className="hamburger" onClick={() => setExpand(!expand)}>
                        <FontAwesomeIcon class="rs-icon" icon={expand ? faTimes : faBars} />
                    </div>

                    {logoSrc ? (
                        <Stack className="header-logo-container" spacing={10}>
                            <Stack.Item className="header-logo">
                                <img
                                    src={logoSrc}
                                    alt="logo"
                                    onClick={() => {
                                        analytics.trackClick('logo', 'header');
                                        navigate(constants.ROUTES.HOME);
                                    }}
                                    onError={() => {
                                        setLogoSrc(renteezyLogo);
                                    }}
                                    onLoad={() => setLogoImgLoaded(true)}
                                />
                            </Stack.Item>
                            {logoImgLoaded && logoSrc !== renteezyLogo && (
                                <Stack.Item className="header-logo-text" alignSelf="flex-end">
                                    {' '}
                                    {t('global.l_powered_by')}
                                    <img
                                        src={renteezyLogo}
                                        className="renteezy-logo"
                                        alt="renteezy logo"
                                    />{' '}
                                </Stack.Item>
                            )}
                        </Stack>
                    ) : (
                        <Placeholder.Paragraph graph="image" active />
                    )}

                    <div>
                        {window.innerWidth > 1024 &&
                            (user?.subscriptionData?.plan_name === 'Trial' ||
                                (user.userType === t(constants.USER_TYPES.TA) &&
                                    getUTCDate(user?.trialEndDate) > getUTCDate())) && (
                                <span>
                                    {t('global.l_days_left_in_trial').replace(
                                        '{{days}}',
                                        trialDaysRemaining
                                    )}
                                </span>
                            )}

                        {user?.userType !== t(constants.USER_TYPES.TA) &&
                            user?.subscriptionData &&
                            !subscriptionExists(user?.subscriptionData.plan_name) && (
                                <Button
                                    appearance="primary"
                                    style={{ marginLeft: '1rem' }}
                                    onClick={() => {
                                        analytics.trackClick(
                                            'upgrade_plan',
                                            analyticsConstants.CATEGORIES.USERS
                                        );
                                        navigate(constants.ROUTES.PLANS);
                                    }}>
                                    {t('plan_overview.l_upgrade')}
                                </Button>
                            )}
                    </div>

                    <Stack spacing={window.innerWidth > 1200 ? 50 : 0} className="cursor-pointer">
                        <Stack.Item>
                            <Whisper
                                trigger="click"
                                placement="autoVerticalEnd"
                                ref={triggerRef}
                                onClick={() => {
                                    analytics.trackClick(
                                        'user_menu',
                                        analyticsConstants.CATEGORIES.USERS
                                    );
                                }}
                                speaker={
                                    <Popover>
                                        <Dropdown.Menu>
                                            <Dropdown.Item className="signed-in-as" disabled>
                                                {t('global.l_signed_in_as')}
                                                <br />
                                                <b> {username}</b>
                                            </Dropdown.Item>
                                            <Dropdown.Separator />
                                            {user && user.userType === constants.ADMIN && (
                                                <Dropdown.Item
                                                    icon={
                                                        <FontAwesomeIcon
                                                            class="rs-icon"
                                                            icon={faHammerWar}
                                                        />
                                                    }
                                                    onClick={() => {
                                                        analytics.trackClick(
                                                            'admin_menu',
                                                            analyticsConstants.CATEGORIES.USERS
                                                        );

                                                        navigate(constants.ROUTES.ADMIN);
                                                    }}>
                                                    {t('global.n_admin')}
                                                </Dropdown.Item>
                                            )}
                                            <Dropdown.Item
                                                icon={
                                                    <FontAwesomeIcon
                                                        class="rs-icon"
                                                        icon={faGear}
                                                    />
                                                }
                                                onClick={() => {
                                                    analytics.trackClick(
                                                        'profile_menu',
                                                        analyticsConstants.CATEGORIES.USERS
                                                    );
                                                    navigate(constants.ROUTES.PROFILE);
                                                }}>
                                                {t('global.n_settings')}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                icon={
                                                    <FontAwesomeIcon
                                                        class="rs-icon"
                                                        icon={faRightFromBracket}
                                                    />
                                                }
                                                onClick={() => {
                                                    analytics.trackClick(
                                                        'signout_menu',
                                                        analyticsConstants.CATEGORIES.USERS
                                                    );
                                                    setOpenConfirmDialog(true);
                                                }}>
                                                {t('global.n_sign_out')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Popover>
                                }>
                                <Avatar size="sm" circle>
                                    <FontAwesomeIcon class="rs-icon" icon={faUser} />
                                </Avatar>
                            </Whisper>
                        </Stack.Item>
                    </Stack>
                </Header>
                <Container>
                    <Sidebar>
                        <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
                            <Sidenav.Body>
                                <Nav activeKey={location.pathname} onSelect={navigate}>
                                    <Nav.Item
                                        eventKey={
                                            taxsubjects.filter(
                                                (taxsubject) => taxsubject?.isTest === false
                                            ).length > 0
                                                ? constants.ROUTES.TAXSUBJECTS.DASHBOARD
                                                : constants.ROUTES.HOME
                                        }
                                        icon={
                                            <FontAwesomeIcon
                                                class="rs-icon"
                                                icon={faTableColumns}
                                            />
                                        }>
                                        {t('global.n_dashboard')}
                                    </Nav.Item>
                                    <Nav.Item
                                        disabled={isSubscriptionExpired}
                                        eventKey={constants.ROUTES.TAXSUBJECTS.LIST}
                                        icon={<FontAwesomeIcon class="rs-icon" icon={faUsers} />}>
                                        {t('global.n_tax_subjects')}
                                    </Nav.Item>
                                    <Nav.Item
                                        disabled={isSubscriptionExpired}
                                        eventKey={constants.ROUTES.PROPERTIES.LIST}
                                        icon={
                                            <FontAwesomeIcon
                                                class="rs-icon"
                                                icon={faBuildingUser}
                                            />
                                        }>
                                        {t('global.n_properties')}
                                    </Nav.Item>
                                    <Nav.Item
                                        disabled={isSubscriptionExpired}
                                        eventKey={constants.ROUTES.FORECAST_CALCULATION.HOME}
                                        icon={
                                            <FontAwesomeIcon class="rs-icon" icon={faChartColumn} />
                                        }>
                                        {t('global.n_forecast_calculations')}
                                    </Nav.Item>
                                    <Divider className="sidebar-divider" />
                                    <Nav.Item
                                        className="sidebar-item-with-link"
                                        target="_blank"
                                        href="https://renteezy.webinargeek.com/"
                                        icon={
                                            <FontAwesomeIcon
                                                class="rs-icon"
                                                icon={faCalendarDays}
                                            />
                                        }>
                                        {t('global.n_webinar')}
                                        <TbExternalLink
                                            size="1.2em"
                                            data-testid="external-link-icon"
                                        />
                                    </Nav.Item>
                                    <Nav.Item
                                        className="sidebar-item-with-link"
                                        target="_blank"
                                        href="https://www.renteezy.at/blog"
                                        icon={
                                            <svg
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="calendar-days"
                                                className="rs-icon"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M162.4 196c4.8-4.9 6.2-5.1 36.4-5.1 27.2 0 28.1 .1 32.1 2.1 5.8 2.9 8.3 7 8.3 13.6 0 5.9-2.4 10-7.6 13.4-2.8 1.8-4.5 1.9-31.1 2.1-16.4 .1-29.5-.2-31.5-.8-10.3-2.9-14.1-17.7-6.6-25.3zm61.4 94.5c-53.9 0-55.8 .2-60.2 4.1-3.5 3.1-5.7 9.4-5.1 13.9 .7 4.7 4.8 10.1 9.2 12 2.2 1 14.1 1.7 56.3 1.2l47.9-.6 9.2-1.5c9-5.1 10.5-17.4 3.1-24.4-5.3-4.7-5-4.7-60.4-4.7zm223.4 130.1c-3.5 28.4-23 50.4-51.1 57.5-7.2 1.8-9.7 1.9-172.9 1.8-157.8 0-165.9-.1-172-1.8-8.4-2.2-15.6-5.5-22.3-10-5.6-3.8-13.9-11.8-17-16.4-3.8-5.6-8.2-15.3-10-22C.1 423 0 420.3 0 256.3 0 93.2 0 89.7 1.8 82.6 8.1 57.9 27.7 39 53 33.4c7.3-1.6 332.1-1.9 340-.3 21.2 4.3 37.9 17.1 47.6 36.4 7.7 15.3 7-1.5 7.3 180.6 .2 115.8 0 164.5-.7 170.5zm-85.4-185.2c-1.1-5-4.2-9.6-7.7-11.5-1.1-.6-8-1.3-15.5-1.7-12.4-.6-13.8-.8-17.8-3.1-6.2-3.6-7.9-7.6-8-18.3 0-20.4-8.5-39.4-25.3-56.5-12-12.2-25.3-20.5-40.6-25.1-3.6-1.1-11.8-1.5-39.2-1.8-42.9-.5-52.5 .4-67.1 6.2-27 10.7-46.3 33.4-53.4 62.4-1.3 5.4-1.6 14.2-1.9 64.3-.4 62.8 0 72.1 4 84.5 9.7 30.7 37.1 53.4 64.6 58.4 9.2 1.7 122.2 2.1 133.7 .5 20.1-2.7 35.9-10.8 50.7-25.9 10.7-10.9 17.4-22.8 21.8-38.5 3.2-10.9 2.9-88.4 1.7-93.9z"
                                                />
                                            </svg>
                                        }>
                                        {t('global.n_blog')}
                                        <TbExternalLink
                                            size="1.2em"
                                            data-testid="external-link-icon"
                                        />
                                    </Nav.Item>
                                </Nav>
                                {expand && (
                                    <Nav className="linksContainer">
                                        <SidebarLink
                                            linkTo="https://www.renteezy.at/nutzungsbedingungen"
                                            text={t('global.l_terms_of_service')}
                                        />
                                        <SidebarLink
                                            linkTo="https://www.renteezy.at/datenschutzbestimmungen"
                                            text={t('global.l_privacy_policy')}
                                        />
                                        <SidebarLink
                                            linkTo="https://www.renteezy.at/impressum"
                                            text={t('global.l_imprint')}
                                        />
                                    </Nav>
                                )}
                            </Sidenav.Body>
                            <Sidenav.Toggle
                                expanded={expand}
                                onToggle={(expanded) => setExpand(expanded)}
                            />
                        </Sidenav>
                    </Sidebar>
                    <Content
                        className={
                            contentPadding +
                            ' ' +
                            ([
                                constants.ROUTES.FORECAST_CALCULATION.HOME,
                                constants.ROUTES.FORECAST_CALCULATION.FORECAST,
                                constants.ROUTES.FORECAST_CALCULATION.HISTORICAL_DATA
                            ].includes(location.pathname)
                                ? 'forecast'
                                : '')
                        }>
                        {content}
                    </Content>
                </Container>
                <HelpDrawer />
                {showLoading && <Loader backdrop content="loading..." vertical size="lg" />}
            </Container>
        );
};

export default Main;
