import React from 'react';
import logo from '../img/renteezy-logo-temp.png';
import authentificationBG from '../img/authentication-bg.png';

const LoginContainer = ({ children, footer }) => {
    return (
        <div className="login-bg">
            <img src={authentificationBG} className="auth-bg" alt="auth-bg" />
            <div className="form-container">
                <img src={logo} className="logo" alt="logo" />
                {children}
            </div>
            {footer}
        </div>
    );
};

export default LoginContainer;
