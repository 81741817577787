import React, { useState, useEffect } from 'react';
import { Stack, Checkbox, Placeholder, Form, SelectPicker } from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import {
    setActiveE1bData,
    getForecastData,
    setRequestFinished,
    setRequestMessage,
    openHelpDrawer,
    setProperties,
    setCurrentProperty
} from '../../reducers/userSlice';
import { useTranslation, Trans } from 'react-i18next';
import ContentPageBase from '../../components/ContentPageBase';
import NavigationFooter from '../../components/NavigationFooter';
import E1bSection from '../../components/E1bSection';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import InputWithLabelOnTop from '../../components/InputWithLabelOnTop';
import DropdownInput from '../../components/DropdownInput';
import sendRequest from '../../utils/sendRequest';
import { useNavigate } from 'react-router-dom';
import constants from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import getUTCDate from '../../utils/getUTCDate';

const E1b = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const activeE1bData = useSelector((state) => state.user.activeE1bData);
    const forecastData = useSelector((state) => state.user.forecastData);
    const taxsubjects = useSelector((state) => state.user.taxsubjects);

    const [taxsubject, setTaxsubject] = useState(null);
    const [propertyForecastData, setPropertyForecastData] = useState(null);
    const [propertyForecastCalculation, setPropertyForecastCalculation] = useState(null);

    const [property, setProperty] = useState(activeE1bData?.property);
    const [selectedYear, setSelectedYear] = useState(
        activeE1bData?.property?.historicalData?.[activeE1bData?.year]
    );
    const [yearDropdownOptions, setYearDropdownOptions] = useState([]);

    const currentYear = getUTCDate().getUTCFullYear();

    const [useBruttoSystem, setUseBruttoSystem] = useState(false);
    const [distributionYears, setDistributionYears] = useState(0);
    const [valueFor9460, setValueFor9460] = useState('');
    const [valueFor9470, setValueFor9470] = useState('');
    const [valueFor9480, setValueFor9480] = useState('');
    const [valueFor9490, setValueFor9490] = useState('');
    const [valueFor9500, setValueFor9500] = useState('');
    const [valueFor9134, setValueFor9134] = useState('');
    const [valueFor9135, setValueFor9135] = useState('');
    const [valueFor9505, setValueFor9505] = useState('');
    const [valueFor9510, setValueFor9510] = useState('');
    const [valueFor9520, setValueFor9520] = useState('');
    const [valueFor9530, setValueFor9530] = useState('');
    const [totalCosts, setTotalCosts] = useState(0);
    const [excessCosts, setExcessCosts] = useState(0);

    const [loaded, setLoaded] = useState(false);

    const closePage = () => {
        navigate(-1);
    };

    const extractVAT = (value) => {
        return value - value / 1.2;
    };

    const pageInfoText = () => ({
        title: <Trans i18nKey="e1b.h_e1b_heading" components={{ span: <span /> }} />,
        message: t('e1b.i_e1b_heading')
    });

    useEffect(() => {
        dispatch(openHelpDrawer(pageInfoText()));
        return () => {
            dispatch(setActiveE1bData(null));
        };
    }, []);

    useEffect(() => {
        setLoaded(false);
        if (!activeE1bData || !activeE1bData.property || !activeE1bData.year) {
            dispatch(setRequestFinished(true));
            dispatch(
                setRequestMessage({
                    type: 'error',
                    message: 'Error: Error while loading historical data.'
                })
            );
            closePage();
        }
        setProperty(activeE1bData?.property);
        setSelectedYear(activeE1bData?.property?.historicalData?.[activeE1bData?.year]);
        const historicalDataYears = Object.keys(activeE1bData?.property.historicalData);
        const yearDropdownOptions = historicalDataYears.map((year) => {
            return { value: year, label: year };
        });
        setYearDropdownOptions(yearDropdownOptions);
        dispatch(getForecastData(activeE1bData?.property?._id));

        const taxsubject = taxsubjects.find((item) => item._id === property?._owner);
        setTaxsubject(taxsubject);
    }, [activeE1bData]);

    useEffect(() => {
        if (!forecastData) return;
        setPropertyForecastData(forecastData.forecastsData);
        if (!property || !taxsubject) return;
        sendRequest('/forecastCalculations', 'post', {
            ID: property._id,
            isSmallBusiness: taxsubject?.taxsubjectsData?.l_small_business_scheme,
            tax_rate: taxsubject?.taxsubjectsData?.tax_progression,
            preset: constants.CALCULATION_PRESETS.E1B,
            last_year: activeE1bData?.year
        }).then((resp) => {
            if (resp?.error) {
                dispatch(setRequestFinished(true));
                dispatch(
                    setRequestMessage({
                        type: 'error',
                        message: t('global.e_server_error')
                    })
                );
                closePage();
            } else {
                setPropertyForecastCalculation(resp.result[0].Prognoserechnung);
            }
        });
    }, [forecastData]);

    useEffect(() => {
        setLoaded(false);
        if (!propertyForecastCalculation) return;
        const forecastCalculationForSelectedYear =
            propertyForecastCalculation[propertyForecastCalculation.length - 1];

        // GET VALUES
        if (taxsubject?.taxsubjectsData?.l_small_business_scheme) {
            setUseBruttoSystem(true);
        }
        // get value for 9460
        let localValueFor9460 = selectedYear?.l_yearly_rent;
        setValueFor9460(localValueFor9460);

        // get value for 9470
        let localValueFor9470 = 0;
        if (activeE1bData?.year < currentYear - 15) {
            Object.keys(property?.historicalData).forEach((year) => {
                if (+year < currentYear - 15)
                    localValueFor9470 +=
                        +property.historicalData[year].l_expenses_paragraph_28_2 / 15;
            });
            setValueFor9470(localValueFor9470);
        }

        // get value for 9480
        let localValueFor9480 = 0;
        let local9480WithoutDistribution = 0;
        if (
            selectedYear?.l_maintenance_expenses_period &&
            +selectedYear.l_maintenance_expenses_period > 0
        ) {
            localValueFor9480 =
                +selectedYear.l_expenses_paragraph_28_3 /
                +selectedYear.l_maintenance_expenses_period;
            setDistributionYears(+selectedYear.l_maintenance_expenses_period);
        } else {
            local9480WithoutDistribution = +selectedYear?.l_expenses_paragraph_28_3;
        }
        setValueFor9480(localValueFor9480);

        //get value for 9490
        let localValueFor9490 = 0;
        if (activeE1bData?.year < currentYear - 10) {
            Object.keys(property?.historicalData).forEach((year) => {
                if (+year < currentYear - 10)
                    localValueFor9490 +=
                        +property.historicalData[year].l_expenses_paragraph_28_4 / 15;
            });
            setValueFor9490(localValueFor9490);
        }

        // get value for 9500
        let localValueFor9500 =
            forecastCalculationForSelectedYear.yearly_depreciation_full +
            local9480WithoutDistribution * (+propertyForecastData.l_depreciation / 100);
        if (forecastCalculationForSelectedYear.yearly_depreciation_full_garage) {
            localValueFor9500 += forecastCalculationForSelectedYear.yearly_depreciation_full_garage;
        }
        if (forecastCalculationForSelectedYear.yearly_depreciation_full_kitchen) {
            localValueFor9500 +=
                forecastCalculationForSelectedYear.yearly_depreciation_full_kitchen;
        }
        setValueFor9500(localValueFor9500);

        // get value for 9134
        // TBD -> unknown value at the moment
        let localValueFor9134 = 0;
        localValueFor9134 > 0 && setValueFor9134(localValueFor9134);

        // get value for 9135
        let localValueFor9135 = 0;
        if (
            propertyForecastData.l_accelerated_depreciation &&
            propertyForecastData.l_accelerated_depreciation === true
        ) {
            localValueFor9135 =
                activeE1bData?.year ==
                getUTCDate(propertyForecastData.l_rent_start_date).getUTCFullYear()
                    ? 2 * forecastCalculationForSelectedYear.yearly_depreciation_full
                    : forecastCalculationForSelectedYear.yearly_depreciation_full;
            setValueFor9135(localValueFor9135);
        }

        // get value for 9505
        // TBD -> unknown value at the moment
        let localValueFor9505 = 0;
        localValueFor9505 > 0 && setValueFor9505(localValueFor9505);

        // get value for 9510
        let localValueFor9510 =
            +selectedYear?.l_interest_expense +
            +selectedYear?.l_special_repayments +
            +selectedYear?.l_ancillary_financing_costs_misc;
        setValueFor9510(localValueFor9510);

        // get value for 9520
        let localValueFor9520 = +selectedYear?.l_immediately_deductables;
        setValueFor9520(localValueFor9520);

        // get value for 9530
        let localValueFor9530 = 0;
        if (taxsubject?.taxsubjectsData?.l_small_business_scheme) {
            localValueFor9530 += local9480WithoutDistribution;
            const purchaseVatAmount = extractVAT(+propertyForecastData.l_purchase_price);
            localValueFor9530 += purchaseVatAmount;
            selectedYear?.l_expenses_paragraph_28_2 &&
                (localValueFor9530 += extractVAT(+selectedYear?.l_expenses_paragraph_28_2));
            selectedYear?.l_expenses_paragraph_28_3 &&
                (localValueFor9530 += extractVAT(+selectedYear?.l_expenses_paragraph_28_3));
            selectedYear?.l_expenses_paragraph_28_4 &&
                (localValueFor9530 += extractVAT(+selectedYear?.l_expenses_paragraph_28_4));

            setValueFor9530(localValueFor9530);
        }

        // get value for Total Costs
        const localTotalCosts =
            localValueFor9470 +
            localValueFor9480 +
            localValueFor9490 +
            localValueFor9500 +
            localValueFor9134 +
            localValueFor9135 +
            localValueFor9505 +
            localValueFor9510 +
            localValueFor9520 +
            localValueFor9530;
        setTotalCosts(localTotalCosts);

        // get value for Excess Costs
        const localExcessCosts = localTotalCosts - localValueFor9460;
        setExcessCosts(localExcessCosts);

        setLoaded(true);
    }, [propertyForecastCalculation]);

    const submitE1b = () => {
        let updatedData = {
            ...property,
            historicalData: {
                ...property.historicalData,
                [activeE1bData.year]: {
                    ...property.historicalData[activeE1bData.year],
                    e1bSubmitted: true
                }
            }
        };
        dispatch(
            setProperties({
                data: updatedData,
                IDs: taxsubjects.map((taxSubject) => taxSubject._id)
            })
        );
        dispatch(setCurrentProperty(updatedData));
        setProperty(updatedData);
    };

    return (
        <ContentPageBase
            footer={
                <NavigationFooter
                    secondLabel={
                        property?.historicalData?.[activeE1bData.year]?.e1bSubmitted
                            ? undefined
                            : t('e1b.l_submitted_to_authorities')
                    }
                    firstLabel={t('global.cancel')}
                    secondFunction={submitE1b}
                    firstFunction={closePage}
                />
            }>
            <Stack className="e1b-heading">
                <Stack.Item>
                    <h2>
                        <Trans i18nKey="e1b.h_e1b_heading" components={{ span: <span /> }} />
                    </h2>
                </Stack.Item>
                <Stack.Item>
                    <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="info-icon"
                        onClick={() => dispatch(openHelpDrawer(pageInfoText()))}
                    />
                </Stack.Item>
            </Stack>
            <Stack justifyContent="flex-end" className="year-selector">
                {loaded && yearDropdownOptions.length > 0 && (
                    <Stack.Item>
                        <label>Für das Jahr: </label>
                        <SelectPicker
                            cleanable={false}
                            searchable={false}
                            data={yearDropdownOptions}
                            value={activeE1bData.year}
                            onChange={(value) =>
                                dispatch(setActiveE1bData({ ...activeE1bData, year: value }))
                            }
                        />
                    </Stack.Item>
                )}
            </Stack>
            <Form>
                <E1bSection title={t('e1b.h_property')} hasBottomMargin>
                    <div className="property-section">
                        <Stack spacing={20} className="property-section-row">
                            <Stack.Item>
                                <InputWithLabelOnTop
                                    label={t('e1b.l_zip_code')}
                                    value={property?.propertiesData?.l_zip}
                                    readOnly
                                />
                            </Stack.Item>
                            <Stack.Item grow={1}>
                                <InputWithLabelOnTop
                                    label={t('e1b.l_city')}
                                    value={property?.propertiesData?.l_city}
                                    readOnly
                                />
                            </Stack.Item>
                        </Stack>
                        <div className="property-section-row">
                            <InputWithLabelOnTop
                                label={t('e1b.l_street')}
                                value={property?.propertiesData?.l_street}
                                readOnly
                            />
                        </div>
                        <Stack spacing={20} className="property-section-row">
                            <Stack.Item flex={1}>
                                <InputWithLabelOnTop
                                    label={t('e1b.l_house_number')}
                                    value={property?.propertiesData?.l_house_number}
                                    readOnly
                                />
                            </Stack.Item>
                            {property?.propertiesData?.l_stair && (
                                <Stack.Item flex={1}>
                                    <InputWithLabelOnTop
                                        label={t('e1b.l_block')}
                                        value={property?.propertiesData?.l_stair}
                                        readOnly
                                    />
                                </Stack.Item>
                            )}
                            {property?.propertiesData?.l_door_number && (
                                <Stack.Item flex={1}>
                                    <InputWithLabelOnTop
                                        label={t('e1b.l_door_number')}
                                        value={property?.propertiesData?.l_door_number}
                                        readOnly
                                    />
                                </Stack.Item>
                            )}
                            <Stack.Item flex={1}>
                                <InputWithLabelOnTop
                                    label={t('e1b.l_country')}
                                    value="Österreich"
                                    readOnly
                                />
                            </Stack.Item>
                        </Stack>
                    </div>
                </E1bSection>

                {loaded ? (
                    <>
                        <E1bSection infoText={t('e1b.i_vat_system')}>
                            <Stack spacing={20}>
                                <Stack.Item flex={1}>
                                    <Checkbox defaultChecked={useBruttoSystem} readOnly>
                                        {t('e1b.l_vat_brutto_system')}
                                    </Checkbox>
                                </Stack.Item>
                                <Stack.Item flex={1}>
                                    <Checkbox defaultChecked={!useBruttoSystem} readOnly>
                                        {t('e1b.l_vat_net_system')}
                                    </Checkbox>
                                </Stack.Item>
                            </Stack>
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_double_taxation')} isDisabled>
                            <Checkbox defaultChecked={false} readOnly>
                                {t('e1b.l_double_taxation')}
                            </Checkbox>
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9030')} isDisabled hasBottomMargin>
                            <LabeledInputWithSymbol
                                label={<Trans i18nKey="e1b.l_9030" components={{ b: <b /> }} />}
                                name=""
                                addon="9030"
                                symbolIcon="€"
                                readOnly={true}
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection
                            title={t('e1b.h_first_time_rent')}
                            isDisabled
                            infoText={t('e1b.i_9047')}
                            titleInfoText={t('e1b.i_first_time_rent')}>
                            <LabeledInputWithSymbol
                                label={
                                    <Trans
                                        i18nKey="e1b.l_9407"
                                        components={{ br: <br />, sup: <sup />, b: <b /> }}
                                    />
                                }
                                name=""
                                addon="9407"
                                symbolIcon="€"
                                hideInfoIcon
                                readOnly={true}
                                hasCheckbox
                            />
                        </E1bSection>
                        <E1bSection isDisabled>
                            <div style={{ width: '100%' }}>
                                <LabeledInputWithSymbol
                                    label={
                                        <Trans
                                            i18nKey="e1b.l_9409"
                                            components={{ br: <br />, b: <b /> }}
                                        />
                                    }
                                    addon="9409"
                                    symbolIcon="€"
                                    infoText={t('e1b.i_9409')}
                                    readOnly={true}
                                    hideInfoIcon
                                    hasCheckbox
                                />
                                <LabeledInputWithSymbol
                                    label={t('e1b.l_9409_b')}
                                    value=""
                                    symbolIcon="%"
                                    infoText={t('e1b.i_9409_a')}
                                    readOnly={true}
                                    hideInfoIcon
                                />
                            </div>
                        </E1bSection>
                        <E1bSection isDisabled hasBottomMargin>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9410')}
                                addon="9410"
                                symbolIcon="€"
                                infoText={t('e1b.i_9410')}
                                readOnly={true}
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection
                            isDisabled
                            infoText={t('e1b.i_resumption_of_renting_a')}
                            title={t('e1b.h_resumption_of_renting')}>
                            <div style={{ width: '100%' }}>
                                <p className="e1b-section-subtitle">
                                    {t('e1b.l_resumption_of_renting_a')}
                                </p>
                                <Checkbox defaultChecked={false} readOnly>
                                    {t('e1b.l_resumption_of_renting_a1')}
                                </Checkbox>
                                <Checkbox defaultChecked={false} readOnly>
                                    {t('e1b.l_resumption_of_renting_a2')}
                                </Checkbox>
                            </div>
                        </E1bSection>
                        <E1bSection isDisabled infoText={t('e1b.i_resumption_of_renting_b')}>
                            <div style={{ width: '100%' }}>
                                <p className="e1b-section-subtitle">
                                    {t('e1b.l_resumption_of_renting_b')}
                                </p>
                                <Checkbox defaultChecked={false} readOnly>
                                    {t('e1b.l_resumption_of_renting_b1')}
                                </Checkbox>
                                <p>{t('e1b.l_9416')}</p>
                            </div>
                        </E1bSection>
                        <E1bSection isDisabled infoText={t('e1b.i_resumption_of_renting_c')}>
                            <DropdownInput
                                label={t('e1b.l_resumption_of_renting_c')}
                                alignRight
                                disabled
                                hasCheckbox
                                readOnly
                                dropdownOption={['Ja', 'Nein']}
                                value="Nein"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection
                            title={t('e1b.h_maintenance_expenses')}
                            infoText={t('e1b.i_maintenance_expenses')}>
                            <LabeledInputWithSymbol
                                label={<Trans i18nKey="e1b.l_9430" components={{ br: <br /> }} />}
                                addon="9430"
                                value={selectedYear?.l_expenses_paragraph_28_2}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hasCheckbox
                                checkboxChecked={selectedYear?.l_distribution_of_expenses_28_2}
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9440')}>
                            <div style={{ width: '100%' }}>
                                <LabeledInputWithSymbol
                                    label={
                                        <Trans i18nKey="e1b.l_9440" components={{ br: <br /> }} />
                                    }
                                    addon="9440"
                                    value={selectedYear?.l_expenses_paragraph_28_3}
                                    symbolIcon="€"
                                    readOnly={true}
                                    type="number"
                                    hasCheckbox
                                    checkboxChecked={
                                        selectedYear?.l_distribution_of_l_maintenance_expenses_period
                                    }
                                    hideInfoIcon
                                />
                                <LabeledInputWithSymbol
                                    label={t('e1b.l_9440_duration')}
                                    value={distributionYears}
                                    symbolIcon="Jahre"
                                    readOnly={true}
                                    type="number"
                                    hideInfoIcon
                                />
                            </div>
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9450')}>
                            <LabeledInputWithSymbol
                                label={<Trans i18nKey="e1b.l_9450" components={{ br: <br /> }} />}
                                addon="9450"
                                value={selectedYear?.l_expenses_paragraph_28_4}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hasCheckbox
                                checkboxChecked={selectedYear?.l_distribution_of_expenses_28_4}
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection
                            infoText={t('e1b.i_9460')}
                            title={t('e1b.h_determination_of_income')}
                            titleInfoText={t('e1b.i_determination_of_income')}
                            hasBoldLabel>
                            <LabeledInputWithSymbol
                                label={<Trans i18nKey="e1b.l_9460" components={{ b: <b /> }} />}
                                addon="9460"
                                value={valueFor9460}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9470')}>
                            <div style={{ width: '100%' }}>
                                <p>{<Trans i18nKey="e1b.h_9470" components={{ b: <b /> }} />}</p>
                                <LabeledInputWithSymbol
                                    label={t('e1b.l_9470')}
                                    addon="9470"
                                    value={valueFor9470}
                                    symbolIcon="€"
                                    readOnly={true}
                                    type="number"
                                    hideInfoIcon
                                />
                            </div>
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9480')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9480')}
                                addon="9480"
                                value={valueFor9480}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9490')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9490')}
                                addon="9490"
                                value={valueFor9490}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9500')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9500')}
                                addon="9500"
                                value={valueFor9500}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9134')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9134')}
                                addon="9134"
                                value={valueFor9134}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9135')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9135')}
                                addon="9135"
                                value={valueFor9135}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9505')} isDisabled>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9505')}
                                addon="9505"
                                value={valueFor9505}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9510')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9510')}
                                addon="9510"
                                value={valueFor9510}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9520')}
                                addon="9520"
                                value={valueFor9520}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9530')}
                                addon="9530"
                                value={valueFor9530}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_sum_expenses')}
                                value={totalCosts}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_9414')} isDisabled>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_9414')}
                                addon="9414"
                                value=""
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                        <E1bSection infoText={t('e1b.i_revenue_surplus')}>
                            <LabeledInputWithSymbol
                                label={t('e1b.l_revenue_surplus')}
                                value={excessCosts}
                                symbolIcon="€"
                                readOnly={true}
                                type="number"
                                hideInfoIcon
                            />
                        </E1bSection>
                    </>
                ) : (
                    <Placeholder.Paragraph rows={10} active />
                )}
            </Form>

            <div className="e1b-comment-section">
                {propertyForecastData?.l_link_to_documents && (
                    <p>
                        <strong>Link: </strong>
                        <a
                            href={propertyForecastData.l_link_to_documents}
                            target="_blank"
                            rel="noreferrer">
                            {propertyForecastData.l_link_to_documents}
                        </a>
                    </p>
                )}
                {propertyForecastData?.l_comment && (
                    <p>
                        <strong>Kommentar: </strong>
                        {propertyForecastData.l_comment}{' '}
                    </p>
                )}
            </div>
        </ContentPageBase>
    );
};

export default E1b;
