import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, useToaster, Message } from 'rsuite';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import sendRequest from '../utils/sendRequest';
import { getUserId, resetAll, setCurrentTaxsubject } from '../reducers/userSlice';
import { useTranslation } from 'react-i18next';
import constants from '../utils/constants';
import { getValidationSchemas } from '../utils/getValidationSchemas';
import LoginContainer from '../components/LoginContainer';
import LoginFooterWithButton from '../components/LoginFooterWithButton';
import { analytics } from '../utils/analytics';
import analyticsConstants from '../utils/analyticsConstants';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).login.objectTypeSchemaSpec;

    const [loginInfo, setLoginInfo] = useState();
    const formRef = useRef();
    const toaster = useToaster();

    const userId = useSelector((state) => state.user.userId);

    const { message } = useParams();

    useEffect(() => {
        analytics.trackPage(analyticsConstants.PAGES.LOGIN);
    }, []);

    useEffect(() => {
        if (message && message === 'unauthorized') {
            toaster.push(errorMessage(t('authentication.l_logout_message')), {
                duration: constants.MESSAGE_DURATION
            });
            dispatch(getUserId);
        } else if (message && message === 'alreadyVerified') {
            toaster.push(errorMessage(t('authentication.l_email_already_verified')), {
                duration: constants.MESSAGE_DURATION
            });
        } else if (message && !userId) {
            toaster.push(successMessage(t('authentication.i_email_verified')), {
                duration: constants.MESSAGE_DURATION
            });
        }
        if (message) navigate('/login');
    }, [message]);

    const errorMessage = (message) => {
        return (
            <Message showIcon type="error" closable>
                {message}
            </Message>
        );
    };

    const successMessage = (message) => {
        return (
            <Message showIcon type="success" closable>
                {message}
            </Message>
        );
    };

    const handleLogin = async () => {
        let hasError = await formRef.current.checkAsync().then((resp) => resp.hasError);
        if (hasError) {
            toaster.push(errorMessage(t('authentication.e_login_failed')), {
                duration: constants.MESSAGE_DURATION
            });
            return;
        }

        sendRequest('/login', 'post', loginInfo).then((resp) => {
            if (resp.message) {
                toaster.push(errorMessage(t('authentication.e_account_not_verified')), {
                    duration: constants.MESSAGE_DURATION
                });
            } else if (resp.success) {
                dispatch(resetAll());
                dispatch(getUserId());
            } else {
                toaster.push(errorMessage(t('authentication.e_login_failed')), {
                    duration: constants.MESSAGE_DURATION
                });
            }
        });
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') handleLogin();
    };

    if (userId) {
        sendRequest('/getTaxSubjects', 'get').then((resp) => {
            if (resp.filter((taxsubject) => taxsubject?.isTest === false).length > 0) {
                dispatch(setCurrentTaxsubject(resp[0]));
                navigate(constants.ROUTES.TAXSUBJECTS.DASHBOARD);
            } else {
                navigate(constants.ROUTES.HOME);
            }
        });
    } else
        return (
            <LoginContainer
                footer={
                    <LoginFooterWithButton
                        pText={t('authentication.l_not_yet_registered')}
                        btnText={t('authentication.l_register_now')}
                        onClick={() => navigate('/signup')}
                    />
                }>
                <h2>{t('authentication.h_login')}</h2>
                <Form
                    ref={formRef}
                    onKeyDown={handleKeyDown}
                    className="hide-error-msg"
                    onChange={(object) => setLoginInfo(object)}>
                    <Form.Group controlId="email">
                        <Form.Control
                            name="email"
                            type="email"
                            autoFocus
                            placeholder={t('authentication.l_email')}
                            rule={validationSchemas.email}
                        />
                    </Form.Group>

                    <Form.Group controlId="password">
                        <Form.Control
                            name="password"
                            type="password"
                            autoComplete="off"
                            placeholder={t('authentication.l_password')}
                            rule={validationSchemas.password}
                        />
                    </Form.Group>
                    <div className="link-container">
                        <a role="button" onClick={() => navigate('/forgot-password')}>
                            {t('authentication.l_forgot_password')} {userId}
                        </a>
                    </div>
                    <Form.Group>
                        <Button appearance="primary" onClick={handleLogin}>
                            {t('authentication.l_login')}
                        </Button>
                    </Form.Group>
                </Form>
            </LoginContainer>
        );
};

export default Login;
