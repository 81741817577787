import React, { useEffect } from 'react';
import { Drawer } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { closeHelpDrawer } from '../reducers/userSlice';
import { analytics } from '../utils/analytics';

const HelpDrawer = () => {
    const openDrawer = useSelector((state) => state.user.openDrawer);
    const dispatch = useDispatch();

    const title = openDrawer.title || 'Info';

    useEffect(() => {
        if (openDrawer.status) analytics.trackHelpPanel(openDrawer.title, true);
    }, [openDrawer]);

    return (
        <Drawer
            enforceFocus={false}
            backdrop={window.innerWidth <= 1024}
            open={openDrawer.status}
            onClose={() => {
                analytics.trackHelpPanel(openDrawer.title, false);
                dispatch(closeHelpDrawer());
            }}>
            <Drawer.Header>
                <Drawer.Title>{title}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <div dangerouslySetInnerHTML={{ __html: openDrawer.message }}></div>
            </Drawer.Body>
        </Drawer>
    );
};

export default HelpDrawer;
