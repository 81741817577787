import React, { useEffect, useState } from 'react';
import LabeledInputWithSymbol from '../../components/LabeledInputWithSymbol';
import ToggleInput from '../../components/ToggleInput';
import { Divider } from 'rsuite';
import { getValidationSchemas } from '../../utils/getValidationSchemas';
import { useTranslation } from 'react-i18next';
import DoubleFieldedInput from '../../components/DoubleFieldedInput';
import DropdownInput from '../../components/DropdownInput';
import { formatNumber } from '../../components/NumericFormatter';
import DatePickerInput from '../../components/DatePickerInput';

const Financing = ({ data, setData }) => {
    const { t } = useTranslation();
    const validationSchemas = getValidationSchemas(t).financing.objectTypeSchemaSpec;
    let purchase_ancillary_costs_incl_advertising_costs =
        +data?.l_real_estate_transfer_tax +
        +data?.l_land_register_registration_fee +
        +data?.l_trust_and_contract_establishment_fee +
        +(data?.l_notary_cash_expenses ? data?.l_notary_cash_expenses : 0) +
        +(data?.l_costs_advertising_misc ? data.l_costs_advertising_misc : 0) +
        data?.l_real_estate_broker_fee;
    data?.otherAncillaryCostPages &&
        data.otherAncillaryCostPages.map(
            (page) =>
                (purchase_ancillary_costs_incl_advertising_costs +=
                    +data['l_anc_cost_item_value_' + page.id])
        );
    let net_purchase_price = +data?.l_purchase_price * (data?.l_purchase_type === 1 ? 10 / 12 : 1);
    let total_loan = +purchase_ancillary_costs_incl_advertising_costs + net_purchase_price;
    const [fullLoan, setFullLoan] = useState(
        total_loan +
            +(data?.l_ancillary_financing_costs_misc !== undefined
                ? +data?.l_ancillary_financing_costs_misc
                : 0)
    );
    const [prevAnc, setPrevAnc] = useState(
        data?.l_ancillary_financing_costs_misc ? +data?.l_ancillary_financing_costs_misc : 0
    );

    let localData = data;

    useEffect(() => {
        if (
            !data?.l_total_loan_value ||
            (data?.l_total_loan_value - prevAnc).toFixed(2) === total_loan.toFixed(2)
        ) {
            setData((prev) => ({
                ...prev,
                l_total_loan_value:
                    total_loan +
                    (data?.l_ancillary_financing_costs_misc !== undefined
                        ? +data?.l_ancillary_financing_costs_misc
                        : 0)
            }));
        }
        setPrevAnc(
            data?.l_ancillary_financing_costs_misc !== undefined
                ? +data?.l_ancillary_financing_costs_misc
                : 0
        );
        setFullLoan(
            total_loan +
                +(data?.l_ancillary_financing_costs_misc
                    ? +data?.l_ancillary_financing_costs_misc
                    : 0)
        );
    }, [data?.l_ancillary_financing_costs_misc]);

    useEffect(() => {
        if (data.l_total_loan_value && data.financing_amount) {
            if (data.l_loan_down_payment !== data.l_total_loan_value - data.financing_amount)
                setData((prev) => ({
                    ...prev,
                    l_loan_down_payment: data.l_total_loan_value - data.financing_amount
                }));
        }
    }, [data?.l_total_loan_value]);

    return (
        <>
            <ToggleInput
                label={t('create_forecast.l_financing_via_loan')}
                infoText={t('create_forecast.i_financing_via_loan')}
                autoFocus
                defaultChecked={
                    data?.l_financing_via_loan !== undefined ? data.l_financing_via_loan : true
                }
                onToggle={(value) => setData((prev) => ({ ...prev, l_financing_via_loan: value }))}
            />
            {(data?.l_financing_via_loan === undefined || data.l_financing_via_loan) && (
                <>
                    <DatePickerInput
                        label={t('create_forecast.l_loan_start_date')}
                        type="date"
                        name="l_loan_start_date"
                        value={data?.l_loan_start_date}
                        onChange={(value) => {
                            setData((prev) => ({ ...prev, l_loan_start_date: value }));
                        }}
                        infoText={t('create_forecast.i_loan_start_date')}
                        rule={validationSchemas.l_loan_start_date}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_loan_term')}
                        type="number"
                        formatNumeric={false}
                        name="l_loan_term"
                        infoText={t('create_forecast.i_loan_term')}
                        symbolIcon="Monate"
                        rule={validationSchemas.l_loan_term}
                    />
                    <DropdownInput
                        label={t('create_forecast.l_loan_type')}
                        alignRight
                        name="l_loan_type"
                        infoText={t('create_forecast.i_loan_type')}
                        dropdownOptionReady
                        rule={validationSchemas.l_loan_type}
                        dropdownOption={[
                            { value: 0, label: t('create_forecast.l_loan_type_fixed') },
                            { value: 1, label: t('create_forecast.l_loan_type_variable') }
                        ]}
                        placeholder={t('global.l_please_select')}
                        value={data?.l_loan_type}
                    />
                    {data?.l_loan_type === 0 && (
                        <>
                            <LabeledInputWithSymbol
                                label={t('create_forecast.l_loan_duration_fixed_rate')}
                                type="number"
                                formatNumeric={false}
                                infoText={t('create_forecast.i_loan_duration_fixed_rate')}
                                symbolIcon="Monate"
                                name="l_loan_duration_fixed_rate"
                                isIndentedInput
                                rule={validationSchemas.l_loan_duration_fixed_rate}
                            />
                            <LabeledInputWithSymbol
                                label={t('create_forecast.l_loan_fixed_rate')}
                                type="number"
                                infoText={t('create_forecast.i_loan_fixed_rate')}
                                symbolIcon="%"
                                name="l_loan_fixed_rate"
                                isIndentedInput
                                rule={validationSchemas.l_loan_fixed_rate}
                            />
                        </>
                    )}
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_loan_adjustable_rate')}
                        type="number"
                        name="l_loan_adjustable_rate"
                        symbolIcon="%"
                        infoText={t('create_forecast.i_loan_adjustable_rate')}
                        rule={validationSchemas.l_loan_adjustable_rate}
                    />
                    <Divider />
                    <DoubleFieldedInput
                        label={t('create_forecast.l_costs_land_register_entry_lien')}
                        infoText={t('create_forecast.i_costs_land_register_entry_lien')}
                        name="l_costs_land_register_entry_lien"
                        fullValue={
                            (fullLoan -
                                +(data?.l_ancillary_financing_costs_misc !== undefined
                                    ? +data?.l_ancillary_financing_costs_misc
                                    : 0)) *
                            (1 -
                                (data?.l_loan_down_payment ? data.l_loan_down_payment : 0) /
                                    (fullLoan -
                                        (data?.l_ancillary_financing_costs_misc !== undefined
                                            ? +data?.l_ancillary_financing_costs_misc
                                            : 0)))
                        }
                        basePercent={
                            data?.l_costs_land_register_entry_lien_percent !== undefined
                                ? data.l_costs_land_register_entry_lien_percent
                                : 1.2
                        }
                        onChange={(item1, item2) => {
                            localData = {
                                ...localData,
                                l_costs_land_register_entry_lien: +item1,
                                l_costs_land_register_entry_lien_percent: +item2
                            };
                            setData(localData);
                        }}
                        percentRule={validationSchemas.l_costs_land_register_entry_lien_percent}
                        valueRule={validationSchemas.l_costs_land_register_entry_lien}
                    />
                    <DoubleFieldedInput
                        label={t('create_forecast.l_bank_processing_fee')}
                        infoText={t('create_forecast.i_bank_processing_fee')}
                        name="l_bank_processing_fee"
                        fullValue={
                            (fullLoan -
                                +(data?.l_ancillary_financing_costs_misc !== undefined
                                    ? +data?.l_ancillary_financing_costs_misc
                                    : 0)) *
                            (1 -
                                (data?.l_loan_down_payment ? data.l_loan_down_payment : 0) /
                                    (fullLoan -
                                        (data?.l_ancillary_financing_costs_misc !== undefined
                                            ? +data?.l_ancillary_financing_costs_misc
                                            : 0)))
                        }
                        basePercent={
                            data?.l_bank_processing_fee_percent !== undefined
                                ? data.l_bank_processing_fee_percent
                                : 1
                        }
                        onChange={(item1, item2) => {
                            localData = {
                                ...localData,
                                l_bank_processing_fee: +item1,
                                l_bank_processing_fee_percent: +item2
                            };
                            setData(localData);
                        }}
                        percentRule={validationSchemas.l_bank_processing_fee_percent}
                        valueRule={validationSchemas.l_bank_processing_fee}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_ancillary_financing_costs_misc')}
                        type="number"
                        infoText={t('create_forecast.i_ancillary_financing_costs_misc')}
                        symbolIcon="€"
                        name="l_ancillary_financing_costs_misc"
                        rule={validationSchemas.l_ancillary_financing_costs_misc}
                    />
                    <Divider />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_total_loan_value')}
                        type="number"
                        name="l_total_loan_value"
                        symbolIcon="€"
                        infoText={t('create_forecast.i_total_loan_value')}
                        helpText={
                            t('create_forecast.l_calculated_total_loan_value') +
                            ' ' +
                            formatNumber(fullLoan) +
                            '€'
                        }
                        rule={validationSchemas.l_total_loan_value}
                    />
                    <DoubleFieldedInput
                        label={t('create_forecast.l_loan_amount_in_percent_of_purchase_price')}
                        infoText={t('create_forecast.i_loan_amount_in_percent_of_purchase_price')}
                        name="financing_amount"
                        fullValue={data?.l_purchase_price}
                        basePercent={
                            data?.financing_amount_percent !== undefined
                                ? data.financing_amount_percent
                                : 90
                        }
                        onChange={(item1, item2) => {
                            localData = {
                                ...localData,
                                financing_amount: +item1,
                                financing_amount_percent: +item2,
                                l_loan_down_payment:
                                    data?.l_total_loan_value && item1
                                        ? data.l_total_loan_value - item1
                                        : 0
                            };
                            setData(localData);
                        }}
                        percentRule={validationSchemas.l_loan_down_payment_percent}
                        valueRule={validationSchemas.l_loan_down_payment}
                    />
                    <LabeledInputWithSymbol
                        label={t('create_forecast.l_loan_down_payment')}
                        type="number"
                        infoText={t('create_forecast.i_loan_down_payment')}
                        symbolIcon="€"
                        name="l_loan_down_payment"
                        disabled
                    />
                </>
            )}
        </>
    );
};

export default Financing;
