import constants from './constants';

export default (subscriptionType) => {
    const validSubscriptionTypes = [
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.basic.value,
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.pro.value,
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.manager.value,
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.advisor.value,
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.ultimate.value,
        constants.SUBSSCRIPTION_TYPE.VALID_SUBSCRIPTION_TYPE.enterprise.value
    ];
    return validSubscriptionTypes.includes(subscriptionType);
};
