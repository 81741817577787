import React from 'react';
import { Placeholder, Tooltip, Whisper } from 'rsuite';
import { formatNumber } from './NumericFormatter';
import { Bar } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

export const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowVerticalLines: false,
    legend: {
        display: false
    },
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        y: {
            ticks: {
                display: false
            }
        },
        x: {
            display: false,
            gridLines: {
                display: false
            }
        }
    }
};

const KpiItem = ({ label, symbol, mainValue, info, tooltip, graphData, removeDynamicColor }) => {
    const labelClassName = `value ${
        removeDynamicColor ? '' : mainValue >= 0 ? 'positive' : 'negative'
    }`;
    const localMainValue = isNaN(mainValue) || mainValue === false ? '-' : formatNumber(mainValue);
    return label || mainValue || graphData ? (
        <>
            <p className="label">
                {label}
                {tooltip && (
                    <Whisper
                        placement="bottomStart"
                        trigger="click"
                        speaker={<Tooltip>{tooltip}</Tooltip>}>
                        <FontAwesomeIcon icon={faCircleInfo} />
                    </Whisper>
                )}
            </p>
            <div className={labelClassName}>
                {mainValue !== undefined ? (
                    `${localMainValue} ${symbol && localMainValue !== '-' ? symbol : ''}`
                ) : (
                    <Placeholder.Paragraph rows={1} active />
                )}
            </div>
            {graphData && (
                <div className="graph-container">
                    {graphData ? (
                        <Bar options={barOptions} data={graphData} />
                    ) : (
                        <Placeholder.Graph active />
                    )}
                </div>
            )}
            {info && <div className="info">{info}</div>}
        </>
    ) : (
        <></>
    );
};

export default KpiItem;
