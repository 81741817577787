import React from 'react';
import { Input } from 'rsuite';
import { NumericFormat } from 'react-number-format';

const InputWithCustomOnChange = ({ ...props }) => {
    return (
        <Input
            ref={props.ref}
            {...props}
            onChange={(value, e) => {
                props.onChange(e);
            }}
        />
    );
};

const formatNumber2Dec = (number) => {
    return parseFloat(number).toLocaleString('de-DE', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
};

const formatNumber = (number) => {
    return parseFloat(number).toLocaleString('de-DE', {
        maximumFractionDigits: 2
    });
};

const NumericFormatter = ({
    value,
    ref,
    unlimitedDecimals = false,
    onChange,
    onFocus,
    ...props
}) => {
    return (
        <NumericFormat
            ref={ref}
            value={value}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={unlimitedDecimals ? undefined : 2}
            customInput={InputWithCustomOnChange}
            onValueChange={(values) => {
                onChange(values.value);
            }}
            onFocus={onFocus}
            {...props}
        />
    );
};

export { NumericFormatter, formatNumber, formatNumber2Dec };
